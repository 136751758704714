import { useQuery } from "@apollo/client";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { Context, useContext, useMemo, useState } from "react";
import Dropdown, {
  DropdownChoice,
  DropdownDirection,
} from "../common/Dropdown";
import ModalContext, { ModalContextValue } from "../modal/ModalContext";
import UrlShortener from "../url_shortener/UrlShortener";
import { UserFromJwt } from "../utils/auth";
import {
  forceOldInterface as forceOldInterfaceDefault,
  redirect as redirectDefault,
} from "../utils/location";
import routes from "../utils/routes";
import { GET_ME_QUERY, MeData } from "./StaffMenu.graphql";
import "./StaffMenu.scss";

interface StaffMenuProps {
  user: UserFromJwt;
}
interface StaffMenuDependencies {
  forceOldInterface?: () => void;
  redirect?: (url: string) => void;
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
}

interface StaffMenuChoice extends DropdownChoice {
  action: () => void;
}

/**
 * A staff menu. It is assumed that the passed-in user is a staff user
 */
const StaffMenu: React.FC<StaffMenuProps & StaffMenuDependencies> = ({
  user,
  forceOldInterface = forceOldInterfaceDefault,
  redirect = redirectDefault,
  useModalContext = useContext,
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const { data } = useQuery<MeData>(GET_ME_QUERY);
  const { setModal } = useModalContext(ModalContext);

  const choices = useMemo(() => {
    const djangoGlobalPerms = new Set(data?.me?.djangoGlobalPerms || []);
    const checkPerm = (perm: string) => {
      return user.is_superuser || djangoGlobalPerms.has(perm);
    };
    const possibleChoices: (StaffMenuChoice & { visible: boolean })[] = [
      {
        id: "force-old-interface",
        display: "Old Web Portal",
        action: forceOldInterface,
        visible: true,
      },
      {
        id: "companies-v2",
        display: "Companies v2 (beta)",
        action: () => redirect(routes.companiesV2),
        visible: true,
      },
      {
        id: "users",
        display: "Users",
        action: () => redirect(routes.staffUsers),
        visible:
          checkPerm("auth.view_user") || checkPerm("auth.view_staff_user"),
      },
      {
        id: "groups",
        display: "Groups",
        action: () => redirect(routes.staffGroups),
        visible: checkPerm("auth.change_group"),
      },
      {
        id: "logs",
        display: "Logs",
        action: () => redirect(routes.staffLogs),
        visible: checkPerm("logger.list_logitem"),
      },
      {
        id: "reports",
        display: "Reports",
        action: () => redirect(routes.staffReports),
        visible: checkPerm("reports.view_reports"),
      },
      {
        id: "folder-actions",
        display: "Folder Actions",
        action: () => redirect(routes.staffFolderActions),
        visible: checkPerm("folder_actions.add_folderaction"),
      },
      {
        id: "bulk-user-create",
        display: "Bulk User Create",
        action: () => redirect(routes.bulkUserCreatePage),
        visible: checkPerm("auth.add_users_in_bulk"),
      },
      {
        id: "shorten-url",
        display: "Shorten URL",
        action: () =>
          setModal({ title: "Shorten URL", content: <UrlShortener /> }),
        visible: true,
      },
      {
        id: "graphiql",
        display: "GraphiQL",
        action: () => redirect(routes.staffGraphiql),
        visible: true,
      },
      {
        id: "django-rq",
        display: "Django RQ",
        action: () => redirect(routes.staffDjangoRq),
        visible: user.is_superuser,
      },
      {
        id: "django-admin",
        display: "Django Admin",
        action: () => redirect(routes.staffDjangoAdmin),
        visible: user.is_superuser,
      },
      {
        id: "content-management",
        display: "Content Management",
        action: () => redirect(routes.contentManagement),
        visible:
          checkPerm("content.view_resourcedisplay") ||
          checkPerm("content.view_promo"),
      },
    ];
    return possibleChoices.filter((choice) => choice.visible);
  }, [user, forceOldInterface, redirect, data, setModal]);

  const staffMenuClassNames = classNames("StaffMenu", "SideNav__li", {
    "StaffMenu--active": dropdownActive,
  });
  return (
    <div className={staffMenuClassNames}>
      <Dropdown<StaffMenuChoice>
        choices={choices}
        setChoice={(choice) => choice.action()}
        dropdownActive={dropdownActive}
        setDropdownActive={setDropdownActive}
        direction={DropdownDirection.DOWN}
        dropdownSpacing="0.5rem"
      >
        <FontAwesomeIcon icon={faBars} />
        <span>Staff Menu</span>
      </Dropdown>
    </div>
  );
};
export default StaffMenu;
