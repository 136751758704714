import { gql } from "@apollo/client";
import { GraphQlObject } from "../../common/graphql";

export interface CreateApiToken extends GraphQlObject {
  id: string;
  token: string;
}

export interface CreateApiTokenData {
  createApiToken: CreateApiToken;
}

export interface CreateApiTokenVars {
  description: string;
}

export const CREATE_API_TOKEN_MUTATION = gql`
  mutation CreateApiToken($description: String) {
    createApiToken(description: $description) {
      id
      token
    }
  }
`;
