import React, { useEffect } from "react";
import { HOME_PAGE, Page, SETTINGS_PAGE } from "../../common/Breadcrumbs";
import Button, { ButtonStyle, ButtonType } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import { useMfaSettingsMeQuery } from "../../generated/graphql";
import Card from "../../layout/Card";
import routes from "../../utils/routes";
import settings from "../../utils/settings";
import "../api_tokens/ApiTokens.scss";
import BackupTokens from "./BackupTokens";
import "./MfaSettings.scss";
import MfaSettingsAuth0 from "./MfaSettingsAuth0";
import ResetMfa from "./ResetMfa";
import "./ResetMfa.scss";

interface MfaSettingsProps {
  setBreadcrumbs: (value: Page[]) => void;
}

export const MFA_SETTINGS_PAGES = [
  HOME_PAGE,
  SETTINGS_PAGE,
  {
    isCurrent: true,
    nameOrIcon: "Multi-Factor Authentication Settings",
  },
];

const MfaSettings: React.FC<MfaSettingsProps> = ({ setBreadcrumbs }) => {
  useEffect(() => {
    setBreadcrumbs(MFA_SETTINGS_PAGES);
  }, [setBreadcrumbs]);
  const { loading, error, data } = useMfaSettingsMeQuery();

  if (settings.features.authzAuthentication) {
    return (
      <MfaSettingsAuth0
        loading={loading}
        error={error}
        data={data}
        data-testid="mfa-settings-auth0"
      />
    );
  }
  if (data?.me.mfaSetupComplete === false) {
    return (
      <div data-testid="mfa-not-set-up">
        <Card title="Multi-factor Authentication Not Setup">
          <p>
            Multi-factor authentication is not setup on your account yet. Click{" "}
            <a href={routes.settingsMfaSetup}>here</a> to set it up now.
          </p>
          <ButtonGroup>
            <Button
              type={ButtonType.Back}
              style={ButtonStyle.TegusPrimary}
              role="button"
            />
          </ButtonGroup>
        </Card>
      </div>
    );
  }
  return (
    <div className="MfaSettings" data-testid="mfa-settings">
      <BackupTokens />
      <ResetMfa loading={loading} error={error} data={data} />
      <ButtonGroup>
        <Button
          type={ButtonType.Back}
          style={ButtonStyle.TegusSecondary}
          data-testid="separated-back-button"
        />
      </ButtonGroup>
    </div>
  );
};

export default MfaSettings;
