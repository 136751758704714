import { captureException as captureExceptionDefault } from "@sentry/browser";
import { getAuthHeaders } from "./auth";
import { SKIP_DIALOG_CONTEXT } from "./sentry";
import settings, { RELEASE_VERSION_UNKNOWN } from "./settings";

const LOGS_ENDPOINT = "/api/logs";
const SOURCE = settings.sentryReleaseVersion || RELEASE_VERSION_UNKNOWN;

export enum Severity {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

/**
 * Data that the logs endpoint expects in each request
 */
interface LogData {
  severity: Severity;
  source: string;
  action: string;
  message: string;
}

export type Logger = Record<
  Severity,
  (
    action: string,
    message: string,
    captureException?: typeof captureExceptionDefault
  ) => Promise<void>
>;

export class LoggerError extends Error {
  constructor(message: string) {
    super(`Logger error: ${message}`);
  }
}

/**
 * A helper method used to construct the methods of the Logger
 */
const log = async (
  severity: Severity,
  action: string,
  message: string,
  captureException = captureExceptionDefault
) => {
  const data: LogData = { severity, source: SOURCE, action, message };
  try {
    const resp = await fetch(LOGS_ENDPOINT, {
      method: "POST",
      credentials: "omit",
      headers: {
        ...getAuthHeaders(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!resp.ok)
      captureException(
        new LoggerError(`status code [${resp.status}]`),
        SKIP_DIALOG_CONTEXT
      );
  } catch (reason) {
    captureException(
      new LoggerError(`network error [${reason}]`),
      SKIP_DIALOG_CONTEXT
    );
  }
};

/**
 * Sends logs to the backend to be printed & ultimately available via Papertrail
 */
const logger: Logger = {
  info: (...args) => log(Severity.INFO, ...args),
  warning: (...args) => log(Severity.WARNING, ...args),
  error: (...args) => log(Severity.ERROR, ...args),
};

export default logger;
