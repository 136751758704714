import { FetchPolicy } from "@apollo/client";
import moment from "moment";
import {
  useContext as useContextDefault,
  useEffect as useEffectDefault,
  useState as useStateDefault,
} from "react";
import CacheExpiryContext from "./CacheExpiryContext";

/**
 * Builds a key string to be used in the cacheExpiry store
 *
 * @param queryName - Name of the query using this fetchPolicy
 * @param variables - The variables that are being used in the query
 *   (Note: include all possible properties)
 */
export const buildCacheKey = (
  queryName: string,
  variables: Record<string, any>
): string => {
  var cacheKey = `lastFetch-${queryName}`;
  Object.entries(variables).forEach(([key, value]) => {
    // Only use if the value is not an object (a primative). Safety check since
    // only primatives should be sent as variables to Apollo anyhow
    if (value !== Object(value)) {
      cacheKey += `-${key}:${value}`;
    }
  });
  return cacheKey;
};

/**
 * A hook to change the Apollo fetch policy based on a given time limit
 *
 * @param expiration - Time to expiry the cache
 * @param queryName - Name of the query using this fetchPolicy
 * @param variables - The variables that are being used in the query
 *   (Note: include all possible properties)
 */
const useCacheWithExpiry = (
  expiration: moment.Duration,
  queryName: string,
  variables: Record<string, any>,
  useContext = useContextDefault,
  useState = useStateDefault,
  useEffect = useEffectDefault
): FetchPolicy => {
  const { currentState, updateState } = useContext(CacheExpiryContext);
  const key = buildCacheKey(queryName, variables);

  const [fetchPolicy, setFetchPolicy] = useState<FetchPolicy>("cache-first");

  useEffect(() => {
    if (
      !(key in currentState) ||
      moment().diff(moment(currentState[key])) > expiration.asMilliseconds()
    ) {
      updateState({ [key]: moment().toISOString() });
      setFetchPolicy("network-only");
    } else {
      setFetchPolicy("cache-first");
    }
  }, [currentState, key, expiration, setFetchPolicy, updateState]);

  return fetchPolicy;
};

export default useCacheWithExpiry;
