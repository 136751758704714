import { useApolloClient } from "@apollo/client";
import { Formik } from "formik";
import { useCallback, useRef } from "react";
import {
  CreateShortUrlMutationVariables,
  MyShortUrlsDocument,
  useCreateShortUrlMutation,
} from "../generated/graphql";
import { noop } from "../utils/functools";
import CreateShortUrlForm from "./CreateShortUrlForm";
import { MY_SHORT_URLS_VARIABLES } from "./MyShortUrls";

export const CACHE_ARGS = {
  query: MyShortUrlsDocument,
  variables: MY_SHORT_URLS_VARIABLES,
};

export const LONG_URL_INITIAL_VALUE = "https://";

const CreateShortUrl: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const client = useApolloClient();
  const [mutationFn, mutationResult] = useCreateShortUrlMutation({
    onCompleted: (data) => {
      formRef.current?.reset();
      const cacheData = client.readQuery(CACHE_ARGS);
      if (cacheData == null) return;
      client.writeQuery({
        ...CACHE_ARGS,
        data: {
          myShortUrls: [
            data.createShortUrl!.shortUrl,
            ...cacheData.myShortUrls,
          ].slice(0, MY_SHORT_URLS_VARIABLES.count),
        },
      });
    },
  });

  const onSubmit = useCallback(
    async (values: CreateShortUrlMutationVariables) => {
      // Catch errors to prevent uncaught promise exceptions:
      // https://github.com/formium/formik/issues/1580
      await mutationFn({ variables: values }).catch(noop);
    },
    [mutationFn]
  );

  return (
    <div>
      <p>
        Create a shorter version of a URL which can be shared with other people.
      </p>
      <Formik<CreateShortUrlMutationVariables>
        initialValues={{ longUrl: LONG_URL_INITIAL_VALUE }}
        onSubmit={onSubmit}
      >
        {(props) => (
          <CreateShortUrlForm
            {...props}
            formRef={formRef}
            mutationError={mutationResult.error}
          />
        )}
      </Formik>
    </div>
  );
};

export default CreateShortUrl;
