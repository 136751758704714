import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import React, { Dispatch, SetStateAction } from "react";
import Button, { ButtonStyle, ButtonType } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import TextField from "../../forms/TextField";
import routes from "../../utils/routes";
import {
  CreateApiTokenData,
  CreateApiTokenVars,
  CREATE_API_TOKEN_MUTATION,
} from "./ApiTokensCreate.graphql";
import "./ApiTokensCreateForm.scss";

export interface ApiTokenCreateFormProps {
  setToken: Dispatch<SetStateAction<string | null>>;
}

const ApiTokensCreateForm: React.FC<ApiTokenCreateFormProps> = ({
  setToken,
}) => {
  const [createApiToken] = useMutation<CreateApiTokenData, CreateApiTokenVars>(
    CREATE_API_TOKEN_MUTATION
  );

  return (
    <div className="ApiTokensCreateForm">
      <Formik
        initialValues={{
          description: "",
          formError: "",
          token: "",
        }}
        onSubmit={async (values, actions) => {
          await createApiToken({
            variables: {
              description: values.description,
            },
          })
            .then((result) => {
              setToken(result!.data!.createApiToken.token);
            })
            .catch((e) => {
              let message = "There was a problem with creating the API token";
              if (e.graphQLErrors.length > 0) {
                const graphqlErrors = e.graphQLErrors.map(
                  (error: { message: string }) => error.message
                );
                message = graphqlErrors.join(". ").trim();
              }
              actions.setErrors({ formError: message });
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {(props) => (
          <Form>
            <TextField
              label="Description"
              name="description"
              type="text"
              required={false}
            />
            <ButtonGroup>
              <Button type={ButtonType.Submit} style={ButtonStyle.TegusPrimary}>
                Create API Token
              </Button>
              <Button
                linkTo={routes.settingsApiTokens}
                style={ButtonStyle.TegusSecondary}
                data-testid="button-cancel"
              >
                Cancel
              </Button>
            </ButtonGroup>
            {props.errors.formError ? (
              <div
                className="ApiTokensCreateForm__error"
                data-testid="form-error"
              >
                <p>{props.errors.formError}.</p>
              </div>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ApiTokensCreateForm;
