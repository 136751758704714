import { faCopy, faLink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context, useContext } from "react";
import FancyListItem from "../common/FancyListItem";
import Spinner, { ColourType } from "../common/Spinner";
import { useMyShortUrlsQuery } from "../generated/graphql";
import ToastContext, { ToastContextValue } from "../toast/ToastContext";
import { ToastStyle } from "../toast/ToastDisplay";
import { FA_ICON_DEFAULT_STYLE } from "../utils/icons";

export const MY_SHORT_URLS_VARIABLES = { count: 3 };

interface MyShortUrlsDeps {
  useToastContext?: (context: Context<ToastContextValue>) => ToastContextValue;
  clipboard?: Clipboard;
}

const MyShortUrls: React.FC<MyShortUrlsDeps> = ({
  useToastContext = useContext,
  clipboard = navigator.clipboard,
}) => {
  const { data, loading, error } = useMyShortUrlsQuery({
    variables: MY_SHORT_URLS_VARIABLES,
  });
  const { setToast } = useToastContext(ToastContext);

  if (loading) return <Spinner colour={ColourType.SECONDARY} size="3rem" />;

  if (error || !data || data.myShortUrls.length === 0) return null;

  return (
    <div>
      {data.myShortUrls.map((shortUrl) => {
        const action = () => {
          clipboard.writeText(shortUrl.shortUrl);
          setToast({ style: ToastStyle.Info, text: "Link copied" });
        };
        return (
          <FancyListItem
            key={shortUrl.id}
            icon={
              <FontAwesomeIcon icon={faLink} style={FA_ICON_DEFAULT_STYLE} />
            }
            action={action}
            text={shortUrl.shortUrl}
            subtext={[shortUrl.longUrl]}
            secondaryActions={[
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faCopy}
                    style={FA_ICON_DEFAULT_STYLE}
                  />
                ),
                id: "copy-short-url",
                action,
                tooltipText: "Copy Short URL",
              },
            ]}
          />
        );
      })}
    </div>
  );
};

export default MyShortUrls;
