import { UpdatePasswordMutationMutationVariables } from "../../generated/graphql";
import settings from "../../utils/settings";

export const ERROR_REQUIRED = "This field is required";
export const ERROR_BAD_CONFIRMATION =
  "The confirmation does not match the new password";

export interface UpdatePasswordValidationErrorProps
  extends Partial<UpdatePasswordMutationMutationVariables> {
  requirements?: string[];
}

export const validate = (
  values: UpdatePasswordMutationMutationVariables,
  passwordRequirements = settings.passwordRequirements
) => {
  const errors: UpdatePasswordValidationErrorProps = {};
  const requirementsErrors: string[] = [];

  // Basic validation
  if (!values.oldPassword) errors.oldPassword = ERROR_REQUIRED;
  if (!values.newPassword) errors.newPassword = ERROR_REQUIRED;
  if (!values.newPasswordConfirmed)
    errors.newPasswordConfirmed = ERROR_REQUIRED;
  if (
    values.newPassword &&
    values.newPasswordConfirmed &&
    values.newPassword !== values.newPasswordConfirmed
  )
    errors.newPasswordConfirmed = ERROR_BAD_CONFIRMATION;

  // Custom requirements from the backend. Don't overwrite an existing error
  passwordRequirements.forEach((requirement) => {
    if (!values.newPassword.match(requirement.regex)) {
      requirementsErrors.push(requirement.description);
      // Don't bother showing an error for the confirmation if the new password
      // doesn't meet requirements. It wouldn't be useful for the user
      delete errors["newPasswordConfirmed"];
    }
  });
  if (requirementsErrors.length) errors.requirements = requirementsErrors;

  return errors;
};
