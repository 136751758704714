import { Form, FormikProps } from "formik";
import { useState } from "react";
import Button, {
  ButtonState,
  ButtonStyle,
  ButtonType,
} from "../../common/buttons/Button";
import FormError from "../../forms/FormError";
import TextField from "../../forms/TextField";
import { UpdatePasswordMutationMutationVariables } from "../../generated/graphql";
import RequirementsCopy from "./RequirementsCopy";
import "./UpdatePasswordForm.scss";
import { UpdatePasswordValidationErrorProps } from "./validation";

interface UpdatePasswordFormProps
  extends FormikProps<
    UpdatePasswordMutationMutationVariables & UpdatePasswordValidationErrorProps
  > {
  mutationError?: string;
}

const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = ({
  mutationError,
  ...formikProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const textFieldProps = {
    type: "password",
    onChange: formikProps.handleChange,
    onBlur: formikProps.handleBlur,
    disabled: formikProps.isSubmitting,
    required: true,
  };
  const hasFormErrors = () => {
    return (
      formikProps.errors.requirements ||
      formikProps.errors.oldPassword ||
      formikProps.errors.newPassword ||
      formikProps.errors.newPasswordConfirmed
    );
  };
  return (
    <Form onSubmit={formikProps.handleSubmit}>
      <div className="UpdatePasswordForm">
        <div className="UpdatePasswordForm__fields">
          <TextField
            className="UpdatePasswordForm__old-password"
            value={formikProps.values.oldPassword}
            name="oldPassword"
            label="Old Password"
            {...textFieldProps}
          />
          <TextField
            value={formikProps.values.newPassword}
            name="newPassword"
            label="New Password"
            {...textFieldProps}
            showPassword={showPassword}
            toggleShowPassword={setShowPassword}
          />
          <TextField
            value={formikProps.values.newPasswordConfirmed}
            name="newPasswordConfirmed"
            label="New Password Confirmation"
            {...textFieldProps}
            showPassword={showPassword}
            toggleShowPassword={setShowPassword}
          />
        </div>
        <div className="UpdatePasswordForm__requirements">
          <h3>Password Requirements</h3>
          <RequirementsCopy
            dirty={
              formikProps.dirty && formikProps.values.newPassword ? true : false
            }
            errors={formikProps.errors as UpdatePasswordValidationErrorProps}
          />
        </div>
      </div>
      <FormError errorText={mutationError} />
      <Button
        className="mr-auto"
        state={
          formikProps.isSubmitting || hasFormErrors()
            ? ButtonState.Disabled
            : undefined
        }
        style={ButtonStyle.TegusPrimary}
        type={ButtonType.Submit}
      >
        Change Password
      </Button>
    </Form>
  );
};

export default UpdatePasswordForm;
