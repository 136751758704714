import { faGlobeAmericas, faSearch } from "@fortawesome/pro-solid-svg-icons";
import React, { ReactElement } from "react";
import { Category } from "../common/interfaces";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";
import "./CompaniesSidebar.scss";
import CompaniesSidebarCategoryList from "./CompaniesSidebarCategoryList";
import CompaniesSidebarSection from "./CompaniesSidebarSection";

interface CompaniesSidebarProps {
  loading: boolean;
  categories: Category[];
  onSelect: (categories: Category[]) => any;
  onExpand: (categories: Category[]) => any;
  search: ReactElement;
}

/**
 * Companies page sidebar component
 *
 * @param loading Whether sidebar categories list is loading
 * @param categories List of categories to display in categories section
 * @param onSelect Callback to call when a category is selected or deselected
 * @param onExpand Callback to call when a category is expanded or collapsed
 */
const CompaniesSidebar: React.FC<CompaniesSidebarProps> = ({
  loading,
  categories,
  onSelect,
  onExpand,
  search,
}) => {
  return (
    <div className="CompaniesSidebar">
      <div className="CompaniesSidebar__list-frame">
        <CompaniesSidebarSection
          title={STRINGS.sidebar.sections.search}
          icon={faSearch}
          expandable={true}
          defaultExpanded={true}
        >
          {search}
        </CompaniesSidebarSection>
        <CompaniesSidebarSection
          title={STRINGS.sidebar.sections.categories}
          icon={faGlobeAmericas}
          expandable={true}
          defaultExpanded={true}
        >
          <CompaniesSidebarCategoryList
            loading={loading}
            categories={categories}
            onSelect={onSelect}
            onExpand={onExpand}
          />
        </CompaniesSidebarSection>
      </div>
    </div>
  );
};

const MemoizedSidebar = React.memo(CompaniesSidebar);

export default MemoizedSidebar;
