import { ApolloError } from "@apollo/client";
import { default as React } from "react";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../../common/Spinner";
import { MfaSettingsMeQuery } from "../../generated/graphql";
import Card from "../../layout/Card";
import routes from "../../utils/routes";
import "../api_tokens/ApiTokens.scss";
import DisableMfaButton from "./DisableMfaButton";
import "./ResetMfa.scss";

interface ResetMfaProps {
  loading: boolean;
  error?: ApolloError;
  data?: MfaSettingsMeQuery;
}

export const RESET_COPY = `
  Reset your multi-factor authentication if you have a new phone. You can then
  set up multi-factor authentication on your new phone.`;

export const DISABLE_COPY = `
  Upon disabling multi-factor authentication, you will no longer have the
  increased security of requiring a one-time code for login.`;

const ResetMfa: React.FC<ResetMfaProps> = ({ loading, error, data }) => {
  if (loading || data?.me?.mfaSetupComplete === false) {
    return (
      <div className="UserSettings UserSettings--loading-spinner-container">
        <Spinner colour={ColourType.SECONDARY} size="3rem" />
      </div>
    );
  }
  if (!data?.me || error) {
    return (
      <Card title="Uh oh." data-testid="error-500">
        <p>
          Something went wrong when retrieving the page for resetting
          multi-factor authentication. Please try again later or{" "}
          <a href={routes.support}>contact support</a> for assistance.
        </p>
        <ButtonGroup>
          <Button linkTo={routes.settings} style={ButtonStyle.TegusPrimary}>
            Back
          </Button>
          <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
            Contact Us
          </Button>
        </ButtonGroup>
      </Card>
    );
  }
  return (
    <div className="ResetMfa">
      <Card tegus>
        <div className="row">
          <div className="ResetMfa__container col-lg">
            <Card data-testid="reset-card">
              <h4>Reset Multi-Factor Authentication</h4>
              <p>{RESET_COPY}</p>
              <ButtonGroup>
                <DisableMfaButton
                  userId={data.me.id}
                  style={ButtonStyle.TegusPrimary}
                  href={routes.settingsMfaSetup}
                  data-testid="reset-button"
                >
                  Reset
                </DisableMfaButton>
              </ButtonGroup>
            </Card>
          </div>
          {!data.me.mfaEnforced && (
            <div className="ResetMfa__container col-lg">
              <Card data-testid="disable-card">
                <h4>Disable Multi-Factor Authentication</h4>
                <p>{DISABLE_COPY}</p>
                <ButtonGroup>
                  <DisableMfaButton
                    userId={data.me.id}
                    style={ButtonStyle.TegusSecondary}
                    linkTo={routes.settings}
                    data-testid="disable-button"
                  >
                    Disable
                  </DisableMfaButton>
                </ButtonGroup>
              </Card>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ResetMfa;
