import { ApolloError, WatchQueryFetchPolicy } from "@apollo/client";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { HOME_PAGE, LOADING_PAGE, Page } from "../../common/Breadcrumbs";
import {
  BrowseQuery,
  FolderItemOrdering,
  useBrowseQuery,
} from "../../generated/graphql";
import useCacheWithExpiry from "../../utils/apollo/useCacheWithExpiry";
import { getWindowToContentBottomDistance } from "../../utils/document";
import { useEvent } from "../../utils/events";
import routes from "../../utils/routes";
import BrowseDisplay from "./BrowseDisplay";
import ItemOrderingWidget from "./ItemOrderingWidget";
import { BrowseFolderByPath } from "./utils/graphql";

export const FETCH_MORE_DISTANCE = 1500;
export const PAGE_SIZE = 50;
export const ROOT_FOLDER_PATH = "";

interface BrowseProps {
  setBreadcrumbs: (value: Page[]) => void;
  setFromFolderId: (folderId?: string) => void;
  emptyCopyOverride?: string;
  fetchPolicyOverride?: WatchQueryFetchPolicy | null;
  folderPathOverride?: string | null;
  breadcrumbsOverride?: Page[];
  titleOverride?: string;
  getDistance?: () => number | undefined;
  description?: string;
}

interface BrowseDeps {
  useBrowseQuery_?: typeof useBrowseQuery;
}

const Browse: React.FC<BrowseProps & BrowseDeps> = ({
  setBreadcrumbs,
  setFromFolderId,
  emptyCopyOverride,
  fetchPolicyOverride,
  folderPathOverride,
  breadcrumbsOverride,
  titleOverride,
  getDistance = getWindowToContentBottomDistance,
  useBrowseQuery_ = useBrowseQuery,
  description,
}) => {
  // This component is responsible for managing data and side effects for the browse
  // page. It works in tandem with the <BrowseDisplay /> component. To manage how data
  // is presented on the browse page, see the <BrowseDisplay /> component.
  const [fetchMoreError, setFetchMoreError] = useState<ApolloError>();
  const [prevCursor, setPrevCursor] = useState<string | undefined | null>(null);
  const [ordering, setOrdering] = useState(FolderItemOrdering.Name);
  const [reverseOrdering, setReverseOrdering] = useState(false);
  const { folderPath } = useParams<{ folderPath: string }>();
  const [itemToHighlightFiid] = useQueryParam("show", StringParam);

  const path = folderPathOverride || folderPath || ROOT_FOLDER_PATH;
  const baseQueryVariables = useMemo(() => {
    return { path, ordering, reverseOrdering, first: PAGE_SIZE };
  }, [path, ordering, reverseOrdering]);

  const fetchPolicy = useCacheWithExpiry(
    moment.duration({ seconds: 30 }),
    "folderByPath",
    { ...baseQueryVariables, after: prevCursor }
  );
  const { loading, error, data, fetchMore } = useBrowseQuery_({
    variables: baseQueryVariables,
    fetchPolicy: fetchPolicyOverride || fetchPolicy,
  });

  useEvent(
    window,
    "scroll",
    useCallback(() => {
      if (shouldFetchMore(data, loading, prevCursor, getDistance)) {
        // The checks in shouldFetchMore ensure that folder is a Folder
        const folder = data!.folderByPath!;
        const after = folder.items.pageInfo.endCursor;
        setPrevCursor(after);
        fetchMore({
          variables: { ...baseQueryVariables, after: after },
        }).catch((fetchMoreError_) => {
          setFetchMoreError(fetchMoreError_);
        });
      }
    }, [baseQueryVariables, data, fetchMore, getDistance, loading, prevCursor])
  );

  useEffect(() => {
    if (loading) {
      setBreadcrumbs([HOME_PAGE, LOADING_PAGE]);
    } else if (breadcrumbsOverride) {
      setBreadcrumbs(breadcrumbsOverride);
    } else if (error) {
      setBreadcrumbs([HOME_PAGE, { isCurrent: true, nameOrIcon: "Error" }]);
    } else if (!data?.folderByPath) {
      setBreadcrumbs([
        HOME_PAGE,
        { isCurrent: true, nameOrIcon: "Folder not Found" },
      ]);
    } else {
      setBreadcrumbs(getFolderBreadcrumbs(data.folderByPath));
    }
  }, [breadcrumbsOverride, data, error, loading, setBreadcrumbs]);

  useEffect(() => {
    if (data?.folderByPath && path !== ROOT_FOLDER_PATH) {
      setFromFolderId(data.folderByPath.id);
    }
  }, [data, path, setFromFolderId]);

  return (
    <BrowseDisplay
      loading={loading}
      error={error || fetchMoreError}
      folder={data?.folderByPath || undefined}
      user={data?.me || undefined}
      folderPath={path}
      itemOrderingWidget={
        <ItemOrderingWidget
          key="item-ordering-widget"
          ordering={ordering}
          reverseOrdering={reverseOrdering}
          setOrdering={setOrdering}
          setReverseOrdering={setReverseOrdering}
        />
      }
      itemToHighlightFiid={itemToHighlightFiid || undefined}
      emptyCopyOverride={emptyCopyOverride}
      titleOverride={titleOverride}
      description={description}
    />
  );
};

const shouldFetchMore = (
  data: BrowseQuery | undefined,
  loading: boolean,
  prevCursor: string | undefined | null,
  getDistance: () => number | undefined
): boolean => {
  // Return whether or not more folder items should be fetched
  const distance = getDistance();
  return (
    !loading &&
    distance !== undefined &&
    distance < FETCH_MORE_DISTANCE &&
    !!data?.folderByPath?.items.pageInfo.hasNextPage &&
    prevCursor !== data?.folderByPath?.items.pageInfo.endCursor
  );
};

const getFolderBreadcrumbs = (folder: BrowseFolderByPath) => {
  // Generate a list of breadcrumb pages for a folder
  const ancestorBreadcrumbs = folder.ancestorFolders
    .slice(0) // Create a copy of the array before reversing
    .reverse()
    .map((folder) => {
      return {
        isCurrent: false,
        nameOrIcon: folder.name,
        url: routes.companies(folder.path),
      };
    });
  return [
    HOME_PAGE,
    ...ancestorBreadcrumbs,
    { isCurrent: true, nameOrIcon: folder.name },
  ];
};

export default Browse;
