import Tippy from "@tippyjs/react";
import { useState } from "react";
import {
  CompaniesCategoryListQuery,
  CompaniesCompanyListQuery,
  useCompaniesResourceLazyQuery,
} from "../../generated/graphql";
import { GenericResource } from "../../home/common/utils/graphql";
import { UserForDl } from "../../utils/variants";
import { Category, CategoryMap, CompaniesRowData } from "../common/interfaces";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";
import { ROOT_CATEGORY_ID } from "./settings";

// Regexes
const REGEX_REGION = /^.* ([A-Z]{2})$/;

// Misc constants
const FOLDER_US_COMPANIES = "US Companies";
const FOLDER_CANADIAN_COMPANIES = "Canadian Companies";
const REGION_US = "US";
const REGION_CN = "CN";

/**
 * Create category FIID -> Category object mapping
 *
 * @param categories List of folders returned from API
 * @returns FIID -> Category object map
 */
export const createCategoryMap = (
  categories: CompaniesCategoryListQuery["categoryList"]
): CategoryMap => {
  const categoryMap: CategoryMap = {};

  categories.forEach((category) => {
    categoryMap[category.id] = {
      id: category.id,
      name: category.name,
      parentId: category.parentId,
      expanded: false,
      selected: false,
      indeterminate: false,
      details: {
        region: STRINGS.table.rows.noRowData,
        sector: STRINGS.table.rows.noRowData,
        industry: STRINGS.table.rows.noRowData,
      },
      linkedFileIds: category.linkedFileIds,
      items: [],
    };
  });

  return categoryMap;
};

/**
 * Create category tree structure to display in sidebar
 *
 * @param categoryMap FIID -> Category object map
 *
 * @returns Nested tree of Category objects
 */
export const createCategoryTree = (categoryMap: CategoryMap): Category[] => {
  const categoryTree: Category[] = [];

  Object.values(categoryMap).forEach((category) => {
    if (category.parentId === ROOT_CATEGORY_ID) {
      categoryTree.push(category);
      return;
    }
    const parent = categoryMap[category.parentId];
    parent.items = [...parent.items, category];
  });

  return categoryTree;
};

/**
 * Create list of all seleted folder IDs by traversing category tree and including
 * the ID for all child categories that are under selected parents
 *
 * @param categoryTree Nested tree of Category objects
 */
export const createSelectedFolderIdList = (
  categoryTree: Category[]
): string[] => {
  const allSelectedIds: string[] = [];

  const traverse = (category: Category, ancestorSelected: boolean) => {
    const selected = ancestorSelected || category.selected;
    if (selected) {
      allSelectedIds.push(category.id);
    }
    category.items.forEach((subcategory) => {
      traverse(subcategory, selected);
    });
  };

  categoryTree.forEach((category) => {
    traverse(category, false);
  });

  return [...new Set(allSelectedIds)];
};

/**
 * Format and add tooltip to column header
 *
 * @param name Column name
 * @param tooltip Column tooltip
 *
 * @returns Formatted column header content
 */
export const formatColumnHeader = (name: string, tooltip: string) => {
  return (
    <Tippy content={tooltip}>
      <span className="CompaniesTable__header-text">{name}</span>
    </Tippy>
  );
};

/**
 * Format number as currency with specified unit
 *
 * - Uses parentheses for negative values
 * - Appends unit if value is nonzero
 * - Returns no row data value if value is null
 *
 * @param value Number value to format
 * @param unit Unit the value is in (eg. M for millions)
 *
 * @returns Formatted value
 */
export const formatCurrency = (value: number | null, unit: string) => {
  if (value === null) {
    return STRINGS.table.rows.noRowData;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    signDisplay: "never",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const formattedValue =
    value === 0
      ? `${formatter.format(value)}`
      : `${formatter.format(value)} ${unit}`;

  return value < 0 ? `(${formattedValue})` : formattedValue;
};

/**
 * Format value of updated, or return empty value if update date unavailable
 *
 * @param value Value of updated field to format
 *
 * @returns Formatted value for updated
 */
export const formatUpdated = (value: string): string => {
  if (value === STRINGS.table.rows.noRowData) {
    return STRINGS.table.rows.noRowData;
  }

  return `${new Date(value).toLocaleDateString("en-us", {
    year: "numeric",
    day: "numeric",
    month: "short",
  })}`;
};

/**
 * Parse companies list returned from API into form displayable in companies table
 *
 * Note: some fields use temporary source of data until proper metadata can be
 * added. If data isn't available, field will be blank ("-").
 *
 * - Region: 'US' if root is US companies, 'CN' if root is Canadian Companies,
 *           otherwise last 2 characters of model name (if 3rd last char is space)
 * - Sector: Name of 2nd level folder model is in
 * - Industry: Name of 3rd level folder model is in
 *
 * @param companies List of all companies
 * @param categories FIID -> Category object map containing folder details
 * @param selectedCategoryIds List of selected category IDs
 *
 * @returns List of parsed companies
 */
export const parseData = (
  companies: CompaniesCompanyListQuery["companiesList"]["companies"],
  categories: CategoryMap,
  selectedCategoryIds: string[]
): CompaniesRowData[] => {
  const companiesData: CompaniesRowData[] = [];
  const selectedCategories: { [key: string]: boolean } = {};
  const linkedFiles: { [key: string]: boolean } = {};

  // Setup category filtering
  selectedCategoryIds.forEach((id) => {
    selectedCategories[id] = true;
    categories[id].linkedFileIds.forEach((id) => {
      linkedFiles[id] = true;
    });
  });

  companies.forEach((company) => {
    // Apply category filtering if any categories are selected
    if (
      selectedCategoryIds.length &&
      !linkedFiles[company.id] &&
      !selectedCategories[company.folderId]
    ) {
      return;
    }

    // Get precomputed folder details
    const details = categories[company.folderId]
      ? categories[company.folderId].details
      : undefined;

    // Get region
    let region = details ? details.region : STRINGS.table.rows.noRowData;
    if (region === STRINGS.table.rows.noRowData) {
      const match = company.name.match(REGEX_REGION);
      if (match) {
        region = match[1];
      }
    }

    // Build row data object
    companiesData.push({
      id: company.id,
      name: company.name,
      ticker: company.ticker ? company.ticker : STRINGS.table.rows.noRowData,
      region: region,
      updated: company.updated ? company.updated : STRINGS.table.rows.noRowData,
      updateType: company.updateType
        ? company.updateType
        : STRINGS.table.rows.noRowData,
      period: company.period ? company.period : STRINGS.table.rows.noRowData,
      revenue: company.revenue !== undefined ? company.revenue : null,
      sector: details ? details.sector : STRINGS.table.rows.noRowData,
      industry: details ? details.industry : STRINGS.table.rows.noRowData,
      watchListed: company.watchListed,
    });
  });

  return companiesData;
};

/**
 * Traverse category tree to generate region / sector / industry for each category
 *
 * Updates categories with generated details
 *
 * @param categoryTree Nested tree of Category objects
 */
export const updateCategoryDetails = (categoryTree: Category[]) => {
  const traverse = (
    category: Category,
    parentRegion: string,
    parentSector: string,
    parentIndustry: string,
    level: number
  ) => {
    let region = parentRegion;
    if (level === 0) {
      if (category.name === FOLDER_US_COMPANIES) {
        region = REGION_US;
      } else if (category.name === FOLDER_CANADIAN_COMPANIES) {
        region = REGION_CN;
      }
    }
    const sector = level === 1 ? category.name : parentSector;
    const industry = level === 2 ? category.name : parentIndustry;

    category.details = {
      region: region,
      sector: sector,
      industry: industry,
    };

    category.items.forEach((subcategory) => {
      traverse(subcategory, region, sector, industry, level + 1);
    });
  };

  categoryTree.forEach((category) => {
    traverse(
      category,
      STRINGS.table.rows.noRowData,
      STRINGS.table.rows.noRowData,
      STRINGS.table.rows.noRowData,
      0
    );
  });
};

/**
 * Check each category in the tree to see if it should be set as indeterminate
 *
 * Sets indeterminate for each category
 *
 * A category is indetermiate if it isn't selected but one or more child
 * categories are selected or are indeterminate
 *
 * @param categoryTree Nested tree of Category objects
 */
export const updateIndeterminate = (categoryTree: Category[]) => {
  const traverse = (category: Category): boolean => {
    category.indeterminate = false;

    if (category.selected) {
      return true;
    }
    if (category.items.length === 0) {
      return false;
    }

    for (const subcategory of category.items) {
      if (traverse(subcategory)) {
        category.indeterminate = true;
        break;
      }
    }

    return category.indeterminate;
  };

  categoryTree.forEach((category) => {
    category.indeterminate = category.selected ? false : traverse(category);
  });
};

/**
 * Hook to get resource object and the current user from backend and then call
 * specified callback with resource object and user as parameters
 *
 * @param callback Callback to call with results of Resource query
 *
 * @returns Function to call with resource ID
 */
export const useResourceQuery = (
  callback: (resource: GenericResource, user: UserForDl) => any
) => {
  const [resource, setResource] = useState<GenericResource>();
  const [user, setUser] = useState<UserForDl>();

  const [getResourceAndUser] = useCompaniesResourceLazyQuery({
    onCompleted: (data) => {
      if (data.file && data.me) {
        setResource(data.file);
        setUser(data.me);
        callback(data.file, data.me);
      }
    },
  });

  return (id: string) => {
    if (resource && resource.id === id && user) {
      callback(resource, user);
      return;
    }
    getResourceAndUser({ variables: { id: id } });
  };
};
