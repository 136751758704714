import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { HOME_PAGE, Page } from "../common/Breadcrumbs";
import Button, { ButtonStyle, ButtonType } from "../common/buttons/Button";
import ButtonGroup from "../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../common/Spinner";
import {
  ResourceDisplayResourceType,
  useResourceDisplayQuery,
} from "../generated/graphql";
import Card from "../layout/Card";
import routes from "../utils/routes";
import "./Resources.scss";
import { ColumnSize, DisplayStyle } from "./ResourcesDisplay";
import ResourcesSection from "./ResourcesSection";
import { ResourcesFolderIds } from "./utils/graphql";
import { RESOURCE_FOLDER_ID_TO_DETAILS } from "./utils/resources";

export const RESOURCES_PAGES = [
  HOME_PAGE,
  {
    isCurrent: true,
    nameOrIcon:
      RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.toolkitAndGuides]
        .overrideName!,
  },
];

export interface ResourcesProps {
  setBreadcrumbs: (value: Page[]) => void;
}

const Resources: React.FC<ResourcesProps> = ({ setBreadcrumbs }) => {
  useEffect(() => {
    setBreadcrumbs(RESOURCES_PAGES);
  }, [setBreadcrumbs]);

  const { data, loading, error } = useResourceDisplayQuery({
    variables: {
      count: 50,
    },
  });

  const [itemToHighlightFiid] = useQueryParam("show", StringParam);

  if (loading) {
    return (
      <div
        className="Resources Resources--loading"
        data-testid="resources-spinner"
      >
        <Spinner colour={ColourType.SECONDARY} size="3rem" />
      </div>
    );
  }

  if (!data || error) {
    return (
      <Card title="Uh oh." data-testid="error-500">
        <p>
          Something went wrong when retrieving the resources page. Please try
          again later or <Link to={routes.support}>contact support</Link> for
          assistance.
        </p>
        <ButtonGroup>
          <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
          <Button linkTo={routes.support} style={ButtonStyle.TegusSecondary}>
            Contact Us
          </Button>
        </ButtonGroup>
      </Card>
    );
  }

  const toolkitResources = data.resourceDisplays.filter(
    (rd) => rd.resourceType === ResourceDisplayResourceType.Toolkit
  );
  const dropInResources = data.resourceDisplays.filter(
    (rd) => rd.resourceType === ResourceDisplayResourceType.DropinTemplate
  );

  return (
    <div className="Resources">
      <div
        className="Resources__container container"
        data-testid="resources-section"
      >
        <Card
          tegus
          title={
            RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.toolkitAndGuides]
              .name
          }
        >
          <p>
            {
              RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.toolkitAndGuides]
                .description
            }
          </p>
          <ResourcesSection
            resourceDisplays={toolkitResources}
            displayInfo={{
              displayStyle: DisplayStyle.STACK_ROW,
              columnSize: ColumnSize.LARGE,
            }}
            showMessageButtons={false}
            testId="toolkit-and-guides"
            itemToHighlightFiid={itemToHighlightFiid || undefined}
          />
        </Card>
      </div>
      <div
        className="Resources__container container"
        data-testid="resources-section"
      >
        <Card
          tegus
          title={
            RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.templates].name
          }
        >
          <p>
            {
              RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.templates]
                .description
            }
          </p>
          <ResourcesSection
            resourceDisplays={dropInResources}
            displayInfo={{
              displayStyle: DisplayStyle.HIDE_ROW,
              columnSize: ColumnSize.DEFAULT,
            }}
            buttonInfo={{
              style: ButtonStyle.TegusPrimary,
              linkTo:
                RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.templates].url,
              children: `View All ${
                RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.templates].name
              }`,
            }}
            showMessageButtons={false}
            testId="templates"
            itemToHighlightFiid={itemToHighlightFiid || undefined}
          />
        </Card>
      </div>
    </div>
  );
};

export default Resources;
