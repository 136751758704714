/**
 * Wait for specified time
 *
 * @param ms Time in ms to wait for
 * @returns Resolved promise after time elapsed
 */
export const wait = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
