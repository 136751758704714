import React from "react";
import {
  DriversWorksheetsKind,
  PeriodOrderKind,
  ThemeKind,
} from "../generated/graphql";

// Copy related to contact us

export const COVERAGE_CRITERIA = (
  <div>
    <p>
      Canalyst's goal is to cover the 10,000 most relevant companies in the
      world. We do not have hard and fast coverage rules, but we do take a
      variety of factors—including revenue, market cap, liquidity, index
      membership and other quantifiable metrics—into consideration when we model
      the public company universe.
    </p>
    <p>
      We welcome indications of interest from clients as to what we should
      prioritize next, so please contact us at{" "}
      <a href="mailto:support@canalyst.com">support@canalyst.com</a> to make any
      coverage suggestions.
    </p>
    <p>
      Please note: Our new issue coverage currently supports revenue-generating
      US domestic IPOs and SPACs with announced deals post-S4 filing. We do not
      yet cover Global IPOs but hope to formally announce coverage of these
      soon.
    </p>
  </div>
);

export const MODEL_REQUEST_RESPONSE_TIME = (
  <p>
    Note: Turnaround time on model requests may be longer than usual during
    earnings seasons due to high volume of earnings reports.
  </p>
);

export const ALT_CONTACT = (
  <p>
    You can also always reach us by phone at{" "}
    <a href="tel:+18008048940">+1 800 804 8940</a> or by email at{" "}
    <a href="mailto:support@canalyst.com">support@canalyst.com</a>.
  </p>
);

export const EMPTY_WATCH_LIST_COPY = `Add companies to your watchlist to
prioritize updates during earnings and receive notifications when there is new
data available.`;

// Copy related to variants

export const PERIOD_ORDER_KIND_DISPLAY_MAP: Record<PeriodOrderKind, string> = {
  CHRONOLOGICAL: "Chronological (QQQQY QQQQY)",
  ANNUAL_GROUPED: "Annual-Grouped (QQQQ YY)",
};

export const DRIVERS_WORKSHEETS_KIND_DISPLAY_MAP: Record<
  DriversWorksheetsKind,
  string
> = {
  NULL: "No Drivers Worksheet",
  STANDARD_FCF: "Drivers Worksheet",
};

export const THEME_KIND_DISPLAY_MAP: Record<ThemeKind, string> = {
  CANALYST:
    "Canalyst (drivers: red, historical values & forecast formulas: black, annual columns: blue background)",
  CLASSIC:
    "Classic (drivers & historical hardcodes: blue, formulas: black, links: green, annual columns: grey background)",
};

export const PERIOD_ORDER_DESCRIPTION = `
  Choose the order in which the company's fiscal periods are displayed in the model
  `;

export const DRIVERS_WORKSHEETS_DESCRIPTION = `
  Add a Drivers Worksheet, where all model drivers and key outputs are centralized
  `;

export const THEME_DESCRIPTION = `Choose the color scheme for your model`;
