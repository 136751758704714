import { Context, useContext } from "react";
import Button, { ButtonStyle } from "../common/buttons/Button";
import ButtonGroup from "../common/buttons/ButtonGroup";
import SimpleTable from "../common/SimpleTable";
import ModalContext from "../modal/ModalContext";
import "./UserCreationErrors.scss";

const FAILED_VALIDATION_DESCRIPTION =
  "The following users failed validation. As a result, no users from the CSV have been created.";
const FIX_ERRORS_DESCRIPTION =
  "Please correct all errors and re-upload the complete CSV to proceed with user creation.";
const BACK_BUTTON_COPY = "Back";
const TABLE_HEADER = ["Row", "First Name", "Last Name", "Email", "Error"];

export interface UserCreationValidationError {
  loc: string[];
  msg: string;
  type: string;
}

export interface UserCreateValidationErrorRow {
  rowNumber: number;
  firstName: string;
  lastName: string;
  email: string;
  rowErrors: UserCreationValidationError[];
}

interface UserCreationErrorsProps {
  errors: UserCreateValidationErrorRow[];
}

interface UserCreationErrorsDeps {
  useContext_?: <T>(context: Context<T>) => T;
}

/**
 * Component to display errors from bulk user creation
 *
 * @param errors - The errors returned from bulk user creation
 */
const UserCreationErrors: React.FC<
  UserCreationErrorsProps & UserCreationErrorsDeps
> = ({ errors, useContext_ = useContext }) => {
  const { clearModal } = useContext_(ModalContext);

  const values = errors.map((error) => {
    const errLines = error.rowErrors.map((err) => (
      <p className="UserCreationErrors__error">
        {err.loc[0]}: {err.msg}
      </p>
    ));
    return [
      error.rowNumber,
      error.firstName,
      error.lastName,
      error.email,
      errLines,
    ];
  });

  return (
    <div className="UserCreationErrors">
      <div className="UserCreationErrors__description">
        {FAILED_VALIDATION_DESCRIPTION}
      </div>
      <div>{FIX_ERRORS_DESCRIPTION}</div>
      <SimpleTable header={TABLE_HEADER} values={values} />
      <div className="UserCreationErrors__footer">
        <ButtonGroup>
          <Button style={ButtonStyle.TegusSecondary} onClick={clearModal}>
            {BACK_BUTTON_COPY}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default UserCreationErrors;
