/*
 * Companies page global settings
 */

/**
 * Automatic companies table data refresh interval (5 minutes)
 */
export const REFRESH_INTERVAL = 5 * 60 * 1000;

/**
 * ID used as the parentID for root categories
 */
export const ROOT_CATEGORY_ID = "cbfe327d-39a5-414a-b797-abd8d325386d";
