import React, { useMemo, useReducer } from "react";
import CacheExpiryContext, { CacheExpiry } from "./CacheExpiryContext";

export const reducer = (
  state: Record<string, string>,
  updateValue: CacheExpiry
) => {
  return { ...state, ...updateValue };
};

const CacheExpiryProvider: React.FC<{
  initialState?: CacheExpiry;
}> = ({ initialState = {}, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const cacheExpiryValue = useMemo(() => {
    return {
      currentState: state,
      updateState: dispatch,
    };
  }, [state]);

  return (
    <CacheExpiryContext.Provider value={cacheExpiryValue}>
      {children}
    </CacheExpiryContext.Provider>
  );
};

export default CacheExpiryProvider;
