import React, { useMemo } from "react";
import ChoiceField from "../forms/ChoiceField";
import { kebab } from "../utils/strings";
import "./VariantDimensionsDropdowns.scss";
import {
  getVariantDimensionsChoices,
  isVariantDimensionField,
  VariantDimensionsChoices,
  VARIANT_DIMENSION_METADATA,
} from "./variants";

interface VariantDimensionsDropdownsProps {
  useDescriptions?: boolean;
  choicesOverride?: VariantDimensionsChoices;
  djangoGlobalPerms?: string[];
}

interface VariantDimensionsDropdownsDeps {
  getVariantDimensionsChoices_?: typeof getVariantDimensionsChoices;
}

const VariantDimensionsDropdowns: React.FC<
  VariantDimensionsDropdownsProps & VariantDimensionsDropdownsDeps
> = ({
  useDescriptions = true,
  choicesOverride,
  djangoGlobalPerms = [],
  getVariantDimensionsChoices_ = getVariantDimensionsChoices,
}) => {
  const choices =
    choicesOverride ||
    getVariantDimensionsChoices_({ djangoGlobalPerms: djangoGlobalPerms });

  const fields = useMemo(() => {
    return Object.entries(choices).reduce<React.ReactElement[]>(
      (acc, value) => {
        const [dimension, dimChoices] = value;
        dimChoices.sort((a, b) => a.id.localeCompare(b.id));

        if (isVariantDimensionField(dimension))
          acc.push(
            <div className={`VariantDimensionsDropdowns__${kebab(dimension)}`}>
              <ChoiceField
                name={dimension}
                label={VARIANT_DIMENSION_METADATA[dimension].displayName}
                descriptions={
                  useDescriptions
                    ? [VARIANT_DIMENSION_METADATA[dimension].description]
                    : []
                }
                choices={dimChoices}
              />
            </div>
          );
        return acc;
      },
      []
    );
  }, [useDescriptions, choices]);

  return <div className="VariantDimensionsDropdowns">{fields}</div>;
};

export default VariantDimensionsDropdowns;
