import React from "react";
import Card, { MaxWidthType } from "../layout/Card";
import routes from "../utils/routes";
import Button, { ButtonStyle, ButtonType } from "./buttons/Button";
import ButtonGroup from "./buttons/ButtonGroup";

const Http404: React.FC = () => {
  return (
    <Card
      title={
        <>
          <span className="font-weight-bold">404.</span> That's an error.
        </>
      }
      withMargins
      maxWidth={MaxWidthType.standard}
    >
      <p>
        The requested URL{" "}
        <span className="font-italic">{window.location.pathname}</span> was not
        found on the server. That's all we know.
      </p>
      <ButtonGroup>
        <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
        <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
          Contact Us
        </Button>
      </ButtonGroup>
    </Card>
  );
};

export default Http404;
