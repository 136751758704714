import {
  DriversWorksheetsKind,
  PeriodOrderKind,
  ResourceVariant,
  ThemeKind,
} from "../generated/graphql";
import settings, { Settings } from "../utils/settings";
import { kebab } from "../utils/strings";
import {
  DRIVERS_WORKSHEETS_DESCRIPTION,
  DRIVERS_WORKSHEETS_KIND_DISPLAY_MAP,
  PERIOD_ORDER_DESCRIPTION,
  PERIOD_ORDER_KIND_DISPLAY_MAP,
  THEME_DESCRIPTION,
  THEME_KIND_DISPLAY_MAP,
} from "./copy";
import { DropdownChoice } from "./Dropdown";

export const THEME_PERMISSION = "files.view_theme";

// These constants should be updated whenever a new variant dimension is added
export const ORIGINAL_VARIANT_COMBO: VariantCombination = {
  periodOrder: PeriodOrderKind.Chronological,
  driversWorksheets: DriversWorksheetsKind.Null,
  theme: ThemeKind.Canalyst,
} as const;

const VARIANT_DIMENSION_ENUMS = [
  PeriodOrderKind,
  DriversWorksheetsKind,
  ThemeKind,
];

export const VARIANT_DIMENSION_METADATA: Record<
  VariantDimensionField,
  DimensionMetadata
> = {
  periodOrder: {
    type: PeriodOrderKind,
    displayMap: PERIOD_ORDER_KIND_DISPLAY_MAP,
    description: PERIOD_ORDER_DESCRIPTION,
    displayName: "Period Order",
  },
  driversWorksheets: {
    type: DriversWorksheetsKind,
    displayMap: DRIVERS_WORKSHEETS_KIND_DISPLAY_MAP,
    description: DRIVERS_WORKSHEETS_DESCRIPTION,
    displayName: "Drivers Worksheet",
  },
  theme: {
    type: ThemeKind,
    displayMap: THEME_KIND_DISPLAY_MAP,
    description: THEME_DESCRIPTION,
    displayName: "Color Scheme",
  },
} as const;

export const VARIANT_DIMENSION_FIELDS: VariantDimensionField[] =
  // We are knowingly using `as` here to cast the string return from Object.keys as
  // VariantDimensionKeyKind. This is safe because the keys from a constant that we know
  // doesn't have any unexpected fields
  Object.keys(ORIGINAL_VARIANT_COMBO).map(
    (value) => value as VariantDimensionField
  );

export type VariantDimensionField =
  | "periodOrder"
  | "driversWorksheets"
  | "theme";
export type VariantDimensionValue =
  | PeriodOrderKind
  | DriversWorksheetsKind
  | ThemeKind;
export type VariantCombination = Pick<ResourceVariant, VariantDimensionField>;

type VariantDimensionType =
  | typeof PeriodOrderKind
  | typeof DriversWorksheetsKind
  | typeof ThemeKind;

export type AvailableDimensions = Record<VariantDimensionField, Set<string>>;
export type VariantDimensionsChoices = Record<
  VariantDimensionField,
  DropdownChoice[]
>;

export interface DimensionMetadata {
  type: VariantDimensionType;
  displayMap: Record<string, string>;
  description: string;
  displayName: string;
}

export interface CustomVariantChoices {
  periodOrder: DropdownChoice;
  driversWorksheets: DropdownChoice;
  theme: DropdownChoice;
}

/**
 * Type guard checking a value is a variant dimension field
 *
 * @param value - Value to be checked
 */
export const isVariantDimensionField = (
  value: string
): value is VariantDimensionField => {
  // Purposefully use `as` here so Typescript allows us to check if value is in
  // VARIANT_DIMENSION_FIELDS
  return VARIANT_DIMENSION_FIELDS.includes(value as VariantDimensionField);
};

/**
 * Type guard checking a value is a variant dimension value
 *
 * @param value - Value to be checked
 */
export const isVariantDimensionValue = (
  value: string
): value is VariantDimensionValue => {
  return VARIANT_DIMENSION_ENUMS.some((dimension) => {
    return Object.values(dimension).includes(value);
  });
};

/**
 * Create a DropdownChoice from a variant dimension enum
 *
 * @param kind - A variant dimension enum value
 * @param mapping - A mapping of internal to external value for a variant dimension
 */
export const kindToDropdown = <T extends string>(
  kind: T,
  mapping: Record<T, string>
): DropdownChoice => {
  return {
    id: kind,
    display: mapping[kind],
  };
};

/**
 * Get the DropdownChoices for a variant dimension
 *
 * @param variantDimEnum - Variant dimension enum
 * @param mapping - A mapping of internal to external value for a variant dimension
 */
export const variantDimensionToChoices = (
  variantDimEnum: Record<string, VariantDimensionValue>,
  mapping: Record<string, string>
): DropdownChoice[] => {
  const choices = [];
  for (const value in variantDimEnum) {
    if (
      variantDimEnum.hasOwnProperty(value) &&
      isVariantDimensionValue(variantDimEnum[value])
    )
      choices.push(kindToDropdown(variantDimEnum[value], mapping));
  }
  return choices;
};

/**
 * Gets dropdown choice values for each value in each variant dimension
 *
 * @param djangoGlobalPerms - A user's djangoGlobalPerms
 * @param dimensionMetadataMap - Map of variant dimension to metadata
 */
export const getVariantDimensionsChoices = ({
  djangoGlobalPerms = [],
  dimensionMetadataMap = VARIANT_DIMENSION_METADATA,
  settings_ = settings,
}: {
  djangoGlobalPerms?: string[];
  dimensionMetadataMap?: typeof VARIANT_DIMENSION_METADATA;
  settings_?: Settings;
} = {}): VariantDimensionsChoices => {
  const choices = Object.create(Object.prototype);

  for (const dimension in dimensionMetadataMap) {
    if (
      dimensionMetadataMap.hasOwnProperty(dimension) &&
      isVariantDimensionField(dimension)
    )
      choices[dimension] = variantDimensionToChoices(
        dimensionMetadataMap[dimension].type,
        dimensionMetadataMap[dimension].displayMap
      );
  }

  // Remove `theme` variant from choices returned if feature flag not on and user
  // doesn't have permission to view theme variants. This hard coding will be removed
  // once the feature is fully released
  if (
    !settings_?.features.themeVariant &&
    !djangoGlobalPerms?.includes(THEME_PERMISSION)
  ) {
    const { theme, ...otherChoices } = choices;
    return otherChoices;
  }

  return choices;
};

/**
 * Get the DropdownChoices for a combination of variant dimension values
 *
 * @param selectedVariants - Variant dimension values for which to get DropdownChoices
 */
export const variantCombinationToChoices = (
  selectedVariants: VariantCombination
): CustomVariantChoices => {
  const choices = Object.create(Object.prototype);

  for (const dimension in selectedVariants) {
    if (
      selectedVariants.hasOwnProperty(dimension) &&
      isVariantDimensionField(dimension)
    )
      choices[dimension] = kindToDropdown(
        selectedVariants[dimension],
        VARIANT_DIMENSION_METADATA[dimension].displayMap
      );
  }

  return choices;
};

/**
 * Get a variant combination object from choice dropdowns
 *
 * @param choices - Dropdown choices
 */
export const ChoicesToVariantCombination = (
  choices: CustomVariantChoices
): VariantCombination => {
  const varComb = Object.create(Object.prototype);

  for (const dimension in choices) {
    if (choices.hasOwnProperty(dimension) && isVariantDimensionField(dimension))
      varComb[dimension] = choices[dimension].id;
  }

  return varComb;
};

/**
 * Get a query string arguments string from a variant combination
 *
 * @param variantCombination - Combo of variants for which to get query string args
 */
export const GetVariantQueryArgs = (
  variantCombination: VariantCombination
): string => {
  const queryArgs: string[] = [];

  for (const dimension in variantCombination) {
    // Ignore code coverage here to avoid writing tests with invalid typing
    /* istanbul ignore next */
    if (
      variantCombination.hasOwnProperty(dimension) &&
      isVariantDimensionField(dimension)
    ) {
      const toPrepend = queryArgs.length < 1 ? "?" : "&";
      queryArgs.push(
        `${toPrepend}${kebab(dimension)}=${kebab(
          variantCombination[dimension].toLowerCase()
        )}`
      );
    }
  }

  return queryArgs.join("");
};
