import { useMemo } from "react";
import Spinner, { ColourType } from "../../common/Spinner";
import { useWhatsNewPromosQuery } from "../../generated/graphql";
import Card from "../../layout/Card";
import PromoDisplay from "../common/PromoDisplay";
import "./WhatsNew.scss";

const _NUM_PROMO_ITEMS = 2;

const WhatsNew: React.FC = () => {
  const { loading, error, data } = useWhatsNewPromosQuery({
    variables: { count: _NUM_PROMO_ITEMS },
  });
  const content = useMemo(() => {
    if (loading) {
      return [
        <div className="WhatsNew__spinner">
          <Spinner colour={ColourType.SECONDARY} size="3rem" />
        </div>,
      ];
    } else if (error || !data?.promos || data?.promos.length === 0) {
      return [
        <p data-testid="promo-error">
          Something went wrong when retrieving the What's New items.
        </p>,
      ];
    } else {
      return data.promos.map((promo) => {
        return <PromoDisplay promo={promo} />;
      });
    }
  }, [loading, error, data]);

  return (
    <div className="WhatsNew">
      <Card>
        <div className="WhatsNew__title">What's New</div>
        <div className="row WhatsNew__feature-updates">
          {content.map((elem, index) => (
            <div
              key={index}
              className="col-sm-6 col-xl-12"
              data-testid="content-col"
            >
              {elem}
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default WhatsNew;
