import React, { useEffect, useState } from "react";
import { RouteProps } from "react-router-dom";
import { Page, SETTINGS_PAGE } from "../../common/Breadcrumbs";
import routes from "../../utils/routes";
import ApiTokensCreateForm from "./ApiTokensCreateForm";
import ApiTokensDescription from "./ApiTokensDescription";
import ApiTokensDisplayToken from "./ApiTokensDisplayToken";

export interface ApiTokensCreateProps extends RouteProps {
  setBreadcrumbs: (value: Page[]) => void;
}

export const API_TOKEN_CREATE_PAGES = [
  SETTINGS_PAGE,
  {
    isCurrent: false,
    nameOrIcon: "API Tokens",
    url: routes.settingsApiTokens,
  },
  {
    isCurrent: true,
    nameOrIcon: "New API Token",
    url: routes.settingsApiTokensCreate,
  },
];

const ApiTokensCreate: React.FC<ApiTokensCreateProps> = ({
  setBreadcrumbs,
}) => {
  useEffect(() => {
    setBreadcrumbs(API_TOKEN_CREATE_PAGES);
  }, [setBreadcrumbs]);

  const [token, setToken] = useState<string | null>(null);

  const getApiTokensCreateContent = () => {
    if (token) {
      return <ApiTokensDisplayToken token={token} />;
    } else {
      return <ApiTokensCreateForm setToken={setToken} />;
    }
  };

  return (
    <div className="ApiTokensCreate">
      <ApiTokensDescription />
      {getApiTokensCreateContent()}
    </div>
  );
};

export default ApiTokensCreate;
