import { Avatar } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as CanalystLogoIcon } from "../assets/logos/canalyst-icon.svg";
import routes from "../utils/routes";
import { COMPANIES_STRINGS as STRINGS } from "./common/strings";
import { CompaniesDisplayProps } from "./CompaniesDisplay";
import "./CompaniesDisplayFull.scss";
import CompaniesNavMenu from "./main/CompaniesNavMenu";
import CompaniesViewButtons from "./main/CompaniesViewButtons";

const ESCAPE = "Escape";

/**
 * Companies page display component (fullscreen version)
 *
 * @param showSidebar Whether the sidebar is displayed
 * @param toggleSidebar Method to call to show/hide sidebar
 * @param toggleFullscreen Method to call to enable/disable fullscreen mode
 * @param table The Table component to render
 * @param sidebar The Sidebar component to render
 */
const CompaniesFull: React.FC<CompaniesDisplayProps> = ({
  showSidebar,
  toggleSidebar,
  toggleFullscreen,
  table,
  sidebar,
}) => {
  // Exit fullscreen mode if esc pressed
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ESCAPE) {
        toggleFullscreen();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [toggleFullscreen]);

  return (
    <div className="CompaniesFull" data-testid="companies-fullscreen">
      {/* Header */}
      <div className="CompaniesFull__header">
        <div className="CompaniesFull__header-left">
          {/* Canalyst logo */}
          <div className="CompaniesFull__header-logo">
            <Link to={routes.home}>
              <Avatar sx={{ height: 48, width: 48 }}>
                <CanalystLogoIcon />
              </Avatar>
            </Link>
          </div>
          {/* Header text */}
          <div className="CompaniesFull__header-text">
            {STRINGS.main.companies}
          </div>
        </div>
        <div className="CompaniesFull__header-right">
          {/* View settings buttons */}
          <CompaniesViewButtons
            showSidebar={showSidebar}
            toggleSidebar={toggleSidebar}
            isFullscreen={true}
            toggleFullscreen={toggleFullscreen}
          />
          {/* Mini nav menu */}
          <CompaniesNavMenu />
        </div>
      </div>
      <div className="CompaniesFull__body">
        {/* Sidebar */}
        <div className="CompaniesFull__sidebar-column">
          {showSidebar && (
            <div className="CompaniesFull__sidebar-column-frame">{sidebar}</div>
          )}
        </div>
        {/* Table */}
        <div className="CompaniesFull__table-column">{table}</div>
      </div>
    </div>
  );
};

export default CompaniesFull;
