import { ApolloClient, useApolloClient } from "@apollo/client";
import React, {
  Context,
  useContext as useContextDefault,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { HOME_PAGE, Page, SETTINGS_PAGE } from "../../common/Breadcrumbs";
import Button, { ButtonStyle, ButtonType } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../../common/Spinner";
import {
  IpoSubscriptions as IpoSubscriptionsType,
  useEmailSettingsMeQuery as useEmailSettingsMeQueryDefault,
  useEmailSettingsUpdateEmailSettingsMutation,
  UserSetupUserSetupDocument,
} from "../../generated/graphql";
import Card from "../../layout/Card";
import ToastContext from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import routes from "../../utils/routes";
import "./EmailSettings.scss";
import EmailSettingsForm from "./EmailSettingsForm";

const ERROR_MESSAGE =
  "There was an issue updating your email settings. Please try again or " +
  "contact Support if the issue persists.";

export const EMAIL_SETTINGS_BREADCRUMBS = [
  HOME_PAGE,
  SETTINGS_PAGE,
  {
    isCurrent: true,
    nameOrIcon: "Email Settings",
  },
];

interface EmailSettingsProps {
  setBreadcrumbs: (value: Page[]) => void;
  isUserSetup?: boolean;
}

interface EmailSettingsDeps {
  useEmailSettingsQuery?: typeof useEmailSettingsMeQueryDefault;
  useContext?: <T>(context: Context<T>) => T;
  apolloClient?: () => ApolloClient<any>;
}

export interface EmailSettingsType {
  emailRecentDownloadUpdate: boolean;
  ipoSubscriptions: IpoSubscriptionsType;
}

const EmailSettings: React.FC<EmailSettingsProps & EmailSettingsDeps> = ({
  setBreadcrumbs,
  isUserSetup,
  useEmailSettingsQuery = useEmailSettingsMeQueryDefault,
  useContext = useContextDefault,
  apolloClient = useApolloClient,
}) => {
  useEffect(() => {
    setBreadcrumbs(EMAIL_SETTINGS_BREADCRUMBS);
  }, [setBreadcrumbs]);

  const refetch = apolloClient();
  const history = useHistory();
  const { setToast } = useContext(ToastContext);
  const { loading, error, data } = useEmailSettingsQuery();

  const [mutationError, setMutationError] = useState<string>();

  const [updateEmailSettingsMutation, updateEmailSettingsMutationResult] =
    useEmailSettingsUpdateEmailSettingsMutation({
      onCompleted: async () =>
        isUserSetup
          ? await refetch.refetchQueries({
              include: [UserSetupUserSetupDocument],
            })
          : navigateToSettingsPage(),
      onError: () => setMutationError(ERROR_MESSAGE),
    });

  const navigateToSettingsPage = async () => {
    history.push(routes.settings);
    setToast({
      style: ToastStyle.Info,
      text: "Email settings successfully changed",
    });
  };

  if (loading || updateEmailSettingsMutationResult.loading) {
    return (
      <div className="EmailSettings EmailSettings--loading">
        <div className="EmailSettings__loading-spinner">
          <Spinner colour={ColourType.SECONDARY} size="3rem" />
        </div>
      </div>
    );
  }

  if (error || !data?.me?.emailSettings) {
    return (
      <div className="EmailSettings">
        <Card title="Sorry, could not load your preferences.">
          <ButtonGroup>
            <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
            <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
              Contact Us
            </Button>
          </ButtonGroup>
        </Card>
      </div>
    );
  } else {
    return (
      <div className="EmailSettings">
        <EmailSettingsForm
          initialValues={data.me.emailSettings}
          updateEmailSettings={updateEmailSettingsMutation}
          mutationError={mutationError}
          hideBackButton={isUserSetup}
        />
      </div>
    );
  }
};

export default EmailSettings;
