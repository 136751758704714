import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import KeyHint from "../../common/KeyHint";
import { PlaceholderForModelRequest } from "../../home/common/PlaceholderModelRequest";
import { getFolderName, getFolderUrl } from "../../utils/folders";
import { FA_ICON_DEFAULT_STYLE, getIconComponent } from "../../utils/icons";
import { ResourceForDl, UserForDl } from "../../utils/variants";
import {
  isPlaceholder,
  isResource,
  isSubFolder,
  SearchDropdownSearchItem,
} from "./graphql";

export const HINT_ENTER_FULL_SEARCH = (
  <>
    <KeyHint>Enter</KeyHint> to execute full search
  </>
);

export const HINT_ENTER_VIEW = (
  <>
    <KeyHint>Enter</KeyHint> to show in folder
  </>
);

export const HINT_ENTER_VIEW_FOLDER = (
  <>
    <KeyHint>Enter</KeyHint> to open folder
  </>
);

export const HINT_SHIFT_ENTER_DOWNLOAD = (
  <>
    <KeyHint>Shift</KeyHint> + <KeyHint>Enter</KeyHint> to download
  </>
);

export const HINT_SHIFT_ENTER_REQUEST_MODEL = (
  <>
    <KeyHint>Shift</KeyHint> + <KeyHint>Enter</KeyHint> to request model
  </>
);

/**
 * Represents a generic search dropdown result
 */
export interface Result {
  id: string;
  text: string;
  subtext?: string;
  action: HintedAction;
  secondaryAction?: { text: string } & HintedAction;
  icon: React.ReactElement;
}

export interface HintedAction {
  action: () => void;
  hint: React.ReactNode;
}

/**
 * Translate a FolderItem object into a search dropdown result
 */
export const folderItemToResult = (
  item: SearchDropdownSearchItem,
  user: UserForDl,
  {
    blurAndRedirect,
    download,
    requestModel,
  }: {
    blurAndRedirect: (linkTo: string) => void;
    download: (resource: ResourceForDl, user: UserForDl) => void;
    requestModel: (placeholder: PlaceholderForModelRequest) => void;
  }
): Result => {
  // item.parentFolder is only ever null/undefined for the root folder. Since
  // the root folder is _never_ shown as a search result, we can assume
  // item.parentFolder always exists & greatly simplify this logic.
  const parentFolder = isSubFolder(item) ? item.parentFolder! : item.folder;
  const action = () =>
    blurAndRedirect(
      isSubFolder(item)
        ? getFolderUrl(item)
        : `${getFolderUrl(parentFolder)}?show=${item.id}`
    );
  const hint = isSubFolder(item) ? HINT_ENTER_VIEW_FOLDER : HINT_ENTER_VIEW;
  const result: Result = {
    id: `result-${item.id}`,
    text: item.name,
    subtext: `in ${getFolderName(parentFolder)}`,
    action: { action, hint },
    icon: getIconComponent(item.icon),
  };

  result["secondaryAction"] = getSecondaryAction(
    item,
    user,
    download,
    requestModel
  );

  return result;
};

/**
 * Get an item's secondary action
 *
 * @param item - Item for which to get a secondary action
 * @param user - User calling query
 * @param download - Callback to download resource
 * @param requestModel - Callback to request placeholder file
 */
const getSecondaryAction = (
  item: SearchDropdownSearchItem,
  user: UserForDl,
  download: (resource: ResourceForDl, user: UserForDl) => void,
  requestModel: (placeholder: PlaceholderForModelRequest) => void
) => {
  if (isResource(item)) {
    return {
      text: "Download",
      action: () => download(item, user),
      hint: HINT_SHIFT_ENTER_DOWNLOAD,
    };
  } else if (isPlaceholder(item)) {
    return {
      text: "Request Model",
      action: () => requestModel(item),
      hint: HINT_SHIFT_ENTER_REQUEST_MODEL,
    };
  }
};

/**
 * Retrieve a "more results" search dropdown result
 */
export const getMoreResultsResult = ({
  executeFullSearch,
}: {
  executeFullSearch: () => void;
}): Result => {
  return {
    id: "result-more-results",
    text: "More search results…",
    action: {
      action: executeFullSearch,
      hint: HINT_ENTER_FULL_SEARCH,
    },
    icon: <FontAwesomeIcon icon={faSearch} style={FA_ICON_DEFAULT_STYLE} />,
  };
};
