import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./ToastContainer.scss";
import ToastContext from "./ToastContext";
import ToastDisplay from "./ToastDisplay";

/**
 * Component responsible for rendering toast notifications
 */
const ToastContainer: React.FC = () => {
  const { currentToast, clearToast } = useContext(ToastContext);
  const history = useHistory();

  useEffect(() => {
    // Clear toast notifications after any navigation
    return history.listen((_location) => {
      clearToast();
    });
  }, [clearToast, history]);

  return (
    <div className="ToastContainer">
      {currentToast && (
        <ToastDisplay toast={currentToast} clearToast={clearToast} />
      )}
    </div>
  );
};

export default ToastContainer;
