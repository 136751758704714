import { ApolloError } from "@apollo/client";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button, {
  ButtonState,
  ButtonStyle,
  ButtonType,
} from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../../common/Spinner";
import { SearchQuery } from "../../generated/graphql";
import Card from "../../layout/Card";
import routes from "../../utils/routes";
import { buildFolderItemComponent } from "../common/utils/components";
import MessageNoResults from "./MessageNoResults";
import "./SearchDisplay.scss";
import SearchHint from "./SearchHint";

interface SearchDisplayProps {
  query: string;
  loading: boolean;
  error?: ApolloError;
  data?: SearchQuery;
  isFolderSearch: boolean;
  setFolderId: (folderId?: string) => void;
  page: number;
  setPage: (page?: number) => void;
}

/**
 * Display component for search results (no data retrieval)
 */
const SearchDisplay: React.FC<SearchDisplayProps> = (props) => {
  if (!props.query) {
    return (
      <div className="SearchDisplay">
        <Card title="Enter a query." data-testid="error-no-query" tegus>
          <p>Please enter your search query in the input above.</p>
          <ButtonGroup>
            <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
            <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
              Contact Us
            </Button>
          </ButtonGroup>
        </Card>
      </div>
    );
  }
  if (props.loading) {
    return (
      <div className="SearchDisplay SearchDisplay--loading">
        <Spinner colour={ColourType.SECONDARY} size="3rem" />
      </div>
    );
  }
  if (!props.data || props.error) {
    return (
      <div className="SearchDisplay">
        <Card title="Uh oh." data-testid="error-500">
          <p>
            Something went wrong when retrieving the search results. Please try
            again later or <a href={routes.support}>contact support</a> for
            assistance.
          </p>
          <ButtonGroup>
            <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
            <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
              Contact Us
            </Button>
          </ButtonGroup>
        </Card>
      </div>
    );
  }
  if (props.data.search.edges.length === 0) {
    return (
      <div className="SearchDisplay">
        <SearchHint {...props} />
        <MessageNoResults {...props} />
      </div>
    );
  }

  // Create a closure with a defined user for the map function
  const user = props.data.me;
  const itemElements = props.data.search.edges.map((edge) => {
    return buildFolderItemComponent(edge.node, user);
  });

  const prevBtnState = !props.data.search.pageInfo.hasPreviousPage
    ? ButtonState.Disabled
    : undefined;
  const nextBtnState = !props.data.search.pageInfo.hasNextPage
    ? ButtonState.Disabled
    : undefined;

  return (
    <div className="SearchDisplay">
      <Card tegus>
        <div className="row">
          <div className="col-12">
            <SearchHint {...props} />
            {itemElements}
            <div className="SearchDisplay__footer">
              <Button
                className="SearchDisplay__button"
                style={ButtonStyle.TegusSecondary}
                state={prevBtnState}
                action={() => {
                  props.setPage(props.page - 1);
                }}
                data-testid="prev-btn"
              >
                <FontAwesomeIcon
                  className="SearchDisplay__chevron-left"
                  icon={faChevronLeft}
                />
              </Button>
              <div className="SearchDisplay__page-num">
                <span data-testid="page-display">Page {props.page}</span>
              </div>
              <Button
                className="SearchDisplay__button"
                style={ButtonStyle.TegusSecondary}
                state={nextBtnState}
                action={() => {
                  props.setPage(props.page + 1);
                }}
                data-testid="next-btn"
              >
                <FontAwesomeIcon
                  className="SearchDisplay__chevron-right"
                  icon={faChevronRight}
                />
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SearchDisplay;
