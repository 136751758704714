import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import Button, {
  ButtonState,
  ButtonStyle,
  ButtonType,
} from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../../common/Spinner";
import {
  useVersionsQuery,
  VersionResourceFragment,
} from "../../generated/graphql";
import Card from "../../layout/Card";
import routes from "../../utils/routes";
import { ResourceForDl, UserForDl } from "../../utils/variants";
import "./Versions.scss";
import VersionsElem from "./VersionsElem";

const VERSIONS_PAGE_SIZE = 20;

interface VersionsProps {
  resource: ResourceForDl;
  user: UserForDl;
  pageSize?: number;
}

interface VersionsDeps {
  useVersionsQuery_?: typeof useVersionsQuery;
}

const Versions: React.FC<VersionsProps & VersionsDeps> = ({
  resource,
  user,
  pageSize = VERSIONS_PAGE_SIZE,
  useVersionsQuery_ = useVersionsQuery,
}) => {
  const [page, setPage] = useState(1);

  const { loading, error, data } = useVersionsQuery_({
    variables: {
      id: resource.id,
      first: pageSize,
      after: pageParamToAfter(page, pageSize),
    },
  });

  const nextPage = useCallback(() => {
    setPage(page + 1);
  }, [page, setPage]);

  const prevPage = useCallback(() => {
    setPage(page - 1);
  }, [page, setPage]);

  if (loading) {
    return (
      <div className="Versions Versions--loading">
        <Spinner colour={ColourType.SECONDARY} size="3rem" />
      </div>
    );
  }

  if (error || !data?.folderItem?.__typename) {
    return (
      <Card title="Uh oh." data-testid="error-500">
        <p>
          Something went wrong when retrieving the file versions page. Please
          try again later or <a href={routes.support}>contact support</a> for
          assistance.
        </p>
        <ButtonGroup>
          <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
          <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
            Contact Us
          </Button>
        </ButtonGroup>
      </Card>
    );
  }

  if (data.folderItem?.__typename === "Resource") {
    const versions = data.folderItem?.allVersions.edges.map((edge) =>
      buildVersionsElem(edge.node, user)
    );
    const prevBtnState = !data.folderItem?.allVersions.pageInfo.hasPreviousPage
      ? ButtonState.Disabled
      : undefined;
    const nextBtnState = !data.folderItem?.allVersions.pageInfo.hasNextPage
      ? ButtonState.Disabled
      : undefined;

    return (
      <div className="Versions">
        <div className="row">
          <div className="col-12">{versions}</div>
        </div>
        <div className="Versions__footer">
          <Button
            className="Versions__button"
            style={ButtonStyle.TegusSecondary}
            state={prevBtnState}
            action={prevPage}
            data-testid="prev-btn"
          >
            <FontAwesomeIcon
              className="Versions__chevron-left"
              icon={faChevronLeft}
            />
          </Button>
          <div className="Versions__page-num">
            <span data-testid="page-display">Page {page}</span>
          </div>
          <Button
            className="Versions__button"
            style={ButtonStyle.TegusSecondary}
            state={nextBtnState}
            action={nextPage}
            data-testid="next-btn"
          >
            <FontAwesomeIcon
              className="Versions__chevron-left"
              icon={faChevronRight}
            />
          </Button>
        </div>
      </div>
    );
  }
  return <></>;
};

const buildVersionsElem = (
  item: VersionResourceFragment,
  user: UserForDl
): React.ReactElement => {
  return (
    <VersionsElem
      version={item}
      user={user}
      key={`resource!${item.id}`}
      data-testid="item-elem"
    />
  );
};

const pageParamToAfter = (page: number, pageSize: number) => {
  // For page 1 & invalid page parameters, set after to undefined. For subsequent pages,
  // set the after cursor to the cursor of the last item on the previous page.
  if (page <= 1) {
    return undefined;
  } else {
    return ((page - 1) * pageSize).toString();
  }
};

export default Versions;
