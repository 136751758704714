import { faRedo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  gridVisibleRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import Tippy from "@tippyjs/react";
import Moment from "react-moment";
import { HOUR_IN_MS } from "../../utils/datetime";
import { COLOUR_TEAL_LIGHTER } from "../../utils/theme";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";
import "./CompaniesTableToolbar.scss";

// Settings
const ICON_BUTTON_SIZE = 32;

interface CompaniesTableToolbarProps {
  totalRows: number;
  updated: Date;
  updateNow: () => any;
}

/**
 * Toolbar that appears at the bottom of the Companies table
 *
 * @param totalRows Maximum number of displayable rows
 * @param updated The last time the companies data was updated
 * @param updateNow Callback to call to trigger companies data update
 */
const CompaniesTableToolbar: React.FC<CompaniesTableToolbarProps> = ({
  totalRows,
  updated,
  updateNow,
}) => {
  const apiRef = useGridApiContext();
  const displayedRows = useGridSelector(apiRef, gridVisibleRowCountSelector);

  return (
    <div className="CompaniesTableToolbar">
      <div className="CompaniesTableToolbar__left">
        {/* Table control buttons */}
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </div>
      <div className="CompaniesTableToolbar__right">
        <div className="CompaniesTableToolbar__separator"></div>
        {/* Last updated */}
        <div className="CompaniesTableToolbar__updated">
          <div className="CompaniesTableToolbar__updated-section">
            <div className="CompaniesTableToolbar__updated-text">
              {STRINGS.table.toolbar.updated}
            </div>
            <Tippy
              content={updated.toLocaleTimeString("en-us")}
              trigger="mouseenter"
            >
              <div
                className="CompaniesTableToolbar__updated-display"
                data-testid="last-updated"
              >
                <Moment fromNowDuring={24 * HOUR_IN_MS}>
                  {updated.toISOString()}
                </Moment>
              </div>
            </Tippy>
          </div>
          <Tippy content={STRINGS.table.toolbar.updateNow} trigger="mouseenter">
            <IconButton
              aria-label={STRINGS.table.toolbar.updateNow}
              color="primary"
              onClick={updateNow}
              sx={{
                width: ICON_BUTTON_SIZE,
                height: ICON_BUTTON_SIZE,
                "&:hover": {
                  backgroundColor: COLOUR_TEAL_LIGHTER,
                },
              }}
            >
              <FontAwesomeIcon
                icon={faRedo}
                fixedWidth
                style={{
                  transform: "scale(0.75)",
                  transformOrigin: "center",
                }}
              />
            </IconButton>
          </Tippy>
        </div>
        <div className="CompaniesTableToolbar__separator"></div>
        {/* Displayed / total companies count */}
        <div
          className="CompaniesTableToolbar__rows-display"
          data-testid="company-statistics"
        >
          {STRINGS.table.toolbar.displayedRows}
          <div className="CompaniesTableToolbar__rows-count-section">
            <div className="CompaniesTableToolbar__rows-count">
              {displayedRows}
            </div>
            {STRINGS.table.toolbar.totalRows}
            <div className="CompaniesTableToolbar__rows-count">{totalRows}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompaniesTableToolbar;
