import {
  faCompress,
  faExpandWide,
  faList,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleButton } from "@mui/material";
import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";

const ICON_STYLE = { transform: "scale(1.25)", transformOrigin: "center" };
const SX_BUTTON_LEFT = {
  border: "0.07rem solid #ccc",
  borderTopLeftRadius: "0.5rem",
  borderBottomLeftRadius: "0.5rem",
  borderTopRightRadius: "0rem",
  borderBottomRightRadius: "0rem",
  borderRight: "0rem",
};
const SX_BUTTON_RIGHT = {
  border: "0.07rem solid #ccc",
  borderTopLeftRadius: "0.0rem",
  borderBottomLeftRadius: "0.0rem",
  borderTopRightRadius: "0.5rem",
  borderBottomRightRadius: "0.5rem",
};

interface CompaniesViewMenuProps {
  showSidebar: boolean;
  toggleSidebar: () => void;
  isFullscreen: boolean;
  toggleFullscreen: () => void;
}

/**
 * Companies page view buttons component
 *
 * An implementation of the MUI 'Toggle button' component.
 *
 * See: https://mui.com/material-ui/react-toggle-button/
 *
 * @param showSidebar Whether the sidebar is displayed
 * @param toggleSidebar Method to call to show/hide sidebar
 * @param isFullscreen Whether page is in fullscreen mode
 * @param toggleFullscreen Method to call to enable/disable fullscreen mode
 */
const CompaniesViewButtons: React.FC<CompaniesViewMenuProps> = ({
  showSidebar,
  toggleSidebar,
  isFullscreen,
  toggleFullscreen,
}) => {
  const [sidebarToggled, setSidebarToggled] = useState<boolean>(showSidebar);
  const [fullscreenToggled, setFullscreenToggled] =
    useState<boolean>(isFullscreen);

  return (
    <div className="CompaniesViewButtons">
      <Tippy
        content={
          sidebarToggled
            ? STRINGS.main.viewButtons.hideSidebar
            : STRINGS.main.viewButtons.showSidebar
        }
      >
        <ToggleButton
          aria-label={STRINGS.main.viewButtons.toggleSidebar}
          color="primary"
          value="sidebar"
          selected={sidebarToggled}
          onChange={() => {
            setSidebarToggled(!sidebarToggled);
            toggleSidebar();
          }}
          sx={SX_BUTTON_LEFT}
        >
          <FontAwesomeIcon fixedWidth icon={faList} style={ICON_STYLE} />
        </ToggleButton>
      </Tippy>
      <Tippy
        content={
          fullscreenToggled
            ? STRINGS.main.viewButtons.exitFullscreen
            : STRINGS.main.viewButtons.showFullscreen
        }
      >
        <ToggleButton
          aria-label={STRINGS.main.viewButtons.toggleFullscreen}
          color="primary"
          value="fullscreen"
          selected={fullscreenToggled}
          onChange={() => {
            setFullscreenToggled(!fullscreenToggled);
            toggleFullscreen();
          }}
          sx={SX_BUTTON_RIGHT}
        >
          <FontAwesomeIcon
            fixedWidth
            icon={fullscreenToggled ? faCompress : faExpandWide}
            style={ICON_STYLE}
          />
        </ToggleButton>
      </Tippy>
    </div>
  );
};

export default CompaniesViewButtons;
