import { useField } from "formik";
import React, { useState } from "react";
import { DropdownChoice } from "../../../common/Dropdown";
import FileUploadDefault, { FileData } from "../../../forms/FileUpload";
import InlineChoiceFieldDefault from "../../../forms/InlineChoiceField";
import { FileTypeKind } from "../../../generated/graphql";
import { RESOURCE_FILE_TYPES } from "../../common/ResourceElem";
import "./AddResourceRow.scss";

// Settings
const FILENAME_WIDTH = "12.2rem";

// End-user visible strings
const TEXT_SELECT_TYPE = "Select type...";

// Detectable filetypes
export enum FileType {
  Excel = "xlsx",
  Executable = "exe",
  PDF = "pdf",
  Other = "other",
}

// Filetype associated with detected mimetype
const MIMETYPE_TO_FILETYPE_MAP: { [key: string]: FileType } = {
  "application/pdf": FileType.PDF,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    FileType.Excel,
  "application/x-msdownload": FileType.Executable,
};

// Allowed resource types
const RESOURCE_TYPE_CHOICE: { [key: string]: DropdownChoice } = {
  dropin: {
    id: FileTypeKind.DropinTemplate,
    display: RESOURCE_FILE_TYPES[FileTypeKind.DropinTemplate],
  },
  dashboard: {
    id: FileTypeKind.Dashboard,
    display: RESOURCE_FILE_TYPES[FileTypeKind.Dashboard],
  },
  executable: {
    id: FileTypeKind.Executable,
    display: RESOURCE_FILE_TYPES[FileTypeKind.Executable],
  },
  pdf: {
    id: FileTypeKind.GuidePdf,
    display: RESOURCE_FILE_TYPES[FileTypeKind.GuidePdf],
  },
  other: {
    id: FileTypeKind.Other,
    display: RESOURCE_FILE_TYPES[FileTypeKind.Other],
  },
};

// Types that appear in the resource type dropdown
const RESOURCE_TYPE_DROPDOWN_CHOICES: { [key: string]: DropdownChoice[] } = {
  [FileType.Excel]: [
    RESOURCE_TYPE_CHOICE.dropin,
    RESOURCE_TYPE_CHOICE.dashboard,
    RESOURCE_TYPE_CHOICE.other,
  ],
  [FileType.Executable]: [
    RESOURCE_TYPE_CHOICE.executable,
    RESOURCE_TYPE_CHOICE.other,
  ],
  [FileType.PDF]: [RESOURCE_TYPE_CHOICE.pdf, RESOURCE_TYPE_CHOICE.other],
  [FileType.Other]: [RESOURCE_TYPE_CHOICE.other],
};

// Automatically selected resource type for each filetype
const FILE_TYPE_DEFAULT_CHOICE: { [key: string]: DropdownChoice } = {
  exe: RESOURCE_TYPE_CHOICE.executable,
  pdf: RESOURCE_TYPE_CHOICE.pdf,
  other: RESOURCE_TYPE_CHOICE.other,
};

interface AddResourceRowProps {
  name: string;
}

interface AddResourceRowDeps {
  FileUpload?: typeof FileUploadDefault;
  InlineChoiceField?: typeof InlineChoiceFieldDefault;
}

/**
 * A single row within the 'Add resource' modal
 *
 * Includes the File and Type fields
 *
 * @param props - Component props
 * @param props.name - The internal name of the row
 */
const AddResourceRow: React.FC<AddResourceRowProps & AddResourceRowDeps> = ({
  name,
  FileUpload = FileUploadDefault,
  InlineChoiceField = InlineChoiceFieldDefault,
}) => {
  const [, , typeHelper] = useField(`${name}.type`);

  const [typeDisabled, setTypeDisabled] = useState<boolean>(true);
  const [typeChoices, setTypeChoices] = useState<DropdownChoice[]>(
    RESOURCE_TYPE_DROPDOWN_CHOICES[FileType.Excel]
  );

  // Handle change file
  const onSelectFile = (file: FileData) => {
    const fileType = MIMETYPE_TO_FILETYPE_MAP[file.mimetype] || FileType.Other;

    // Enable type field & set allowed choices
    setTypeChoices(RESOURCE_TYPE_DROPDOWN_CHOICES[fileType]);
    setTypeDisabled(false);

    // Automatically set type if not Excel
    if (fileType === FileType.Excel) {
      typeHelper.setValue(null);
    } else {
      typeHelper.setValue(FILE_TYPE_DEFAULT_CHOICE[fileType]);
    }
  };

  // Handle clear file
  const onClearFile = () => {
    typeHelper.setValue(null);
    setTypeDisabled(true);
  };

  return (
    <div className="AddResourceRow">
      <div className="AddResourceRow__item ResourceFile">
        {/* Resource file field */}
        <FileUpload
          name={`${name}.file`}
          onFileSelected={onSelectFile}
          onFileCleared={onClearFile}
          filenameWidth={FILENAME_WIDTH}
        />
      </div>
      <div className="AddResourceRow__item ResourceType">
        {/* Resource type field */}
        <InlineChoiceField
          name={`${name}.type`}
          disabled={typeDisabled}
          choices={typeChoices}
          nullChoiceText={TEXT_SELECT_TYPE}
        />
      </div>
    </div>
  );
};

export default AddResourceRow;
