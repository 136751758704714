import React, { useEffect } from "react";
import Zendesk from "react-zendesk";
import { CustomWindow } from "../common/window";
import settingsDefault, { Settings } from "./settings";

export const ZENDESK_KEY = "68c4c3ea-63f1-4400-9576-e1e0fdec2d6b";

declare const window: CustomWindow;

interface ZendeskWidgetScriptProps {
  settings?: Settings;
}

/**
 * Component that adds the Zendesk widget script to the page
 */
export const ZendeskWidgetScript: React.FC<ZendeskWidgetScriptProps> = ({
  settings = settingsDefault,
}) => {
  useEffect(() => authenticate(settings.zendeskWidgetToken), [settings]);
  return <Zendesk zendeskKey={ZENDESK_KEY} />;
};

/**
 * Add JWT from settings to Zendesk settings of window object
 */
export const authenticate = (
  token: string | null,
  window_: CustomWindow = window
) => {
  window_.zESettings = {
    webWidget: {
      authenticate: {
        jwtFn: (callback) => callback(token),
      },
    },
  };
};
