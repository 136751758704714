/**
 * Object containing all routes. Use this instead of magic variables!
 *
 * If a route is static, it can be represented as a string. If it is dynamic,
 * use a function that returns a string. All URLs returned should be relative
 * and should NOT contain query parameters or fragments. Try to keep the logic
 * of the functions as simple as possible.
 *
 * Please try to keep the keys sorted alphabetically too (thank you).
 */
const routes = {
  bulkUserCreateEndpoint: "/users/bulk-create",
  bulkUserCreatePage: "/bulk-user-create",
  companies: (folderPath?: string) =>
    folderPath ? `/companies/${folderPath}` : "/companies",
  companiesV2: "/companies-v2",
  contentManagement: "/content-management/",
  dashboards: "/dashboards",
  home: "/",
  legacyDashboards: "/tools/dashboards-and-comp-sheets",
  legacyDownload: (folderPath: string, fileSlug: string) =>
    `/files/download/${folderPath}/${fileSlug}`,
  legacySettingsApiTokensDelete: (id: string) =>
    `/u/settings/api-tokens/${id}/delete`,
  login: "/login",
  logout: "/logout",
  privateFolder: "/private-folder",
  search: "/search",
  settings: "/settings",
  settingsApiTokens: "/settings/api-tokens",
  settingsApiTokensCreate: "/settings/api-tokens/create",
  settingsDefaultModelFormat: "/settings/default-model-format",
  settingsEmail: "/settings/email",
  settingsPassword: "/settings/password",
  settingsMfa: "/settings/multi-factor-authentication",
  settingsMfaSetup: "/settings/multi-factor-authentication/setup",
  share: (id: string, slug?: string) =>
    slug ? `/share/${id}/${slug}` : `/share/${id}`,
  staffDjangoAdmin: "/admin/",
  staffDjangoRq: "/django-rq/",
  staffFolderActions: "/folder-actions/",
  staffGraphiql: "/graphql",
  staffGroups: "/groups/",
  staffLogs: "/logs/",
  staffReports: "/reports/",
  staffUsers: "/users/",
  support: "/contact-us",
  tegusLink: (csin: string) => `/tegus-link/${csin}`,
  tools: "/tools",
  toolsDropInTemplates: "/tools/drop-in-templates",
  uploadFile: "/files/upload-file",
  uploadFileStatus: "/files/upload-file/status",
  watchList: "/watch-list",
  zendesk: "/authenticate-zendesk",
} as const;

export default routes;
