import React from "react";
import { useHistory } from "react-router-dom";
import routes from "../../../src/utils/routes";
import Spinner, { ColourType } from "../../common/Spinner";
import { useTegusLinkQuery } from "../../generated/graphql";

/**
 * Handle Tegus links like /tegus-link/CSIN
 *
 * These links are available for any Resource.
 * They redirect to the containing folder and highlight the resource
 */

const TegusLink: React.FC = () => {
  const history = useHistory();
  // We expect the URL to be formatted like SITE_HOST/tegus-link/CSIN, so we
  // grab the CSIN by substringing from the last "/"
  const csin = history.location.pathname.substring(
    history.location.pathname.lastIndexOf("/") + 1
  );

  const { loading, error, data } = useTegusLinkQuery({
    variables: {
      csin: csin,
    },
  });

  if (loading) {
    return (
      <div data-testid="tegus-link-spinner">
        <Spinner size="3rem" colour={ColourType.SECONDARY} />
      </div>
    );
  } else if (
    error ||
    !data?.filesByFolderRecursive ||
    data?.filesByFolderRecursive.edges.length === 0
  ) {
    return (
      <p data-testid="error-500">
        Something went wrong when retrieving this item. Please ensure the CSIN
        is correct.
      </p>
    );
  } else {
    // We can safely grab the first element from edges
    // because we specify latestVersion as True
    const tegusPath =
      data.filesByFolderRecursive.edges[0]?.node?.tegusLink || "";
    history.replace(`${routes.companies(tegusPath)}`);
    // We are being redirected, so doesn't matter
    return <div data-testid="tegus-link-success"></div>;
  }
};

export default TegusLink;
