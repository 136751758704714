import classNames from "classnames";
import { Action, Location } from "history";
import React, { useEffect, useState } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";
import "./App.scss";
import BulkUserCreate from "./bulk_user/BulkUserCreate";
import Breadcrumbs, { Page } from "./common/Breadcrumbs";
import { EMPTY_WATCH_LIST_COPY } from "./common/copy";
import Http404 from "./common/Http404";
import Spinner, { ColourType } from "./common/Spinner";
import Companies from "./companies/Companies";
import ContactUs from "./contact_us/ContactUs";
import Browse from "./home/browse/Browse";
import DownloadLink from "./home/browse/DownloadLink";
import HomePage from "./home/browse/HomePage";
import ShareLink from "./home/browse/ShareLink";
import TegusLink from "./home/browse/TegusLink";
import Search from "./home/search/Search";
import CopyrightFooter from "./layout/CopyrightFooter";
import NavRoute from "./layout/NavRoute";
import SideNav from "./layout/SideNav";
import TopNav from "./layout/TopNav";
import ModalContainer from "./modal/ModalContainer";
import Resources from "./resources/Resources";
import ResourcesFull from "./resources/ResourcesFull";
import { ResourcesFolderIds } from "./resources/utils/graphql";
import { RESOURCE_FOLDER_ID_TO_DETAILS } from "./resources/utils/resources";
import ToastContainer from "./toast/ToastContainer";
import ApiTokens from "./user_settings/api_tokens/ApiTokens";
import ApiTokensCreate from "./user_settings/api_tokens/ApiTokensCreate";
import EmailSettings from "./user_settings/email_settings/EmailSettings";
import MfaSettings from "./user_settings/multi_factor_auth/MfaSettings";
import MfaSetupPage from "./user_settings/multi_factor_auth/MfaSetupPage";
import UpdatePassword from "./user_settings/update_password/UpdatePassword";
import UserSettings from "./user_settings/UserSettings";
import VariantPreference from "./user_settings/variant_preference/VariantPreference";
import UserSetup from "./user_setup/UserSetup";
import { getUserFromJwt as getUserFromJwtDefault } from "./utils/auth";
import { buildSubrootBreadcrumb } from "./utils/breadcrumbs";
import loggerDefault from "./utils/logger";
import routes from "./utils/routes";
import settingsDefault, {
  features,
  Features,
  Settings,
} from "./utils/settings";
import TrailingSlashRedirect from "./utils/TrailingSlashRedirect";
import { ZendeskWidgetScript } from "./utils/zendesk";

const PRIVATE_FOLDER_BREADCRUMBS = buildSubrootBreadcrumb("Private Folder");
const WATCH_LIST_BREADCRUMBS = buildSubrootBreadcrumb("Watchlist");
const DASHBOARDS_BREADCRUMBS = buildSubrootBreadcrumb(
  RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.screens].name
);

interface AppProps {}

interface AppDeps {
  getUserFromJwt?: typeof getUserFromJwtDefault;
  settings?: Settings;
  features_?: Features;
  useHistoryLogger_?: typeof useHistoryLogger;
}

const App: React.FC<AppProps & AppDeps> = ({
  getUserFromJwt = getUserFromJwtDefault,
  settings = settingsDefault,
  features_ = features,
  useHistoryLogger_ = useHistoryLogger,
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Page[]>([]);
  const [contentOnly, setContentOnly] = useState(true);
  const [currentRoute, setCurrentRoute] = useState<string>();
  const [fromFolderId, setFromFolderId] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [userSetupComplete, setUserSetupComplete] = useState(
    settings.userSetupComplete
  );
  useHistoryLogger_();

  const user = getUserFromJwt().user;
  const getPersonalizedTitle = (title: string) => {
    return user ? `${user.first_name} ${user.last_name}’s ${title}` : title;
  };

  const commonNavRouteProps = {
    isAuthenticated: () => user !== null,
    setContentOnly,
    setCurrentRoute,
    setIsLoading,
  };

  const appClassNames = classNames("App", {
    "App--content-only": contentOnly,
    "App--is-loading": isLoading,
  });
  return (
    <div className={appClassNames}>
      <TrailingSlashRedirect />
      {!contentOnly && <TopNav fromFolderId={fromFolderId} />}
      {!contentOnly && <SideNav currentRoute={currentRoute} />}
      <div className="App__body">
        {!contentOnly && breadcrumbs.length > 0 && (
          <div className="App__breadcrumbs">
            <Breadcrumbs pages={breadcrumbs} />
          </div>
        )}
        <div className="App__content">
          <Switch>
            {!userSetupComplete && ( // Catch-all route when setup is not done
              <NavRoute routeName="setup" contentOnly {...commonNavRouteProps}>
                <UserSetup setUserSetupComplete={setUserSetupComplete} />
              </NavRoute>
            )}
            <NavRoute
              routeName="home"
              exact
              path={routes.home}
              {...commonNavRouteProps}
            >
              <HomePage setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="search"
              path={routes.search}
              {...commonNavRouteProps}
            >
              <Search setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="download"
              path={routes.legacyDownload(":folderPath*", ":fileSlug")}
              {...commonNavRouteProps}
            >
              <DownloadLink />
            </NavRoute>
            <NavRoute
              routeName="share"
              path={routes.share(":id")}
              {...commonNavRouteProps}
            >
              <ShareLink />
            </NavRoute>
            <NavRoute
              routeName="tegus-link"
              path={routes.tegusLink(":csin")}
              {...commonNavRouteProps}
            >
              <TegusLink />
            </NavRoute>
            <NavRoute
              routeName="companies"
              path={routes.companies(":folderPath*")}
              {...commonNavRouteProps}
            >
              <Browse
                setBreadcrumbs={setBreadcrumbs}
                setFromFolderId={setFromFolderId}
              />
            </NavRoute>
            <NavRoute
              routeName="companies-v2"
              path={routes.companiesV2}
              {...commonNavRouteProps}
            >
              <Companies setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <Redirect from={routes.legacyDashboards} to={routes.dashboards} />
            <NavRoute
              routeName="dashboards"
              path={routes.dashboards}
              {...commonNavRouteProps}
            >
              <Browse
                setBreadcrumbs={setBreadcrumbs}
                setFromFolderId={setFromFolderId}
                folderPathOverride={settings.screensFolderPath} // See settings.tsx
                breadcrumbsOverride={DASHBOARDS_BREADCRUMBS}
                description={
                  RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.screens]
                    .description
                }
                titleOverride={
                  RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.screens].name
                }
              />
            </NavRoute>
            <NavRoute // PCF browse page
              routeName="private-folder"
              path={routes.privateFolder}
              {...commonNavRouteProps}
            >
              <Browse
                setBreadcrumbs={setBreadcrumbs}
                setFromFolderId={setFromFolderId}
                fetchPolicyOverride={"network-only"}
                folderPathOverride={settings.privateFolderPath} // See settings.tsx
                breadcrumbsOverride={PRIVATE_FOLDER_BREADCRUMBS}
                titleOverride={getPersonalizedTitle("Private Folder")}
              />
            </NavRoute>
            <NavRoute // Watchlist browse page
              routeName="watch-list"
              path={routes.watchList}
              {...commonNavRouteProps}
            >
              <Browse
                setBreadcrumbs={setBreadcrumbs}
                setFromFolderId={setFromFolderId}
                emptyCopyOverride={EMPTY_WATCH_LIST_COPY}
                fetchPolicyOverride={"network-only"}
                folderPathOverride={settings.watchListFolderPath} // See settings.tsx
                breadcrumbsOverride={WATCH_LIST_BREADCRUMBS}
                titleOverride={getPersonalizedTitle("Watchlist")}
              />
            </NavRoute>
            <NavRoute
              routeName="support"
              path={routes.support}
              {...commonNavRouteProps}
            >
              <ContactUs setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="tools"
              exact
              path={
                RESOURCE_FOLDER_ID_TO_DETAILS[
                  ResourcesFolderIds.toolkitAndGuides
                ].url
              }
              {...commonNavRouteProps}
            >
              <Resources setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="tools"
              exact
              path={
                RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.templates].url
              }
              {...commonNavRouteProps}
            >
              <ResourcesFull
                folderId={ResourcesFolderIds.templates}
                setBreadcrumbs={setBreadcrumbs}
              />
            </NavRoute>
            <NavRoute
              routeName="bulk-user-create"
              exact
              path={routes.bulkUserCreatePage}
              {...commonNavRouteProps}
            >
              <BulkUserCreate setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="settings"
              exact
              path={routes.settings}
              {...commonNavRouteProps}
            >
              <UserSettings setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="settings"
              exact
              path={routes.settingsApiTokens}
              {...commonNavRouteProps}
            >
              <ApiTokens setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="settings"
              path={routes.settingsApiTokensCreate}
              {...commonNavRouteProps}
            >
              <ApiTokensCreate setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="settings"
              path={routes.settingsDefaultModelFormat}
              {...commonNavRouteProps}
            >
              <VariantPreference setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="settings"
              path={routes.settingsPassword}
              {...commonNavRouteProps}
            >
              <UpdatePassword setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="settings"
              path={routes.settingsEmail}
              {...commonNavRouteProps}
            >
              <EmailSettings setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="settings"
              exact
              path={routes.settingsMfa}
              {...commonNavRouteProps}
            >
              <MfaSettings setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="settings"
              exact
              path={routes.settingsMfaSetup}
              {...commonNavRouteProps}
            >
              <MfaSetupPage setBreadcrumbs={setBreadcrumbs} />
            </NavRoute>
            <NavRoute
              routeName="http404"
              contentOnly
              publicRoute
              {...commonNavRouteProps}
            >
              <Http404 />
            </NavRoute>
          </Switch>
        </div>
        <div className="App__footer">
          <CopyrightFooter />
        </div>
        {features_?.zendeskWidget && <ZendeskWidgetScript />}
      </div>
      <div className="App__loading-spinner-container">
        <div className="d-flex justify-content-center align-items-center">
          <div className="p-2">
            <Spinner colour={ColourType.SECONDARY} size="3rem" />
          </div>
        </div>
      </div>
      <ModalContainer />
      <ToastContainer />
    </div>
  );
};

/**
 * A hook that sends React Router navigation logs to the backend
 */
export const useHistoryLogger = (logger = loggerDefault) => {
  const history = useHistory();
  useEffect(() => {
    const logHistoryAction = (location: Location, action: Action | "LOAD") => {
      logger.info(
        `PAGE:${action}`,
        `${location.pathname}${location.search}${location.hash}`
      );
    };
    logHistoryAction(history.location, "LOAD");
    return history.listen(logHistoryAction);
  }, [logger, history]);
};

export default App;
