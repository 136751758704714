import { ApolloCache, useMutation } from "@apollo/client";
import { faStar } from "@fortawesome/pro-light-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, {
  Context,
  useContext as useContextDefault,
  useState,
} from "react";
import {
  ToggleWatchListData,
  ToggleWatchListVars,
  TOGGLE_WATCH_LIST,
} from "../../home/common/WatchListElem.graphql";
import ToastContext from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import { FA_ICON_DEFAULT_STYLE } from "../../utils/icons";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";
import { RowActionButton } from "./CompaniesTableRowActions";
import "./CompaniesTableWatchListButton.scss";

export interface CompaniesTableWatchListButtonProps {
  id: string;
  watchListed: boolean;
}

export interface CompaniesTableWatchListButtonDeps {
  useContext?: <T>(context: Context<T>) => T;
}

/**
 * Companies table row watchlist button
 *
 * Functionally a clone of WatchListElem but modified to work for Company objects
 *
 * @param id Company ID
 * @param watchListed Whether company is currently watchlisted
 */
const CompaniesTableWatchListButton: React.FC<
  CompaniesTableWatchListButtonProps & CompaniesTableWatchListButtonDeps
> = ({ id, watchListed, useContext = useContextDefault }) => {
  const [toggleWatchList] = useMutation<
    ToggleWatchListData,
    ToggleWatchListVars
  >(TOGGLE_WATCH_LIST, {
    update: (cache, { data }) => handleUpdate(cache, data),
    onCompleted: (result) => handleSuccess(result),
    onError: () => handleError(),
  });

  const [showToggledOn, setShowToggledOn] = useState(watchListed === true);
  const [isWatchListed, setIsWatchListed] = useState(watchListed === true);
  const { setToast } = useContext(ToastContext);

  const handleUpdate = (
    cache: ApolloCache<ToggleWatchListData>,
    data: ToggleWatchListData | null | undefined
  ) => {
    const newValue = data?.toggleWatchListItem?.watchListItem.toggled;
    if (newValue != null) {
      cache.modify({
        id: cache.identify({ id: id, __typename: "Company" }),
        fields: {
          watchListed() {
            return newValue;
          },
        },
      });
    }
  };
  const handleSuccess = (data: ToggleWatchListData) => {
    const toggled = data?.toggleWatchListItem?.watchListItem.toggled;
    if (toggled != null) {
      setIsWatchListed(toggled);
      setShowToggledOn(toggled);
    }
  };
  const handleError = () => {
    setShowToggledOn(isWatchListed);
    setToast({
      style: ToastStyle.Error,
      text: STRINGS.table.rowActions.watchListFailed,
    });
  };

  const handleClick = () => {
    if (showToggledOn === !isWatchListed) {
      return;
    }
    setShowToggledOn(!isWatchListed);
    toggleWatchList({ variables: { resourceId: id } });
  };

  const className = classNames("CompaniesTableWatchListButton", {
    "CompaniesTableWatchListButton--on": showToggledOn,
  });

  return (
    <RowActionButton
      icon={
        <div className={className}>
          <FontAwesomeIcon
            icon={faStarSolid}
            style={FA_ICON_DEFAULT_STYLE}
            className="CompaniesTableWatchListButton__star-bg-icon"
          />
          <FontAwesomeIcon
            icon={faStar}
            style={FA_ICON_DEFAULT_STYLE}
            className="CompaniesTableWatchListButton__star-icon"
          />
        </div>
      }
      onClick={handleClick}
      tooltip={
        showToggledOn
          ? STRINGS.table.rowActions.removeFromWatchList
          : STRINGS.table.rowActions.addToWatchList
      }
    />
  );
};

export default CompaniesTableWatchListButton;
