import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { HOME_PAGE, Page, SETTINGS_PAGE } from "../../common/Breadcrumbs";
import { ButtonStyle } from "../../common/buttons/Button";
import Spinner, { ColourType } from "../../common/Spinner";
import {
  UpdatePasswordMutationMutationVariables,
  useUpdatePasswordMutationMutation,
} from "../../generated/graphql";
import Card from "../../layout/Card";
import { noop } from "../../utils/functools";
import { redirect } from "../../utils/location";
import routes from "../../utils/routes";
import { features } from "../../utils/settings";
import Auth0ChangePasswordButton from "./Auth0ChangePasswordButton";
import "./UpdatePassword.scss";
import UpdatePasswordForm from "./UpdatePasswordForm";
import { validate } from "./validation";

const AUTH0_FEATURE_FLAG = "authzAuthentication";

export const UPDATE_PASSWORD_BREADCRUMBS = [
  HOME_PAGE,
  SETTINGS_PAGE,
  {
    isCurrent: true,
    nameOrIcon: "Change Password",
  },
];

interface UpdatePasswordProps {
  setBreadcrumbs: (value: Page[]) => void;
}

interface UpdatePasswordDeps {
  validate_?: typeof validate;
  redirect_?: (url: string) => void;
  features_?: typeof features;
}

const UpdatePassword: React.FC<UpdatePasswordProps & UpdatePasswordDeps> = ({
  children,
  setBreadcrumbs,
  validate_ = validate,
  redirect_ = redirect,
  features_ = features,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setBreadcrumbs(UPDATE_PASSWORD_BREADCRUMBS);
  }, [setBreadcrumbs]);

  const [mutationFn, mutationResult] = useUpdatePasswordMutationMutation({
    onCompleted: () => redirect_(routes.logout),
  });

  const auth0Authentication = () => {
    return (
      AUTH0_FEATURE_FLAG in features_ && features_[AUTH0_FEATURE_FLAG] === true
    );
  };

  const onSubmit = useCallback(
    async (values: UpdatePasswordMutationMutationVariables) => {
      // Catch errors to prevent uncaught promise exceptions:
      // https://github.com/formium/formik/issues/1580
      await mutationFn({ variables: values }).catch(noop);
    },
    [mutationFn]
  );

  return (
    <div className="UpdatePassword">
      <Card title="Change Password" tegus>
        {auth0Authentication() ? (
          <Auth0ChangePasswordButton
            style={ButtonStyle.TegusPrimary}
            setIsUpdating={setIsUpdating}
          >
            Change Password
          </Auth0ChangePasswordButton>
        ) : (
          <div className="UpdatePassword__authzero-content">
            {children}
            <p>
              You will be asked to log in again after changing your password.
            </p>
            <Formik<UpdatePasswordMutationMutationVariables>
              initialValues={{
                oldPassword: "",
                newPassword: "",
                newPasswordConfirmed: "",
              }}
              onSubmit={onSubmit}
              validate={validate_}
            >
              {(props) => (
                <UpdatePasswordForm
                  {...props}
                  mutationError={mutationResult.error?.message}
                />
              )}
            </Formik>
          </div>
        )}
      </Card>
      {isUpdating && (
        <div className="UpdatePassword__overlay">
          <Spinner colour={ColourType.SECONDARY} size="3rem" />
        </div>
      )}
    </div>
  );
};

export default UpdatePassword;
