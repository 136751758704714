import React, { RefObject, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import { BackupTokensUpdateMfaBackupTokensMutationFn } from "../../generated/graphql";
import { useDownloadAsTxt } from "../../utils/location";
import { MfaEnforcedContext } from "./MfaSetup";

interface BackupTokenButtonsProps {
  tokens: string[];
  tokensRef: RefObject<HTMLDivElement>;
  generateNewTokens: BackupTokensUpdateMfaBackupTokensMutationFn;
  onFinish?: () => Promise<void>;
  useDownloadAsTxt_?: typeof useDownloadAsTxt;
  useReactToPrint_?: typeof useReactToPrint;
}
const BackupTokenButtons: React.FC<BackupTokenButtonsProps> = ({
  tokens,
  tokensRef,
  generateNewTokens,
  onFinish,
  useDownloadAsTxt_ = useDownloadAsTxt,
  useReactToPrint_ = useReactToPrint,
}) => {
  const { mfaEnforced } = useContext(MfaEnforcedContext);
  const tokensString = tokens.join("\n");
  const downloadAsTxt = useDownloadAsTxt_();
  const handlePrint = useReactToPrint_({
    content: () => tokensRef.current,
    copyStyles: false,
  });
  return (
    <div className="BackupTokenButtons">
      <ButtonGroup>
        <Button
          style={
            mfaEnforced ? ButtonStyle.TegusSecondary : ButtonStyle.TegusPrimary
          }
          action={() => navigator.clipboard.writeText(tokensString)}
          data-testid="copy"
        >
          Copy Codes
        </Button>
        <Button
          style={ButtonStyle.TegusSecondary}
          action={() =>
            downloadAsTxt(tokensString, "canalyst-backup-codes.txt")
          }
          data-testid="download"
        >
          Download
        </Button>
        <Button
          style={ButtonStyle.TegusSecondary}
          action={handlePrint}
          data-testid="print"
        >
          Print
        </Button>
        {mfaEnforced ? (
          <div className="ml-auto">
            <Button
              style={ButtonStyle.TegusPrimary}
              action={onFinish}
              data-testid="finish"
            >
              Finish
            </Button>
          </div>
        ) : (
          <Button
            style={ButtonStyle.TegusSecondary}
            action={generateNewTokens}
            data-testid="new-codes"
          >
            Generate New Codes
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
};

export default BackupTokenButtons;
