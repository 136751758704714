import { Folder } from "../generated/graphql";
import { ResourcesFolderIds } from "../resources/utils/graphql";
import { RESOURCE_FOLDER_ID_TO_DETAILS } from "../resources/utils/resources";
import routes from "./routes";

export const getFolderUrl = (
  folder: Pick<Folder, "__typename" | "id" | "path">
) => {
  return isResourceFolderId(folder.id)
    ? RESOURCE_FOLDER_ID_TO_DETAILS[folder.id].url
    : routes.companies(folder.path);
};

export const getFolderName = (
  folder: Pick<Folder, "__typename" | "id" | "name">
) => {
  return isResourceFolderId(folder.id)
    ? RESOURCE_FOLDER_ID_TO_DETAILS[folder.id].overrideName ||
        RESOURCE_FOLDER_ID_TO_DETAILS[folder.id].name
    : folder.name;
};

export const isResourceFolderId = (id: string): id is ResourcesFolderIds => {
  return Object.values(ResourcesFolderIds).includes(id as ResourcesFolderIds);
};
