/**
 * Companies table field names
 */
export enum Fields {
  name = "name",
  ticker = "ticker",
  region = "region",
  updated = "updated",
  updateType = "updateType",
  period = "period",
  revenue = "revenue",
  sector = "sector",
  industry = "industry",
  actions = "actions",
}

/**
 * Sort directions
 */
export enum SortDir {
  asc = "asc",
  desc = "desc",
}
