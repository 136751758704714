import annual_summary from "../../assets/annual_summary.jpg";
import banks_ddm from "../../assets/banks_ddm.png";
import banks_ma from "../../assets/banks_ma.png";
import bull_bear_base from "../../assets/bull_bear_base.jpg";
import dcf from "../../assets/dcf.jpg";
import grey_user_guide from "../../assets/grey_user_guide.jpg";
import incremental_ma from "../../assets/incremental_ma.png";
import lbo from "../../assets/lbo.jpg";
import ratios from "../../assets/ratios.jpg";
import reverse_dcf from "../../assets/reverse_dcf.png";
import toolkit_add_in from "../../assets/toolkit_add_in.jpg";
import toolkit_standalone from "../../assets/toolkit_standalone.jpg";
import yellow_user_guide from "../../assets/yellow_user_guide.jpg";
import { Page } from "../../common/Breadcrumbs";
import routes from "../../utils/routes";
import { ResourcesFolderIds } from "./graphql";

interface ResourceDetails {
  image: string | null;
  description: string | null;
}

interface ResourceFolderDetails {
  name: string;
  overrideName?: string;
  description: string;
  url: string;
}

export interface ResourceDetailsLookup {
  [seriesId: string]: ResourceDetails;
}

export const RESOURCE_FOLDER_ID_TO_DETAILS: Record<
  ResourcesFolderIds,
  ResourceFolderDetails
> = {
  [ResourcesFolderIds.screens]: {
    name: "Dashboards",
    description: `
      Feature data from multiple Canalyst models for comparative analysis and
      idea generation.
    `,
    url: routes.dashboards,
  },
  [ResourcesFolderIds.templates]: {
    name: "Drop-in Templates",
    description: `
      Add these pre-built templates to any Canalyst model for additional
      analyses.
      `,
    url: routes.toolsDropInTemplates,
  },
  [ResourcesFolderIds.toolkitAndGuides]: {
    name: "Canalyst Toolkit",
    overrideName: "Tools",
    description: `
      Update your custom models for earnings, and leverage other useful
      modeling tools.
    `,
    url: routes.tools,
  },
};

export const RESOURCE_FOLDER_ID_TO_PAGE: Record<ResourcesFolderIds, Page> = {
  [ResourcesFolderIds.screens]: {
    nameOrIcon: RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.screens].name,
    isCurrent: true,
  },
  [ResourcesFolderIds.templates]: {
    nameOrIcon:
      RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.templates].name,
    isCurrent: true,
  },
  [ResourcesFolderIds.toolkitAndGuides]: {
    nameOrIcon:
      RESOURCE_FOLDER_ID_TO_DETAILS[ResourcesFolderIds.toolkitAndGuides].name,
    isCurrent: true,
  },
};

export const RESOURCE_DETAILS_LOOKUP: ResourceDetailsLookup = {
  // Toolkit and Guides
  "a8814801-a71f-4c41-a3b9-7a7d20d83996": {
    // Toolkit - Standalone Program
    image: toolkit_standalone,
    description: `
      Use the Toolkit's Updater tool to automatically update your customized models
      with the most recent data from Canalyst. This standalone version requires no
      installation and can be run immediately upon download.
    `,
  },
  "8197db22-603b-41c7-8a42-1c484b20dd95": {
    // Toolkit - Excel Add-in
    image: toolkit_add_in,
    description: `
      Use the Toolkit's Updater tool to automatically update your customized models
      with the most recent data from Canalyst, right within Excel. Installation
      instructions for this Excel add-in can be found in the Canalyst Toolkit User
      Guide.
    `,
  },
  "661f6141-310c-4ecd-b9f1-6e2552bee212": {
    // Toolkit User Guide
    image: grey_user_guide,
    description: `
      This comprehensive guide includes the Canalyst Toolkit installation instructions,
      best practices, and tips for using the Updater and other tools in the Toolkit.
    `,
  },
  "5fafe7d1-6e45-4d42-9c00-87f1cbf835b6": {
    // Updater Quick Start Guide
    image: yellow_user_guide,
    description: `
      A one-page overview detailing the best practices for working with the
      Updater tool.
    `,
  },
  // Drop-in Templates
  "87dd0839-df1d-4a85-8142-0b8193233889": {
    // DCF
    image: dcf,
    description: `
      Estimates a company's valuation using the discounted cash flow method.
    `,
  },
  "ec3b841d-ab7f-4f3c-8a52-8948104ccabe": {
    // Reverse DCF
    image: reverse_dcf,
    description: `
      Estimates the terminal growth and discount rates the market is applying at the current stock price.
    `,
  },
  "6c834763-3c80-472d-b4dc-f39e83546063": {
    // Annual Summary
    image: annual_summary,
    description: `
      Provides an annual view of a company's key financial metrics.
    `,
  },
  "25df93c8-a484-4f6a-98f7-cb22ce9ddd15": {
    // Ratios (Annual-Grouped)
    image: ratios,
    description: `
      Provides a summary of a company's key activity, liquidity, solvency,
      profitability, and valuation ratios. For models in annual-grouped format.
    `,
  },
  "e876d270-9ab6-45fc-a9ca-ccb4f7fc93f9": {
    // Ratios
    image: ratios,
    description: `
      Provides a summary of a company's key activity, liquidity, solvency,
      profitability, and valuation ratios. For models in chronological format.
    `,
  },
  "331e9bc1-f206-4bc3-b238-42b7d24e84fb": {
    // LBO
    image: lbo,
    description: `
      Estimates the company's valuation in a leveraged buyout scenario.
    `,
  },
  "8ae09676-0978-47a7-99f1-f9b717f15708": {
    // Incremental M&A
    image: incremental_ma,
    description: `
      Estimates the effect of an acquisition on the financials of the acquiring
      company.
    `,
  },
  "128e3780-c5e5-43c6-bbd1-8ac279c6b372": {
    // Banks DDM
    image: banks_ddm,
    description: `
      Estimates a bank's potential dividend payouts, which are constrained by
      regulatory capital ratios.
    `,
  },
  "3e47272b-27de-4cbc-985e-91956d5d04a3": {
    // Banks M&A
    image: banks_ma,
    description: `
      Estimates the effect of an acquisition on the acquirer bank's financials.
    `,
  },
  "c019b54c-026b-4191-bf83-b3b776bc0c5e": {
    // Quick Bull-Bear-Base
    image: bull_bear_base,
    description: `
      Provides a quick bull/bear/base analysis on the stock's return using
      margin analysis.
    `,
  },
};
