import React from "react";
import { Link } from "react-router-dom";
import Button, { ButtonStyle } from "../common/buttons/Button";
import ButtonGroup from "../common/buttons/ButtonGroup";
import routes from "../utils/routes";

export const DOWNLOAD_LIMIT_EXCEEDED_TITLE = "Download Limit Exceeded";

const DownloadLimitExceeded: React.FC<{ primaryAction: () => void }> = ({
  primaryAction,
}) => {
  return (
    <div>
      <p>
        This download could not be completed because you have reached your
        download limit. Please try again later or{" "}
        <Link to={routes.support}>contact us</Link> if you are receiving this
        message in error.
      </p>
      <ButtonGroup>
        <Button style={ButtonStyle.TegusPrimary} action={primaryAction}>
          OK
        </Button>
        <Button style={ButtonStyle.TegusSecondary} linkTo={routes.support}>
          Contact Us
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default DownloadLimitExceeded;
