import { ApolloError } from "@apollo/client";
import { Formik } from "formik";
import React, { Context, useContext as useContextDefault } from "react";
import Spinner, { ColourType } from "../common/Spinner";
import {
  ContactUsCreateSupportRequestMutation,
  RequestKind,
  useContactUsCreateSupportRequestMutation as useContactUsCreateSupportRequestMutationDefault,
} from "../generated/graphql";
import { GenericResource } from "../home/common/utils/graphql";
import ModalContext, { ModalContextValue } from "../modal/ModalContext";
import ToastContext, { ToastContextValue } from "../toast/ToastContext";
import { ToastStyle } from "../toast/ToastDisplay";
import { UpdateReasonChoice } from "./ContactUs";
import "./ModelUpdateRequest.scss";
import ModelUpdateRequestForm from "./ModelUpdateRequestForm";

interface ModelUpdateRequestProps {
  resource: GenericResource;
}

export interface ModelUpdateRequestState {
  updateReason?: UpdateReasonChoice;
  company: string;
  comments: string;
  formError: string;
}

export interface ModelUpdateRequestDeps {
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
  useToastContext?: (context: Context<ToastContextValue>) => ToastContextValue;
  useCreateSupportRequestMutation?: typeof useContactUsCreateSupportRequestMutationDefault;
}

const ModelUpdateRequest: React.FC<
  ModelUpdateRequestProps & ModelUpdateRequestDeps
> = ({
  resource,
  useModalContext = useContextDefault,
  useToastContext = useContextDefault,
  useCreateSupportRequestMutation = useContactUsCreateSupportRequestMutationDefault,
}) => {
  const { setToast } = useToastContext(ToastContext);
  const { clearModal } = useModalContext(ModalContext);

  const onError = (error: ApolloError) => {
    setToast({
      style: ToastStyle.Error,
      text: "Uh oh. There was an issue sending the support request email",
    });
  };
  const onCompleted = (data: ContactUsCreateSupportRequestMutation) => {
    setToast({
      style: ToastStyle.Info,
      text: "Support request successfully sent",
    });
    clearModal();
  };
  const [sendRequest, result] = useCreateSupportRequestMutation({
    onCompleted: onCompleted,
    onError: onError,
  });

  return (
    <div className="ModelUpdateRequest">
      <Formik<ModelUpdateRequestState>
        initialValues={{
          updateReason: undefined,
          company: resource.name,
          comments: "",
          formError: "",
        }}
        onSubmit={(values, actions) => {
          if (!values.updateReason) {
            actions.setErrors({
              formError: "You must select an Update Reason",
            });
          } else {
            setToast({
              style: ToastStyle.Info,
              text: "Sending support request…",
            });
            sendRequest({
              variables: {
                companyOrTicker: values.company,
                requestBody: values.comments,
                modelUpdateType: values.updateReason?.id,
                requestType: RequestKind.ModelUpdate,
              },
            });
          }
        }}
      >
        {(FormikProps) => {
          if (result.called && result.loading) {
            return (
              <div className="ModelUpdateRequest__loading">
                <Spinner
                  colour={ColourType.SECONDARY}
                  size="3rem"
                  data-testid="loading"
                />
              </div>
            );
          } else {
            return (
              <ModelUpdateRequestForm
                values={FormikProps.values}
                errors={FormikProps.errors}
                onCancel={clearModal}
              />
            );
          }
        }}
      </Formik>
    </div>
  );
};

export default ModelUpdateRequest;
