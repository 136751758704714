import React, { RefObject, useCallback, useEffect } from "react";
import { useEvent } from "../utils/events";
import { Result } from "./utils/results";

export interface SearchDropdownShortcutsProps {
  inputRef: RefObject<HTMLInputElement>;
  results: Result[] | null;
  selectedResult: Result | null;
  setSelectedResult: (value: Result | null) => void;
}

/**
 * Manages keyboard shortcuts for the search dropdown. Does not render anything
 *
 * @param props - Component props
 * @param props.inputRef - A reference to the search <input />
 * @param props.results - The current search results
 * @param props.selectedResult - The current selected/highlighted search result
 * @param props.setSelectedResult - Set the selected/highlighted search result
 */
const SearchDropdownShortcuts: React.FC<SearchDropdownShortcutsProps> = ({
  inputRef,
  results,
  selectedResult,
  setSelectedResult,
}) => {
  useEvent(
    window,
    "keydown",
    useCallback(
      (ev) => {
        if (ev.target !== inputRef.current) return;
        if (ev.key !== "ArrowUp" && ev.key !== "ArrowDown") return;
        if (!results?.length) return;

        ev.preventDefault();

        const up = ev.key === "ArrowUp";
        const selectedResultIdxNew = (() => {
          if (selectedResult === null) {
            return up ? results.length - 1 : 0;
          } else {
            const selectedResultIdx = results.findIndex(
              (r) => r.id === selectedResult?.id
            );
            return up ? selectedResultIdx - 1 : selectedResultIdx + 1;
          }
        })();
        setSelectedResult(results[selectedResultIdxNew] || null);
      },
      [inputRef, selectedResult, setSelectedResult, results]
    )
  );

  useEffect(() => {
    if (results === null) setSelectedResult(null);
  }, [results, setSelectedResult]);

  return null;
};

export default SearchDropdownShortcuts;
