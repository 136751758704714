import { gql } from "@apollo/client";
import { GraphQlObject } from "../../common/graphql";

// Type definitions for the ApiTokens GraphQL query
export interface ApiToken extends GraphQlObject {
  id: string;
  description: string | null;
  created: string;
}

export interface ApiTokenData {
  myApiTokens: ApiToken[];
}

// The above types must be kept in sync with this query
export const GET_USER_API_TOKENS_QUERY = gql`
  query getMyApiTokens {
    myApiTokens {
      id
      description
      created
    }
  }
`;
