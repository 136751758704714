import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import Action from "../common/Action";
import Button, { ButtonStyle } from "../common/buttons/Button";
import ContextMenu from "../context_menu/ContextMenu";
import { ResourceDisplay } from "../generated/graphql";
import { useCopyShareLink } from "../home/browse/utils/sharing";
import Card from "../layout/Card";
import { useDownload as useDownloadDefault } from "../utils/location";
import "./ResourceCard.scss";
import { ColumnSize } from "./ResourcesDisplay";

interface ResourceDisplayProps {
  resourceDisplay: ResourceDisplay;
  columnSize: ColumnSize;
  highlighted: boolean;
}

interface ResourceDisplayDeps {
  useDownload?: () => (url: string) => void;
  useCopyShareLink_?: typeof useCopyShareLink;
}

const ResourceDisplayItem = React.forwardRef<
  HTMLDivElement,
  ResourceDisplayProps & ResourceDisplayDeps
>(
  (
    {
      resourceDisplay,
      columnSize,
      highlighted,
      useDownload = useDownloadDefault,
      useCopyShareLink_ = useCopyShareLink,
    },
    ref
  ) => {
    const download = useDownload();
    const copyShareLink = useCopyShareLink_();
    const downloadUrl = resourceDisplay.downloadUrl || "";
    const resourceCardClassName = classNames("ResourceCard", columnSize);
    return (
      <div
        className={resourceCardClassName}
        data-testid="resourcedisplayitem"
        ref={ref}
      >
        <Card highlighted={highlighted}>
          <img
            src={resourceDisplay.imageUrl}
            alt={resourceDisplay.imageAltText}
            data-testid="resourcedisplayitem-img"
          />
          <h4 data-testid="resourcedisplayitem-title">
            {resourceDisplay.title}
          </h4>
          <p role="paragraph" data-testid="resourcedisplayitem-descr">
            {resourceDisplay.description}
          </p>
          <div className="ResourceDisplayItem__button">
            <ContextMenu
              items={
                <>
                  <Action
                    action={() =>
                      copyShareLink({
                        id: resourceDisplay.fiid || "",
                        slug: resourceDisplay.slug || "",
                      })
                    }
                    data-testid="action-copy-link"
                  >
                    Copy Link
                  </Action>
                </>
              }
            >
              <Button
                role="button"
                style={ButtonStyle.TegusTertiaryAlt}
                action={() => download(downloadUrl)}
                data-testid="resourcedisplayitem-button"
                aria-label={`download ${resourceDisplay.title}`}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{
                    transform: "scale(0.9) translate(0rem, -0.067rem)",
                    marginRight: "0.25rem",
                  }}
                />
                Download
              </Button>
            </ContextMenu>
          </div>
        </Card>
      </div>
    );
  }
);

export default ResourceDisplayItem;
