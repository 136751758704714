// Modify the `settings.FRONTEND_SETTINGS` Django setting to pass environment variables
// to the frontend app (here). The values of `settings.FRONTEND_SETTINGS` are rendered
// in the `frontend/public/index.html` template then retrieved here.

export type Features = Record<string, boolean | undefined>;
export type PasswordRequirement = { regex: string; description: string };

export interface Settings {
  ipoFolderPath: string | null;
  privateFolderItemCount: number | null;
  privateFolderPath: string | null;
  sentryDsn: string | null;
  sentryReleaseVersion: string | null;
  useOldInterface: boolean;
  watchListFolderPath: string | null;
  screensFolderPath: string | null;
  features: Features;
  jwtExpirySeconds: number | null;
  passwordRequirements: PasswordRequirement[];
  userSetupComplete: boolean;
  zendeskWidgetToken: string | null;
}

// A display value for when the release version is unknown. sentryReleaseVersion
// still defaults to null which is more useful in most code.
export const RELEASE_VERSION_UNKNOWN = "canalyst@unknown";

// Since the properties of the settings object parsed from JSON cannot be enforced at
// runtime, we must specify default property values. Remember to do that here.
export const settingsDefault: Settings = {
  ipoFolderPath: null,
  privateFolderItemCount: null,
  privateFolderPath: null,
  sentryDsn: null,
  sentryReleaseVersion: null,
  useOldInterface: false,
  watchListFolderPath: null,
  screensFolderPath: null,
  features: {},
  jwtExpirySeconds: null,
  passwordRequirements: [],
  userSetupComplete: true, // Default to true e.g. for anonymous users
  zendeskWidgetToken: null,
};

let settingsSparse = {};
const settingsElem = document.getElementById("settings");
if (settingsElem) {
  try {
    // `textContent` should never be null in our case. See here:
    // https://developer.mozilla.org/en-US/docs/Web/API/Node/textContent
    settingsSparse = JSON.parse(settingsElem.textContent as string);
  } catch (err) {
    console.error(
      `Error parsing #settings element JSON. Using default settings:\n${err}`
    );
  }
} else {
  console.error("Cannot find #settings element. Using default settings");
}

// The values spread from `settingsSparse` override the values spread from
// `settingsDefault`. This allows us to apply default values for undefined properties.
const settings: Settings = {
  ...settingsDefault,
  ...settingsSparse,
};

export const { features } = settings;
export default settings;
