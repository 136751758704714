import React from "react";
import "./KeyHint.scss";

interface KeyHintProps {
  className?: string;
}

const KeyHint: React.FC<KeyHintProps> = ({ children, className = "" }) => {
  return <span className={`KeyHint ${className}`}>{children}</span>;
};

export default KeyHint;
