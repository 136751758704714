import {
  faCogs,
  faEnvelope,
  faKey,
  faLockAlt,
  faUserShield,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { RouteProps } from "react-router-dom";
import { HOME_PAGE, Page, SETTINGS_PAGE } from "../common/Breadcrumbs";
import Button, { ButtonStyle, ButtonType } from "../common/buttons/Button";
import ButtonGroup from "../common/buttons/ButtonGroup";
import FancyListItem from "../common/FancyListItem";
import Spinner, { ColourType } from "../common/Spinner";
import { useUserSettingsMeQuery } from "../generated/graphql";
import Card from "../layout/Card";
import { FA_ICON_DEFAULT_STYLE } from "../utils/icons";
import routes from "../utils/routes";
import { features } from "../utils/settings";
import "./UserSettings.scss";

export interface UserSettingsProps extends RouteProps {
  setBreadcrumbs: (value: Page[]) => void;
}

export const SETTINGS_PAGES = [
  HOME_PAGE,
  { ...SETTINGS_PAGE, isCurrent: true },
];

const UserSettings: React.FC<UserSettingsProps> = ({ setBreadcrumbs }) => {
  const { loading, error, data } = useUserSettingsMeQuery();

  useEffect(() => {
    setBreadcrumbs(SETTINGS_PAGES);
  }, [setBreadcrumbs]);

  if (loading) {
    return (
      <div className="UserSettings UserSettings--loading-spinner-container">
        <Spinner colour={ColourType.SECONDARY} size="3rem" />
      </div>
    );
  }
  if (!data?.me || error) {
    return (
      <Card title="Uh oh." data-testid="error-500">
        <p>
          Something went wrong when retrieving the settings page. Please try
          again later or <a href={routes.support}>contact support</a> for
          assistance.
        </p>
        <ButtonGroup>
          <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
          <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
            Contact Us
          </Button>
        </ButtonGroup>
      </Card>
    );
  }

  const noSetupRequired =
    features.authzAuthentication || data.me.mfaSetupComplete;

  return (
    <div className="UserSettings">
      <Card tegus>
        <div className="row">
          <div className="col-12">
            {data.me.djangoGlobalPerms.includes("authorization.add_appjwt") ? (
              <FancyListItem
                data-testid="item-api-tokens"
                icon={
                  <FontAwesomeIcon icon={faKey} style={FA_ICON_DEFAULT_STYLE} />
                }
                text="API Tokens"
                linkTo={routes.settingsApiTokens}
              />
            ) : null}
            <FancyListItem
              data-testid="item-password"
              icon={
                <FontAwesomeIcon
                  icon={faLockAlt}
                  style={FA_ICON_DEFAULT_STYLE}
                />
              }
              text="Change Password"
              linkTo={routes.settingsPassword}
            />
            <FancyListItem
              data-testid="item-email"
              icon={
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={FA_ICON_DEFAULT_STYLE}
                />
              }
              text="Email Settings"
              linkTo={routes.settingsEmail}
            />
            <FancyListItem
              data-testid="item-default-format"
              icon={
                <FontAwesomeIcon icon={faCogs} style={FA_ICON_DEFAULT_STYLE} />
              }
              text="Default Model Format Settings"
              linkTo={routes.settingsDefaultModelFormat}
            />
            {noSetupRequired ? (
              <FancyListItem
                data-testid="item-mfa-settings"
                icon={
                  <FontAwesomeIcon
                    icon={faUserShield}
                    style={FA_ICON_DEFAULT_STYLE}
                  />
                }
                text="Multi-Factor Authentication Settings"
                linkTo={routes.settingsMfa}
              />
            ) : (
              <FancyListItem
                data-testid="item-mfa-setup"
                icon={
                  <FontAwesomeIcon
                    icon={faUserShield}
                    style={FA_ICON_DEFAULT_STYLE}
                  />
                }
                text="Multi-Factor Authentication Setup"
                linkTo={routes.settingsMfaSetup}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default UserSettings;
