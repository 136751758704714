import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./ModalContainer.scss";
import ModalContext from "./ModalContext";
import ModalDisplay from "./ModalDisplay";

/**
 * Component responsible for rendering modal
 */
const ModalContainer: React.FC = () => {
  const { currentModal, clearModal } = useContext(ModalContext);
  const history = useHistory();

  useEffect(() => {
    // Clear modal after any navigation
    return history.listen((_location) => {
      clearModal();
    });
  }, [clearModal, history]);

  // preventDefault is called on the onMouseDown event to stop the focus being removed
  // from the search dropdown when a user clicks off the modal
  return (
    <div className="ModalContainer">
      {currentModal && <ModalDisplay modal={currentModal} />}
      {currentModal && (
        <div
          className="ModalContainer__wrapper ModalContainer__wrapper--animate"
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => clearModal()}
        />
      )}
    </div>
  );
};

export default ModalContainer;
