import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { useField } from "formik";
import React, { useState } from "react";
import { noop } from "../utils/functools";
import { readFile } from "../utils/readFile";
import "./FileUpload.scss";

// Settings
const defaultFilenameWidth = "12rem";
const defaultHasClearButton = false;

// End-user visible strings
const buttonText = "Choose file...";
const clearFileText = "Clear file";
const defaultFilenameText = "No file chosen";

// Error messages
const fileReadError = "Unable to read file";

// ARIA labels
const LABEL_FILE_UPLOAD = "File upload";

export interface FileData {
  name: string;
  mimetype: string;
  data: string;
  size: number;
}

export interface FileUploadProps {
  name: string;
  filenameWidth?: string;
  hasClearButton?: boolean;
  onFileSelected?: (file: FileData) => any;
  onFileCleared?: () => any;
}

export interface FileUploadDeps {
  logError?: typeof console.error;
}

/**
 * A custom-themed file selector form control
 *
 * @param props - Component props
 * @param props.name - The internal name of the field
 * @param props.filenameWidth - Filename display width
 * @param props.hasClearButton - Whether to show the reset / clear file button
 * @param props.onFileSelected - Hook that is called (with FileData object) when user selects a file
 * @param props.onFileCleared - Hook that is called when user clears the selected file
 */
const FileUpload: React.FC<FileUploadProps & FileUploadDeps> = ({
  name,
  filenameWidth = defaultFilenameWidth,
  hasClearButton = defaultHasClearButton,
  onFileSelected = noop,
  onFileCleared = noop,
  logError = console.error,
}) => {
  const [fileInputKey, setFileInputKey] = useState(name + Date.now());
  const [clearEnabled, setClearEnabled] = useState(false);
  const [field, , helper] = useField(name);

  // Get MIME type from base64 file data
  const _getMimeType = (data: string) => {
    return data.split(",")[0].split(":")[1].split(";")[0];
  };

  // Handle clear button
  const _onClear = () => {
    helper.setValue(null);
    setFileInputKey(name + Date.now());

    setClearEnabled(false);
    onFileCleared();
  };

  // Handle file select
  const _onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "" || e.target.files === null) {
      _onClear();
      return;
    }

    // Remove C:\fakepath\ from filename
    const name = e.target.value.replace(/^.*\\/g, "");

    // Enable clear button
    setClearEnabled(true);

    // Store file data
    try {
      const fileSize = e.target.files[0].size;
      const fileContents = await readFile(e.target.files[0]);
      const file: FileData = {
        name: name,
        mimetype: _getMimeType(fileContents),
        data: fileContents,
        size: fileSize,
      };
      helper.setValue(file);
      onFileSelected(file);
    } catch (e: any) {
      logError(fileReadError);
    }
  };

  return (
    <div className="FileUpload">
      {/* Choose file button */}
      <label
        htmlFor={field.name}
        className="FileUpload__button"
        role="button"
        aria-label={LABEL_FILE_UPLOAD}
      >
        <input
          key={fileInputKey}
          id={field.name}
          type="file"
          data-testid="file-upload"
          onChange={_onChange}
          role="button"
          aria-label="File Upload"
        />
        {buttonText}
      </label>
      {/* Filename display */}
      <div
        className="FileUpload__filename"
        title={field.value ? field.value.name : defaultFilenameText}
        style={{ width: filenameWidth }}
        role="note"
        data-testid="file-upload-description"
      >
        {field.value ? field.value.name : defaultFilenameText}
      </div>
      {/* Clear button */}
      {hasClearButton && (
        <div
          className="FileUpload__clear"
          onClick={clearEnabled ? _onClear : undefined}
          role="button"
          aria-label={clearFileText}
          aria-disabled={!clearEnabled}
        >
          <Tippy content={clearFileText} delay={100}>
            <span>
              <FontAwesomeIcon
                icon={faTimes}
                className={
                  clearEnabled ? "ClearButton" : "ClearButton--disabled"
                }
                style={{
                  transform: "scale(1.5)",
                  transformOrigin: "center",
                  cursor: clearEnabled ? "pointer" : "default",
                }}
              />
            </span>
          </Tippy>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
