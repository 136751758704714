import { ReactElement } from "react";
import routes from "../utils/routes";

export const PLEASE_TRY_AGAIN = "Please try again later or ";
export const FOR_ASSISTANCE = " for assistance.";
export const CONTACT_SUPPORT = "contact support";

/**
 * Return standardized error message
 *
 * @param message Custom message to add
 * @param addLineBreak (optional) Whether to include line break after custom message
 * @returns Error message JSX
 */
export const getErrorMessage = (
  message: string,
  addLineBreak?: boolean
): ReactElement => {
  return (
    <>
      {message}
      {!!addLineBreak ? <br /> : " "}
      {PLEASE_TRY_AGAIN}
      <a href={routes.support}>{CONTACT_SUPPORT}</a>
      {FOR_ASSISTANCE}
    </>
  );
};
