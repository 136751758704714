import { Context, useContext as useContextDefault } from "react";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import ModalContext, { ModalContextValue } from "../../modal/ModalContext";

interface Auth0RecoveryTokenModalProps {
  token: string;
}

interface Auth0RecoveryTokenModalDeps {
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
}

const Auth0RecoveryTokenModal: React.FC<
  Auth0RecoveryTokenModalProps & Auth0RecoveryTokenModalDeps
> = ({ token, useModalContext = useContextDefault }) => {
  const { clearModal } = useModalContext(ModalContext);

  return (
    <div
      className="Auth0RecoveryTokenModal"
      data-testid="auth0-recovery-token-modal"
    >
      <p role="paragraph">
        Important note: We recommend that you copy, download, or print this
        recovery token and keep it in a safe place.
      </p>
      <p role="paragraph">
        Use this recovery token to log in to your Canalyst account if you cannot
        access the authenticator app on your phone.
      </p>
      <ul>
        <li>{token}</li>
      </ul>
      <ButtonGroup>
        <Button
          style={ButtonStyle.TegusPrimary}
          action={clearModal}
          role="button"
        >
          Continue
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default Auth0RecoveryTokenModal;
