import { ApolloCache } from "@apollo/client";
import React, { Context, useContext as useContextDefault } from "react";
import { useHistory } from "react-router-dom";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import {
  DisableMfaButtonDisableMfaMutation,
  useDisableMfaButtonDisableMfaMutation,
} from "../../generated/graphql";
import ToastContext from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import { redirect as redirectDefault } from "../../utils/location";

interface DisableMfaButtonProps {
  userId: string;
  style: ButtonStyle;
  href?: string;
  linkTo?: string;
}

interface DisableMfaButtonDeps {
  useContext?: <ToastContextValue>(
    context: Context<ToastContextValue>
  ) => ToastContextValue;
  redirect?: (url: string) => void;
}

/**
 * A button that disables userId's MFA and optionally redirects
 *
 * Provide href to redirect to pages on the MPA and linkTo to redirect to pages
 * on the SPA. If both are provided href will be used and if niether are
 * provided the button will not redirect.
 */
const DisableMfaButton: React.FC<DisableMfaButtonProps & DisableMfaButtonDeps> =
  ({
    children,
    userId,
    style,
    href,
    linkTo,
    useContext = useContextDefault,
    redirect = redirectDefault,
  }) => {
    const history = useHistory();
    const [disableMfa] = useDisableMfaButtonDisableMfaMutation({
      update: (cache, { data }) => handleUpdate(cache, data),
      onCompleted: (result) => handleSuccess(result),
      onError: () => handleError(),
    });
    const { setToast } = useContext(ToastContext);

    const handleUpdate = (
      cache: ApolloCache<DisableMfaButtonDisableMfaMutation>,
      data?: DisableMfaButtonDisableMfaMutation | null
    ) => {
      if (data?.disableMfa?.success) {
        cache.modify({
          id: `User:${userId}`,
          fields: {
            mfaSetupComplete() {
              return false;
            },
          },
        });
      }
    };
    const handleSuccess = (result: DisableMfaButtonDisableMfaMutation) => {
      if (result.disableMfa?.success) {
        setToast({
          style: ToastStyle.Info,
          text: "MFA device successfully removed",
        });
        handleRedirect();
      } else {
        handleError();
      }
    };
    const handleError = () => {
      setToast({
        style: ToastStyle.Error,
        text: "Removing your current MFA device failed",
      });
    };
    const handleClick = () => {
      disableMfa({ variables: { userId: userId } });
    };
    const handleRedirect = () => {
      if (href) {
        redirect(href);
      } else if (linkTo) {
        history.push(linkTo);
      }
    };
    return (
      <Button style={style} action={handleClick}>
        {children}
      </Button>
    );
  };

export default DisableMfaButton;
