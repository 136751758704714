import classNames from "classnames";
import React, { memo } from "react";
import Button, { ButtonStyle } from "../common/buttons/Button";
import "./SearchResult.scss";
import { Result } from "./utils/results";

interface SearchResultProps {
  result: Result;
  selectedResult: Result | null;
  setSelectedResult: (value: Result | null) => void;
}

/**
 * Display a single search result (in the search dropdown)
 *
 * @param props - Component props
 * @param props.result - The search result
 * @param props.selectedResult - The current selected/highlighted search result
 * @param props.setSelectedResult - Set the selected/highlighted search result
 */
const SearchResult: React.FC<SearchResultProps> = ({
  result,
  selectedResult,
  setSelectedResult,
}) => {
  const secondaryActionContent = (() => {
    if (!result.secondaryAction) return null;
    const secondaryAction = result.secondaryAction;
    const onMouseUp = (event: React.MouseEvent) => {
      event.stopPropagation();
    };
    return (
      <Button
        style={ButtonStyle.TegusSecondarySlim}
        action={secondaryAction.action}
        className="SearchResult__secondary-action"
        onMouseUp={onMouseUp}
      >
        {secondaryAction.text}
      </Button>
    );
  })();

  const className = classNames("SearchResult", {
    "SearchResult--selected": result.id === selectedResult?.id,
    "SearchResult--with-subtext": !!result.subtext,
  });
  return (
    <div
      className={className}
      onMouseDown={(e) => e.preventDefault()}
      onMouseUp={() => result.action.action()}
      onMouseEnter={() => setSelectedResult(result)}
    >
      <div className="SearchResult__icon">{result.icon}</div>
      <div className="SearchResult__text-container">
        <div className="SearchResult__text">{result.text}</div>
        {result.subtext && (
          <div className="SearchResult__subtext">{result.subtext}</div>
        )}
      </div>
      {secondaryActionContent}
    </div>
  );
};

export default memo(SearchResult);
