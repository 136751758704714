import { Context, useContext as useContextDefault } from "react";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import {
  MfaSettingsAuth0ResetMfaMutation,
  useMfaSettingsAuth0ResetMfaMutation,
} from "../../generated/graphql";
import ToastContext from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import { redirect as redirectDefault } from "../../utils/location";

interface ResetMfaAuth0ButtonProps {
  userId: string;
  disable: boolean;
  style: ButtonStyle;
  setIsUpdating: (value: boolean) => void;
}

interface ResetMfaAuth0ButtonDeps {
  useToastContext?: <ToastContextValue>(
    context: Context<ToastContextValue>
  ) => ToastContextValue;
  redirect?: (url: string) => void;
  open?: (url: string, target: string) => void;
}

const ResetMfaAuth0Button: React.FC<
  ResetMfaAuth0ButtonProps & ResetMfaAuth0ButtonDeps
> = ({
  children,
  userId,
  disable,
  style,
  setIsUpdating,
  useToastContext = useContextDefault,
  redirect = redirectDefault,
  open = window.open,
}) => {
  const { setToast } = useToastContext(ToastContext);

  const [resetMfa] = useMfaSettingsAuth0ResetMfaMutation({
    onCompleted: (result) => handleSuccess(result),
    onError: () => handleError(),
  });

  const handleSuccess = (result: MfaSettingsAuth0ResetMfaMutation) => {
    if (result.resetMfaAuth0?.success) {
      setIsUpdating(false);
      setToast({
        style: ToastStyle.Info,
        text: "MFA successfully reset",
      });
      if (disable) {
        window.location.reload();
      }
      if (!disable && result.resetMfaAuth0?.url) {
        handleRedirect(result.resetMfaAuth0.url);
      }
    } else {
      handleError();
    }
  };

  const handleClick = () => {
    setIsUpdating(true);
    resetMfa({ variables: { userId: userId, generateTicket: !disable } });
    // How to redirect back to app after MFA reset:
    // https://community.auth0.com/t/want-to-redirect-user-after-mfa-enrollment/79252
  };

  const handleError = () => {
    setIsUpdating(false);
    setToast({
      style: ToastStyle.Error,
      text: "Failed to reset your MFA",
    });
  };

  const handleRedirect = (url: string) => {
    open(url, "_blank");
    redirect("/settings");
  };

  return (
    <Button style={style} action={handleClick} role="button">
      {children}
    </Button>
  );
};

export default ResetMfaAuth0Button;
