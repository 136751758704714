import { Context, useContext as useContextDefault } from "react";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import { useUpdatePasswordCreateAuth0PasswordResetTicketMutation } from "../../generated/graphql";
import ToastContext from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import { redirect as redirectDefault } from "../../utils/location";

interface Auth0ChangePasswordButtonProps {
  style: ButtonStyle;
  setIsUpdating: (value: boolean) => void;
}

interface Auth0ChangePasswordButtonDeps {
  useToastContext?: <ToastContextValue>(
    context: Context<ToastContextValue>
  ) => ToastContextValue;
  redirect?: (url: string) => void;
}

const Auth0ChangePasswordButton: React.FC<
  Auth0ChangePasswordButtonProps & Auth0ChangePasswordButtonDeps
> = ({
  children,
  style,
  setIsUpdating,
  useToastContext = useContextDefault,
  redirect = redirectDefault,
}) => {
  const { setToast } = useToastContext(ToastContext);
  const [generateTicket] =
    useUpdatePasswordCreateAuth0PasswordResetTicketMutation({
      onCompleted: (data) => {
        if (data.createAuth0PasswordResetToken?.ticket) {
          redirect(data.createAuth0PasswordResetToken.ticket);
        } else {
          handleError();
        }
      },
      onError: () => handleError(),
    });

  const handleError = () => {
    setIsUpdating(false);
    setToast({
      style: ToastStyle.Error,
      text: "Failed to generate password reset ticket",
    });
  };

  const handleClick = () => {
    setIsUpdating(true);
    generateTicket();
  };

  return (
    <div className="Auth0ChangePasswordButton">
      <Button style={style} action={handleClick} role="button">
        {children}
      </Button>
    </div>
  );
};

export default Auth0ChangePasswordButton;
