/**
 * Omit the "test-id" property from an object. Useful for helping tests
 *
 * @param obj - Any object (usually component props)
 */
export const omitTestId = <T,>(obj: T): Omit<T, "data-testid"> => {
  const { "data-testid": omitted, ...objFinal } = obj as any;
  return objFinal as Omit<T, "data-testid">;
};

/**
 * Pick fields from an object
 *
 * @param obj - Object from which to pick fields
 * @param fields - Fields you want picked from the objects
 */
export const pick = <T extends Record<string, any>, U extends keyof T>(
  obj: T,
  fields: Array<U>
): Pick<T, U> => {
  const ret = Object.create(null);
  for (const k of fields) {
    ret[k] = obj[k];
  }
  return ret;
};
