import React, { useMemo, useState } from "react";
import ToastContext from "./ToastContext";
import { Toast } from "./ToastDisplay";

const ToastProvider: React.FC = ({ children }) => {
  const [currentToast, setCurrentToast] = useState<Toast | null>(null);

  const toastContextValue = useMemo(() => {
    return {
      currentToast: currentToast,
      clearToast: () => setCurrentToast(null),
      setToast: (val: Toast | null) => {
        setCurrentToast(val);
      },
      setToastAsync: (val: Toast | null) => {
        setTimeout(() => setCurrentToast(val));
      },
    } as any;
  }, [currentToast]);

  return (
    <ToastContext.Provider value={toastContextValue}>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
