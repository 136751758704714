import { ApolloCache, ApolloError, useMutation } from "@apollo/client";
import { faStar } from "@fortawesome/pro-light-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, {
  Context,
  useContext as useContextDefault,
  useState,
} from "react";
import ToastContext from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import { FA_ICON_DEFAULT_STYLE } from "../../utils/icons";
import { GenericResource } from "./utils/graphql";
import {
  ToggleWatchListData,
  ToggleWatchListVars,
  TOGGLE_WATCH_LIST,
} from "./WatchListElem.graphql";
import "./WatchListElem.scss";

export interface WatchListElemProps {
  resource: GenericResource;
}

export interface WatchListElemDeps {
  useContext?: <T>(context: Context<T>) => T;
}

const WatchListElem: React.FC<WatchListElemProps & WatchListElemDeps> = ({
  resource,
  useContext = useContextDefault,
}) => {
  const [toggleWatchList] = useMutation<
    ToggleWatchListData,
    ToggleWatchListVars
  >(TOGGLE_WATCH_LIST, {
    update: (cache, { data }) => handleUpdate(cache, data),
    onCompleted: (result) => handleSuccess(result),
    onError: (error) => handleError(error),
  });
  const [showToggledOn, setShowToggledOn] = useState(
    resource.watchListed === true
  );
  const [isWatchListed, setIsWatchListed] = useState(
    resource.watchListed === true
  );
  const { setToast } = useContext(ToastContext);

  const handleUpdate = (
    cache: ApolloCache<ToggleWatchListData>,
    data: ToggleWatchListData | null | undefined
  ) => {
    const newValue = data?.toggleWatchListItem.watchListItem.toggled;
    if (newValue != null) {
      cache.modify({
        id: cache.identify(resource),
        fields: {
          watchListed() {
            return newValue;
          },
        },
      });
    }
  };
  const handleSuccess = (data: ToggleWatchListData) => {
    const toggled = data.toggleWatchListItem.watchListItem.toggled;
    setIsWatchListed(toggled);
    setShowToggledOn(toggled);
  };
  const handleError = (error: ApolloError) => {
    setShowToggledOn(isWatchListed);
    setToast({ style: ToastStyle.Error, text: "Watchlisting failed" });
  };
  const handleNotSupported = () => {
    setToast({
      style: ToastStyle.Error,
      text: "Watchlisting is not supported for this resource",
    });
  };
  const handleClick = () => {
    if (resource.watchListed === null) {
      handleNotSupported();
      return;
    }
    if (showToggledOn === !isWatchListed) {
      return;
    }
    setShowToggledOn(!isWatchListed);

    toggleWatchList({ variables: { resourceId: resource.id } });
  };

  const className = classNames("WatchListElem", {
    "WatchListElem--on": showToggledOn,
  });
  return (
    <div className={className} onClick={handleClick}>
      <FontAwesomeIcon
        icon={faStarSolid}
        style={FA_ICON_DEFAULT_STYLE}
        className="WatchListElem__star-bg-icon"
      />
      <FontAwesomeIcon
        icon={faStar}
        style={FA_ICON_DEFAULT_STYLE}
        className="WatchListElem__star-icon"
      />
    </div>
  );
};

export default WatchListElem;
