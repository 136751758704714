import {
  BrowseFolderFragment,
  BrowsePlaceholderFragment,
  BrowseResourceFragment,
  SearchFolderFragment,
  SearchPlaceholderFragment,
  SearchResourceFragment,
} from "../../../generated/graphql";
import { BrowseFolderItem } from "../../browse/utils/graphql";
import { SearchSearchItem } from "../../search/utils/graphql";

export type GenericItem = BrowseFolderItem | SearchSearchItem;

export type GenericFolder = BrowseFolderFragment | SearchFolderFragment;
export type GenericPlaceholder =
  | BrowsePlaceholderFragment
  | SearchPlaceholderFragment;
export type GenericResource = BrowseResourceFragment | SearchResourceFragment;
export const isGenericPlaceholder = (
  item: GenericItem
): item is GenericPlaceholder => {
  return item.__typename === "Placeholder";
};
export const isGenericResource = (
  item: GenericItem
): item is GenericResource => {
  return item.__typename === "Resource";
};

export const isSearchFolder = (
  folder: GenericFolder
): folder is SearchFolderFragment => {
  return folder.hasOwnProperty("parentFolder");
};
export const isSearchPlaceholder = (
  placeholder: GenericPlaceholder
): placeholder is SearchPlaceholderFragment => {
  return placeholder.hasOwnProperty("folder");
};
export const isSearchResource = (
  resource: GenericResource
): resource is SearchResourceFragment => {
  return (
    resource.hasOwnProperty("folder") &&
    resource.folder.hasOwnProperty("ancestorFolders")
  );
};
