import React from "react";
import Button, { ButtonProps } from "../common/buttons/Button";
import ButtonGroup from "../common/buttons/ButtonGroup";
import { ResourceDisplay } from "../generated/graphql";
import MessageNoResources from "./MessageNoResources";
import ResourcesDisplay, { ColumnSize, DisplayStyle } from "./ResourcesDisplay";
import "./ResourcesSection.scss";

interface DisplayInfo {
  displayStyle: DisplayStyle;
  columnSize: ColumnSize;
}
interface ResourcesSectionProps {
  resourceDisplays: ResourceDisplay[];
  displayInfo: DisplayInfo;
  buttonInfo?: ButtonProps;
  showMessageButtons?: boolean;
  testId?: string;
  itemToHighlightFiid?: string;
}

const ResourcesSection: React.FC<ResourcesSectionProps> = ({
  resourceDisplays,
  displayInfo,
  testId,
  buttonInfo,
  showMessageButtons = true,
  itemToHighlightFiid,
}) => {
  if (resourceDisplays && resourceDisplays.length > 0) {
    return (
      <div className="ResourcesSection" data-testid="resources-display">
        <ResourcesDisplay
          resourceDisplays={resourceDisplays}
          displayStyle={displayInfo.displayStyle}
          columnSize={displayInfo.columnSize}
          div-testid={testId}
          itemToHighlightFiid={itemToHighlightFiid}
        />
        {buttonInfo && (
          <div className="ResourcesSection__button-group">
            <ButtonGroup>
              <Button {...buttonInfo} data-testid="section-button" />
            </ButtonGroup>
          </div>
        )}
      </div>
    );
  } else {
    return <MessageNoResources showButtons={showMessageButtons} />;
  }
};

export default ResourcesSection;
