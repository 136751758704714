import React from "react";
import "./SearchDropdownFooter.scss";
import { HINT_ENTER_FULL_SEARCH, Result } from "./utils/results";

interface SearchDropdownFooterProps {
  selectedResult: Result | null;
}

/**
 * Shows available keyboard shortcuts (hints) at the bottom of the search dropdown
 *
 * @param props - Component props
 * @param props.selectedResult - The current selected/highlighted search result
 */
const SearchDropdownFooter: React.FC<SearchDropdownFooterProps> = ({
  selectedResult,
}) => {
  const enterHint = selectedResult ? (
    <div className="SearchDropdownFooter__hint" data-testid="action-hint">
      {selectedResult.action.hint}
    </div>
  ) : (
    <div className="SearchDropdownFooter__hint" data-testid="full-search">
      {HINT_ENTER_FULL_SEARCH}
    </div>
  );

  const shiftEnterHint = selectedResult?.secondaryAction ? (
    <div
      className="SearchDropdownFooter__hint SearchDropdownFooter__hint--last"
      data-testid="secondary-action-hint"
    >
      {selectedResult.secondaryAction.hint}
    </div>
  ) : null;

  return (
    <div className="SearchDropdownFooter">
      {enterHint}
      {shiftEnterHint}
    </div>
  );
};

export default SearchDropdownFooter;
