import React from "react";
import Card, { MaxWidthType } from "../layout/Card";

export interface Modal {
  title: string;
  content: React.ReactElement;
  noScroll?: boolean;
  maxWidth?: MaxWidthType;
}

const ModalDisplay: React.FC<{ modal: Modal }> = ({ modal }) => {
  return (
    <Card
      title={modal.title}
      maxWidth={modal.maxWidth || MaxWidthType.standard}
      withMargins
      withTitleColour
      withScrollbar={!modal.noScroll}
    >
      {modal.content}
    </Card>
  );
};

export default ModalDisplay;
