/**
 * Retrieve the distance from the bottom of the viewport to the bottom of the content
 *
 * Calculated using IE11-compatible properties:
 *   (content height) - ((top of viewport) + (viewport height))
 */
export const PIX_TO_REM_CONVERSION = 16;

export const getWindowToContentBottomDistance = () => {
  return document.body.scrollHeight - (window.pageYOffset + window.innerHeight);
};
