import { useCallback, useContext } from "react";
import { FolderItem } from "../../../generated/graphql";
import ToastContext from "../../../toast/ToastContext";
import { ToastStyle } from "../../../toast/ToastDisplay";
import routes from "../../../utils/routes";

export type FolderItemForSharing = Pick<FolderItem, "id" | "slug">;

/**
 * Generate a shareable link for a folder item. It's a relative URL
 *
 * Note that slugs for certain types of folder items are versioned (i.e. they
 * are suffixed by a number). We generalize all slugs here by removing numbers
 * from the end of the slug.
 */
export const genShareLink = (
  item: FolderItemForSharing,
  forceLatest = true
): string => {
  const latest = forceLatest ? "" : `?latest=0`;
  const url = routes.share(item.id, item.slug.replace(/[-0-9]+$/, ""));
  return url + latest;
};

/**
 * Custom hook that provides an action to copy a share link to the clipboard
 */
export const useCopyShareLink = (
  forceLatest: boolean = true,
  clipboard = navigator.clipboard,
  useContext_ = useContext
) => {
  const { setToast } = useContext_(ToastContext);
  return useCallback(
    (item: FolderItemForSharing) => {
      const { origin } = window.location; // Need to copy an absolute URL
      clipboard.writeText(`${origin}${genShareLink(item, forceLatest)}`);
      setToast({ style: ToastStyle.Info, text: "Link copied to clipboard" });
    },
    [forceLatest, clipboard, setToast]
  );
};
