import { Form, Formik, FormikValues } from "formik";
import React from "react";
import { DropdownChoice } from "../../common/Dropdown";
import ChoiceField from "../../forms/ChoiceField";
import { FolderItemOrdering } from "../../generated/graphql";
import { noop } from "../../utils/functools";
import "./ItemOrderingWidget.scss";

export type ItemOrderingFormState = {
  orderingChoice: OrderingChoice;
};

export interface OrderingChoice extends DropdownChoice {
  ordering: FolderItemOrdering;
  reverseOrdering: boolean;
  id: string;
  display: string;
}

export const ORDERING_CHOICES_DEFAULT: OrderingChoice[] = [
  {
    ordering: FolderItemOrdering.Name,
    reverseOrdering: false,
    id: "name_a_to_z",
    display: "Name — A to Z",
  },
  {
    ordering: FolderItemOrdering.Name,
    reverseOrdering: true,
    id: "name_z_to_a",
    display: "Name — Z to A",
  },
  {
    ordering: FolderItemOrdering.Updated,
    reverseOrdering: false,
    id: "updated_latest_first",
    display: "Updated — Latest to Oldest",
  },
  {
    ordering: FolderItemOrdering.Updated,
    reverseOrdering: true,
    id: "updated_oldest_first",
    display: "Updated — Oldest to Latest",
  },
];

interface ItemOrderingWidgetProps {
  ordering: FolderItemOrdering;
  reverseOrdering: boolean;
  setOrdering: (ordering: FolderItemOrdering) => void;
  setReverseOrdering: (reverseOrdering: boolean) => void;
  orderingChoices?: OrderingChoice[];
}
const ItemOrderingWidget: React.FC<ItemOrderingWidgetProps> = ({
  ordering,
  reverseOrdering,
  setOrdering,
  setReverseOrdering,
  orderingChoices = ORDERING_CHOICES_DEFAULT,
}) => {
  const getChoice = (
    ordering: FolderItemOrdering,
    reverseOrdering: boolean
  ) => {
    return (
      orderingChoices.find(
        (choice) =>
          choice.ordering === ordering &&
          choice.reverseOrdering === reverseOrdering
      ) ?? orderingChoices[0]
    );
  };
  const selectChoice = (values: FormikValues) => {
    setOrdering(values.orderingChoice.ordering);
    setReverseOrdering(values.orderingChoice.reverseOrdering);
  };
  return (
    <div className="ItemOrderingWidget">
      <Formik<ItemOrderingFormState>
        initialValues={{ orderingChoice: getChoice(ordering, reverseOrdering) }}
        onSubmit={noop}
        validate={selectChoice}
      >
        {(formikProps) => {
          return (
            <Form>
              <ChoiceField
                name="orderingChoice"
                label="Sort by: "
                choices={orderingChoices}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default ItemOrderingWidget;
