import React, { RefObject, useCallback, useEffect, useState } from "react";
import {
  SearchDropdownQueryResult,
  useSearchDropdownQuery,
} from "../generated/graphql";
import { useEvent } from "../utils/events";
import { useRequestModelModal } from "../utils/modal";
import { useDownloadAction } from "../utils/variants";
import SearchDropdownDisplay from "./SearchDropdownDisplay";
import SearchDropdownShortcuts from "./SearchDropdownShortcuts";
import {
  folderItemToResult,
  getMoreResultsResult,
  Result,
} from "./utils/results";

export interface SearchDropdownProps {
  inputRef: RefObject<HTMLInputElement>;
  query: string;
  selectedResult: Result | null;
  setSelectedResult: (value: Result | null) => void;
  setHasContent: (value: boolean) => void;
  blurAndRedirect: (linkTo: string) => void;
  executeFullSearch: () => void;
}
export interface SearchDropdownDeps {
  useSleeping_?: typeof useSleeping;
  useQueryDelayed_?: typeof useQueryDelayed;
  useDelayingSpinner_?: typeof useDelayingSpinner;
  useResults_?: typeof useResults;
}

export const AUTO_SEARCH_DELAY = 200;
export const SPINNER_DELAY = 600;
export const DROPDOWN_MAX_RESULTS = 5;

/**
 * Component to handle the logic of the search dropdown (no display)
 *
 * @param props.inputRef - A reference to the search <input />
 * @param props.query - The current query from the user, clean/trimmed
 * @param props.selectedResult - The current selected/highlighted search result
 * @param props.setSelectedResult - Set the selected/highlighted search result
 * @param props.setHasContent - Set whether or not the dropdown has content
 * @param props.blurAndRedirect - Blur the search input & redirect to some URL
 * @param props.executeFullSearch - Execute a full search with the current query
 */
const SearchDropdown: React.FC<SearchDropdownProps & SearchDropdownDeps> = ({
  inputRef,
  query,
  selectedResult,
  setSelectedResult,
  setHasContent,
  blurAndRedirect,
  executeFullSearch,
  useSleeping_ = useSleeping,
  useQueryDelayed_ = useQueryDelayed,
  useDelayingSpinner_ = useDelayingSpinner,
  useResults_ = useResults,
}) => {
  const sleeping = useSleeping_(inputRef);
  const [queryDelayed, delayingQuery] = useQueryDelayed_({ query });
  const delayingSpinner = useDelayingSpinner_({ query });

  const queryResult = useSearchDropdownQuery({
    fetchPolicy: "network-only",
    variables: {
      query: queryDelayed,
      first: DROPDOWN_MAX_RESULTS,
    },
    skip: sleeping || !queryDelayed,
  });

  const spinning = !delayingSpinner && (delayingQuery || queryResult.loading);
  const results = useResults_({
    queryResult,
    blurAndRedirect,
    executeFullSearch,
  });
  useEffect(() => {
    setHasContent(spinning || results !== null);
  }, [setHasContent, spinning, results]);

  return (
    <>
      <SearchDropdownDisplay
        error={queryResult.error}
        spinning={spinning}
        results={results}
        selectedResult={selectedResult}
        setSelectedResult={setSelectedResult}
      />
      <SearchDropdownShortcuts
        inputRef={inputRef}
        results={results}
        selectedResult={selectedResult}
        setSelectedResult={setSelectedResult}
      />
    </>
  );
};

/**
 * Hook to decide when we should "delay" showing the spinner. I.e. even if we
 * are executing a query (or about to) don't show the spinner yet; this makes
 * the search dropdown feel faster.
 *
 * The spinner delay is reset whenever the search query changes.
 *
 * @param query - The current query from the user, clean/trimmed
 */
export const useDelayingSpinner = ({
  query,
  setTimeout = window.setTimeout,
  clearTimeout = window.clearTimeout,
}: {
  query: string;
  setTimeout?: typeof window.setTimeout;
  clearTimeout?: typeof window.clearTimeout;
}) => {
  const [delayingSpinner, setDelayingSpinner] = useState(true);

  useEffect(() => {
    setDelayingSpinner(true);
    if (query) {
      const timeout = setTimeout(
        () => setDelayingSpinner(false),
        SPINNER_DELAY
      );
      return () => clearTimeout(timeout);
    }
  }, [query, setTimeout, clearTimeout]);

  return delayingSpinner;
};

/**
 * Hook to debounce the query to be used with Apollo. Done by implementing a
 * short delay after typing before the new query is used. Without this too many
 * unused GraphQL queries will be made.
 *
 * Do NOT delay if the query is empty ("") since no GraphQL query is necessary
 * in this case (the dropdown will be hidden).
 *
 * @param query - The current query from the user, clean/trimmed
 */
export const useQueryDelayed = ({
  query,
  setTimeout = window.setTimeout,
  clearTimeout = window.clearTimeout,
}: {
  query: string;
  setTimeout?: typeof window.setTimeout;
  clearTimeout?: typeof window.clearTimeout;
}) => {
  const [queryDelayed, setGraphqlQuery] = useState<string>(query);
  const delayingQuery = query !== queryDelayed;

  useEffect(() => {
    if (query === queryDelayed) return;
    if (query) {
      const timeout = setTimeout(
        () => setGraphqlQuery(query),
        AUTO_SEARCH_DELAY
      );
      return () => clearTimeout(timeout);
    } else {
      setGraphqlQuery("");
    }
  }, [query, setTimeout, clearTimeout, queryDelayed]);

  return [queryDelayed, delayingQuery] as const;
};

/**
 * Hook to take a search query response and map it to a list of generic
 * results. By being generic, we can easily include folder items mixed in with
 * "special" results like the "More search results…" result.
 *
 * This hook also keeps around the last successful query results.
 *
 * @param queryResult - The search query result
 * @param blurAndRedirect - Blur the search input & redirect to some URL
 * @param executeFullSearch - Execute a full search with the current query
 */
export const useResults = ({
  queryResult,
  blurAndRedirect,
  executeFullSearch,
}: {
  queryResult: SearchDropdownQueryResult;
  blurAndRedirect: (linkTo: string) => void;
  executeFullSearch: () => void;
  folderItemToResult_?: typeof folderItemToResult;
}): Result[] | null => {
  const download = useDownloadAction();
  const requestModel = useRequestModelModal();
  const [results, setResults] = useState<Result[] | null>(null);

  useEffect(() => {
    if (queryResult.loading) return;
    if (!queryResult.variables?.query || !queryResult.data) {
      setResults(null);
    } else {
      const user = queryResult.data.me;
      const memo: Result[] = queryResult.data.search.edges.map((e) =>
        folderItemToResult(e.node, user, {
          blurAndRedirect,
          download,
          requestModel,
        })
      );
      if (queryResult.data.search.pageInfo.hasNextPage)
        memo.push(getMoreResultsResult({ executeFullSearch }));

      setResults(memo);
    }
  }, [queryResult, blurAndRedirect, executeFullSearch, download, requestModel]);

  return results;
};

/**
 * Hook to evaluate when the dropdown is "sleeping" or not. The dropdown should
 * be sleeping until it is first focused. This prevents useless GraphQL queries
 * when the input is populated on load (e.g. when using ?query=foo in the URL).
 * Instead, the query will be executed only when the user looks at the dropdown.
 *
 * @param inputRef - A reference to the search <input />
 */
export const useSleeping = (inputRef: RefObject<HTMLInputElement>) => {
  const [sleeping, setSleeping] = useState<boolean>(true);

  useEvent(
    window,
    "focus",
    useCallback(() => {
      if (document.activeElement === inputRef.current) setSleeping(false);
    }, [inputRef]),
    true
  );

  return sleeping;
};

export default SearchDropdown;
