import classNames from "classnames";
import React from "react";
import "./Card.scss";

export enum MaxWidthType {
  standard,
  wide,
}

interface CardProps {
  title?: React.ReactNode;
  titleContent?: React.ReactNode;
  tegus?: boolean;
  withMargins?: boolean;
  maxWidth?: MaxWidthType;
  withTitleColour?: boolean;
  withScrollbar?: boolean;
  highlighted?: boolean;
}

/**
 * A component whose children are contained in a Bootstrap `div.card-body` element. See
 * https://getbootstrap.com/docs/4.4/components/card/.
 *
 * @param props - Component props
 * @param props.tegus - Tegus-ify card (no border, padding, background colour)
 * @param props.title - The card title. End all titles with a period
 * @param props.withMargins - Include margins around the card (default `false`)
 * @param props.maxWidth - Optionally set a max width for the card
 * @param props.withTitleColour - Use yellow titlebar instead of white (default `false`)
 * @param props.withScrollbar - Enable scrollbar in card body if card height is > 90vh (default `false`)
 * @param props.highlighted - Apply highlight to the card (default `false`)
 */
const Card: React.FC<CardProps> = ({
  children,
  title,
  titleContent,
  tegus = false,
  withMargins = false,
  maxWidth = undefined,
  withTitleColour = false,
  withScrollbar = false,
  highlighted = false,
}) => {
  const cardClassNames = classNames("Card", {
    "Card--tegus": tegus,
    "Card--with-margins": withMargins,
    "Card--with-no-margins": !withMargins,
    "Card--max-width-standard": maxWidth === MaxWidthType.standard,
    "Card--max-width-wide": maxWidth === MaxWidthType.wide,
    "Card--highlighted": highlighted,
  });
  const cardTitleClassNames = classNames("Card__card-title", {
    "Card__card-title--coloured": withTitleColour,
  });
  const cardBodyClassNames = classNames("Card__card-body", {
    "Card__card-body--coloured": withTitleColour,
    "Card__card-body--with-scrollbar": withScrollbar,
  });

  return (
    <div className={cardClassNames}>
      <div className="Card__card card">
        <div className={cardTitleClassNames}>
          {title && <h2>{title}</h2>}
          {titleContent}
        </div>
        <div className={cardBodyClassNames}>{children}</div>
      </div>
    </div>
  );
};

export default Card;
