import React from "react";
import "./ButtonGroup.scss";

const ButtonGroup: React.FC = ({ children }) => {
  // A wrapper for multiple <Button /> components. Ensures that proper spacing is
  // applied to groups of buttons. Children should only be <Button /> components.
  return <div className="ButtonGroup">{children}</div>;
};

export default ButtonGroup;
