import React from "react";
import QRCode from "react-qr-code";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../../common/Spinner";
import { useMfaSetupStepsMyMfaSetupUrlQuery } from "../../generated/graphql";
import Card from "../../layout/Card";
import routes from "../../utils/routes";
import "../api_tokens/ApiTokens.scss";
import MfaSetupForm from "./MfaSetupForm";
import "./MfaSetupSteps.scss";

export const mfaSetupLoading = (
  <div className="MfaSetup MfaSetupSteps--loading">
    <Spinner colour={ColourType.SECONDARY} size="3rem" />
  </div>
);

export const mfaSetupError = (
  <Card title="Uh oh." data-testid="error-500" tegus>
    <p>
      Something went wrong while retrieving your information for setting up
      multi-factor authentication. Please try again later or{" "}
      <a href={routes.support}>contact support</a> for assistance.
    </p>
  </Card>
);

interface MfaSetupProps {
  backAction: () => void;
}

const MfaSetupSteps: React.FC<MfaSetupProps> = ({ backAction }) => {
  const { loading, error, data } = useMfaSetupStepsMyMfaSetupUrlQuery();
  if (loading) {
    return mfaSetupLoading;
  }
  if (error || !data?.myMfaSetupUrl) {
    return mfaSetupError;
  }
  return (
    <div className="MfaSetupSteps">
      <Card title="Multi-factor Authentication Setup" tegus>
        <div className="row">
          <div className="col-md-4">
            <Card>
              <p>
                <b>1.</b> Download a multi-factor authentication app onto your
                phone.
              </p>
              <p>
                We recommend{" "}
                <a
                  href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid"
                  target="_blank"
                  rel="noreferrer"
                >
                  Google Authenticator
                </a>{" "}
                or{" "}
                <a href="https://authy.com/" target="_blank" rel="noreferrer">
                  Authy
                </a>
                .
              </p>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <p>
                <b>2.</b> Use the app to scan the following QR Code:
              </p>
              <div className="MfaSetupSteps__qrcode">
                <QRCode size={175} value={data.myMfaSetupUrl} />
              </div>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <p>
                <b>3. </b> Enter the secure token generated by the app:
              </p>
              <MfaSetupForm />
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <ButtonGroup>
              <Button
                style={ButtonStyle.TegusSecondary}
                action={backAction}
                data-testid="back-button"
              >
                Back
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MfaSetupSteps;
