import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from "@apollo/client";
import { faTimes, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { useField as useFieldDefault } from "formik";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import IconButton, { IconButtonSize } from "../../common/buttons/IconButton";
import Spinner, { ColourType } from "../../common/Spinner";
import { Exact, Maybe, Scalars } from "../../generated/graphql";
import { useQueryDelayed as useQueryDelayedDefault } from "../../search_box/SearchDropdown";
import { noop } from "../../utils/functools";
import "./MultiSelectList.scss";
import MultiSelectListRow from "./MultiSelectListRow";

// Constants
const ITEM_HEIGHT = 3; // height of a row item, in rem
const GAP_HEIGHT = 0.25; // height of the gap between items, in rem
const EXTRA_HEIGHT = 4.625; // height of the search bar & padding, in rem

// Settings
const DEFAULT_NUM_ROWS = 5;
const MIN_ROWS = 2;

// End-user visible strings
const TEXT_SEARCH = "Search...";
const TEXT_CLEAR_SEARCH = "Clear search criteria";
const TEXT_NO_ITEMS_SELECTED =
  "No items selected. Search for an item to select using the search bar above.";
const TEXT_NO_SEARCH_RESULTS = "No items found matching search criteria.";
export const TEXT_ADD = "Add {name} to the list";
export const TEXT_REMOVE = "Remove {name} from the list";
const TEXT_UNDO = "Undo";
const TEXT_SAVE = "Save changes";
const TEXT_ACTIONS = "Actions";

const DEFAULT_TEXT = {
  search: TEXT_SEARCH,
  noSearchResults: TEXT_NO_SEARCH_RESULTS,
  noItemsSelected: TEXT_NO_ITEMS_SELECTED,
  addItem: TEXT_ADD,
  removeItem: TEXT_REMOVE,
};

export interface MultiSelectListItem {
  value: string;
  display: string;
  subtext?: string;
}
export interface MultiSelectListEntry {
  item: MultiSelectListItem;
  selected: boolean;
}

export interface MultiSelectListCustomText {
  search: string;
  noSearchResults: string;
  noItemsSelected: string;
  addItem: string;
  removeItem: string;
}

export interface MultiSelectListProps {
  name: string;
  queryHook: (
    queryOptions: QueryHookOptions<
      OperationVariables,
      Exact<{ query: string; id?: Maybe<Scalars["ID"]> }>
    >
  ) => QueryResult<any, Exact<{ query: string; id?: Maybe<Scalars["ID"]> }>>;
  title?: string;
  description?: string;
  numRows?: number;
  rowIcon?: IconDefinition;
  customText?: MultiSelectListCustomText;
  objectId?: string;
  onSave?: () => any;
}

export interface MultiSelectListDeps {
  useField?: typeof useFieldDefault;
  useQueryDelayed?: typeof useQueryDelayedDefault;
}

/**
 * A field in which multiple items can be selected from a list via searching for the
 * item by name (or other property).
 *
 * Notes on queryHook:
 * - Must accept a string parameter 'query' to use for searching for items
 * - Can optionally accept an 'id' parameter containing the id of an object related to the search
 * - Must return a list in field 'results', containing objects that match the interface
 *   for MultiSelectListItem
 * - GraphQL field aliases can be used to ensure the response field names are correct
 *   regardless of what the actual field names are in the query
 *
 * Notes on customText:
 * - For addItem and removeItem, add {name} to the string to replace with the item name
 *
 * @param name - The internal name of the field (Formik field name)
 * @param queryHook - The 'useQuery' method to call when searching for objects
 * @param title - (Optional) Field title
 * @param description - (Optional) Field description
 * @param numRows - (Optional) # of rows to display (determines height), default: DEFAULT_NUM_ROWS
 * @param rowIcon - (Optional) Icon to use for each row item
 * @param customText - (Optional) Object containing custom strings to use
 * @param objectId - (Optional) ID of object to pass into search queryHook
 * @param onSave - (Optional) Hook that is called when changes are saved
 */
const MultiSelectList: React.FC<MultiSelectListProps & MultiSelectListDeps> = ({
  name,
  title,
  description,
  numRows = DEFAULT_NUM_ROWS,
  rowIcon,
  customText = DEFAULT_TEXT,
  objectId = undefined,
  queryHook,
  onSave = noop,
  useField = useFieldDefault,
  useQueryDelayed = useQueryDelayedDefault,
}) => {
  const [field, , helper] = useField(name);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentItems, setCurrentItems] = useState<MultiSelectListItem[]>([
    ...field.value,
  ]);
  const [changedItems, setChangedItems] = useState<MultiSelectListItem[]>([]);

  // Configure search query
  const [query, isDelaying] = useQueryDelayed({ query: searchTerm.trim() });
  const queryResult = queryHook({
    fetchPolicy: "network-only",
    variables: { query: query, id: objectId },
    skip: !query,
  });
  const showSpinner = queryResult.loading || isDelaying;

  // Ensure row count is above the minimum
  if (numRows < MIN_ROWS) {
    numRows = MIN_ROWS;
  }

  // Generate list of results to display
  const displayedEntries: MultiSelectListEntry[] =
    useMultiSelectListQueryResult({
      queryResult,
      currentItems,
      searchTerm,
    });

  // Overall height: height of rows + gap between rows + search bar & padding
  const height = `${
    numRows * ITEM_HEIGHT + GAP_HEIGHT * (numRows - 1) + EXTRA_HEIGHT
  }rem`;

  // Update changed item list
  const updateChangedItems = (item: MultiSelectListItem) => {
    const idx = changedItems.indexOf(item);
    if (idx !== -1) {
      changedItems.splice(idx, 1);
    } else {
      changedItems.push(item);
    }
    setChangedItems([...changedItems]);
  };

  // Handle search input
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Handle clear search button
  const handleClearSearch = () => {
    setSearchTerm("");
  };

  // Handle item clicked
  const handleClick = (index: number) => {
    const entry = displayedEntries[index];

    if (entry.selected) {
      currentItems.splice(currentItems.indexOf(entry.item), 1);
    } else {
      currentItems.push(entry.item);
    }

    updateChangedItems(entry.item);
    setCurrentItems([...currentItems]);
  };

  // Handle changes saved
  const handleSave = () => {
    helper.setValue([...currentItems]);

    setSearchTerm("");
    setChangedItems([]);
    onSave();
  };

  // Handle changes cancelled
  const handleUndo = () => {
    setCurrentItems([...field.value]);
    setChangedItems([]);
  };

  return (
    <div
      className="MultiSelectList"
      role="listbox"
      aria-multiselectable="true"
      aria-labelledby={`${name}-title`}
      aria-describedby={`${name}-description`}
    >
      {/* Title & description */}
      {title && (
        <label id={`${name}-title`} className="MultiSelectList__title">
          {title}
        </label>
      )}
      {description && (
        <p id={`${name}-description`} className="MultiSelectList__description">
          {description}
        </p>
      )}
      <div
        className="MultiSelectList__frame"
        style={{ height: height }}
        data-testid="frame"
      >
        {/* Search bar */}
        <div className="MultiSelectList__search-bar" role="search">
          {/* Search field*/}
          <input
            type="text"
            value={searchTerm}
            placeholder={customText.search}
            className="MultiSelectList__search-field"
            onChange={handleSearch}
            aria-label={customText.search}
          />
          {/* Clear search button */}
          <div className="MultiSelectList__search-clear">
            <IconButton
              icon={faTimes}
              tooltip={TEXT_CLEAR_SEARCH}
              size={IconButtonSize.SmallMedium}
              onClick={handleClearSearch}
              disabled={searchTerm === ""}
              aria-label={TEXT_CLEAR_SEARCH}
            />
          </div>
        </div>

        {/* Item list */}
        <div className="MultiSelectList__items" ref={wrapperRef}>
          {showSpinner ? (
            // Loading spinner
            <div className="MultiSelectList__spinner">
              <Spinner
                key="spinner"
                colour={ColourType.SECONDARY}
                size="3rem"
              />
            </div>
          ) : displayedEntries.length === 0 ? (
            // Search hint (when no items selected or search returns no results)
            <div className="MultiSelectList__search-hint" role="note">
              {searchTerm !== ""
                ? customText.noSearchResults
                : customText.noItemsSelected}
            </div>
          ) : (
            // List of items
            displayedEntries.map((entry, index) => (
              <MultiSelectListRow
                key={index}
                entry={entry}
                icon={rowIcon}
                searchActive={searchTerm !== ""}
                onClick={() => {
                  handleClick(index);
                }}
                addItemText={customText.addItem}
                removeItemText={customText.removeItem}
                wrapperRef={wrapperRef}
              />
            ))
          )}
        </div>
        {/* Save & undo buttons */}
        {changedItems.length !== 0 && (
          <div
            className="MultiSelectList__footer"
            role="group"
            aria-label={TEXT_ACTIONS}
          >
            <div
              className="MultiSelectList__change-summary"
              data-testid="items-modified"
            >
              {`${changedItems.length} items modified`}
            </div>
            <div className="MultiSelectList__buttons">
              <Button
                style={ButtonStyle.TegusTertiaryAlt}
                onClick={handleUndo}
                aria-label={TEXT_UNDO}
              >
                {TEXT_UNDO}
              </Button>
              <Button
                style={ButtonStyle.PrimarySlim}
                onClick={handleSave}
                aria-label={TEXT_SAVE}
              >
                {TEXT_SAVE}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Sorting method used to alphabetically sort arrays of MultiSelectListItems
 *
 * @param a - A MultiSelectListItem to compare
 * @param b - Another MultiSelectListItem to compare it to
 * @returns 1 if a > b, -1 if a < b, 0 if a == b
 */
export const sortMultiSelectListItems = (
  a: MultiSelectListItem,
  b: MultiSelectListItem
) => {
  const aDisplay = a.display.toLowerCase();
  const bDisplay = b.display.toLowerCase();
  if (aDisplay > bDisplay) {
    return 1;
  }
  if (aDisplay < bDisplay) {
    return -1;
  }
  return 0;
};

/**
 * Hook to determine which items to display in a MultiSelectList and how to display them.
 *
 * If there is a search term, the list will display results of query for that search
 * term and show items that are currently selected as 'checked'.
 *
 * If there is no search term, the list will display the currently selected items.
 *
 * @param queryResult - results from GraphQL query
 * @param currentItems - array of currently selected items
 * @param searchTerm - current search term
 */
export const useMultiSelectListQueryResult = ({
  queryResult,
  currentItems,
  searchTerm,
}: {
  queryResult: QueryResult<
    any,
    Exact<{ query: string; id?: Maybe<Scalars["ID"]> }>
  >;
  currentItems: MultiSelectListItem[];
  searchTerm: string;
}): MultiSelectListEntry[] => {
  const [results, setResults] = useState<MultiSelectListEntry[]>([]);

  useEffect(() => {
    // Display currently selected items if no search term
    if (searchTerm === "") {
      setResults(
        currentItems
          .sort(sortMultiSelectListItems)
          .map((item: MultiSelectListItem) => {
            return {
              item: item,
              selected: true,
            };
          })
      );
      return;
    }
    // Do not change listed items if query is still loading
    if (queryResult.loading) {
      return;
    }
    // Set results to blank if query is invalid or returns no results
    if (
      !queryResult.variables?.query ||
      !queryResult.data ||
      !queryResult.data.results
    ) {
      setResults([]);
    } else {
      // Generate currentItems map
      const currentItemsMap: { [key: string]: MultiSelectListItem } = {};
      currentItems.forEach((currentItem) => {
        currentItemsMap[currentItem.value] = currentItem;
      });

      // Display query results & highlight currently selected ones
      setResults(
        [...queryResult.data.results]
          .sort(sortMultiSelectListItems)
          .map((item: MultiSelectListItem) => {
            return {
              item: item,
              selected: !!currentItemsMap[item.value],
            };
          })
      );
    }
  }, [queryResult, currentItems, searchTerm]);
  return results;
};

export default MultiSelectList;
