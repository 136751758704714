import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RouteProps, useHistory } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import KeyHint from "../common/KeyHint";
import { SearchParam } from "../home/search/Search";
import routes from "../utils/routes";
import "./SearchBox.scss";
import SearchBoxShortcuts from "./SearchBoxShortcuts";
import SearchDropdown from "./SearchDropdown";
import { Result } from "./utils/results";

interface SearchBoxProps extends RouteProps {
  fromFolderId?: string;
}

/**
 * The main search box
 *
 * @param props - Component props
 * @param props.fromFolderId - The last folder the user viewed
 */
const SearchBox: React.FC<SearchBoxProps> = ({ fromFolderId }) => {
  const folderId = useQueryParam(SearchParam.FOLDER, StringParam)[0];
  const [fromFolderIdFromParam] = useQueryParam(
    SearchParam.FROM_FOLDER,
    StringParam
  );
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);

  const [queryFromUser, setQueryFromUser] = useState<string>("");
  const query = queryFromUser.trim();

  // If the user is currently performing a search, re-use the existing search context
  const queryFromParam = useQueryParam(SearchParam.QUERY, StringParam)[0];
  useEffect(() => {
    setQueryFromUser(queryFromParam || "");
  }, [queryFromParam]);

  const [hasContent, setHasContent] = useState(false);
  const [selectedResult, setSelectedResult] = useState<Result | null>(null);

  const blurAndRedirect = useCallback(
    (linkTo: string) => {
      inputRef.current?.blur();
      setSelectedResult(null);
      history.push(linkTo);
    },
    [history, inputRef, setSelectedResult]
  );

  const executeFullSearch = useCallback(() => {
    const args = getSearchArgs({
      folderId,
      fromFolderId,
      fromFolderIdFromParam,
      queryFromUser,
    });
    blurAndRedirect(`${routes.search}?${args}`);
  }, [
    folderId,
    fromFolderId,
    fromFolderIdFromParam,
    queryFromUser,
    blurAndRedirect,
  ]);

  const executeAction = useCallback(
    (shiftKey: boolean = false) => {
      if (!query) return;

      if (selectedResult) {
        if (shiftKey && selectedResult.secondaryAction) {
          selectedResult.secondaryAction.action();
        } else {
          selectedResult.action.action();
        }
      } else {
        executeFullSearch();
      }
    },
    [query, selectedResult, executeFullSearch]
  );

  const className = classNames("SearchBox", {
    "SearchBox--with-query": !!queryFromUser,
    "SearchBox--with-query-clean": !!query,
    "SearchBox--with-content": hasContent,
  });
  return (
    <div className={className}>
      <KeyHint className="SearchBox__key-hint">/</KeyHint>
      <FontAwesomeIcon
        icon={faTimes}
        className="SearchBox__clear-icon"
        onMouseDown={(ev) => ev.preventDefault()} // Don't lose input focus
        onMouseUp={() => setQueryFromUser("")}
      />
      <FontAwesomeIcon
        icon={faSearch}
        className="SearchBox__search-icon"
        onMouseDown={(ev) => ev.preventDefault()} // Don't lose input focus
        onMouseUp={() => executeAction()}
      />
      <div className="SearchBox__input-dropdown-wrapper">
        <input
          className="SearchBox__input"
          name="search-box-input"
          onChange={(ev) => setQueryFromUser(ev.target.value)}
          onKeyUp={(ev) => {
            if (ev.key === "Enter") executeAction(ev.shiftKey);
          }}
          autoComplete="off"
          placeholder="Search by tickers, company, and more…"
          ref={inputRef}
          spellCheck="false"
          type="text"
          value={queryFromUser}
        />
        <SearchDropdown
          inputRef={inputRef}
          query={query}
          selectedResult={selectedResult}
          setSelectedResult={setSelectedResult}
          setHasContent={setHasContent}
          blurAndRedirect={blurAndRedirect}
          executeFullSearch={executeFullSearch}
        />
      </div>
      <SearchBoxShortcuts inputRef={inputRef} />
    </div>
  );
};

/**
 * Generate the URL arguments for a search query
 *
 * @param args.folderId - The folder to search within
 * @param args.fromFolderId - The last folder the user viewed. Cleared on refresh
 * @param args.fromFolderIdParam - The last folder the user viewed (from a URL param)
 * @param args.queryFromUser - The raw search query from the search input
 */
const getSearchArgs = ({
  folderId,
  fromFolderId,
  fromFolderIdFromParam,
  queryFromUser,
}: {
  folderId?: string | null;
  fromFolderId?: string;
  fromFolderIdFromParam?: string | null;
  queryFromUser: string;
}): URLSearchParams => {
  const fromFolderIdFinal = fromFolderId || fromFolderIdFromParam;

  const args: Record<string, string> = {};
  if (folderId) {
    args[SearchParam.FOLDER] = folderId;
  }
  if (fromFolderIdFinal) {
    args[SearchParam.FROM_FOLDER] = fromFolderIdFinal;
  }
  args[SearchParam.QUERY] = queryFromUser;
  const paramsObj = new URLSearchParams(args);
  paramsObj.sort();
  return paramsObj;
};

export default SearchBox;
