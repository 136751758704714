import { RequestKind } from "../generated/graphql";
import { stringIsEmptyOrBlank } from "../utils/strings";
import { ContactUsFormState } from "./ContactUs";

export enum FormError {
  SubjectError = "Please select a subject.",
  UpdateError = "You must select an Update Reason.",
  TextError = "You must add either a company name, ticker, or request text.",
  MaxLengthError = "One or more fields have exceeded their max character length.",
}

export enum MaxChars {
  Comments = 1024,
  Company = 128,
}

export const getContactUsFormError = (
  formState: ContactUsFormState
): FormError | null => {
  if (!formState.subject) {
    return FormError.SubjectError;
  } else if (
    formState.subject.id === RequestKind.ModelUpdate &&
    !formState.updateReason
  ) {
    return FormError.UpdateError;
  } else if (
    stringIsEmptyOrBlank(formState.company) &&
    stringIsEmptyOrBlank(formState.comments)
  ) {
    return FormError.TextError;
  } else if (
    formState.company.length > MaxChars.Company ||
    formState.comments.length > MaxChars.Comments
  ) {
    return FormError.MaxLengthError;
  } else {
    return null;
  }
};
