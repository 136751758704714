import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import React from "react";
import { noop } from "../../utils/functools";
import "./IconButton.scss";

const DEFAULT_TOOLTIP = "";

export enum IconButtonSize {
  Small = "1.08rem",
  SmallMedium = "1.25rem",
  Medium = "2rem",
}

export interface IconButtonProps {
  id?: string;
  icon: IconDefinition;
  tooltip?: string;
  onClick?: () => any;
  size?: IconButtonSize;
  disabled?: boolean;
}

/**
 * A button widget composed of an icon & tooltip
 *
 * @param props - Component props
 * @param props.id - Button ID
 * @param props.icon - Icon to display as the button (FontAwesome icon)
 * @param props.tooltip - Text to display in hover tooltip
 * @param props.onClick - Hook that is called when the button is clicked
 * @param props.size - Button size (default: small)
 * @param props.disabled - Whether the button is disabled
 */
const IconButton: React.FC<IconButtonProps> = ({
  id = undefined,
  icon,
  tooltip = DEFAULT_TOOLTIP,
  onClick = noop,
  size = IconButtonSize.Small,
  disabled = false,
}) => {
  const frameClass = "IconButton__frame" + (disabled ? "--disabled" : "");
  const clickAction = disabled ? noop : onClick;
  return (
    <div className="IconButton">
      <Tippy content={tooltip} delay={100} disabled={disabled}>
        <div
          id={id}
          className={frameClass}
          onClick={clickAction}
          role="button"
          aria-label={tooltip}
        >
          <FontAwesomeIcon
            icon={icon}
            className="IconButton__icon"
            style={{
              fontSize: size,
            }}
          />
        </div>
      </Tippy>
    </div>
  );
};

export default IconButton;
