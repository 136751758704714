import {
  faFile,
  faFileAlt,
  faFileInvoice,
  faFileSpreadsheet,
  faFolder,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const FA_ICON_DEFAULT_STYLE: React.CSSProperties = {
  width: "100%",
  height: "100%",
  verticalAlign: "middle",
};

const ICON_KIND_TO_COMPONENT_MAP: Record<string, React.ReactElement> = {
  DOCUMENT: <FontAwesomeIcon icon={faFileAlt} style={FA_ICON_DEFAULT_STYLE} />,
  EXECUTABLE: (
    <FontAwesomeIcon icon={faFileInvoice} style={FA_ICON_DEFAULT_STYLE} />
  ),
  FOLDER: <FontAwesomeIcon icon={faFolder} style={FA_ICON_DEFAULT_STYLE} />,
  SPREADSHEET: (
    <FontAwesomeIcon icon={faFileSpreadsheet} style={FA_ICON_DEFAULT_STYLE} />
  ),
  OTHER: <FontAwesomeIcon icon={faFile} style={FA_ICON_DEFAULT_STYLE} />,
};

export const getIconComponent = (iconKind: string): React.ReactElement => {
  // Return the icon component based on an IconKind string
  return ICON_KIND_TO_COMPONENT_MAP[iconKind];
};
