export const pluralize = (count: number, noun: string) =>
  `${noun}${count !== 1 ? "s" : ""}`;

export const stringIsEmptyOrBlank = (str: string) => {
  return !str.trim();
};

export const title = (str: string) => {
  const words = str.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(" ");
};

export const kebab = (str: string) => {
  const words = str.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(unCapitalize).join("-");
};

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};

const unCapitalize = (str: string) => {
  return str.charAt(0).toLowerCase() + str.substring(1);
};
