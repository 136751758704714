import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useField as useFieldDefault } from "formik";
import React, { useState } from "react";
import Dropdown, { DropdownChoice } from "../common/Dropdown";
import { FA_ICON_DEFAULT_STYLE } from "../utils/icons";
import "./ChoiceField.scss";

export interface ChoiceFieldProps {
  label: string;
  name: string;
  choices: DropdownChoice[];
  descriptions?: string[];
  nullChoiceText?: string;
}

export interface ChoiceFieldDeps {
  useField?: typeof useFieldDefault;
}

/**
 * A field for selecting a single option from a dropdown
 *
 * @param props - Component props
 * @param props.label - The label to show above the field
 * @param props.name - The internal name of the field
 * @param props.choices - Available choices. First one will be default, if available
 * @param props.descriptions - An array of descriptions for the field, each item in the
 * array will be a separate description paragraph
 * @param props.nullChoiceText - Text to display if no choice is selected
 */
const ChoiceField: React.FC<ChoiceFieldProps & ChoiceFieldDeps> = ({
  choices,
  descriptions,
  nullChoiceText = "Please select one…",
  useField = useFieldDefault,
  ...props
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [field, , helpers] = useField(props.name);
  const fieldClassNames = classNames("ChoiceField__field", {
    "ChoiceField__field--active": dropdownActive,
  });
  const changeSelection = (choice: DropdownChoice) => {
    setDropdownActive(false);
    helpers.setValue(choice);
  };
  const getArrow = () => {
    if (dropdownActive) {
      return (
        <FontAwesomeIcon
          className="ChoiceField__arrow"
          icon={faChevronUp}
          style={FA_ICON_DEFAULT_STYLE}
          data-testid="up-arrow"
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          className="ChoiceField__arrow"
          icon={faChevronDown}
          style={FA_ICON_DEFAULT_STYLE}
          data-testid="down-arrow"
        />
      );
    }
  };
  return (
    <div className="ChoiceField">
      <label className="ChoiceField__label" htmlFor={props.name}>
        {props.label}
      </label>
      {descriptions?.map((description, index) => (
        <p key={index} className="ChoiceField__description">
          {description}
        </p>
      ))}
      <Dropdown
        choices={choices}
        setChoice={changeSelection}
        dropdownActive={dropdownActive}
        setDropdownActive={setDropdownActive}
      >
        <div className={fieldClassNames} {...field} {...props} id={props.name}>
          <span className="ChoiceField__selection-text">
            {choices.length > 0 ? field?.value?.display ?? nullChoiceText : "-"}
          </span>
          <div className="ChoiceField__arrow-container">{getArrow()}</div>
        </div>
      </Dropdown>
    </div>
  );
};

export default ChoiceField;
