import { useApolloClient } from "@apollo/client";
import { Form, Formik } from "formik";
import React, {
  Context,
  useContext as useContextDefault,
  useState,
} from "react";
import Button, {
  ButtonStyle,
  ButtonType,
} from "../../../common/buttons/Button";
import ButtonGroup from "../../../common/buttons/ButtonGroup";
import TextField from "../../../forms/TextField";
import { useAddFolderModalCreateFolderMutation } from "../../../generated/graphql";
import ModalContext, { ModalContextValue } from "../../../modal/ModalContext";
import ToastContext, { ToastContextValue } from "../../../toast/ToastContext";
import { ToastStyle } from "../../../toast/ToastDisplay";
import { BrowseFolderByPath } from "../utils/graphql";
import "./AddFolderModal.scss";

// End-user visible strings
const TEXT_INPUT_TITLE = "Folder name";
const TEXT_SAVE = "Create";
const TEXT_CANCEL = "Cancel";

// Toast messages
export const MSG_CREATE_FOLDER = "Creating folder '{name}'...";
export const MSG_CREATE_FOLDER_SUCCESS = "Folder Successfully Created";
export const MSG_CREATE_FOLDER_ERROR = "Failed to create folder";

// Validation error messages
export const ERROR_INVALID_NAME = "Folder name requires alphabetic characters.";
export const ERROR_DUPLICATE_NAME =
  "Sorry, folder name already used by another folder in the current folder. Please try another name or remove the folder with the same name.";
export const ERROR_INVALID_FORMAT =
  "Numbers at the end of folder name cannot be separated by spaces or special characters.";

// Max folder name lengths
export const MAX_CHAR_COUNT = 50;
export const MAX_LENGTH = 50;

// Regex
export const REGEX_ALPHABETICAL = /[A-Za-z]+/;
export const REGEX_INVALID_SLUG = /[\W_][0-9]+$/;

export enum ToastMessageType {
  INFO,
  SUCCESS,
  ERROR,
}

interface Folder {
  folderName: any | null;
}
export interface AddFolderState {
  folder: Folder[];
}

export interface AddFolderModalProps {
  folder: BrowseFolderByPath;
  setIsUpdating?: (val: boolean) => any;
}

export interface AddFolderModalDeps {
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
  useToastContext?: (context: Context<ToastContextValue>) => ToastContextValue;
}

/**
 * Content of the 'Add folder' modal
 *
 * @param props - Component props
 * @param props.folder - Parent folder that thew new folder is being added to
 * @param props.setIsUpdating - Hook to call when a folder is being created
 */
const AddFolderModal: React.FC<AddFolderModalProps & AddFolderModalDeps> = ({
  folder,
  setIsUpdating = (val: boolean) => {},
  useModalContext = useContextDefault,
  useToastContext = useContextDefault,
}) => {
  const { setToast } = useToastContext(ToastContext);
  const { clearModal } = useModalContext(ModalContext);

  const client = useApolloClient();

  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [createFolderMutation] = useAddFolderModalCreateFolderMutation({
    onCompleted: () => handleCompleted(),
    onError: (error) => {
      handleError(error.message);
    },
  });

  const InitialValues: Folder = {
    folderName: "",
  };

  // Handle mutation completed
  const handleCompleted = async () => {
    clearModal();

    setToast({
      style: ToastStyle.Info,
      text: getToastMessage(ToastMessageType.SUCCESS),
    });

    await client.refetchQueries({
      include: ["Browse"],
    });

    setIsUpdating(false);
  };

  // Handle mutation error
  const handleError = (errorMessage: string) => {
    const errors: string[] = [];
    errors.push(errorMessage);

    setShowError(true);
    setErrorMessages(errors);

    setToast({
      style: ToastStyle.Error,
      text: getToastMessage(ToastMessageType.ERROR),
    });

    setIsUpdating(false);
  };

  // Validate form
  const _validate = (values: Folder) => {
    setShowError(false);

    const errors: string[] = [];

    if (values.folderName.match(REGEX_ALPHABETICAL) === null) {
      errors.push(ERROR_INVALID_NAME);
    } else if (values.folderName.match(REGEX_INVALID_SLUG) != null) {
      errors.push(ERROR_INVALID_FORMAT);
    } else {
      folder.items.edges.forEach((edge) => {
        if (edge.node.name.toLowerCase() === values.folderName.toLowerCase()) {
          errors.push(ERROR_DUPLICATE_NAME);
        }
      });
    }

    setErrorMessages(errors);
  };

  // Submit form
  const _submit = (values: any, actions: any) => {
    if (errorMessages.length > 0) {
      setShowError(true);
    } else {
      setIsUpdating(true);

      createFolderMutation({
        variables: {
          folderName: values.folderName,
          parentFolderFiid: folder.id,
        },
      });

      setToast({
        style: ToastStyle.Info,
        text: getToastMessage(ToastMessageType.INFO, values),
      });
    }
  };

  return (
    <div className="AddFolderModal" data-testid="AddFolderModal">
      <Formik
        initialValues={InitialValues}
        validate={_validate}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={_submit}
      >
        {() => (
          <Form>
            <div className="AddFolderModal__input-box">
              <TextField
                label={TEXT_INPUT_TITLE}
                name="folderName"
                type="string"
                maxCharCount={MAX_CHAR_COUNT}
                required={true}
                maxLength={MAX_LENGTH}
                role="input"
              />
              {/* Validation error message */}
              <div
                className="AddFolderModal__error-message"
                data-testid="error-message"
              >
                {showError && <>{errorMessages[0]}</>}
              </div>
            </div>
            <ButtonGroup>
              {/* Cancel button */}
              <Button
                data-testid="cancel-button"
                id="cancel"
                style={ButtonStyle.TegusSecondary}
                action={clearModal}
              >
                {TEXT_CANCEL}
              </Button>
              {/* Save button */}
              <Button
                data-testid="submit-button"
                id="Create folder"
                style={ButtonStyle.TegusPrimary}
                type={ButtonType.Submit}
              >
                {TEXT_SAVE}
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/**
 * Get toast message to display based on message type and resources uploaded
 *
 * @param messageType Type of message to display (info, success, or error)
 * @param folder Folder created
 * @returns Toast message
 */
export const getToastMessage = (
  messageType: ToastMessageType,
  folder?: any
): string => {
  if (messageType === ToastMessageType.INFO) {
    return MSG_CREATE_FOLDER.replace("{name}", folder.folderName);
  }
  if (messageType === ToastMessageType.SUCCESS) {
    return MSG_CREATE_FOLDER_SUCCESS;
  }

  return MSG_CREATE_FOLDER_ERROR;
};

export default AddFolderModal;
