import { ApolloError } from "@apollo/client";
import { Form, FormikProps, useField } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import Button, {
  ButtonState,
  ButtonStyle,
  ButtonType,
} from "../../common/buttons/Button";
import Checkbox from "../../common/Checkbox";
import routes from "../../utils/routes";

const USER_AGREEMENT_CHECKBOX = "I accept the terms of this agreement";

interface UserAgreementFormProps extends FormikProps<{ hasAgreed: boolean }> {
  mutationError?: ApolloError;
  mutationSuccess?: boolean;
  hasReadAgreement: boolean;
}

const UserAgreementForm: React.FC<UserAgreementFormProps> = ({
  mutationError,
  mutationSuccess,
  hasReadAgreement,
  ...formikProps
}) => {
  const errorMessage = () => (
    <p data-testid="error-500">
      Something went wrong while attempting to accept the user agreement. Please
      try again later or <Link to={routes.support}>contact support</Link> for
      assistance.
    </p>
  );
  return (
    <Form>
      <UserAgreementCheckbox
        value={formikProps.values.hasAgreed}
        disabled={!hasReadAgreement}
      />
      {mutationError ? errorMessage() : null}
      <Button
        className="mr-auto"
        state={
          !formikProps.values.hasAgreed ||
          formikProps.isSubmitting ||
          mutationSuccess
            ? ButtonState.Disabled
            : undefined
        }
        style={ButtonStyle.TegusPrimary}
        type={ButtonType.Submit}
      >
        Accept
      </Button>
    </Form>
  );
};

interface UserAgreementCheckboxProps {
  value: boolean;
  disabled: boolean;
}

const UserAgreementCheckbox: React.FC<UserAgreementCheckboxProps> = ({
  value,
  disabled,
}) => {
  const [, , helpers] = useField("hasAgreed");
  return (
    <Checkbox
      value={USER_AGREEMENT_CHECKBOX}
      testId="user-agreement-checkbox"
      checked={value}
      disabled={disabled}
      onChange={() => helpers.setValue(!value)}
    />
  );
};

export default UserAgreementForm;
