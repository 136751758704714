import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { RouteProps, useHistory } from "react-router-dom";
import { HOME_PAGE, Page, SETTINGS_PAGE } from "../../common/Breadcrumbs";
import Button, { ButtonStyle, ButtonType } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../../common/Spinner";
import {
  ChoicesToVariantCombination,
  variantCombinationToChoices,
} from "../../common/variants";
import {
  useVariantPreferenceMeQuery as useVariantPreferenceMeQueryDefault,
  useVariantPreferenceUpdateUserVariantPreferenceMutation as useVariantPreferenceUpdateUserVariantPreferenceMutationDefault,
  VariantPreferenceUpdateUserVariantPreferenceMutationVariables,
} from "../../generated/graphql";
import Card from "../../layout/Card";
import ToastContext from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import routes from "../../utils/routes";
import "./VariantPreference.scss";
import VariantPreferenceForm, {
  VariantPreferenceFromStateType,
} from "./VariantPreferenceForm";

export const DEFAULT_SETTINGS = [
  HOME_PAGE,
  SETTINGS_PAGE,
  {
    isCurrent: true,
    nameOrIcon: "Default Model Format Settings",
  },
];

export interface VariantPreferenceProps extends RouteProps {
  setBreadcrumbs: (value: Page[]) => void;
}

export interface VariantPreferenceDeps {
  useVariantPreferenceQuery?: typeof useVariantPreferenceMeQueryDefault;
  useVariantPreferenceMutation?: typeof useVariantPreferenceUpdateUserVariantPreferenceMutationDefault;
}

const VariantPreference: React.FC<
  VariantPreferenceProps & VariantPreferenceDeps
> = ({
  setBreadcrumbs,
  useVariantPreferenceQuery = useVariantPreferenceMeQueryDefault,
  useVariantPreferenceMutation = useVariantPreferenceUpdateUserVariantPreferenceMutationDefault,
}) => {
  useEffect(() => {
    setBreadcrumbs(DEFAULT_SETTINGS);
  }, [setBreadcrumbs]);

  const history = useHistory();
  const { setToast } = useContext(ToastContext);

  const { loading, error, data } = useVariantPreferenceQuery({
    fetchPolicy: "network-only",
  });

  const errorMessage =
    "There was an issue updating your default variant. Please try again or " +
    "contact Support if the issue persists.";
  const [mutationError, setMutationError] = useState<string>();
  const [updatePreferenceMutation, updatePreferenceMutationResult] =
    useVariantPreferenceMutation({
      onCompleted: () => navigateToSettingsPage(),
      onError: () => setMutationError(errorMessage),
    });
  const updateVariantsWrapper = async (
    vars: VariantPreferenceUpdateUserVariantPreferenceMutationVariables
  ) => {
    await updatePreferenceMutation({ variables: vars });
  };
  const navigateToSettingsPage = async () => {
    history.push(routes.settings);
    setToast({
      style: ToastStyle.Info,
      text: "Default model format successfully changed",
    });
  };

  if (loading || updatePreferenceMutationResult.loading) {
    return (
      <div className="VariantPreference__loading">
        <div className="VariantPreference__loading--spinner">
          <Spinner colour={ColourType.SECONDARY} size="3rem" />
        </div>
      </div>
    );
  }

  if (error || !data?.me) {
    return (
      <div className="VariantPreference">
        <Card title="Sorry, could not load your preferences.">
          <ButtonGroup>
            <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
            <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
              Contact Us
            </Button>
          </ButtonGroup>
        </Card>
      </div>
    );
  } else {
    const perms = data.me.djangoGlobalPerms;
    return (
      <div className="VariantPreference">
        <Card title="Default Model Format Settings" tegus>
          <p className="VariantPreference__description">
            Canalyst models are available in different presentation formats. Use
            the options below to change the default format for all the Canalyst
            models that you download.
          </p>
          <Formik<VariantPreferenceFromStateType>
            initialValues={variantCombinationToChoices(
              data.me.variantPreference
            )}
            validateOnMount={true}
            onSubmit={(values, actions) => {
              updateVariantsWrapper(ChoicesToVariantCombination(values));
            }}
          >
            {(formikProps) => {
              return (
                <VariantPreferenceForm
                  formikProps={formikProps}
                  mutationError={mutationError}
                  djangoGlobalPerms={perms}
                />
              );
            }}
          </Formik>
        </Card>
      </div>
    );
  }
};

export default VariantPreference;
