import React, { useEffect, useState } from "react";
import Checkbox from "./Checkbox";
import "./CheckboxList.scss";

export type CheckboxListValueNameMap = Record<string, CheckboxListValue>;

export interface CheckboxListValue {
  id: string;
  name: string;
  isChecked: boolean;
}

interface CheckboxListProps {
  name: string;
  allValuesName?: string;
  values: CheckboxListValueNameMap;
  setValues: (values: CheckboxListValueNameMap) => void;
}

const CheckboxList: React.FC<CheckboxListProps> = ({
  name,
  values,
  setValues,
  allValuesName = "All Values",
}) => {
  const [allValues, setAllValues] = useState(false);

  useEffect(() => {
    setAllValues(Object.values(values).every((value) => value.isChecked));
  }, [values]);

  const allValuesOnClick = () => {
    setAllValues(!allValues);
    setValues(
      Object.keys(values).reduce<CheckboxListValueNameMap>((acc, name) => {
        return {
          ...acc,
          [name]: {
            ...values[name],
            isChecked: !allValues,
          },
        };
      }, {})
    );
  };

  const getHandleBoxChecked = (checkboxValue: CheckboxListValue) => {
    // Returns a function to be used as onChange for the specific checkboxValue provided
    return () => {
      setValues({
        ...values,
        [checkboxValue.name]: {
          ...checkboxValue,
          isChecked: !checkboxValue.isChecked,
        },
      });

      if (allValues) {
        setAllValues(false);
      } else if (
        Object.keys(values)
          .filter((key) => key !== checkboxValue.name)
          .every((key) => values[key].isChecked)
      ) {
        setAllValues(true);
      }
    };
  };

  const checkboxes: React.ReactElement[] = Object.values(values).map(
    (checkboxValue) => {
      return (
        <ul className="CheckboxList__checkbox-item" key={checkboxValue.name}>
          <Checkbox
            value={checkboxValue.name}
            checked={checkboxValue.isChecked}
            onChange={getHandleBoxChecked(checkboxValue)}
          />
        </ul>
      );
    }
  );

  return (
    <div className="CheckboxList">
      <h6 className="CheckboxList__heading">{name}</h6>
      <Checkbox
        value={allValuesName}
        checked={allValues}
        onChange={allValuesOnClick}
      />
      {checkboxes}
    </div>
  );
};

export default CheckboxList;
