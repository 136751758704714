import React, { useContext as useContextDefault } from "react";
import { useHistory, useParams } from "react-router-dom";
import Spinner, { ColourType } from "../../common/Spinner";
import {
  ResourceByPathAndSlugQuery,
  useResourceByPathAndSlugQuery,
} from "../../generated/graphql";
import ToastContext from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import { useDownload as useDownloadDefault } from "../../utils/location";
import routes from "../../utils/routes";
import "./DownloadLink.scss";

interface DownloadLinkDeps {
  useDownload?: () => (url: string) => void;
  useContext?: typeof useContextDefault;
}

const DownloadLink: React.FC<DownloadLinkDeps> = ({
  useDownload = useDownloadDefault,
  useContext = useContextDefault,
}) => {
  const history = useHistory();
  const download = useDownload();
  const { setToast } = useContext(ToastContext);
  const { folderPath, fileSlug } = useParams<{
    folderPath: string;
    fileSlug: string;
  }>();
  useResourceByPathAndSlugQuery({
    variables: {
      folderPath: folderPath,
      fileSlug: fileSlug,
    },
    fetchPolicy: "cache-first",
    onCompleted: (data) => onCompleted(data),
    onError: () => onError(),
  });

  const onCompleted = (data: ResourceByPathAndSlugQuery) => {
    if (data.resourceByPathAndSlug?.downloadUrl !== undefined) {
      history.push(routes.companies(folderPath));
      download(data.resourceByPathAndSlug.downloadUrl);
    } else {
      onError();
    }
  };
  const onError = () => {
    history.push(routes.home);
    setToast({
      style: ToastStyle.Error,
      text: "Sorry, the requested file could not be found",
    });
  };

  return (
    <div className="DownloadLink">
      <Spinner size="3rem" colour={ColourType.SECONDARY} />
    </div>
  );
};

export default DownloadLink;
