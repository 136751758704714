import { LocationDescriptor } from "history";
import React, { HTMLAttributes, useMemo } from "react";
import { Link } from "react-router-dom";
import settingsDefault, { Settings } from "../utils/settings";

export enum ActionType {
  FUNCTION,
  HREF,
  LINK_TO,
  NO_ACTION,
}

// The props of <Action /> are used by <Button />, and <Button /> has its own
// style prop, so exclude it here (otherwise the typing won't work)
type ActionHtmlProps = Omit<HTMLAttributes<HTMLElement>, "style">;

export interface ActionProps extends ActionHtmlProps {
  action?: () => void; // A function to be executed on click
  href?: string; // Link based on an <a> element (full page refresh)
  linkTo?: LocationDescriptor; // Link based on a <Link> component (react router)
  spaOrMpa?: string; // Either an <a> or <Link> according to the useOldInterface setting
}
interface ActionDeps {
  settings?: Settings;
}

const Action: React.FC<ActionProps & ActionDeps> = ({
  children,
  action,
  href,
  linkTo,
  spaOrMpa,
  settings = settingsDefault,
  ...htmlProps
}) => {
  const numActionPropsDefined = [action, href, linkTo, spaOrMpa].reduce(
    (memo, prop) => (prop === undefined ? memo : memo + 1),
    0
  );
  if (numActionPropsDefined > 1) {
    throw Error(
      'Only one of "action", "href", "linkTo" or "spaOrMpa" can be defined'
    );
  }

  const actionElem = useMemo(() => {
    if (action !== undefined) {
      return (
        <span {...htmlProps} onClick={action}>
          {children}
        </span>
      );
    } else if (href !== undefined) {
      return getHrefElem({ children, href, htmlProps });
    } else if (linkTo !== undefined) {
      return getLinkToElem({ children, linkTo, htmlProps });
    } else if (spaOrMpa !== undefined) {
      if (settings.useOldInterface) {
        return getHrefElem({ children, href: spaOrMpa, htmlProps });
      } else {
        return getLinkToElem({ children, linkTo: spaOrMpa, htmlProps });
      }
    } else {
      return <span {...htmlProps}>{children}</span>;
    }
  }, [
    children,
    action,
    href,
    linkTo,
    settings.useOldInterface,
    spaOrMpa,
    htmlProps,
  ]);

  return actionElem;
};

const getHrefElem = ({
  children,
  href,
  htmlProps,
}: {
  children: React.ReactNode;
  href?: string;
  htmlProps: ActionHtmlProps;
}) => {
  return (
    <a {...htmlProps} href={href} data-testid="anchor-href">
      {children}
    </a>
  );
};

const getLinkToElem = ({
  children,
  linkTo,
  htmlProps,
}: {
  children: React.ReactNode;
  linkTo: LocationDescriptor;
  htmlProps: ActionHtmlProps;
}) => {
  return (
    <Link {...htmlProps} to={linkTo}>
      {children}
    </Link>
  );
};

export default Action;
