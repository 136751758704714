import { GridFilterModel } from "@mui/x-data-grid-pro";
import {
  Context,
  useCallback,
  useContext as useContextDefault,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { HOME_PAGE, Page } from "../common/Breadcrumbs";
import {
  useCompaniesCategoryListQuery,
  useCompaniesCompanyListQuery,
} from "../generated/graphql";
import ToastContext, { ToastContextValue } from "../toast/ToastContext";
import { ToastStyle } from "../toast/ToastDisplay";
import { Category, CategoryMap, CompaniesRowData } from "./common/interfaces";
import { REFRESH_INTERVAL } from "./common/settings";
import { COMPANIES_STRINGS as STRINGS } from "./common/strings";
import {
  createCategoryMap,
  createCategoryTree,
  createSelectedFolderIdList,
  parseData,
  updateCategoryDetails,
  updateIndeterminate,
} from "./common/utils";
import CompaniesDisplay, { CompaniesDisplayProps } from "./CompaniesDisplay";
import CompaniesDisplayFull from "./CompaniesDisplayFull";
import CompaniesQuickFilter from "./sidebar/CompaniesQuickFilter";
import CompaniesSidebar from "./sidebar/CompaniesSidebar";
import CompaniesTableDefault from "./table/CompaniesTable";

const DEFAULT_QUICK_FILTER_TERM = "";
const DEFAULT_FILTER_MODEL = {
  items: [],
  quickFilterValues: [DEFAULT_QUICK_FILTER_TERM],
};

interface CompaniesProps {
  setBreadcrumbs: (value: Page[]) => void;
}

interface CompaniesDeps {
  parseData_?: typeof parseData;
  useToastContext?: (context: Context<ToastContextValue>) => ToastContextValue;
  CompaniesTable?: typeof CompaniesTableDefault;
}

/**
 * Companies page
 *
 * @param setBreadcrumbs Method to call when setting breadcrumbs
 */
const Companies: React.FC<CompaniesProps & CompaniesDeps> = ({
  setBreadcrumbs,
  parseData_ = parseData,
  useToastContext = useContextDefault,
  CompaniesTable = CompaniesTableDefault,
}) => {
  const { setToast } = useToastContext(ToastContext);

  const [categories, setCategories] = useState<Category[]>([]);
  const [rows, setRows] = useState<CompaniesRowData[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [updated, setUpdated] = useState<Date>(new Date());
  const [dataUpdated, setDataUpdated] = useState<string>("");
  const [refreshEnabled, setRefreshEnabled] = useState<boolean>(true);
  const [quickFilterTerm, setQuickFilterTerm] = useState<string>("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [filterModel, setFilterModel] =
    useState<GridFilterModel>(DEFAULT_FILTER_MODEL);
  const [showSidebar, setShowSidebar] = useState<boolean>(true);
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  const companiesQuery = useCompaniesCompanyListQuery({
    variables: { lastUpdate: null },
  });
  const categoriesQuery = useCompaniesCategoryListQuery();

  const categoryMap = useRef<CategoryMap>({});

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      HOME_PAGE,
      { isCurrent: true, nameOrIcon: STRINGS.main.companies },
    ]);
  }, [setBreadcrumbs]);

  // Update state after companies list query returns
  useEffect(() => {
    if (!companiesQuery.loading) {
      setLoading(false);
      if (companiesQuery.data) {
        setTotal(companiesQuery.data.companiesList.companies.length);
        setUpdated(new Date());
        setDataUpdated(companiesQuery.data.companiesList.updated);
      }
    }
  }, [companiesQuery]);

  // Update state after categories list query returns
  useEffect(() => {
    if (!categoriesQuery.loading && categoriesQuery.data) {
      categoryMap.current = createCategoryMap(
        categoriesQuery.data.categoryList
      );
      const categoryTree = createCategoryTree(categoryMap.current);

      updateCategoryDetails(categoryTree);
      setCategories(categoryTree);
    }
  }, [categoriesQuery]);

  // Update rows after both companies and categories queries return
  useEffect(() => {
    if (categories.length && companiesQuery.data) {
      setRows(
        parseData_(
          companiesQuery.data.companiesList.companies,
          categoryMap.current,
          selectedCategories
        )
      );
    }
  }, [categories.length, companiesQuery.data, parseData_, selectedCategories]);

  // Update filter model
  const updateFilterModel = useCallback((newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel);
  }, []);

  // Update quick filter term
  const updateQuickFilter = useCallback(
    (searchTerm) => {
      setFilterModel({ ...filterModel, quickFilterValues: [searchTerm] });
      setQuickFilterTerm(searchTerm);
    },
    [filterModel]
  );

  // Handle category select / deselect
  const select = useCallback((newCategories: Category[]) => {
    updateIndeterminate(newCategories);
    setCategories(newCategories);
    setSelectedCategories(createSelectedFolderIdList(newCategories));
  }, []);

  // Handle category expand / collapse
  const expand = useCallback((newCategories: Category[]) => {
    setCategories(newCategories);
  }, []);

  // Toggle sidebar
  const toggleSidebar = useCallback(() => {
    setShowSidebar(!showSidebar);
  }, [showSidebar]);

  // Toggle fullscreen mode
  const toggleFullscreen = useCallback(() => {
    setFullscreen(!fullscreen);
  }, [fullscreen]);

  // Refresh companies data
  const updateNow = useCallback(() => {
    if (dataUpdated === "") {
      return;
    }

    setLoading(true);

    companiesQuery
      .fetchMore({
        variables: { lastUpdate: dataUpdated },
      })
      .then((response) => {
        setUpdated(new Date());
        setDataUpdated(response.data.companiesList.updated);
      })
      .catch((reason) => {
        setRefreshEnabled(false);
        setToast({
          style: ToastStyle.Error,
          text: STRINGS.main.toasts.refreshError,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companiesQuery, dataUpdated, setToast]);

  // Start periodic refresh timer
  useEffect(() => {
    if (refreshEnabled) {
      const interval = setInterval(() => {
        updateNow();
      }, REFRESH_INTERVAL);

      return () => clearInterval(interval);
    }
  }, [refreshEnabled, updateNow]);

  // Setup display props
  const companiesDisplayProps: CompaniesDisplayProps = useMemo(() => {
    return {
      showSidebar: showSidebar,
      toggleSidebar: toggleSidebar,
      toggleFullscreen: toggleFullscreen,
      sidebar: (
        <CompaniesSidebar
          loading={categories.length === 0}
          categories={categories}
          onSelect={select}
          onExpand={expand}
          search={
            <CompaniesQuickFilter
              filterTerm={quickFilterTerm}
              updateQuickFilter={updateQuickFilter}
            />
          }
        />
      ),
      table: (
        <CompaniesTable
          loading={loading}
          error={companiesQuery.error}
          rows={rows}
          filterModel={filterModel}
          updateFilterModel={updateFilterModel}
          numCompanies={total}
          updated={updated}
          updateNow={updateNow}
        />
      ),
    };
  }, [
    CompaniesTable,
    categories,
    companiesQuery.error,
    expand,
    filterModel,
    loading,
    quickFilterTerm,
    rows,
    select,
    showSidebar,
    toggleFullscreen,
    toggleSidebar,
    total,
    updateFilterModel,
    updateNow,
    updateQuickFilter,
    updated,
  ]);

  return fullscreen ? (
    <CompaniesDisplayFull {...companiesDisplayProps} />
  ) : (
    <CompaniesDisplay {...companiesDisplayProps} />
  );
};

export default Companies;
