import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";
import Action from "../../common/Action";
import FancyListItem from "../../common/FancyListItem";
import ContextMenu from "../../context_menu/ContextMenu";
import { FA_ICON_DEFAULT_STYLE, getIconComponent } from "../../utils/icons";
import { useRequestModelModal } from "../../utils/modal";
import { useCopyShareLink } from "../browse/utils/sharing";
import FolderItemFolderElem from "./FolderItemFolderElem";
import { GenericPlaceholder, isSearchPlaceholder } from "./utils/graphql";

interface PlaceholderElemNoMemoProps {
  placeholder: GenericPlaceholder;
  highlighted?: boolean;
}
interface PlaceholderElemNoMemoDeps {
  useCopyShareLink_?: typeof useCopyShareLink;
}

const PlaceholderElemNoMemo = React.forwardRef<
  HTMLDivElement,
  PlaceholderElemNoMemoProps & PlaceholderElemNoMemoDeps
>(({ placeholder, highlighted, useCopyShareLink_ = useCopyShareLink }, ref) => {
  const requestModel = useRequestModelModal();
  const copyShareLink = useCopyShareLink_();
  return (
    <ContextMenu
      items={
        <>
          <Action
            action={() => copyShareLink(placeholder)}
            data-testid="action-copy-link"
          >
            Copy Link
          </Action>
        </>
      }
    >
      <FancyListItem
        icon={getIconComponent(placeholder.icon)}
        action={() => requestModel(placeholder)}
        subtext={[
          <>
            {isSearchPlaceholder(placeholder) && (
              <FolderItemFolderElem folder={placeholder.folder} />
            )}
            Available by request
          </>,
        ]}
        text={placeholder.name}
        secondaryActions={[
          {
            icon: (
              <FontAwesomeIcon icon={faPlus} style={FA_ICON_DEFAULT_STYLE} />
            ),
            id: "file-request",
            action: () => requestModel(placeholder),
            tooltipText: "Request the Model",
          },
        ]}
        highlighted={highlighted}
        ref={ref}
      />
    </ContextMenu>
  );
});

export default memo(PlaceholderElemNoMemo);
