import { gql } from "@apollo/client";
import { GraphQlObject } from "../common/graphql";

export interface User extends GraphQlObject {
  id: string;
  djangoGlobalPerms: string[];
}

export interface MeData {
  me: User | null;
}

// The above types must be kept in sync with this query.
export const GET_ME_QUERY = gql`
  query getMe {
    me {
      id
      djangoGlobalPerms
    }
  }
`;
