import React from "react";
import { Link } from "react-router-dom";
import Button, { ButtonStyle, ButtonType } from "../common/buttons/Button";
import ButtonGroup from "../common/buttons/ButtonGroup";
import routes from "../utils/routes";

interface MessageNoResourcesProps {
  showButtons?: boolean;
}

const MessageNoResources: React.FC<MessageNoResourcesProps> = ({
  showButtons = true,
}) => {
  return (
    <div className="MessageNoResources" data-testid="no-resources">
      <p>
        There are currently no available resources in this section. Please{" "}
        <Link to={routes.support}>contact support</Link> if you require
        assistance.
      </p>
      {showButtons && (
        <ButtonGroup>
          <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
          <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
            Contact Us
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};

export default MessageNoResources;
