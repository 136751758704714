import { ApolloClient, useApolloClient } from "@apollo/client";
import { Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  UserSetupUserSetupDocument,
  useUserSetupAcceptUserAgreementMutation,
} from "../../generated/graphql";
import Card from "../../layout/Card";
import { noop } from "../../utils/functools";
import "./UserAgreement.scss";
import UserAgreementCopy from "./UserAgreementCopy";
import UserAgreementForm from "./UserAgreementForm";

interface UserAgreementDeps {
  intersectionObserver_?: typeof IntersectionObserver;
  apolloClient?: () => ApolloClient<any>;
}

const UserAgreement: React.FC<UserAgreementDeps> = ({
  intersectionObserver_ = IntersectionObserver,
  apolloClient = useApolloClient,
}) => {
  const refetch = apolloClient();
  const [hasReadAgreement, setHasReadAgreement] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);
  const [mutationFn, mutationResult] = useUserSetupAcceptUserAgreementMutation({
    onCompleted: async () =>
      await refetch.refetchQueries({
        include: [UserSetupUserSetupDocument],
      }),
  });

  const onSubmit = useCallback(async () => {
    // Catch errors to prevent uncaught promise exceptions:
    // https://github.com/formium/formik/issues/1580
    await mutationFn().catch(noop);
  }, [mutationFn]);

  useEffect(() => {
    // Detect when the user has scrolled to the end of the agreement
    const observer = new intersectionObserver_(
      ([entry]) => {
        if (entry.isIntersecting && !hasReadAgreement) {
          setHasReadAgreement(true);
        }
      },
      {
        root: wrapperRef.current,
        rootMargin: "50px",
        threshold: 0.1,
      }
    );
    observer.observe(placeholderRef.current as Element);
    return () => {
      observer.disconnect();
    };
  }, [placeholderRef, hasReadAgreement, intersectionObserver_]);

  return (
    <div className="UserAgreement">
      <Card title="Service Agreement">
        <p className="UserAgreement__instructions">
          You must scroll to the end before accepting the service agreement.
        </p>
        <div
          className="UserAgreement__copy"
          data-testid="user-agreement-copy"
          ref={wrapperRef}
        >
          <UserAgreementCopy />
          <div
            className="UserAgreement__copy-placeholder"
            ref={placeholderRef}
          ></div>
        </div>
        <Formik initialValues={{ hasAgreed: false }} onSubmit={onSubmit}>
          {(props) => (
            <UserAgreementForm
              {...props}
              mutationError={mutationResult.error}
              mutationSuccess={
                mutationResult.data?.acceptUserAgreement?.success
              }
              hasReadAgreement={hasReadAgreement}
            />
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default UserAgreement;
