import { Form, FormikErrors } from "formik";
import React, { useMemo } from "react";
import Button, { ButtonStyle, ButtonType } from "../common/buttons/Button";
import ButtonGroup from "../common/buttons/ButtonGroup";
import { ALT_CONTACT, MODEL_REQUEST_RESPONSE_TIME } from "../common/copy";
import { DropdownChoice } from "../common/Dropdown";
import ChoiceField from "../forms/ChoiceField";
import FormError from "../forms/FormError";
import TextField, { TextFieldType } from "../forms/TextField";
import { mapKindToDropdownChoices, UpdateReasonChoices } from "./ContactUsForm";
import { ModelUpdateRequestState } from "./ModelUpdateRequest";
import "./ModelUpdateRequestForm.scss";
import { MaxChars } from "./utils";

interface ModelUpdateRequestFormProps {
  values: ModelUpdateRequestState;
  errors: FormikErrors<ModelUpdateRequestState>;
  onCancel: () => void;
}

const ModelUpdateRequestForm: React.FC<ModelUpdateRequestFormProps> = ({
  values,
  errors,
  onCancel,
}) => {
  const updateReasonChoices = useMemo<DropdownChoice[]>(
    () => mapKindToDropdownChoices(UpdateReasonChoices),
    []
  );
  return (
    <div className="ModelUpdateRequestForm">
      <Form>
        <div className="row">
          <div className="col">
            <p>
              Use this form to request an update for this model and we will
              respond as soon as possible.
            </p>
            {ALT_CONTACT}
            {MODEL_REQUEST_RESPONSE_TIME}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ChoiceField
              label="Update Reason"
              name="updateReason"
              choices={updateReasonChoices}
              nullChoiceText="Please select one..."
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TextField
              label="Company Name or Ticker"
              name="company"
              type="text"
              value={values.company}
              disabled={true}
              required={false}
              fieldType={TextFieldType.INPUT}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TextField
              label="Comments"
              name="comments"
              type="text"
              maxCharCount={MaxChars.Comments}
              required={false}
              fieldType={TextFieldType.AREA}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            {errors.formError && <FormError errorText={errors.formError} />}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ButtonGroup>
              <Button style={ButtonStyle.TegusPrimary} type={ButtonType.Submit}>
                Send Request
              </Button>
              <Button
                className="ContactUsForm__cancel-button"
                style={ButtonStyle.TegusSecondary}
                action={onCancel}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ModelUpdateRequestForm;
