import {
  ResourcesResourceFragment,
  ResourcesResourcesQuery,
} from "../../generated/graphql";

export const NUM_ITEMS = 100;

export enum ResourcesFolderIds {
  screens = "4ES8qM",
  templates = "J6Spo1",
  toolkitAndGuides = "j6S9Rq",
}

type ResourcesScreensPreview = NonNullable<ResourcesResourcesQuery["screens"]>;
type ResourcesTemplatesPreview = NonNullable<
  ResourcesResourcesQuery["templates"]
>;
type ResourcesToolkitAndGuidesPreview = NonNullable<
  ResourcesResourcesQuery["toolkitAndGuides"]
>;

export type ResourcesPreviewFolder =
  | ResourcesScreensPreview
  | ResourcesTemplatesPreview
  | ResourcesToolkitAndGuidesPreview;

export type ResourcesFolderItem =
  | ResourcesResourceFragment
  | { __typename: string };
