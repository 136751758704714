import React, { RefObject, useEffect, useState } from "react";
import { FolderItemEdge } from "../../generated/graphql";

interface BrowseAutoScrollProps {
  enabled: boolean;
  itemToHighlightRef: RefObject<HTMLDivElement>;
  itemAtBottomRef: RefObject<HTMLDivElement>;
  edges: Pick<FolderItemEdge, "__typename">[];
  itemToHighlightFiid?: string;
}

/**
 * Automatically scrolls to a specified folder item. Does not render anything
 *
 * @param props - Component props
 * @param props.enabled - If auto-scrolling is enabled
 * @param props.itemToHighlightRef - A ref of the item to highlight / scroll to
 * @param props.itemAtBottomRef - A ref of the current item at the bottom of the page
 * @param props.edges - The edges of the browse page. Will change as more items are
 *   loaded, causing the useEffect hook to be re-executed (necessary since useEffect is
 *   NOT re-executed when a reference such as itemAtBottomRef changes)
 * @param props.itemToHighlightFiid - useEffect hook must be re-executed when this
 *   changes as well. That is, we want to re-scroll if the item to highlight changes
 */
const BrowseAutoScroll: React.FC<BrowseAutoScrollProps> = ({
  enabled,
  itemToHighlightRef,
  itemAtBottomRef,
  edges,
  itemToHighlightFiid,
}) => {
  const [lastTarget, setLastTarget] = useState<HTMLDivElement>();

  useEffect(() => {
    const target = itemToHighlightRef.current || itemAtBottomRef.current;

    // Explicit check for scrollIntoView as Enzyme doesn't properly mock it
    if (!enabled || !target?.scrollIntoView) return;

    // Prevent more scrolling once the target is found. Without this,
    // auto-scroll will eagerly keep scrolling back to the same target
    if (target === lastTarget) return;
    setLastTarget(target);

    target.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "nearest",
    });

    // edges and itemToHighlightFiid must be included as a dependency s.t. this effect
    // is triggered when new folder items are loaded or the item to highlight changes.
  }, [
    enabled,
    itemToHighlightRef,
    itemAtBottomRef,
    edges,
    itemToHighlightFiid,
    lastTarget,
    setLastTarget,
  ]);
  return null;
};

export default BrowseAutoScroll;
