import {
  faCheck,
  faTrash,
  IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { RefObject, useEffect, useRef, useState } from "react";
import { MultiSelectListEntry } from "./MultiSelectList";
import "./MultiSelectListRow.scss";

export interface MultiSelectListRowProps {
  entry: MultiSelectListEntry;
  searchActive: boolean;
  icon?: IconDefinition;
  onClick: () => any;
  addItemText: string;
  removeItemText: string;
  wrapperRef: RefObject<HTMLDivElement>;
}

interface MultiSelectListRowDeps {
  intersectionObserver_?: typeof IntersectionObserver;
}

/**
 * MultiSelectListRow component - an individual row within a MultiSelectList
 *
 * @param props - Component props
 * @param props.entry - The entry to display
 * @param props.searchActive - Whether there is a current search term
 * @param props.icon - (Optional) Icon to display for the row
 * @param props.onClick - Hook to call when the row is clicked
 * @param props.addItemText - String to use for 'Add item' tooltip
 * @param props.removeItemText - String to use for 'Remove item' tooltip
 * @param props.wrapperRef - Ref for the parent containing element
 */
const MultiSelectListRow: React.FC<
  MultiSelectListRowProps & MultiSelectListRowDeps
> = ({
  entry,
  searchActive,
  icon,
  onClick,
  addItemText,
  removeItemText,
  wrapperRef,
  intersectionObserver_ = IntersectionObserver,
}) => {
  const [isVisible, setVisible] = useState<boolean>(false);
  const rowRef = useRef<HTMLDivElement>(null);

  const ariaLabelId = `${entry.item.value}-label`;
  const ariaDescId = `${entry.item.value}-description`;

  // Enable soft scrolling (items fade when scrolling into / out of view)
  useEffect(() => {
    const observer = new intersectionObserver_(
      (entries) => {
        entries.forEach((entry) => {
          setVisible(entry.isIntersecting);
        });
      },
      {
        root: wrapperRef.current,
        rootMargin: "20px 5px 0px 5px",
        threshold: 0.5,
      }
    );

    observer.observe(rowRef.current as Element);
    return () => {
      observer.disconnect();
    };
  }, [rowRef, wrapperRef, intersectionObserver_]);

  const classNames = {
    row: isVisible
      ? "MultiSelectListRow MultiSelectListRow--visible"
      : "MultiSelectListRow",
    body:
      searchActive && entry.selected
        ? "MultiSelectListRow__body MultiSelectListRow__body--selected"
        : "MultiSelectListRow__body",
    icon:
      searchActive && entry.selected
        ? "MultiSelectListRow__icon MultiSelectListRow__icon--selected"
        : "MultiSelectListRow__icon",
    name:
      searchActive && entry.selected
        ? "MultiSelectListRow__name MultiSelectListRow__name--selected"
        : "MultiSelectListRow__name",
  };

  return (
    <div ref={rowRef} className={classNames.row}>
      <Tippy
        content={
          entry.selected
            ? removeItemText.replace("{name}", entry.item.display)
            : addItemText.replace("{name}", entry.item.display)
        }
        delay={[250, 0]}
        duration={[300, 0]}
        placement="right"
        popperOptions={{
          strategy: "fixed",
          modifiers: [
            {
              name: "flip",
              options: {
                fallbackPlacements: ["left", "top"],
              },
            },
          ],
        }}
        offset={[0, 10]}
      >
        <div
          className={classNames.body}
          onClick={onClick}
          role="option"
          aria-selected={entry.selected}
          aria-labelledby={ariaLabelId}
          aria-describedby={ariaDescId}
        >
          <div className="MultiSelectListRow__display">
            {icon && (
              <FontAwesomeIcon
                icon={icon}
                className={classNames.icon}
                data-testid={`${entry.item.value}-icon`}
              />
            )}
            <div className={classNames.name} id={ariaLabelId}>
              {entry.item.display}
            </div>
            {entry.item.subtext && (
              <div className="MultiSelectListRow__subtext" id={ariaDescId}>
                {entry.item.subtext}
              </div>
            )}
          </div>
          <div className="MultiSelectListRow__action-icon-container">
            {searchActive ? (
              entry.selected && (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="MultiSelectListRow__action-icon--selected"
                />
              )
            ) : (
              <FontAwesomeIcon
                icon={faTrash}
                className="MultiSelectListRow__action-icon"
              />
            )}
          </div>
        </div>
      </Tippy>
    </div>
  );
};

export default MultiSelectListRow;
