import React, { Context, useContext as useContextDefault } from "react";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import ModalContext, { ModalContextValue } from "../../modal/ModalContext";

export interface BackupTokensModalDeps {
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
}

const BackupTokensModal: React.FC<BackupTokensModalDeps> = ({
  useModalContext = useContextDefault,
}) => {
  const { clearModal } = useModalContext(ModalContext);
  return (
    <div className="BackupTokensModal">
      <p>
        Important note: We recommend that you copy, download, or print these
        backup codes and keep them in a safe place.
      </p>
      <p>
        Use these backup codes to log in to your Canalyst account if you cannot
        access the authenticator app on your phone. Each code can only be used
        once.
      </p>
      <ButtonGroup>
        <Button style={ButtonStyle.TegusPrimary} action={clearModal}>
          Continue
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default BackupTokensModal;
