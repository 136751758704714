import {
  faChartLine,
  faHome,
  faIndustryAlt,
  faStar,
  faToolbox,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as PrivateFoldersIcon } from "../assets/icons/private-folder.svg";
import { useSideNavFolderByPathQuery as useSideNavFolderByPathQueryDefault } from "../generated/graphql";
import {
  getUserFromJwt as getUserFromJwtDefault,
  UserFromJwt,
} from "../utils/auth";
import routes from "../utils/routes";
import settingsDefault, { Settings } from "../utils/settings";
import "./SideNav.scss";
import StaffMenu from "./StaffMenu";

export const PRIVATE_FOLDER_POLL_INTERVAL = 5 * 60 * 1000; // 5 minutes in ms

export interface SideNavProps {
  currentRoute?: string;
}
export interface SideNavDeps {
  getUserFromJwt?: () => { user: UserFromJwt | null };
  settings?: Settings;
  useSideNavFolderByPathQuery?: typeof useSideNavFolderByPathQueryDefault;
}

const SideNav: React.FC<SideNavProps & SideNavDeps> = ({
  currentRoute,
  getUserFromJwt = getUserFromJwtDefault,
  settings = settingsDefault,
  useSideNavFolderByPathQuery = useSideNavFolderByPathQueryDefault,
}) => {
  const { user } = getUserFromJwt();
  const [privateFolderHasItems, setPrivateFolderHasItems] = useState(
    !!settings.privateFolderItemCount
  );

  // Skip the query under the following circumstances:
  //   1. The current user doesn't have a private folder path available
  //   2. We already know the user likely has items in their private folder
  const { data } = useSideNavFolderByPathQuery({
    variables: { path: settings.privateFolderPath || "" },
    skip: !settings.privateFolderPath || privateFolderHasItems,
    pollInterval: PRIVATE_FOLDER_POLL_INTERVAL,
  });

  useEffect(() => {
    // Never change this value from true to false. It's possible for either of
    // the data sources (settings or GraphQL) to be more up-to-date. It's better
    // to show the Private Folder link when there are no items vs. not show it
    // when there are items
    //
    // Note that the way we retrieve and store `privateFolderHasItems` state has
    // been carefully constructed such that:
    //   1. We can avoid making unnecessary requests (i.e. don't make any
    //      further GraphQL requests if we know there are folder items)
    //   2. We can avoid infinite render loops. This is deceptively tricky since
    //      `privateFolderHasItems` informs when to skip the query, and
    //      the query data informs `privateFolderHasItems`
    if (privateFolderHasItems) return;
    setPrivateFolderHasItems(!!data?.folderByPath?.itemCountRecursive);
  }, [privateFolderHasItems, data]);

  const SideNavLi: React.FC<{ route?: string }> = useCallback(
    ({ children, route }) => {
      const liClassNames = classNames("SideNav__li", {
        "SideNav__li--active": route && route === currentRoute,
      });
      return <div className={liClassNames}>{children}</div>;
    },
    [currentRoute]
  );

  return (
    <div className="SideNav">
      <div className="SideNav__ul">
        <SideNavLi route="home">
          <Link to={routes.home}>
            <FontAwesomeIcon icon={faHome} />
            <span>Home</span>
          </Link>
        </SideNavLi>
        <SideNavLi route="companies">
          <Link to={routes.companies()}>
            <FontAwesomeIcon icon={faIndustryAlt} />
            <span>Companies</span>
          </Link>
        </SideNavLi>
        <SideNavLi route="dashboards">
          <Link to={routes.dashboards}>
            <FontAwesomeIcon icon={faChartLine} />
            <span>Dashboards</span>
          </Link>
        </SideNavLi>
        <SideNavLi route="tools">
          <Link to={routes.tools}>
            <FontAwesomeIcon icon={faToolbox} />
            <span>Tools</span>
          </Link>
        </SideNavLi>
        {privateFolderHasItems && (
          <SideNavLi route="private-folder">
            <Link to={routes.privateFolder}>
              <PrivateFoldersIcon />
              <span>Private Folder</span>
            </Link>
          </SideNavLi>
        )}
        <SideNavLi route="watch-list">
          <Link to={routes.watchList}>
            <FontAwesomeIcon
              icon={faStar}
              style={{
                transform: "scale(1.1)",
                transformOrigin: "center",
              }}
            />
            <span>Watchlist</span>
          </Link>
        </SideNavLi>

        {user?.is_staff && <StaffMenu user={user} />}
      </div>
    </div>
  );
};

export default SideNav;
