import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as CanalystLogo } from "../assets/logos/logo.svg";
import SearchBox from "../search_box/SearchBox";
import routes from "../utils/routes";
import NavMenu from "./NavMenu";
import "./TopNav.scss";

export const FA_ICON_STYLE: React.CSSProperties = {
  width: "100%",
  verticalAlign: "middle",
};

interface TopNavProps {
  fromFolderId?: string;
}

const TopNav: React.FC<TopNavProps> = ({ fromFolderId }) => {
  return (
    <div className="TopNav">
      <Link to={routes.home} className="TopNav__left-section">
        <CanalystLogo />
      </Link>
      <div className="TopNav__right-section">
        <SearchBox fromFolderId={fromFolderId} />
        <NavMenu />
      </div>
    </div>
  );
};

export default TopNav;
