import React, { useMemo } from "react";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import { GetVariantQueryArgs, VariantCombination } from "../../common/variants";
import { useDownload as useDownloadDefault } from "../../utils/location";

type VariantPreferenceFormDeps = {
  useDownload?: () => (url: string) => void;
};
type VariantPreferenceExampleButtonProps = {
  variantCombination: VariantCombination;
};
const VariantPreferenceExampleButton: React.FC<
  VariantPreferenceExampleButtonProps & VariantPreferenceFormDeps
> = ({ variantCombination, useDownload = useDownloadDefault }) => {
  const download = useDownload();
  const downloadUrl = useMemo(() => {
    return `/files/example-variant${GetVariantQueryArgs(variantCombination)}`;
  }, [variantCombination]);
  return (
    <Button
      className="VariantPreferenceFormExampleButton"
      style={ButtonStyle.TegusSecondary}
      action={() => download(downloadUrl)}
    >
      Download Example Model
    </Button>
  );
};
export default VariantPreferenceExampleButton;
