import React, { useEffect, useState } from "react";
import { useUserSetupUserSetupQuery } from "../generated/graphql";
import TopNavMinimal from "../layout/TopNavMinimal";
import { redirect } from "../utils/location";
import routes from "../utils/routes";
import UserSetupDisplay from "./UserSetupDisplay";

interface UserSetupProps {
  setUserSetupComplete: (userSetupComplete: boolean) => void;
}

/**
 * Show setup steps to the user. This component blocks access to the rest of the
 * app until the user has completed all setup steps.
 *
 * Business logic is contained in this component; UserSetupDisplay is
 * responsible for rendering.
 */
const UserSetup: React.FC<UserSetupProps> = ({ setUserSetupComplete }) => {
  const { loading, data, error } = useUserSetupUserSetupQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [totalSteps, setTotalSteps] = useState(0);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (loading || !data) return;
    if (data.userSetup.complete || data.userSetup.tasks.length === 0) {
      setUserSetupComplete(true);
      redirect(routes.home);
    }
  }, [loading, data, setUserSetupComplete]);
  /**
   * Sets the starting total number of steps to complete
   *
   * After each step is completed refetch is called by the component for that
   * step. UserSetupQuery will return the remaining tasks each time refetch is
   * called. This is why the starting total number of steps must be preserved.
   */
  useEffect(() => {
    const tasks = data?.userSetup.tasks;
    if (totalSteps === 0 && tasks?.length) {
      setTotalSteps(tasks.length);
    }
  }, [data, totalSteps]);

  /**
   * Increments to first step and following steps after each task is completed
   */
  useEffect(() => {
    if (!loading && !error) {
      setStep((step) => step + 1);
    }
  }, [loading, error]);

  return (
    <>
      <TopNavMinimal
        title="Account Setup"
        totalSteps={totalSteps > 1 ? totalSteps : 0}
        currentStep={step}
      />
      <UserSetupDisplay
        loading={loading}
        tasks={data?.userSetup.tasks}
        error={error}
      />
    </>
  );
};

export default UserSetup;
