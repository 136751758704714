/**
 * Get browser cookie value for specified cookie name
 *
 * @param name Cookie name to get value for
 * @returns Cookie value, or blank string if cookie not found
 */
export const getCookie = (name: string): string => {
  const cookies = document.cookie.split("; ");

  for (let i = 0; i < cookies.length; i++) {
    if (cookies[i].substring(0, name.length + 1) === name + "=") {
      return decodeURIComponent(cookies[i].substring(name.length + 1));
    }
  }

  return "";
};

/**
 * Set a new browser cookie with the specified name and value, that expires after
 * the specified number of days
 *
 * @param name Cookie name
 * @param value Cookie value
 * @param expiryDays Number of days after which cookie expires
 */
export const setCookie = (name: string, value: string, expiryDays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000);

  const expires = "expires=" + d.toUTCString();
  const cookie = name + "=" + value + ";" + expires;

  document.cookie = cookie;
};
