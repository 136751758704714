import React, { useContext, useEffect } from "react";
import MfaSetup, {
  MfaEnforcedContext,
} from "../../user_settings/multi_factor_auth/MfaSetup";

/**
 * Sets the mfaEnforced context for MFA user setup step
 *
 * When mfaEnforced is true MFA backup tokens are generated and displayed inside
 * a modal after Verify is clicked.
 */
const MfaSetupEnforced: React.FC = () => {
  const { setMfaEnforced } = useContext(MfaEnforcedContext);
  useEffect(() => setMfaEnforced(true));
  return <MfaSetup />;
};

export default MfaSetupEnforced;
