import React, { memo } from "react";
import Button, { ButtonStyle, ButtonType } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import { COVERAGE_CRITERIA } from "../../common/copy";
import { SearchQuery } from "../../generated/graphql";
import Card from "../../layout/Card";
import routes from "../../utils/routes";

/**
 * A subset of `SearchDisplayProps` necessary for displaying the message
 */
interface MessageNoResultsProps {
  query: string;
  data?: SearchQuery;
  isFolderSearch: boolean;
  setFolderId: (folderId?: string) => void;
  setPage: (page?: number) => void;
}

/**
 * Message for when there are no search results
 *
 * @param props - Same props as `<SearchDisplay />`
 */
const MessageNoResults: React.FC<MessageNoResultsProps> = ({
  query,
  data,
  isFolderSearch,
  setFolderId,
  setPage,
}) => {
  if (data?.searchFolder && isFolderSearch) {
    return (
      <Card data-testid="msg-within-folder">
        <h4>No search results within {data.searchFolder.name}.</h4>
        <p>
          No files or folders matching{" "}
          <span className="font-italic">{query}</span> could be found within{" "}
          {data.searchFolder.name}.
        </p>
        <p>
          Note: Search is case-insensitive and matches against file and folder
          names only.
        </p>
        <ButtonGroup>
          <Button
            action={() => {
              setPage();
              setFolderId();
            }}
            style={ButtonStyle.TegusPrimary}
            data-testid="search-all-folders"
          >
            Search All Folders
          </Button>
          <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
            Contact Us
          </Button>
        </ButtonGroup>
      </Card>
    );
  } else {
    return (
      <Card data-testid="msg-all-folders">
        <h4>No search results.</h4>
        <p>
          No files or folders matching{" "}
          <span className="font-italic">{query}</span> could be found.{" "}
          <a href={routes.support}>Contact us</a> to submit a request for the
          model you are looking for.
        </p>
        {COVERAGE_CRITERIA}
        <p>
          Note: Search is case-insensitive and matches against file and folder
          names only.
        </p>
        <ButtonGroup>
          <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
          <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
            Contact Us
          </Button>
        </ButtonGroup>
      </Card>
    );
  }
};

export default memo(MessageNoResults);
