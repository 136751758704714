import { HTTP_STATUS } from "../constants/http";
import { FileData } from "../forms/FileUpload";
import { getCookie } from "./cookies";
import { poll as pollDefault } from "./poll";
import { convertFileData } from "./readFile";
import routes from "./routes";

const POLL_INTERVAL = 3000;
const INITIAL_FORMS = "0";
const MAX_NUM_FORMS = "8";

export enum FileType {
  ModelFile = "model",
  ResourceFile = "resource",
  OtherFile = "other",
}

export interface FileUpload {
  name?: string;
  file: FileData;
  fileType: FileType;
  resourceType?: string;
  replaceId?: string;
}

/**
 * Upload model or resource file(s) to the specified folder
 *
 * @param files List of files to upload
 * @param folderId fiid of folder to upload the files to
 * @returns Fetch API response object containing response from the backend
 */
export const uploadFiles = async (
  files: FileUpload[],
  folderId: string
): Promise<Response> => {
  // Build request form data
  const requestData = new FormData();
  requestData.append("csrfmiddlewaretoken", getCookie("csrftoken"));
  requestData.append("folder", folderId);
  requestData.append("form-TOTAL_FORMS", files.length.toString());
  requestData.append("form-INITIAL_FORMS", INITIAL_FORMS);
  requestData.append("form-MAX_NUM_FORMS", MAX_NUM_FORMS);

  // Add each file
  files.forEach((file, index) => {
    requestData.append(
      `form-${index}-file`,
      convertFileData(file.file.data, file.file.name, file.file.mimetype)
    );
    requestData.append(`form-${index}-file_type`, file.fileType);
    if (file.name) {
      requestData.append(`form-${index}-name`, file.name);
    }
    if (file.resourceType) {
      requestData.append(
        `form-${index}-resource_type`,
        file.resourceType.toLowerCase()
      );
    }
    if (file.replaceId) {
      requestData.append(`form-${index}-replace_id`, file.replaceId);
    }
  });

  // Upload files
  const response = await fetch(routes.uploadFile, {
    method: "POST",
    body: requestData,
  });

  return response;
};

/**
 * Wait for file upload process to complete
 *
 * @param jobId ID of upload job (returned in response data from call to uploadFiles)
 * @returns Fetch API response object containing response from the backend
 */
export const waitForUpload = async (
  jobId: string,
  poll: typeof pollDefault = pollDefault
): Promise<Response> => {
  return poll<Promise<Response>>(
    () => {
      return fetch(routes.uploadFileStatus + "?id=" + jobId, {
        method: "GET",
      });
    },
    (result: Response) => {
      return result.status === HTTP_STATUS.ACCEPTED;
    },
    POLL_INTERVAL
  );
};
