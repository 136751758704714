import React from "react";
import { noop } from "../functools";

export type CacheExpiry = {
  [key: string]: string;
};

export type CacheExpiryContextValue = {
  currentState: CacheExpiry;
  updateState: React.Dispatch<CacheExpiry>;
};

const CONTEXT_DEFAULT_VALUE: CacheExpiryContextValue = {
  currentState: {},
  updateState: noop,
};

const CacheExpiryContext: React.Context<CacheExpiryContextValue> =
  React.createContext(CONTEXT_DEFAULT_VALUE);

export default CacheExpiryContext;
