import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { Context, useContext } from "react";
import Button, { ButtonStyle } from "../common/buttons/Button";
import ButtonGroup from "../common/buttons/ButtonGroup";
import SimpleTable from "../common/SimpleTable";
import ModalContext from "../modal/ModalContext";
import { FA_ICON_DEFAULT_STYLE } from "../utils/icons";
import "./CreatedUsers.scss";

const BACK_BUTTON_COPY = "Back";
const TABLE_HEADER = [
  "First Name",
  "Last Name",
  "Email",
  "Welcome Email Sent",
  "Password Reset Link",
];
export const TOOLTIP_TEXT = "Welcome email was sent to ";

export interface CreatedBulkUser {
  firstName: string;
  lastName: string;
  email: string;
  welcomeEmailSent: boolean;
  passwordResetLink: {
    link: string;
    expiryInDays: number;
  };
}

interface CreatedUsersProps {
  createdUsers: CreatedBulkUser[];
}

interface CreatedUsersDeps {
  useContext_?: <T>(context: Context<T>) => T;
}

/**
 * Component to display users created in bulk
 *
 * @param createdUsers - The created users
 */
const CreatedUsers: React.FC<CreatedUsersProps & CreatedUsersDeps> = ({
  createdUsers,
  useContext_ = useContext,
}) => {
  const { clearModal } = useContext_(ModalContext);

  const values = createdUsers.map((user) => {
    const emailSentIcon = user.welcomeEmailSent ? (
      <Tippy content={TOOLTIP_TEXT + user.email}>
        <div className="CreatedUsers__envelope-icon-parent">
          <FontAwesomeIcon
            className="CreatedUsers__envelope-icon"
            icon={faEnvelope}
            style={FA_ICON_DEFAULT_STYLE}
            data-testid="envelope-icon"
          />
        </div>
      </Tippy>
    ) : (
      <></>
    );

    return [
      user.firstName,
      user.lastName,
      user.email,
      emailSentIcon,
      user.passwordResetLink.link,
    ];
  });

  return (
    <div className="CreatedUsers">
      <SimpleTable header={TABLE_HEADER} values={values} />
      <div className="CreatedUsers__footer">
        <ButtonGroup>
          <Button style={ButtonStyle.TegusSecondary} onClick={clearModal}>
            {BACK_BUTTON_COPY}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default CreatedUsers;
