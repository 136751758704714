import React, { useContext } from "react";
import mfa_code from "../../assets/mfa_code.jpg";
import mfa_login from "../../assets/mfa_login.jpg";
import mfa_phone from "../../assets/mfa_phone.jpg";
import Button, { ButtonStyle, ButtonType } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Card from "../../layout/Card";
import "../api_tokens/ApiTokens.scss";
import "./MfaInfo.scss";
import { MfaEnforcedContext } from "./MfaSetup";

const MFA_INFO_TITLE = "Multi-factor Authentication Setup";
const MFA_INFO_REQUIRED_MSG =
  "Canalyst requires that you enable multi-factor authentication now.";
const MFA_INFO_MSG =
  "Multi-factor authentication (MFA) is a method of access control that significantly enhances the security of your account by requiring an extra piece of evidence when you are logging in. This is done by entering a secure token generated on your smartphone.";
const MFA_INFO_LOGIN_MSG =
  "When you log into the Canalyst Web Portal you will enter your email and a password.";
const MFA_INFO_PHONE_MSG =
  "Using a multi-factor authentication app on your phone you will be provided with a one time secure token to further identify yourself.";
const MFA_INFO_CODE_MSG =
  "Once your token is entered on the Canalyst Web Portal you will be successfully logged in.";

interface MfaInfoProps {
  nextAction: () => void;
}

const MfaInfo: React.FC<MfaInfoProps> = ({ nextAction }) => {
  const { mfaEnforced } = useContext(MfaEnforcedContext);
  return (
    <div className="MfaInfo">
      <Card tegus>
        <div className="row">
          <div className="col-xl-12 MainCard">
            <h4>{MFA_INFO_TITLE}</h4>
            {mfaEnforced && (
              <div className="row" data-testid="mfa-enforced">
                <div className="col-xl-12">
                  <p className="MfaInfo__required">{MFA_INFO_REQUIRED_MSG}</p>
                </div>
              </div>
            )}
            <p>{MFA_INFO_MSG}</p>
          </div>
        </div>
        <div className="MfaInfo__images row">
          <div className="col-sm-8 col-xl-4">
            <Card>
              <img src={mfa_login} alt="Canalyst login screen"></img>
              <p>{MFA_INFO_LOGIN_MSG}</p>
            </Card>
          </div>
          <div className="col-sm-8 col-xl-4">
            <Card>
              <img src={mfa_phone} alt="An MFA code on a phone screen"></img>
              <p>{MFA_INFO_PHONE_MSG}</p>
            </Card>
          </div>
          <div className="col-sm-8 col-xl-4">
            <Card>
              <img
                src={mfa_code}
                alt="An MFA code being input into the Web Portal"
              ></img>
              <p>{MFA_INFO_CODE_MSG}</p>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <ButtonGroup>
              <Button
                style={ButtonStyle.TegusPrimary}
                action={nextAction}
                data-testid="next-button"
              >
                Next
              </Button>
              {!mfaEnforced && (
                <Button
                  type={ButtonType.Back}
                  style={ButtonStyle.TegusSecondary}
                  data-testid="back-button"
                />
              )}
            </ButtonGroup>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MfaInfo;
