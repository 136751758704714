import { faSignOut, faUserCog } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getUserFromJwt, getUserInitials, UserFromJwt } from "../../utils/auth";
import { redirect } from "../../utils/location";
import routes from "../../utils/routes";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";

const ICON_BUTTON_SIZE = 36;
const ICON_STYLE = { transform: "scale(1.25)", transformOrigin: "center" };
interface CompaniesNavMenuDeps {
  _redirect?: (url: string) => void;
  _history?: typeof useHistory;
  _getUserFromJwt?: typeof getUserFromJwt;
}

/**
 * Companies page nav menu component
 *
 * MUI version of the nav menu
 */
const CompaniesNavMenu: React.FC<CompaniesNavMenuDeps> = ({
  _redirect = redirect,
  _history = useHistory,
  _getUserFromJwt = getUserFromJwt,
}) => {
  const [anchorElement, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);
  const history = _history();

  const { user: currentUser } = _getUserFromJwt();

  // Handle menu clicked
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle menu closed
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tippy content={STRINGS.main.navMenu.userMenu}>
        <Avatar
          sx={{
            height: ICON_BUTTON_SIZE,
            width: ICON_BUTTON_SIZE,
            cursor: "pointer",
            fontSize: "1rem",
          }}
          onClick={handleClick}
          data-testid="nav-menu"
        >
          {getUserInitials(currentUser as UserFromJwt)}
        </Avatar>
      </Tippy>
      <Menu
        id="nav-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
      >
        {/* User settings */}
        <MenuItem
          onClick={() => {
            history.push(routes.settings);
          }}
        >
          <ListItemIcon sx={{ pl: "0.3rem" }}>
            <FontAwesomeIcon icon={faUserCog} style={ICON_STYLE} />
          </ListItemIcon>
          <ListItemText sx={{ pr: "0.25rem" }}>
            {STRINGS.main.navMenu.settings}
          </ListItemText>
        </MenuItem>

        {/* Logout */}
        <MenuItem
          onClick={() => {
            _redirect(routes.logout);
          }}
        >
          <ListItemIcon sx={{ pl: "0.3rem" }}>
            <FontAwesomeIcon icon={faSignOut} style={ICON_STYLE} />
          </ListItemIcon>
          <ListItemText sx={{ pr: "0.25rem" }}>
            {STRINGS.main.navMenu.logout}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CompaniesNavMenu;
