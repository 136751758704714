/**
 * End-user visible strings for all components on Companies page
 */
export const COMPANIES_STRINGS = {
  main: {
    companies: "Companies",
    loadingTable: "Loading companies table...",
    toasts: {
      refreshError: "Error refreshing companies data",
    },
    navMenu: {
      userMenu: "User menu",
      settings: "Settings",
      logout: "Log out",
    },
    viewButtons: {
      toggleFullscreen: "Toggle fullscreen mode",
      exitFullscreen: "Exit fullscreen mode",
      showFullscreen: "Fullscreen mode",
      toggleSidebar: "Toggle sidebar",
      hideSidebar: "Hide sidebar",
      showSidebar: "Show sidebar",
    },
  },
  table: {
    columnHeaders: {
      companyName: "Company name",
      ticker: "Ticker",
      region: "Region",
      lastUpdated: "Last updated",
      updateType: "Update type",
      period: "Period",
      revenue: "Revenue (FY0)",
      sector: "Sector",
      industry: "Industry",
      actions: "Actions",
    },
    columnDescriptions: {
      updateType: "The type of filing the model was most recently updated for",
      period: "The period the model was most recently updated for",
      revenue: "Revenue from the most recently reported fiscal year",
    },
    downloadMenu: {
      downloadMenu: "Download menu",
      copyDownloadLink: "Copy download link",
      downloadDifferentModelFormat: "Download a Different Model Format",
      downloadDifferentFormat: "Download a different format",
      downloadModel: "Download model",
      loading: "Loading...",
    },
    loading: {
      description: "Loading companies data...",
      error: "Unable to load companies data.",
    },
    rows: {
      noRowsHeading: "No results",
      noRowsBody:
        "We couldn't find any companies matching your filter settings",
      noRowData: "-",
    },
    rowActions: {
      addToWatchList: "Add to watchlist",
      watchListFailed: "Watchlist update failed",
      download: "Download",
      modelUpdateRequest: "Model Update Request",
      removeFromWatchList: "Remove from watchlist",
      requestUpdate: "Request an update",
    },
    sort: {
      ascending: "Sort ascending",
      descending: "Sort descending",
    },
    toolbar: {
      updated: "Last updated: ",
      updateNow: "Update now",
      displayedRows: "Companies displayed: ",
      totalRows: "of",
    },
  },
  sidebar: {
    section: {
      label: "{section} section",
      expand: "Expand {section}",
    },
    sections: {
      search: "Search",
      categories: "Categories",
    },
    quickFilter: {
      placeholder: "Search companies list...",
      label: "Companies list search",
      textboxLabel: "Enter search term",
      clear: "Clear filter",
    },
    category: {
      filterBy: "Filter by {category}",
      expand: "Expand {category}",
    },
    categoryList: {
      loading: "Loading category list...",
    },
  },
  presets: {
    delete: {
      description: "Are you sure you want to delete '{name}'?",
      confirm: "Yes, delete '{name}'",
      delete: "Delete",
      deleting: "Deleting preset...",
      cancel: "Cancel",
    },
    save: {
      cancel: "Cancel",
      save: "Save",
      saveNew: "Save as New Preset",
      saving: "Saving preset...",
    },
  },
};
