import Tippy from "@tippyjs/react";
import classNames from "classnames";
import React, { useMemo } from "react";
import "tippy.js/dist/tippy.css";
import { omitTestId } from "../utils/objects";
import Action, { ActionProps } from "./Action";
import "./FancyListItem.scss";

export const TEXT_SEPARATOR = (
  <span className="FancyListItem__text-separator">|</span>
);

export interface SecondaryAction extends ActionProps {
  icon: React.ReactElement;
  id: string;
  disabled?: boolean;
  tooltipText: string;
}

interface FancyListItemProps {
  // secondaryActions are elements that have actions tied to them, e.g. an icon that
  // triggers an event to happen when clicked. If secondaryActions are provided, they
  // will be displayed on the right side of the FancyListItem.
  icon: React.ReactElement;
  text: string;
  subtext?: React.ReactNode[];
  secondaryActions?: SecondaryAction[];
  highlighted?: boolean;
}

const FancyListItem = React.forwardRef<
  HTMLDivElement,
  FancyListItemProps & ActionProps
>(
  (
    {
      icon,
      text,
      subtext = [],
      secondaryActions = [],
      highlighted = false,
      ...actionProps
    },
    ref
  ) => {
    // Since `actionProps` can contain a "data-testid" property, but that
    // property is only intended to be applied higher up in the DOM, exclude it
    // here. Otherwise testing will return too many nodes for a given test ID
    const actionPropsFinal = omitTestId(actionProps);
    const fliClassNames = classNames("FancyListItem", {
      "FancyListItem--has-secondary-actions": secondaryActions.length > 0,
      "FancyListItem--no-subtext":
        subtext?.length === 0 || subtext.every((t) => !t),
      "FancyListItem--highlighted": highlighted,
    });
    const secondaryActionElems = useMemo(() => {
      return secondaryActions.map((secondaryAction) => {
        return getSecondaryActionElem(secondaryAction);
      });
    }, [secondaryActions]);
    const subtextElems = subtext?.map((line, index) => {
      return (
        <div key={index} className="FancyListItem__subtext">
          {line}
        </div>
      );
    });
    return (
      <div className={fliClassNames} ref={ref}>
        <Action className="FancyListItem__main-icon" {...actionPropsFinal}>
          {icon}
        </Action>
        <div className="FancyListItem__text-container">
          <Action
            className="FancyListItem__text-action-overlay"
            {...actionPropsFinal}
          ></Action>
          <div className="FancyListItem__text">{text}</div>
          {subtextElems}
        </div>
        {secondaryActionElems}
      </div>
    );
  }
);

export default FancyListItem;

const getSecondaryActionElem = ({
  icon,
  id,
  disabled,
  tooltipText,
  ...actionProps
}: SecondaryAction) => {
  const containerClassNames = classNames(
    "FancyListItem__secondary-action-container",
    {
      "FancyListItem__secondary-action-container--disabled": disabled,
    }
  );

  return (
    <Tippy content={tooltipText} key={`secondary-action-${id}`}>
      <div className={containerClassNames}>
        <Action
          className="FancyListItem__secondary-action"
          key={`secondary-action-${id}`}
          {...actionProps}
          data-testid="secondary-action"
        >
          {icon}
        </Action>
      </div>
    </Tippy>
  );
};
