import { faChevronRight, faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import routes from "../utils/routes";
import "./Breadcrumbs.scss";

export interface Page {
  isCurrent: boolean;
  nameOrIcon: string | JSX.Element;
  url?: string;
}

export const HOME_ICON = <FontAwesomeIcon icon={faHome} />;

export const HOME_PAGE: Page = {
  isCurrent: false,
  nameOrIcon: HOME_ICON,
  url: routes.home,
};

export const SETTINGS_PAGE: Page = {
  isCurrent: false,
  nameOrIcon: "Settings",
  url: routes.settings,
};

export const LOADING_PAGE: Page = {
  isCurrent: true,
  nameOrIcon: "Loading…",
};

interface BreadcrumbsProps {
  pages: Page[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ pages }) => {
  const pageElem = pages.map((page, index) => {
    const pageElemClassNames = classNames("Breadcrumbs__item", {
      "Breadcrumbs__item--active": page.isCurrent,
    });
    const key = `${page.isCurrent}#${page.url}`;
    const displayType = typeof page.nameOrIcon === "string" ? "text" : "icon";
    const itemClassName = `Breadcrumbs__item-${displayType}`;
    return (
      <span className={pageElemClassNames} key={key}>
        {page.url ? (
          <Link className={itemClassName} to={page.url}>
            {page.nameOrIcon}
          </Link>
        ) : (
          <span className={itemClassName}>{page.nameOrIcon}</span>
        )}
        {index < pages.length - 1 && (
          <FontAwesomeIcon
            className="Breadcrumbs__chevron"
            icon={faChevronRight}
          />
        )}
      </span>
    );
  });

  return (
    <div className="Breadcrumbs">
      <div className="Breadcrumbs__container">
        <div className="Breadcrumbs__sub-container">{pageElem}</div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
