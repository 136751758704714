import {
  faAngleDown,
  faAngleRight,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";
import "./CompaniesSidebarSection.scss";

const DEFAULT_EXPANDABLE = true;
const DEFAULT_EXPANDED = false;

interface CompaniesSidebarSectionProps {
  title: string;
  icon?: IconDefinition;
  expandable?: boolean;
  defaultExpanded?: boolean;
  children?: ReactNode;
}

/**
 * A section within the companies sidebar
 *
 * @param title Section title
 * @param icon Section icon
 * @param expandable Whether section is expandable
 * @param defaultExpanded Whether section is expanded by default
 * @param loading Whether section content is still loading
 */
const CompaniesSidebarSection: React.FC<CompaniesSidebarSectionProps> = ({
  title,
  icon,
  expandable = DEFAULT_EXPANDABLE,
  defaultExpanded = DEFAULT_EXPANDED,
  children,
}) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  if (expandable) {
    return (
      <>
        <List
          dense={true}
          disablePadding
          aria-label={STRINGS.sidebar.section.label.replace("{section}", title)}
        >
          <ListItem disablePadding aria-label={title}>
            <ListItemButton
              onClick={() => setExpanded(!expanded)}
              aria-label={STRINGS.sidebar.section.expand.replace(
                "{section}",
                title
              )}
              aria-pressed={expanded}
            >
              {icon && (
                <ListItemIcon sx={{ pl: "0.5rem", minWidth: "2.75rem" }}>
                  <FontAwesomeIcon
                    icon={icon}
                    style={{
                      transform: "scale(1.125)",
                      transformOrigin: "center",
                    }}
                    data-testid="icon"
                  />
                </ListItemIcon>
              )}
              <ListItemText
                disableTypography
                sx={{
                  fontSize: "1.06rem",
                  fontWeight: 500,
                  py: "0.25rem",
                  color: "#333",
                }}
              >
                {title}
              </ListItemText>

              <div className="CompaniesSidebarSection__expand-icon">
                <FontAwesomeIcon icon={expanded ? faAngleDown : faAngleRight} />
              </div>
            </ListItemButton>
          </ListItem>
        </List>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </>
    );
  }

  return (
    <>
      <div
        className="CompaniesSidebarSection"
        role="tree"
        aria-labelledby={`${title}-title`}
      >
        {icon && (
          <div className="CompaniesSidebarSection__icon">
            <FontAwesomeIcon
              icon={icon}
              style={{ transform: "scale(1.125)", transformOrigin: "center" }}
              data-testid="icon"
            />
          </div>
        )}
        <div className="CompaniesSidebarSection__title" id={`${title}-title`}>
          {title}
        </div>
      </div>
      {children}
    </>
  );
};

export default CompaniesSidebarSection;
