import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as ContactUs } from "../assets/icons/contact.svg";
import Dropdown, { DropdownPositions } from "../common/Dropdown";
import { getUserFromJwt, getUserInitials, UserFromJwt } from "../utils/auth";
import { redirect } from "../utils/location";
import routes from "../utils/routes";
import "./NavMenu.scss";
import { FA_ICON_STYLE } from "./TopNav";

const DROPDOWN_SPACING = "1rem";
const CHEVRON_SIZE = "1rem";
const HELP_CENTER = "Help Center";
const CONTACT_US = "Contact Us";

interface NavMenuProps {
  isUserSetup?: boolean;
}

interface NavMenuDeps {
  _redirect?: (url: string) => void;
  _history?: typeof useHistory;
  _getUserFromJwt?: typeof getUserFromJwt;
}

const NavMenu: React.FC<NavMenuProps & NavMenuDeps> = ({
  isUserSetup = false,
  _redirect = redirect,
  _history = useHistory,
  _getUserFromJwt = getUserFromJwt,
}) => {
  const [userMenuActive, setUserMenuActive] = useState(false);
  const { user: currentUser } = _getUserFromJwt();
  const history = _history();

  const userMenuChoices = () => {
    const choices = [
      {
        id: "2",
        display: "Log Out",
        action: () => _redirect(routes.logout),
      },
    ];
    if (!isUserSetup) {
      choices.splice(0, 0, {
        id: "1",
        display: "Settings",
        action: () => history.push(routes.settings),
      });
    }
    return choices;
  };

  return (
    <div className="NavMenu">
      <Tippy content={HELP_CENTER}>
        <a
          className="NavMenu__helpIcon"
          href="/authenticate-zendesk"
          target="_blank"
          data-testid="help-center"
        >
          <FontAwesomeIcon icon={faQuestionCircle} style={FA_ICON_STYLE} />
        </a>
      </Tippy>
      <Tippy content={CONTACT_US}>
        {isUserSetup ? (
          <a className="NavMenu__helpIcon" href="mailto:support@canalyst.com">
            <ContactUs />
          </a>
        ) : (
          <Link
            className="NavMenu__helpIcon"
            to={routes.support}
            data-testid="contact-us"
          >
            <ContactUs />
          </Link>
        )}
      </Tippy>
      <Dropdown
        className="NavMenu__dropdown"
        choices={userMenuChoices()}
        dropdownActive={userMenuActive}
        setDropdownActive={setUserMenuActive}
        setChoice={(choice) => choice.action()}
        dropdownSpacing={DROPDOWN_SPACING}
        dropdownPosition={DropdownPositions.LEFT}
        dataTestId="nav-menu"
      >
        <div className="NavMenu__dropdownIcon">
          <span className="NavMenu__initials">
            {getUserInitials(currentUser as UserFromJwt)}
          </span>
        </div>
        <span className="NavMenu__name">{`${currentUser?.first_name} ${currentUser?.last_name}`}</span>
        <FontAwesomeIcon icon={faChevronDown} style={{ width: CHEVRON_SIZE }} />
      </Dropdown>
    </div>
  );
};

export default NavMenu;
