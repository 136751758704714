import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Input, InputAdornment } from "@mui/material";
import Tippy from "@tippyjs/react";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useQueryDelayed } from "../../search_box/SearchDropdown";
import { COLOUR_TEAL } from "../../utils/theme";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";
import "./CompaniesQuickFilter.scss";

interface CompaniesQuickFilterProps {
  filterTerm: string;
  updateQuickFilter: (searchTerm: string) => void;
}

/**
 * Companies page quick filter component
 *
 * @param filterTerm The default filter term to use
 * @param updateQuickFilter Method to call to set new search term
 */
const CompaniesQuickFilter: React.FC<CompaniesQuickFilterProps> = ({
  filterTerm,
  updateQuickFilter,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>(filterTerm);
  const [query] = useQueryDelayed({ query: searchTerm.trim() });

  const clearDisabled = searchTerm === "";
  const prevQuery = useRef(query);

  // Update quick search term after input debounce
  useEffect(() => {
    if (query !== prevQuery.current) {
      updateQuickFilter(query);
      prevQuery.current = query;
    }
  }, [query, updateQuickFilter]);

  // Handle quick search input
  const handleQuickFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  // Handle clear quick search
  const handleClearFilter = useCallback(() => {
    setSearchTerm("");
  }, []);

  return (
    <div
      className="CompaniesQuickFilter"
      role="search"
      aria-label={STRINGS.sidebar.quickFilter.label}
    >
      <Input
        id="companies-quick-filter"
        fullWidth
        placeholder={STRINGS.sidebar.quickFilter.placeholder}
        value={searchTerm}
        onChange={handleQuickFilter}
        inputProps={{
          "aria-label": STRINGS.sidebar.quickFilter.textboxLabel,
        }}
        endAdornment={
          <InputAdornment position="end">
            <Tippy content={STRINGS.sidebar.quickFilter.clear}>
              <IconButton
                aria-label={STRINGS.sidebar.quickFilter.clear}
                edge="end"
                sx={{ height: 32, width: 32, mr: "-0.5rem" }}
                onClick={handleClearFilter}
                disabled={clearDisabled}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: clearDisabled ? "#999" : COLOUR_TEAL,
                    transform: "scale(0.75)",
                  }}
                />
              </IconButton>
            </Tippy>
          </InputAdornment>
        }
      />
    </div>
  );
};

export default CompaniesQuickFilter;
