import { ApolloError } from "@apollo/client";
import React, { Context, useContext, useRef, useState } from "react";
import Button, { ButtonStyle, ButtonType } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../../common/Spinner";
import Card from "../../layout/Card";
import ModalContext from "../../modal/ModalContext";
import routes from "../../utils/routes";
import settings from "../../utils/settings";
import { UserForDl } from "../../utils/variants";
import { buildFolderItemComponent } from "../common/utils/components";
import BrowseAutoScroll from "./BrowseAutoScroll";
import "./BrowseDisplay.scss";
import AddFolderModal from "./modals/AddFolderModal";
import AddResourceModal from "./modals/AddResourceModal";
import { BrowseFolderByPath } from "./utils/graphql";

interface BrowseDisplayProps {
  loading: boolean;
  error?: ApolloError;
  folder?: BrowseFolderByPath;
  folderPath: string;
  user?: UserForDl;
  itemOrderingWidget: React.ReactElement;
  itemToHighlightFiid?: string;
  emptyCopyOverride?: string;
  titleOverride?: string;
  description?: string;
}

interface BrowseDisplayDeps {
  useContext_?: <T>(context: Context<T>) => T;
}

const BrowseDisplay: React.FC<BrowseDisplayProps & BrowseDisplayDeps> = ({
  loading,
  error,
  folder,
  folderPath,
  user,
  itemOrderingWidget,
  itemToHighlightFiid,
  emptyCopyOverride,
  titleOverride,
  description,
  useContext_ = useContext,
}) => {
  const itemToHighlightRef = useRef<HTMLDivElement>(null);
  const itemAtBottomRef = useRef<HTMLDivElement>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const { setModal } = useContext_(ModalContext);

  const setAddItemModal = () => {
    setModal({
      title: "Add resource",
      content: (
        <AddResourceModal
          folder={folder as BrowseFolderByPath}
          setIsUpdating={(val) => {
            setIsUpdating(val);
          }}
        />
      ),
      noScroll: true,
    });
  };

  const setAddFolderModal = () => {
    setModal({
      title: "Create folder",
      content: (
        <AddFolderModal
          folder={folder as any}
          setIsUpdating={(val) => {
            setIsUpdating(val);
          }}
        />
      ),
      noScroll: true,
    });
  };

  if (loading && !folder?.items.edges.length) {
    return (
      <div className="BrowseDisplay BrowseDisplay--loading">
        <Spinner colour={ColourType.SECONDARY} size="3rem" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="BrowseDisplay">
        <Card title="Uh oh." data-testid="error-500" tegus>
          <p>
            Something went wrong when retrieving this folder. Please try again
            later or <a href={routes.support}>contact support</a> for
            assistance.
          </p>
          <ButtonGroup>
            <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
            <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
              Contact Us
            </Button>
          </ButtonGroup>
        </Card>
      </div>
    );
  }
  if (!folder || !user) {
    return (
      <div className="BrowseDisplay">
        <Card title="Folder not found." data-testid="error-404" tegus>
          <p>
            The requested folder{" "}
            <span className="font-italic">{folderPath}</span> was not found on
            the server.
          </p>
          <ButtonGroup>
            <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
            <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
              Contact Us
            </Button>
          </ButtonGroup>
        </Card>
      </div>
    );
  }

  const canUploadResourceFiles =
    folder.ancestorFolders.length > 0 &&
    user?.staff &&
    user?.djangoGlobalPerms.includes("files.add_resourcefile") &&
    folder.folderTags.includes("can_contain_resource_files") &&
    folder.mePermissions.includes("change_folder");

  const canCreateFolders =
    user?.staff &&
    user?.djangoGlobalPerms.includes("files.add_folder") &&
    folder.path !== settings.watchListFolderPath &&
    folder.path !== settings.screensFolderPath;

  const showStaffButtons = canUploadResourceFiles || canCreateFolders;

  const itemElements = folder.items.edges.map((edge, index) => {
    const highlighted = edge.cursor === itemToHighlightFiid;
    return buildFolderItemComponent(edge.node, user, {
      highlighted,
      ref: highlighted
        ? itemToHighlightRef
        : index + 1 === folder.items.edges.length
        ? itemAtBottomRef
        : undefined,
    });
  });

  if (itemElements.length === 0) {
    const parentFolder =
      folder.ancestorFolders.length > 0 ? folder.ancestorFolders[0] : null;
    const testId = parentFolder
      ? "message-empty-with-parent"
      : "message-empty-without-parent";

    return (
      <div className="BrowseDisplay">
        <Card
          title={emptyCopyOverride || "This folder is empty."}
          data-testid={testId}
          tegus
        >
          <ButtonGroup>
            <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
            <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
              Contact Us
            </Button>
            {canUploadResourceFiles && (
              <Button
                action={setAddItemModal}
                style={ButtonStyle.TegusSecondary}
              >
                Add resource
              </Button>
            )}
            {canCreateFolders && (
              <Button
                action={setAddFolderModal}
                style={ButtonStyle.TegusSecondary}
              >
                Create new folder
              </Button>
            )}
          </ButtonGroup>
        </Card>
      </div>
    );
  }

  const loadingMore = folder.items.pageInfo.hasNextPage;
  return (
    <div className="BrowseDisplay">
      <Card
        tegus
        title={titleOverride || folder.name}
        titleContent={
          folder.placeholderCount || folder.resourceCount
            ? itemOrderingWidget
            : null
        }
      >
        {description && <p data-testid="description">{description}</p>}
        <div className="row">
          <div className="col-12">
            {itemElements}
            {loadingMore && (
              <Spinner colour={ColourType.SECONDARY} size="3rem" />
            )}
          </div>
        </div>
        <BrowseAutoScroll
          enabled={!!itemToHighlightFiid}
          itemToHighlightRef={itemToHighlightRef}
          itemAtBottomRef={itemAtBottomRef}
          edges={folder.items.edges}
          itemToHighlightFiid={itemToHighlightFiid}
        />
        {showStaffButtons && (
          <div className="BrowseDisplay__staff-buttons">
            <ButtonGroup>
              {canUploadResourceFiles && (
                <Button
                  action={setAddItemModal}
                  style={ButtonStyle.TegusSecondary}
                >
                  Add resource
                </Button>
              )}
              {canCreateFolders && (
                <Button
                  action={setAddFolderModal}
                  style={ButtonStyle.TegusSecondary}
                >
                  Create new folder
                </Button>
              )}
            </ButtonGroup>
          </div>
        )}
      </Card>
      {isUpdating && (
        <div className="BrowseDisplay__overlay">
          <Spinner colour={ColourType.SECONDARY} size="3rem" />
        </div>
      )}
    </div>
  );
};
export default BrowseDisplay;
