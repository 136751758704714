import {
  faCheckCircle,
  faInfoCircle,
  faTimesCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import settings, { PasswordRequirement } from "../../utils/settings";
import "./RequirementsCopy.scss";
import { UpdatePasswordValidationErrorProps } from "./validation";

interface RequirementsCopyDeps {
  passwordRequirements?: PasswordRequirement[];
  dirty: boolean;
  errors: UpdatePasswordValidationErrorProps;
}

/**
 * Shows visual feedback for each newPassword requirement
 *
 * When the user enters their old password the validate method
 * is immediately called in UpdatePasswordForm which will
 * populate the requirements 'errors' for this component.
 *
 * The 'dirty' prop is used to ensure visual feedback will only
 * update when the user types in the newPassword field.
 */
const RequirementsCopy: React.FC<RequirementsCopyDeps> = ({
  passwordRequirements = settings.passwordRequirements,
  dirty,
  errors,
}) => {
  const isError = (description: string) => {
    return (
      dirty && errors.requirements && errors.requirements.includes(description)
    );
  };
  return (
    <ul className="RequirementsCopy">
      {passwordRequirements.map((r, i) => (
        <li key={i}>
          {isError(r.description) ? (
            <FontAwesomeIcon
              className="RequirementsCopy__error"
              icon={faTimesCircle}
            />
          ) : dirty ? (
            <FontAwesomeIcon
              className="RequirementsCopy__valid"
              icon={faCheckCircle}
            />
          ) : (
            <FontAwesomeIcon icon={faInfoCircle} />
          )}
          {r.description}
        </li>
      ))}
    </ul>
  );
};

export default RequirementsCopy;
