import React, { RefObject, useCallback } from "react";
import { useEvent } from "../utils/events";

interface SearchBoxShortcutsProps {
  inputRef: RefObject<HTMLInputElement>;
}

/**
 * Manages keyboard shortcuts for the search box. Does not render anything
 *
 * @param props - Component props
 * @param props.inputRef - A reference to the search <input />
 */
const SearchBoxShortcuts: React.FC<SearchBoxShortcutsProps> = ({
  inputRef,
}) => {
  // Focus on the search input when "/" is pressed
  const handleKeyUpFocus = useCallback(
    (ev: KeyboardEvent) => {
      if (!isKeyMatch(ev, "/")) return;
      if (someInputFocused(ev)) return;

      ev.preventDefault();
      inputRef.current?.focus();
    },
    [inputRef]
  );

  // Blur (un-focus) the search input when "ESC" is pressed
  const handleKeyUpBlur = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key !== "Escape") return;
      if (ev.target !== inputRef.current) return;

      ev.preventDefault();
      inputRef.current?.blur();
    },
    [inputRef]
  );

  // Combining the keyup event listeners makes testing easier
  useEvent(
    window,
    "keyup",
    useCallback(
      (ev) => {
        handleKeyUpFocus(ev);
        handleKeyUpBlur(ev);
      },
      [handleKeyUpFocus, handleKeyUpBlur]
    )
  );

  // Prevent FireFox from bringing up "Quick find" when "/" is pressed
  useEvent(
    window,
    "keydown",
    useCallback((ev) => {
      if (!isKeyMatch(ev, "/")) return;
      if (someInputFocused(ev)) return;
      ev.preventDefault();
    }, [])
  );

  return null;
};

/**
 * Returns whether or not a key (without modifiers) is being pressed
 *
 * @param ev - They current keyboard event
 * @param key - The key to check for a match
 */
const isKeyMatch = (ev: KeyboardEvent, key: string) => {
  return ev.key === key && !ev.altKey && !ev.ctrlKey && !ev.metaKey;
};

/**
 * Returns whether or not an input (or similar) is currently focused
 *
 * @param ev - They current keyboard event
 */
const someInputFocused = (ev: KeyboardEvent) => {
  return (
    ev.target instanceof Element &&
    /^(?:input|textarea|select)$/i.test(ev.target.tagName)
  );
};

export default SearchBoxShortcuts;
