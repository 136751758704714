import { ApolloError } from "@apollo/client";
import { Form, FormikProps } from "formik";
import { useContext } from "react";
import Button, {
  ButtonState,
  ButtonStyle,
  ButtonType,
} from "../common/buttons/Button";
import ButtonGroup from "../common/buttons/ButtonGroup";
import FormError from "../forms/FormError";
import TextField from "../forms/TextField";
import { CreateShortUrlMutationVariables } from "../generated/graphql";
import ModalContext from "../modal/ModalContext";

interface CreateShortUrlFormProps
  extends FormikProps<CreateShortUrlMutationVariables> {
  formRef: React.RefObject<HTMLFormElement>;
  mutationError?: ApolloError;
}

const CreateShortUrlForm: React.FC<CreateShortUrlFormProps> = ({
  formRef,
  mutationError,
  ...formikProps
}) => {
  const { clearModal } = useContext(ModalContext);
  return (
    <Form onSubmit={formikProps.handleSubmit} ref={formRef}>
      <TextField
        value={formikProps.values.longUrl}
        name="longUrl"
        label="URL to Shorten"
        type="url"
        onChange={formikProps.handleChange}
        onBlur={formikProps.handleBlur}
        disabled={formikProps.isSubmitting}
        required
      />
      {mutationError && <FormError errorText={mutationError.message} />}
      <ButtonGroup>
        <Button
          state={formikProps.isSubmitting ? ButtonState.Disabled : undefined}
          style={ButtonStyle.TegusPrimary}
          type={ButtonType.Submit}
        >
          Shorten URL
        </Button>
        <Button style={ButtonStyle.TegusSecondary} action={clearModal}>
          Done
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default CreateShortUrlForm;
