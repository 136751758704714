import { Form, Formik } from "formik";
import React from "react";
import Button, { ButtonStyle, ButtonType } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import FormError from "../../forms/FormError";
import { EmailSettingsUpdateEmailSettingsMutationOptions } from "../../generated/graphql";
import Card from "../../layout/Card";
import routes from "../../utils/routes";
import EmailRecentDownloadUpdate from "./EmailRecentDownloadUpdate";
import { EmailSettingsType } from "./EmailSettings";
import IpoSubscriptions from "./IpoSubscriptions";

interface EmailSettingsFormProps {
  initialValues: EmailSettingsType;
  updateEmailSettings: (
    options: EmailSettingsUpdateEmailSettingsMutationOptions
  ) => void;
  mutationError?: string;
  hideBackButton?: boolean;
}

const EmailSettingsForm: React.FC<EmailSettingsFormProps> = ({
  initialValues,
  updateEmailSettings,
  mutationError,
  hideBackButton,
}) => {
  return (
    <div className="EmailSettingsForm">
      <Card title="Email Settings" tegus>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            updateEmailSettings({
              variables: {
                ipoSubscriptions: {
                  allFolders: values.ipoSubscriptions.allFolders,
                  folders: values.ipoSubscriptions.folders.map((value) => {
                    const { __typename, ...data } = value;
                    return data;
                  }),
                },
                emailRecentDownloadUpdate: values.emailRecentDownloadUpdate,
              },
            });
          }}
        >
          {(formikProps) => {
            return (
              <Form>
                <EmailRecentDownloadUpdate
                  value={formikProps.values.emailRecentDownloadUpdate}
                />
                {formikProps.values.ipoSubscriptions.folders.length > 0 && (
                  <IpoSubscriptions
                    values={formikProps.values.ipoSubscriptions}
                  />
                )}
                {mutationError && <FormError errorText={mutationError} />}
                <ButtonGroup>
                  <Button
                    style={ButtonStyle.TegusPrimary}
                    type={ButtonType.Submit}
                  >
                    Save
                  </Button>
                  {!hideBackButton && (
                    <Button
                      linkTo={routes.settings}
                      style={ButtonStyle.TegusSecondary}
                    >
                      Back
                    </Button>
                  )}
                </ButtonGroup>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </div>
  );
};

export default EmailSettingsForm;
