/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { faUserFriends } from "@fortawesome/pro-regular-svg-icons";
import { Form, Formik, FormikErrors } from "formik";
import React, { Context, useContext, useEffect, useState } from "react";
import { HOME_PAGE, Page } from "../common/Breadcrumbs";
import Button, {
  ButtonState,
  ButtonStyle,
  ButtonType,
} from "../common/buttons/Button";
import ButtonGroup from "../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../common/Spinner";
import { HTTP_STATUS } from "../constants/http";
import FileUpload, { FileData } from "../forms/FileUpload";
import MultiSelectList, {
  MultiSelectListCustomText,
  MultiSelectListItem,
  TEXT_ADD,
  TEXT_REMOVE,
} from "../forms/MultiSelectList/MultiSelectList";
import { useBulkUserCreateGroupsQuery } from "../generated/graphql";
import Card, { MaxWidthType } from "../layout/Card";
import ModalContext from "../modal/ModalContext";
import ToastContext from "../toast/ToastContext";
import { ToastStyle } from "../toast/ToastDisplay";
import { getCookie } from "../utils/cookies";
import { convertFileData } from "../utils/readFile";
import routes from "../utils/routes";
import "./BulkUserCreate.scss";
import CreatedUsers, { CreatedBulkUser } from "./CreatedUsers";
import UserCreationErrors, {
  UserCreateValidationErrorRow,
} from "./UserCreationErrors";

// Constants
const FILENAME_WIDTH = "auto";
const TEMPLATE_LINK_URL =
  "https://docs.google.com/spreadsheets/d/1A6EpRsJYJII1qCHT_qt1WjufZFmHsCVf_mhv2IxCTG0/edit?usp=sharing";
export const FILE_PASSING_ERROR =
  "File parsing failure. Please see errors for details";

// Toasts
const MSG_SUCCESS = "Bulk user creation successful.";
const MSG_VALIDATION_ERR = "Bulk user creation validation error.";
const MSG_NO_PERM = "You do not have permission to do that.";
const MSG_ERR = "Uh oh. There was an issue creating users.";

// End-user visible strings
const CARD_TITLE = "Bulk User Creation";
const SUBMIT_BTN_TEXT = "Create Bulk Users";
const TEXT_SEARCH = "Search for groups...";
const TEXT_NO_ITEMS_SELECTED =
  "No groups selected. Search for a group to select using the search bar above.";
const TEXT_NO_SEARCH_RESULTS = "No groups found matching search criteria.";
const TEMPLATE_LINK_INTRO_COPY = "Bulk User Create CSV ";
const TEMPLATE_LINKED_WORD = "template";
const FORM_ERR_WRONG_FILE_TYPE = "Uploaded files should be CSVs.";
const DESCRIPTION =
  "Upload a CSV of users to be created in bulk using the template provided below.";

const MULTI_SELECT_LIST_TEXT: MultiSelectListCustomText = {
  search: TEXT_SEARCH,
  noSearchResults: TEXT_NO_SEARCH_RESULTS,
  noItemsSelected: TEXT_NO_ITEMS_SELECTED,
  addItem: TEXT_ADD,
  removeItem: TEXT_REMOVE,
};

export interface FileUploadData {
  name?: string;
  file: FileData;
}

export interface BulkUserUploadState {
  file?: FileData;
  groups: MultiSelectListItem[];
}
export interface BulkUserErrorResponse {
  error?: string;
  errors: UserCreateValidationErrorRow[];
}

export interface CreatedUsersResponse {
  createdUsers: CreatedBulkUser[];
}

interface BulkUserCreateProps {
  setBreadcrumbs: (value: Page[]) => void;
}

interface BulkUserCreateDeps {
  useToastContext?: <T>(context: Context<T>) => T;
  useModalContext?: <T>(context: Context<T>) => T;
  useBulkUserCreateGroupsQuery_?: typeof useBulkUserCreateGroupsQuery;
  uploadCsv_?: typeof uploadCsv;
}

export const BULK_USER_CREATE_PAGE = [
  HOME_PAGE,
  { nameOrIcon: "Bulk User Creation", isCurrent: true },
];

/**
 * Bulk user create page.
 *
 * Page from which staff users with the correct permissions can create multiple
 * users at once by uploading a CSV with the users' details.
 *
 * @param props.submitting - State on whether the form is currently submitting
 */
const BulkUserCreate: React.FC<BulkUserCreateProps & BulkUserCreateDeps> = ({
  setBreadcrumbs,
  useToastContext = useContext,
  useModalContext = useContext,
  useBulkUserCreateGroupsQuery_ = useBulkUserCreateGroupsQuery,
  uploadCsv_ = uploadCsv,
}) => {
  const [submitting, setSubmitting] = useState(false);
  // Simply used to reset the form on upload
  const [formKey, setFormKey] = useState<number>(1);
  const [fileSelected, setFileSelected] = useState(false);

  const { setToast } = useToastContext(ToastContext);
  const { setModal } = useModalContext(ModalContext);

  useEffect(() => {
    setBreadcrumbs(BULK_USER_CREATE_PAGE);
  }, [setBreadcrumbs]);

  const onFileSelected = () => {
    setFileSelected(true);
  };

  const clearFile = () => {
    setFileSelected(false);
  };

  const clearForm = () => {
    setFormKey(formKey + 1);
    setFileSelected(false);
  };

  const getButtonState = () => {
    if (submitting) return ButtonState.Disabled;
    return fileSelected ? undefined : ButtonState.Disabled;
  };

  const _handleResponse = async (response: Response) => {
    if (response.status === HTTP_STATUS.CREATED) {
      setToast({
        style: ToastStyle.Info,
        text: MSG_SUCCESS,
      });
      showCreatedUsers(await response.json());
    } else if (response.status === HTTP_STATUS.BAD_REQUEST) {
      const errResponse = await response.json();
      if (errResponse.error === FILE_PASSING_ERROR) {
        setToast({
          style: ToastStyle.Error,
          text: MSG_VALIDATION_ERR,
        });
        showErrors(errResponse);
      } else {
        setToast({
          style: ToastStyle.Error,
          text: errResponse.error,
        });
      }
    } else if (response.status === HTTP_STATUS.FORBIDDEN) {
      setToast({
        style: ToastStyle.Error,
        text: MSG_NO_PERM,
      });
    } else {
      setToast({
        style: ToastStyle.Error,
        text: MSG_ERR,
      });
    }
  };

  const showErrors = (response: BulkUserErrorResponse) => {
    setModal({
      title: "Bulk User Creation Errors",
      content: <UserCreationErrors errors={response.errors} />,
      noScroll: false,
      maxWidth: MaxWidthType.wide,
    });
  };

  const showCreatedUsers = (response: CreatedUsersResponse) => {
    setModal({
      title: "Created Users",
      content: <CreatedUsers createdUsers={response.createdUsers} />,
      noScroll: false,
      maxWidth: MaxWidthType.wide,
    });
  };

  const _upload = async (file: FileUploadData, groups: string[]) => {
    setSubmitting(true);
    await uploadCsv_(file, groups)
      .then(_handleResponse)
      .catch(() => {
        setToast({
          style: ToastStyle.Error,
          text: MSG_ERR,
        });
      })
      .finally(() => {
        setSubmitting(false);
        clearForm();
      });
  };
  const style = { color: "red" };

  return (
    <div className="BulkUserCreate">
      <Card title={CARD_TITLE}>
        <div style={style}>
          Bulk User Creation is temporarily disabled. Please use the individual
          User Form. We are working to bring this functionality back ASAP
        </div>
        <div className="BulkUserCreate__description" role="note">
          {DESCRIPTION}
        </div>
        <div className="BulkUserCreate__template-link" role="note">
          {TEMPLATE_LINK_INTRO_COPY}
          <a href={TEMPLATE_LINK_URL} target="_blank" rel="noreferrer">
            {TEMPLATE_LINKED_WORD}
          </a>
          .
        </div>
        <Formik
          key={formKey}
          initialValues={{ groups: [] }}
          onSubmit={(values: BulkUserUploadState, actions) =>
            onSubmit(values, actions.setErrors, _upload)
          }
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} role="form">
              <div className="BulkUserCreate__form">
                <div className="BulkUserCreate__form-fields">
                  <FileUpload
                    name="file"
                    filenameWidth={FILENAME_WIDTH}
                    onFileSelected={onFileSelected}
                    onFileCleared={clearFile}
                    hasClearButton={fileSelected}
                  />
                  <MultiSelectList
                    name="groups"
                    queryHook={useBulkUserCreateGroupsQuery_}
                    title="Groups"
                    customText={MULTI_SELECT_LIST_TEXT}
                    rowIcon={faUserFriends}
                  />
                </div>
                <div className="BulkUserCreate__form-errors-wrapper">
                  {props.errors.file && (
                    <div className="BulkUserCreate__form-errors" role="alert">
                      {props.errors.file}
                    </div>
                  )}
                </div>
                <div className="BulkUserCreate__form-footer">
                  <ButtonGroup>
                    <Button
                      type={ButtonType.Submit}
                      style={ButtonStyle.TegusPrimary}
                      // state={getButtonState()}
                      state={ButtonState.Disabled}
                    >
                      {SUBMIT_BTN_TEXT}
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {submitting && (
          <div className="BulkUserCreate__overlay">
            <Spinner
              colour={ColourType.SECONDARY}
              size="3rem"
              data-testid="loading"
            />
          </div>
        )}
      </Card>
    </div>
  );
};

/**
 * Upload CSV to bulk user creation endpoint.
 *
 * @param file - The CSV file containing users to be created
 * @param group_ids - The IDs of groups the user should be added to
 */
export const uploadCsv = async (file: FileUploadData, group_ids: string[]) => {
  const requestData = new FormData();
  requestData.append("csrfmiddlewaretoken", getCookie("csrftoken"));
  requestData.append("name", file.name || "");
  requestData.append(
    "file",
    convertFileData(file.file.data, file.file.name, file.file.mimetype)
  );

  for (const group_id of group_ids) {
    requestData.append("groups", group_id);
  }

  const response = await fetch(routes.bulkUserCreateEndpoint, {
    method: "POST",
    body: requestData,
  });

  return response;
};

export default BulkUserCreate;

/**
 * Actions to take when bulk user create form is submitted
 *
 * @param values - The form values
 * @param setErrors - A callback for error setting
 * @param uploadCallback - A callback for uploading the file
 */
export const onSubmit = (
  values: BulkUserUploadState,
  setErrors: (errors: FormikErrors<BulkUserUploadState>) => void,
  uploadCallback: (file: FileUploadData, groups: string[]) => void
) => {
  if (values.file) {
    if (values.file.mimetype !== "text/csv") {
      setErrors({ file: FORM_ERR_WRONG_FILE_TYPE });
    } else {
      uploadCallback(
        {
          name: values.file.name,
          file: values.file,
        },
        values.groups.map((group) => group.value)
      );
    }
  }
};
