import { Formik } from "formik";
import React, { Context, useContext as useContextDefault } from "react";
import {
  ChoicesToVariantCombination,
  CustomVariantChoices,
  ORIGINAL_VARIANT_COMBO,
  variantCombinationToChoices,
} from "../../common/variants";
import {
  Resource,
  ResourceVariant,
  User,
  useVariantPreferenceMeQuery as useVariantPreferenceMeQueryDefault,
  useVariantPreferenceUpdateUserVariantPreferenceMutation as useVariantPreferenceUpdateUserVariantPreferenceMutationDefault,
  VariantPreference,
} from "../../generated/graphql";
import ModalContext, { ModalContextValue } from "../../modal/ModalContext";
import { useDownload as useDownloadDefault } from "../../utils/location";
import DownloadCustomVariantForm, {
  CustomVariantFormValuesType,
} from "./DownloadCustomVariantForm";
import { getVariantByDimensions } from "./utils/variants";

type UserVariantPreference = Pick<
  VariantPreference,
  "periodOrder" | "driversWorksheets" | "theme" | "__typename"
>;

type VariantForDcv = Pick<
  ResourceVariant,
  "id" | "periodOrder" | "driversWorksheets" | "theme" | "downloadUrl"
>;

export type UserForDcv = Pick<
  User,
  "id" | "variantPreference" | "djangoGlobalPerms"
>;

export type ResourceForDcv = Pick<Resource, "id" | "name"> & {
  variants: VariantForDcv[];
};

interface DownloadCustomVariantProps {
  resource: ResourceForDcv;
  user: UserForDcv;
}

interface DownloadCustomVariantDeps {
  useDownload?: () => (url: string) => void;
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
  useVariantPreferenceQuery?: typeof useVariantPreferenceMeQueryDefault;
  useVariantPreferenceMutation?: typeof useVariantPreferenceUpdateUserVariantPreferenceMutationDefault;
}

const DownloadCustomVariant: React.FC<
  DownloadCustomVariantProps & DownloadCustomVariantDeps
> = ({
  resource,
  user,
  useDownload = useDownloadDefault,
  useModalContext = useContextDefault,
  useVariantPreferenceMutation = useVariantPreferenceUpdateUserVariantPreferenceMutationDefault,
}) => {
  const download = useDownload();
  const { clearModal } = useModalContext(ModalContext);

  const [updatePreferenceMutation] = useVariantPreferenceMutation();

  return (
    <div className="DownloadCustomVariant">
      <Formik<CustomVariantFormValuesType>
        initialValues={{
          ...getInitialVariantDimensionValues(resource, user.variantPreference),
          changeDefault: false,
        }}
        validateOnMount={true}
        onSubmit={(values, actions) => {
          const dimensionValues = ChoicesToVariantCombination(values);
          if (values.changeDefault) {
            updatePreferenceMutation({
              variables: dimensionValues,
            });
          }
          const resourceVariant = getVariantByDimensions(
            resource.variants,
            dimensionValues
          );
          if (resourceVariant) {
            clearModal();
            download(resourceVariant.downloadUrl);
          } else {
            actions.setErrors({
              formError: `A variant of this type doesn't exist for this ${resource.name}`,
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {(formikProps) => {
          return (
            <DownloadCustomVariantForm
              resource={resource}
              formError={formikProps.errors.formError}
              djangoGlobalPerms={user.djangoGlobalPerms}
            />
          );
        }}
      </Formik>
    </div>
  );
};

/**
 * Get initial variant dimensions values
 *
 * If the user's preferred variant isn't available for this resource or the request
 * to get the user's preference fails, use the 'original' variant
 *
 * @param resource - The resource for which a user is trying to download a variant
 * @param userVariantPreference - The user variant preference, if available
 */
const getInitialVariantDimensionValues = (
  resource: ResourceForDcv,
  userVariantPreference: UserVariantPreference | undefined
): CustomVariantChoices => {
  if (
    !!userVariantPreference &&
    getVariantByDimensions(resource.variants, userVariantPreference)
  ) {
    return variantCombinationToChoices(userVariantPreference);
  } else return variantCombinationToChoices(ORIGINAL_VARIANT_COMBO);
};

export default DownloadCustomVariant;
