import React from "react";
import { noop } from "../utils/functools";
import { Modal } from "./ModalDisplay";

const CONTEXT_DEFAULT_VALUE: ModalContextValue = {
  currentModal: null,
  clearModal: noop,
  setModal: noop,
};

export interface ModalContextValue {
  currentModal: Modal | null;
  clearModal: () => void;
  setModal: (modal: Modal) => void;
}

const ModalContext: React.Context<ModalContextValue> = React.createContext(
  CONTEXT_DEFAULT_VALUE
);

export default ModalContext;
