import React, { createContext, useState } from "react";
import "../api_tokens/ApiTokens.scss";
import MfaInfo from "./MfaInfo";
import "./MfaSetup.scss";
import MfaSetupSteps from "./MfaSetupSteps";

export const MfaEnforcedContext = createContext<{
  mfaEnforced: boolean;
  setMfaEnforced: React.Dispatch<React.SetStateAction<boolean>>;
}>({ mfaEnforced: false, setMfaEnforced: () => null });

export const MfaEnforcedContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [mfaEnforced, setMfaEnforced] = useState(false);

  return (
    <MfaEnforcedContext.Provider value={{ mfaEnforced, setMfaEnforced }}>
      {children}
    </MfaEnforcedContext.Provider>
  );
};

const MfaSetup: React.FC = () => {
  const [showInfo, setShowInfo] = useState(true);
  const content = showInfo ? (
    <MfaInfo nextAction={() => setShowInfo(false)} />
  ) : (
    <MfaSetupSteps backAction={() => setShowInfo(true)} />
  );
  return <div className="MfaSetup">{content}</div>;
};

export default MfaSetup;
