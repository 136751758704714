import {
  faCopy,
  faFileDownload as faFileDownloadSolid,
  faFileSearch,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useCopyShareLink } from "../../home/browse/utils/sharing";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";
import "./CompaniesTableDownloadMenu.scss";

interface CompaniesTableDownloadMenuProps {
  companyId: string;
  anchorElement: null | HTMLElement;
  closeMenu: () => any;
  downloadResource: (id: string) => any;
  showVariantModal: (id: string) => any;
}

interface CompaniesTableDownloadMenuDeps {
  useCopyShareLink_?: typeof useCopyShareLink;
}

/**
 * Companies table row download menu
 *
 * @param companyId The ID of the associated resource
 * @param anchorElement The element on the page the menu should be rendered below
 * @param closeMenu Callback to call in order to close the menu
 * @param downloadResource Function to call to download the associated resource
 * @param showVariantModal Function to call to show the DownloadCustomVariant modal
 */
const CompaniesTableDownloadMenu: React.FC<
  CompaniesTableDownloadMenuProps & CompaniesTableDownloadMenuDeps
> = ({
  companyId,
  anchorElement,
  closeMenu,
  downloadResource,
  showVariantModal,
  useCopyShareLink_ = useCopyShareLink,
}) => {
  const copyShareLink = useCopyShareLink_();

  const open = !!anchorElement;
  const iconStyle = { transform: "scale(1.25)", transformOrigin: "center" };

  return (
    <Menu
      id="download-menu"
      anchorEl={anchorElement}
      open={open}
      onClose={closeMenu}
      MenuListProps={{
        "aria-label": STRINGS.table.downloadMenu.downloadMenu,
      }}
    >
      {/* Download model */}
      <MenuItem
        onClick={() => {
          downloadResource(companyId);
          closeMenu();
        }}
      >
        <ListItemIcon sx={{ pl: "0.3rem" }}>
          <FontAwesomeIcon icon={faFileDownloadSolid} style={iconStyle} />
        </ListItemIcon>
        <ListItemText sx={{ pr: "0.25rem" }}>
          {STRINGS.table.downloadMenu.downloadModel}
        </ListItemText>
      </MenuItem>
      <Divider />

      {/* Download a different format */}
      <MenuItem
        onClick={() => {
          showVariantModal(companyId);
          closeMenu();
        }}
      >
        <ListItemIcon sx={{ pl: "0.25rem" }}>
          <FontAwesomeIcon icon={faFileSearch} style={iconStyle} />
        </ListItemIcon>
        <ListItemText sx={{ pr: "0.25rem" }}>
          {STRINGS.table.downloadMenu.downloadDifferentFormat}
        </ListItemText>
      </MenuItem>

      {/* Copy share link */}
      <MenuItem
        onClick={() => {
          copyShareLink({ id: companyId, slug: "" });
          closeMenu();
        }}
      >
        <ListItemIcon sx={{ pl: "0.25rem" }}>
          <FontAwesomeIcon icon={faCopy} style={iconStyle} />
        </ListItemIcon>
        <ListItemText sx={{ pr: "0.25rem" }}>
          {STRINGS.table.downloadMenu.copyDownloadLink}
        </ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default CompaniesTableDownloadMenu;
