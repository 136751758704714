import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { Route, RouteProps, useLocation } from "react-router-dom";
import { redirect as redirectDefault } from "../utils/location";
import routes from "../utils/routes";

interface NavRouteProps extends RouteProps {
  routeName: string; // The name/slug of the route
  contentOnly?: boolean; // Hide the navigation sidebar and breadcrumbs completely
  publicRoute?: boolean; // Allow unauthenticated access

  // Functions managed and passed in by the parent component. Allows sharing
  // state with the parent and other sibling components
  isAuthenticated: () => boolean;
  setCurrentRoute: (value: string) => void;
  setContentOnly: (value: boolean) => void;
  setIsLoading: (value: boolean) => void;
}

interface NavRouteDeps {
  redirect?: (url: string) => void;
}

/**
 * An augmented version of <Route /> that allows us to conditionally hide
 * sidebar navigation, among other new features. Accepts at least the same props
 * as <Route />
 */
const NavRoute: React.FC<NavRouteProps & NavRouteDeps> = ({
  routeName,
  contentOnly = false,
  publicRoute = false,

  isAuthenticated,
  setCurrentRoute,
  setContentOnly,
  setIsLoading,

  redirect = redirectDefault,

  ...routeProps
}) => {
  const location = useLocation();

  const needAuth = useMemo(
    () => !publicRoute && !isAuthenticated(),
    [publicRoute, isAuthenticated]
  );
  const routePropsFinal = useMemo(() => {
    // It's important to NOT render the route component if authentication is
    // required & missing, and we are in the process of redirecting to /login
    // (see the useLayoutEffect side effect below). This is because some route
    // components also perform redirects & can interfere with the login redirect
    return {
      ...routeProps,
      component: needAuth ? undefined : routeProps.component,
      render: needAuth ? undefined : routeProps.render,
      children: needAuth ? undefined : routeProps.children,
    };
  }, [routeProps, needAuth]);

  useLayoutEffect(() => {
    // useLayoutEffect is used instead of useEffect as setting hideSidebar and
    // currentRoute must be performed synchronously, before any drawing happens in the
    // browser (otherwise CSS animations play on load). Actions in useEffect are
    // executed after drawing while useLayoutEffect executes before drawing
    setIsLoading(true);
    setCurrentRoute(routeName);
    setContentOnly(contentOnly);
  }, [routeName, contentOnly, setCurrentRoute, setContentOnly, setIsLoading]);

  useEffect(() => {
    if (needAuth) {
      const next = location.pathname + location.search + location.hash;
      redirect(`${routes.login}?next=${encodeURIComponent(next)}`);
    } else {
      setIsLoading(false);
    }
  }, [location, needAuth, redirect, setIsLoading]);

  return <Route {...routePropsFinal} />;
};

export default NavRoute;
