import {
  SearchDropdownFolderFragment,
  SearchDropdownPlaceholderFragment,
  SearchDropdownResourceFragment,
} from "../../generated/graphql";

export type SearchDropdownSearchItem =
  | SearchDropdownFolderFragment
  | SearchDropdownPlaceholderFragment
  | SearchDropdownResourceFragment;

export const isSubFolder = (
  item: SearchDropdownSearchItem
): item is SearchDropdownFolderFragment => {
  return item.__typename === "Folder";
};

export const isPlaceholder = (
  item: SearchDropdownSearchItem
): item is SearchDropdownPlaceholderFragment => {
  return item.__typename === "Placeholder";
};

export const isResource = (
  item: SearchDropdownSearchItem
): item is SearchDropdownResourceFragment => {
  return item.__typename === "Resource";
};
