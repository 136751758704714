import React from "react";

const UserAgreementCopy: React.FC = () => {
  return (
    <div className="UserAgreementCopy">
      <p>
        This Free Trial License Agreement (the “Agreement”) is entered into by
        and between Canalyst Financial Modeling Corporation, a company
        registered in British Columbia, Canada, whose registered address is 1700
        – 1055 West Hastings Street, Vancouver, British Columbia, V6E 2E9
        (“Canalyst”) and the Client as defined in a fully executed Canalyst Free
        Trial Order form (“Trial Order”), as of the Effective Date stated on the
        Trial Order. Together, Canalyst and Client comprise the “Parties” (any
        one being a “Party”).
      </p>

      <p>
        Any terms referenced herein but not defined in this Agreement may be
        defined in the Service Order.
      </p>

      <p>
        <strong>WHEREAS:</strong>
      </p>

      <p>
        A. This Agreement governs the access and use of the products and
        services (“Products”), which includes the materials and content therein
        (such as the Models) (“Materials”), made available to Client by Canalyst
        via the website http://www.canalyst.com/, or such other website
        specified to Client by Canalyst from time to time, whether accessed by
        web application, mobile application, or otherwise;
      </p>

      <p>
        B. the Licensor created a private access program for prospective clients
        to access and test the Products without cost (the “Free Trial”); and
      </p>

      <p>C. the Licensee wishes to participate in the Free Trial.</p>

      <p>
        NOW THEREFORE IN CONSIDERATION of $10.00 CAD from the Licensee to the
        Licensor, and other good and valuable consideration set forth herein
        (the receipt and sufficiency of which are hereby acknowledged by both
        Parties), the Parties agree as follows:
      </p>

      <p>
        <strong>1. GRANT OF LICENCE</strong>
      </p>

      <p>
        In exchange for the obligations provided for in this Agreement, the
        Licensor hereby grants and the Licensee hereby accepts, a limited,
        non-exclusive, revocable, non-sublicensable license (the “License”) to
        use the Products for the non-commercial purpose of participating in the
        Free Trial and under the terms and conditions specified in this
        Agreement.
      </p>

      <p>
        <strong>2. USE OF THE PRODUCTS</strong>
      </p>

      <p>
        2.1. Subject to the terms of this Agreement, the Licensee has the right
        under the License, during the term of the License, to access, download,
        and use the Products on the Licensee’s computers.
      </p>

      <p>
        2.2. For certainty, the Licensee is prohibited from using the Products
        for commercial purposes, or any purpose other than to test the Products
        and provide Feedback (as such term is defined in Section 6.1) to the
        Licensor on the Products as part of the Free Trial. Further, the
        Licensee shall not do any of the following:
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;i. attempt to reverse engineer, reprogram, modify,
        improve, disassemble, decompile or jeopardize the original functioning
        of the Products, or otherwise attempt to derive, copy, or manipulate the
        algorithms, formulas, calculations, or assumptions that underlie the
        Products;
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;ii. remove, cover, or otherwise alter any name or
        other identifying marks of the Products or in the Products; or
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;iii. facilitate or encourage any violations of this
        Agreement.
      </p>

      <p>
        2.3. Nothing contained on the Website or in the contents of the Products
        should be construed as a grant, by implication, estoppel, or otherwise,
        of any license or right to use the Products in any way except: (a) as
        expressly permitted by this Agreement; or (b) with Licensor’s prior
        written consent, which may be unreasonably withheld.
      </p>

      <p>
        <strong>3. IMPROVEMENTS AND MODIFICATIONS</strong>
      </p>

      <p>
        3.1. Improvements. Subject to Section 2.2, the Licensee may alter,
        modify, amend, enhance, improve, or manipulate the Products for the
        purpose of which they are provided (the “Improvements”). The Licensee
        waives all moral rights attached to any Improvements made to the
        Products which it may otherwise have rights to or ownership of, and the
        Licensee agrees that such Improvements shall be deemed as owned and
        authored by the Licensor.
      </p>

      <p>
        3.2. Power of Attorney. Further to Subsection 3.1 above, the Licensee
        hereby nominates, appoints, and constitutes the Licensor as the
        Licensee’s true and lawful attorney, with power, coupled with an
        interest, to do all things and execute all documents required to vest,
        register, record, and otherwise perfect the Licensor’s interest in any
        Improvements made to the Products during the term of this Agreement,
        whether such Improvements are made as contemplated in Section 3.1 above
        or as a result of Feedback.
      </p>

      <p>
        <strong>4. REPRESENTATIONS AND WARRANTS</strong>
      </p>

      <p>
        4.1. Mutual Representations and Warranties. Each Party hereby represents
        and warrants to the other Party that:
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;i. such Party is a corporation duly incorporated or
        duly constituted and validly existing;
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;ii. such Party has all necessary power and authority
        to enter into this Agreement and to carry out its obligations hereunder
        and such Party has authorized the person signing this Agreement to do so
        on the Party’s behalf;
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;iii. the execution and delivery of this Agreement and
        the performance of its obligations hereunder have been duly authorized;
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;iv. this Agreement constitutes a valid and binding
        obligation on such Party enforceable against such Party in accordance
        with its terms, subject, however, to the customary limitations with
        respect to bankruptcy, insolvency or other laws affecting creditors’
        rights generally and with respect to the availability of equitable
        remedies; and
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;v. such Party is not a party to, bound by or subject
        to any agreement, instrument, charter or by-law provision, statute,
        regulation, order, judgment, decree or law which would be violated,
        contravened or breached by, or under which any default would occur as a
        result of the execution and delivery by such Party of this Agreement or
        the performance by such Party of any of the terms hereof.
      </p>

      <p>
        4.2. Licensor Representations and Warranties. The Licensor represents
        and warrants to the Licensee as follows as of the date hereof, and
        acknowledges and confirms that the Licensee is relying upon such
        representations and warranties in connection with this License:
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;i. The Licensor represents and warrants that it has
        the sole right to grant the rights and licences provided for in this
        Agreement and that it has not assigned, licensed or otherwise conveyed
        the sole and exclusive rights and licences outlined in Section 1 to any
        other person, such that it would conflict with this License.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;ii. The Licensor shall, at its own cost, take all
        necessary steps to ensure that the Product’s licensed rights are
        maintained and preserved during the term of this Agreement. The Licensor
        shall, at its own cost, as far as commercially reasonable, pursue
        appropriate intellectual property protections for the Products and any
        and all Improvements. All of this Section 4.2(ii) is subject to Section
        9 of this Agreement.
      </p>

      <p>
        <strong>5. CONFIDENTIALITY</strong>
      </p>

      <p>
        5.1. “Confidential Information” means the Licensor’s information,
        communicated in any form or medium, whether or not reduced to writing,
        relating to the Website, the Products, and the Free Trial, and includes
        without limitation:
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;i. any related software, documents, logic, design,
        structure, algorithms, assumptions, calculations, formulas, processes,
        technical documentation, ideas, know-how, discoveries, inventions
        (whether or not patentable), techniques, mask works, industrial designs,
        and research and development information;
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;ii. lists of clients and other related information;
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;iii. marketing and financial plans, data and
        information related thereto;
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;iv. business plans, processes or information related
        thereto;
      </p>

      <p>&nbsp;&nbsp;&nbsp;v. Improvements and Feedback;</p>

      <p>
        &nbsp;&nbsp;&nbsp;vi. the existence of this Agreement including
        modifications or amendments to the Agreement and the terms herein; and
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;vii. any other information (whether tangible or
        intangible) of the Licensor (or its parent company, subsidiaries or
        affiliates, suppliers, agents, clients, end users or others) that the
        Licensor shares with the Licensee or that the Licensee should know, by
        virtue of the Licensee’s position or the circumstances in which it was
        learned or disclosed, should be kept confidential.
      </p>

      <p>
        Confidential Information also includes information of the Licensor’s
        parent company, subsidiaries or affiliates, and their shareholders,
        partners, employees, consultants, suppliers, agents, clients, end users
        or others, which is defined as Confidential Information in accordance
        with the foregoing definition.
      </p>

      <p>
        5.2. The Licensee acknowledges that it may have access to Confidential
        Information, and that the disclosure of such information to competitors,
        clients or partners of the Licensor, or to the general public, would be
        highly detrimental to the best interests and business of the Licensor.
      </p>

      <p>
        5.3. Notwithstanding the provisions of Section 5, “Confidential
        Information” does not include information or data which the Licensee can
        prove was in the public domain at the date of disclosure to it, or which
        thereafter enters the public domain through no fault of the Licensee’s
        (but only after it enters the public domain), provided that any
        combination of the information which comprises part of the Confidential
        Information will not be included within the foregoing exceptions merely
        because individual parts of the information were within the public
        domain.
      </p>

      <p>
        5.4. Notwithstanding the provisions of Section 5, “Confidential
        Information” does not include information or data which the Licensee can
        prove was independently developed by the Licensee, provided that the
        Licensee provides written evidence to the Licensor substantiating such
        independent development. Such written evidence shall be excluded from
        the definition of Feedback, provided that the Parties mutually agree
        that the development substantiated with the written evidence was
        independently developed. If the Parties agree that the written evidence
        substantiates independent development on the part of the Licensee that
        does not conflict with the terms of this Agreement, such written
        evidence shall be treated by the Licensor with the same levels of
        confidentiality as afforded to the Confidential Information.
      </p>

      <p>
        5.5. The Licensee shall not disclose, during the term of this Agreement
        or for a period of two (2) years thereafter, any Confidential
        Information to any person, nor will it use or exploit, directly or
        indirectly, the Confidential Information for any purpose other than to
        participate in the Free Trial, nor will it use for any purpose, other
        than to participate in the Free Trial, the private affairs of the
        Company, its clients, or any other information which the Licensee may
        acquire during the term of this Agreement with respect to the Products,
        whether acquired in the course of the Engagement or incidentally.
      </p>

      <p>
        5.6. Notwithstanding the foregoing, the Licensee shall be entitled to
        disclose such information if required by law provided that it promptly
        notify the Licensor, consult with the Licensor and cooperate with the
        Licensor in any attempt to resist or narrow such disclosure or to obtain
        an order or other assurance that such information will be accorded
        confidential treatment. Notwithstanding any disclosure required by law,
        the Confidential Information disclosed will, for all other purposes,
        continue to be treated as Confidential Information under this Agreement.
      </p>

      <p>
        <strong>6. FEEDBACK</strong>
      </p>

      <p>
        6.1. General. The Licensee shall provide feedback on the Products to the
        Licensor upon termination of this Agreement (the “Feedback”). The
        Licensee shall engage in at least one exit interview with the Licensor
        in order to provide the Feedback (the “Interview”). The Interview shall
        be conducted at a time mutually agreed upon by the Parties.
      </p>

      <p>
        6.2. Feedback Ownership. Any communications between the Licensee and the
        Licensor, such as email or other correspondence, including all
        communications during the Interview relating to suggestions or comments
        for improving or modifying the Products or any part thereof will be
        deemed Confidential Information and proprietary to the Licensor. All
        such communications shall be included as Feedback for the purposes of
        this Agreement. The Licensee waives any and all moral rights that may be
        associated with the Feedback.
      </p>

      <p>
        6.3. Feedback Representations and Warranties. Further to the ownership
        provisions included in Section 6.2 above, the Licensee agrees that when
        providing Feedback, the Licensee shall not breach any of its
        confidentiality obligations and that all such Feedback shall be free and
        clear of any third party proprietary rights. The Licensee agrees that it
        shall not be entitled to receive any additional compensation or
        re-imbursement of any kind from the Licensor for any Feedback and that
        the Licensor may irrevocably freely use, reproduce, exploit, and
        publicize the Feedback as it deems fit.
      </p>

      <p>
        <strong>7. PUBLICITY</strong>
      </p>

      <p>
        By participating in the Free Trial and as part of the consideration for
        this License, the Licensee agrees that the Licensor may disclose the
        Licensee’s name as a participant in the Free Trial in its confidential
        investor materials. No other announcements regarding the Licensee’s
        participation in the Free Trial, or the Licensee’s general relationship
        with the Licensor, shall be made without mutual consent by the Parties,
        which consent may be unreasonably withheld.
      </p>

      <p>
        <strong>8. TERM AND TERMINATION</strong>
      </p>

      <p>
        This Agreement shall remain in effect until terminated as provided
        herein.
      </p>

      <p>
        8.1. Termination. The Parties shall have the right to terminate this
        Agreement immediately and at any time upon giving notice to the other
        Party to that effect.
      </p>

      <p>
        8.2. Survival. Any provision of this Agreement which imposes an
        obligation after termination of this Agreement shall survive the
        termination of this Agreement including without limitation: Sections 3
        (Improvements and Modifications), 4 (Representations and Warranties), 5
        (Confidentiality), 6 (Feedback), 7 (Publicity), 9 (Enforcement), 11
        (Indemnity), 12 (Disclaimers), 13 (Liability Limitation), and14
        (General).
      </p>

      <p>
        8.3. Further Assurances. In the event of termination, the Licensee
        hereby agrees upon such termination to irretrievably destroy any
        Confidential Information in its possession (which includes any copies or
        derivatives of the Products), and, upon the Licensor’s request, to
        provide the Licensor forthwith with a certificate in writing executed by
        an officer of the Licensee confirming that all such materials have been
        irretrievably destroyed.
      </p>

      <p>
        <strong>9. ENFORCEMENT</strong>
      </p>

      <p>
        9.1. Mitigation. As it relates to the license granted in Section 1 of
        this Agreement, the Licensor reserves the right to take such steps as it
        deems necessary to restrain any unauthorized activity by the Licensee,
        including, without limitation, access suspension, access termination,
        and legal action.
      </p>

      <p>
        9.2. Equitable Relief. The Licensee acknowledges and agrees that the
        Products are of an extraordinary and unique character and that the
        injury which would be suffered by the Licensor in the event of a breach
        by the Licensee of any of its obligations hereunder would be irreparable
        and otherwise of a character which could not be fully compensated for
        solely by recovery of monetary damages. Accordingly, the Licensee agrees
        that, without in any way limiting the other rights or remedies of the
        Licensor, the Licensor shall be entitled to apply to obtain equitable
        relief, restraining order, injunction, decree, or remedy, as may be
        appropriate to restrain any breach or threatened breach of this License.
      </p>

      <p>
        9.3. Infringement by Third Parties. The Licensee shall promptly notify
        the Licensor of any infringement or suspected infringement of the
        intellectual property rights related to the Products (which shall
        include any Improvements), shall not make any admissions in respect of
        them, and shall give the Licensor all such information and assistance,
        other than financial assistance, as the Licensor may reasonably require
        to enable such proceedings to be taken and prosecuted against the
        infringer or suspected infringer as the Licensor may in its discretion
        deem necessary or desirable.
      </p>

      <p>
        9.4. Conduct and Participation. The Licensor shall have the right of
        sole conduct of all actions and proceedings relating to the Products and
        Improvements, including infringement claims, interferences, and
        conflicts, but the Licensor shall be under no obligation to exercise
        this right.
      </p>

      <p>
        <strong>10. RELATIONSHIP BETWEEN PARTIES</strong>
      </p>

      <p>
        The Parties acknowledge and agree that each is an independent business
        entity and, as such, neither Party may represent itself as an employee,
        agent, or representative of the other; nor may it incur any obligations
        on behalf of the other Party, which is not specifically authorized in
        this Agreement. This License does not create an association, joint
        venture, or partnership between the Parties, nor does it impose any
        partnership liability upon either Party.
      </p>

      <p>
        <strong>11. INDEMNITY</strong>
      </p>

      <p>
        The Licensee agrees to indemnify, defend, and hold harmless the Licensor
        from and against any and all losses, costs, expenses, claims, or damages
        arising out of any claim, suit, action, or judgment brought against the
        Licensor by a third party as a result of the use by the Licensee of the
        Products, any act of the Licensee beyond its authority under this
        Agreement, or as a result of any breach by the Licensee of any covenant,
        warranty or representation made under this Agreement.
      </p>

      <p>
        <strong>12. DISCLAIMERS</strong>
      </p>

      <p>
        12.1. Accuracy of Information. The Licensor does not guarantee that the
        information (which may include financial figures) included in the
        Products will be accurate, up-to-date or complete. The Products may
        contain errors, omissions or inaccuracies, including both typographical
        and substantive errors. The Licensor reserves the right, in its sole
        discretion, without any obligation and without any notice requirement,
        to discontinue, change, improve, or correct the information, algorithms,
        formulas, calculations, designs, and descriptions provided in the
        Products, and to suspend or deny access to the Licensee for scheduled or
        unscheduled maintenance, upgrades, improvements or corrections. Any
        dated information is published as of its date only, and the Licensor has
        no responsibility to update or amend any such information (whether or
        not material events occur relating to market changes, financial updates,
        news events, or any other event which may impact the Products and the
        information found therein).
      </p>

      <p>
        12.2. No Warranty. Subject to the provisions of Section 4 and to the
        maximum extent permitted by law, the Products are provided “as is” and
        are used at the Licensee’s sole risk with no warranties from the
        Licensor whatsoever. Without limiting the foregoing, Licensor does not
        make any warranties, claims or representations and expressly disclaims
        all such warranties of any kind, whether express, implied or statutory,
        with respect to the Products or Website including warranties or
        conditions of quality, performance, non-infringement, merchantability,
        or fitness for use for a particular purpose.
      </p>

      <p>
        12.3. Downtime. The Licensor does not guarantee that access to the
        Products, or any part thereof, will be available 100% of the time.
        Interruptions and delays in accessing the Products may occur during the
        Free Trial, and the Licensor disclaims any liability for damages
        resulting from such problems, including, but not limited to, loss of
        opportunity or loss of use or enjoyment.
      </p>

      <p>
        12.4. No Advice. The Licensor makes no recommendations, endorsements,
        warranties, representations or otherwise whatsoever, regarding any
        security or securities products, or regarding any of the companies
        referenced in the Products. The Licensor does not provide financial or
        investment advice, and the Products should not be relied upon in making
        any kind of financial investment or construed as being advisory in
        nature. The Licensor is not a licensed financial or investment advisor
        and strongly recommends the Licensee only utilize the Products under the
        close supervision of a licensed financial or investment advisor if
        utilizing the Products to make investment decisions.
      </p>

      <p>
        12.5. Third Party Materials or Links. The Products may contain third
        party materials or website links (the “Links”). The Licensor provides
        the Links for convenience only and makes no representations whatsoever
        about any site whose services may be accessed through the Products or
        whose services may be described or offered through the Products. The use
        of Links does not mean that the Licensor endorses or accepts any
        responsibility for the content, or use, of such third party material or
        website.
      </p>

      <p>
        12.6. Reliance. The Licensee expressly acknowledges that the Licensor is
        providing this License in reliance upon the limitations and exclusions
        of liability and the disclaimers set forth herein and that the same form
        an essential basis of this Agreement. The Licensee expressly agrees that
        the limitations and exclusions of liability and the disclaimers set
        forth herein shall survive, and continue to apply in the case of a
        fundamental breach or breaches of, the failure of essential purpose of
        contract, the failure of any exclusive remedy or the termination or
        suspension by the Licensor of the Licensee’s use of, or access to, the
        Products.
      </p>

      <p>
        <strong>13. LIABILITY LIMITATION</strong>
      </p>

      <p>
        THE MAXIMUM AGGREGATE LIABILITY OF THE LICENSOR, ITS OFFICERS,
        DIRECTORS, SHAREHOLDERS, EMPLOYEES, AND AGENTS, UNDER THIS LICENSE FOR
        ALL LOSSES, DAMAGES, EXPENSES, OR INJURIES, WHETHER UNDER CONTRACT, TORT
        (INCLUDING WITHOUT LIMITATION, NEGLIGENCE, AND STRICT LIABILITY), BY
        STATUTE, OTHER LEGAL THEORY, OR OTHERWISE, HOWSOEVER ARISING, SHALL BE
        LIMITED TO THE MONETARY CONSIDERATION PROVIDED TO THE LICENSEE FROM THE
        LICENSOR UNDER THIS AGREEMENT ($10.00 CAD). NO ACTION OR PROCEEDING
        RELATING TO THIS LICENSE MAY BE COMMENCED BY THE LICENSEE MORE THAN ONE
        (1) YEAR AFTER THE CAUSE OF ACTION ARISES.
      </p>

      <p>
        IN NO EVENT SHALL THE LICENSOR BE LIABLE TO THE LICENSEE OR TO ANY THIRD
        PARTY FOR: (1) ANY AMOUNTS REPRESENTING LOSS OF PROFITS OR REVENUES
        HOWSOEVER ARISING; (2) SPECIAL, INDIRECT, PUNITIVE, INCIDENTAL, OR
        CONSEQUENTIAL DAMAGES EVEN IF ADVISED OF THE POSSIBILITY OF SAME; (3)
        DAMAGES OR EXPENSES ARISING FROM CHANGES IN OPERATING CHARACTERISTICS OF
        THE PRODUCTS WHICH ARE MADE DURING THE TERM OF THIS LICENSE; OR (4)
        DAMAGES ARISING FROM THE USE OF THE PRODUCTS WITH THE OTHER SOFTWARE.
        FURTHER TO SECTION 11, THE LICENSEE SHALL INDEMNIFY, DEFEND, AND HOLD
        HARMLESS THE LICENSOR IN RESPECT OF ALL CLAIMS OR DAMAGES EXCLUDED
        HEREUNDER.
      </p>

      <p>
        <strong>14. GENERAL</strong>
      </p>

      <p>
        14.1. Governing Law. This Agreement shall be governed by and construed
        in accordance with the laws of the Province of British Columbia and the
        laws of Canada applicable therein.
      </p>

      <p>
        14.2. Further Assurances. The Parties shall make best efforts to
        execute, acknowledge, and deliver all such further assurances and
        documents and do all such other acts as may be necessary or appropriate
        in order to carry out the intent and purposes of this Agreement.
      </p>

      <p>
        14.3. Entire Agreement. This Agreement, which includes the attached
        Schedule “A”, constitutes the entire agreement between the Parties
        relating to the subject matter hereof and supersedes all previous
        writings and understandings.
      </p>

      <p>
        14.4. Severability. If any provision of this Agreement is or becomes
        fully or partially invalid or unenforceable for any reason whatsoever or
        should be adjudged to violate any applicable law, this Agreement is to
        be considered divisible as to such provision and such provision, to the
        extent that it is invalid or unenforceable, is to be deemed deleted from
        this Agreement, and the remainder of this Agreement shall be valid and
        binding as if such provision were not included herein.
      </p>

      <p>
        14.5. Non-Waiver. No waiver of any violation or non-performance of this
        Agreement shall be deemed to be a waiver of any subsequent violation or
        non-performance.
      </p>

      <p>
        14.6. Language. Whenever a word is used in the singular in this
        Agreement, it shall also mean the plural, and vice versa, unless
        otherwise noted. The headings in this Agreement are inserted for
        convenience of reference only and shall not affect the construction
        thereof.
      </p>

      <p>14.7. Time. Time shall be of the essence of this Agreement.</p>

      <p>
        14.8. Notice. All notices, reports, or other communication that the
        Parties give each other in connection with this Agreement shall be in
        writing, and shall be delivered by hand, e-mail, or registered mail to
        the recipient at the Party’s address or e-mail as set forth in the Trial
        Order. Proof of delivery in a prescribed manner will constitute proof of
        receipt.
      </p>

      <p>
        14.9. Construction of Agreement. This Agreement is deemed to be drafted
        by both Parties. Interpretation of this Agreement shall not be construed
        against either Party.
      </p>

      <p>
        14.10. Assignment. The Licensor may assign its rights, obligations and
        duties under this Agreement with notice to the Licensee. The Licensee
        may assign its rights, obligations and duties under this Agreement only
        with prior written approval from the Licensor, which approval may be
        unreasonably withheld.
      </p>

      <p>
        14.11. Enurement. This Agreement shall enure to the benefit of the
        Parties’ permitted assigns.
      </p>

      <p>
        14.12. Amendmenents. This Agreement may be amended or modified from time
        to time by a written document signed by the Licensor and the Licensee,
        or its permitted assigns.
      </p>

      <p>
        14.13. Dispute Resolution. All disputes arising out of or in connection
        with this Agreement, or in respect of any defined legal relationship
        associated therewith or derived therefrom shall be referred to and
        finally resolved by arbitration under the International Commercial
        Arbitration Rules of Procedure of the British Columbia International
        Commercial Arbitration Centre. The appointing authority shall be the
        British Columbia International Commercial Arbitration Centre. The place
        of arbitration shall be Vancouver, British Columbia. All of the costs
        and expenses of the arbitration shall be borne equally by the parties to
        the dispute. Any award rendered by the arbitrator shall be final and
        binding on the parties. Nothing in this Agreement shall prevent the
        Licensor from applying to an appropriate court in any part of the
        province, state, territory, or elsewhere for any injunction or other
        like remedy to restrain the Licensee from committing any breach or any
        anticipated breach of this Agreement and for consequential relief.
      </p>

      <p>
        14.14. Counterparts. This Agreement may be executed in one or more
        counterparts, each of which shall be deemed an original, but all of
        which taken together constitute one and the same instrument.
      </p>
    </div>
  );
};

export default UserAgreementCopy;
