import { useEffect } from "react";

type EventMap = HTMLElementEventMap;
type Listener<K extends keyof EventMap> = (ev: EventMap[K]) => void;
type Target<K extends keyof EventMap> = {
  addEventListener: (
    type: K,
    listener: Listener<K>,
    options?: boolean | AddEventListenerOptions
  ) => any;
  removeEventListener: (type: K, listener: Listener<K>) => any;
};

/**
 * Add an event listener. Includes proper cleanup
 *
 * @param target - The target to add the event listener to, e.g. `ref.current`
 *   or `document`. If `null`, NO event listener will be added
 * @param type - The type of event
 * @param listenerWithUseCallback - The listener function. IMPORTANT: Remember
 *   to wrap the listener with `useCallback`, otherwise the listener function
 *   will change on every render. This will cause the `useEffect` in this hook
 *   to also trigger on ever render, which is a waste of resources
 * @param options - Event listener options
 *
 * @example
 * useEvent(
 *   ref.current,
 *   "contextmenu",
 *   useCallback((ev) => ev.preventDefault(), []),
 *   true
 * )
 */
export const useEvent = <K extends keyof EventMap>(
  target: Target<K> | null,
  type: K,
  listenerWithUseCallback: Listener<K>,
  options?: boolean | AddEventListenerOptions
) => {
  useEffect(() => {
    if (target === null) return;
    target.addEventListener(type, listenerWithUseCallback, options);
    return () => target.removeEventListener(type, listenerWithUseCallback);
  }, [target, type, listenerWithUseCallback, options]);
};
