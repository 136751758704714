import React, {
  Context,
  useCallback,
  useContext as useContextDefault,
} from "react";
import PlaceholderModelRequest, {
  PlaceholderForModelRequest,
} from "../home/common/PlaceholderModelRequest";
import ModalContext from "../modal/ModalContext";

/**
 * Returns a callback that sets a modal with the PlaceholderModelRequest component
 * @param useContext
 */
export const useRequestModelModal = (
  useContext: <T>(context: Context<T>) => T = useContextDefault
) => {
  const { setModal } = useContext(ModalContext);

  return useCallback(
    (placeholder: PlaceholderForModelRequest) => {
      setModal({
        title: placeholder.name,
        content: <PlaceholderModelRequest placeholder={placeholder} />,
      });
    },
    [setModal]
  );
};
