import React, { memo } from "react";
import "./Spinner.scss";

/**
 * ColourType: allows for two <colour> types for the `text-<colour>` Bootstrap class
 * See https://getbootstrap.com/docs/4.4/utilities/colors/
 *
 * PRIMARY - used for the `text-primary` class
 * SECONDARY - used for the `text-secondary` class
 **/
export enum ColourType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export interface SpinnerProps {
  colour: ColourType;
  size: string; // A CSS spacial unit. Try to use rem units, e.g. "3rem".
}

const Spinner: React.FC<SpinnerProps> = ({ colour, size }) => {
  // This is a presentation-only component (see the use of `React.memo` below). It
  // should not have any side effects.
  const divStyle = {
    width: size,
    height: size,
  };
  const subDivClassName = `Spinner__border spinner-border text-${colour}`;
  return (
    <div className="Spinner text-center">
      <div className={subDivClassName} role="status" style={divStyle}>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default memo(Spinner);
