import { captureException } from "@sentry/browser";
import Cookies from "js-cookie";
import DownloaderDefault from "js-file-downloader";
import React, { useCallback, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import DownloadLimitExceeded, {
  DOWNLOAD_LIMIT_EXCEEDED_TITLE,
} from "../faq/DownloadLimitExceeded";
import ModalContext from "../modal/ModalContext";
import ToastContext from "../toast/ToastContext";
import { ToastStyle } from "../toast/ToastDisplay";
import { getUserJwt } from "./auth";
import loggerDefault from "./logger";
import routes from "./routes";
import { SKIP_DIALOG_CONTEXT } from "./sentry";

/**
 * A hook to handle file downloads with toast notifications
 *
 * The filename passed to the callback is expected to have a file extension
 */
const AUTO = "True";
export const useDownload = ({
  Downloader = DownloaderDefault,
  useModalContext = useContext,
  useToastContext = useContext,
  captureException_ = captureException,
  getUserJwt_ = getUserJwt,
  logger = loggerDefault,
} = {}) => {
  const { setModal, clearModal } = useModalContext(ModalContext);
  const { setToast, clearToast } = useToastContext(ToastContext);
  return useCallback(
    (url: string, filename?: string) => {
      setToast({ style: ToastStyle.Info, text: "Downloading file…" });
      logger.info("DOWNLOAD:START", url);
      const downloader = new Downloader({
        url,
        autoStart: false,
        headers: [{ name: "Authorization", value: `Bearer ${getUserJwt_()}` }],
        timeout: 3 * 60 * 1000, // 3 minutes; Toolkit is slow to DL sometimes
        filename,
        nameCallback: decodeURIComponent,
      });
      const t0 = performance.now();
      const perfDisplay = (t1: number) => `${((t1 - t0) / 1000).toFixed(2)}s`;
      return downloader
        .start()
        .then(() => {
          const t1 = performance.now();
          setToast({ style: ToastStyle.Info, text: "Download completed" });
          logger.info("DOWNLOAD:SUCCESS", `${url} (${perfDisplay(t1)})`);
        })
        .catch((err) => {
          const t1 = performance.now();
          const errIdentifier = err?.request?.status || "???";
          if (err?.request?.status === 429) {
            clearToast();
            setModal({
              title: DOWNLOAD_LIMIT_EXCEEDED_TITLE,
              content: <DownloadLimitExceeded primaryAction={clearModal} />,
            });
          } else {
            captureException_(err, SKIP_DIALOG_CONTEXT);
            setToast({
              style: ToastStyle.Error,
              text: "Download failed. Please try again later",
            });
          }
          logger.info(
            "DOWNLOAD:FAILURE",
            `[${errIdentifier}] ${url} (${perfDisplay(t1)})`
          );
        });
    },
    [
      Downloader,
      captureException_,
      getUserJwt_,
      logger,
      setModal,
      clearModal,
      setToast,
      clearToast,
    ]
  );
};

/**
 * A hook that downloads a string argument as a .txt file
 *
 * The filename value passed to the callback is expected to end in .txt
 */
export const useDownloadAsTxt = (
  createObjectUrl = window.URL.createObjectURL,
  useDownload_ = useDownload
) => {
  const download = useDownload_();
  return useCallback(
    (value: string, filename: string) => {
      const blob = new Blob([value], {
        type: "text/plain",
      });
      const textFile = createObjectUrl(blob);
      download(textFile, filename);
    },
    [download, createObjectUrl]
  );
};

/**
 * Set cookies to force the old Web Portal to be used then refresh the page
 */
export const forceOldInterface = () => {
  Cookies.set("ca_force_old_interface", "42", { "max-age": "1209600" }); // 2 week expiry
  Cookies.remove("ca_force_new_interface");
  window.location.reload();
};

/**
 * Redirect (full page refresh) to the specified URL
 *
 * This function is simple but useful for testing redirects in other components. Testing
 * global state (window.location.href) is tricky, so we contain it here.
 *
 * @param url - The URL to redirect to
 */
export const redirect = (url: string) => {
  window.location.assign(url);
};

/**
 * Navigate a user to a page based on their history
 *
 * This function checks the history stack to determine if the user's previous
 * page was on the MPA before redirecting back to where they were or to home.
 *
 * Note that the user will be directed to home if they have come from the MPA,
 * an external site or caused a page refresh; this is a known limitation, as the
 * the history stack is cleared on a refresh.
 *
 * @param reactHistory - A history instance from useHistory for navigation
 */
export const goBack = (reactHistory: RouteComponentProps["history"]) => {
  if (window.history.state) {
    reactHistory.goBack();
  } else {
    reactHistory.push(routes.home);
  }
};

/**
 * Returns an encoded next query arg from the string provided
 *
 * The default value for next is the current pathname as creating a next query arg from
 * the current path is a common pattern
 *
 * auto: automatically logout
 * @param next - The URI component to create a next query arg from
 * @param auto - The URI component to check if logout automatically is true
 */
export const getNextEncoded = (next: string | null = null) => {
  const search = window.location.search || "";
  const hash = window.location.hash || "";
  const urlComp =
    next === null ? `${window.location.pathname}${search}${hash}` : next;

  return `?next=${encodeURIComponent(urlComp)}&auto=${encodeURIComponent(
    AUTO
  )}`;
};
