import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom";
import Moment from "react-moment";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import App from "./App";
import schemaDefault from "./generated/schema.json";
import "./index.scss";
import ModalProvider from "./modal/ModalProvider";
import ToastProvider from "./toast/ToastProvider";
import { MfaEnforcedContextProvider } from "./user_settings/multi_factor_auth/MfaSetup";
import CacheExpiryProvider from "./utils/apollo/CacheExpiryProvider";
import { getAuthHeaders, setJwtTimeout } from "./utils/auth";
import { DATE_FORMAT } from "./utils/datetime";
import { getPossibleTypes, TYPE_POLICIES } from "./utils/schema";
import * as sentry from "./utils/sentry";
import settings, { features } from "./utils/settings";
import { THEME_DEFAULT } from "./utils/theme";

sentry.init(); // Initialize Sentry first so it can catch errors going forward
console.info("Using features", features);

// Start the pooled timer which runs every 60 seconds (60000 milliseconds) by default.
// Using a pooled timer improves performance when many <Moment /> instances are used.
Moment.startPooledTimer();
Moment.globalFormat = DATE_FORMAT;
Moment.globalLocal = true;

// possibleTypes is generated dynamically using schema.json
const POSSIBLE_TYPES = getPossibleTypes(schemaDefault);
const cache = new InMemoryCache({
  possibleTypes: POSSIBLE_TYPES,
  typePolicies: TYPE_POLICIES,
});

// Credentials (cookies) are omitted and thus are not passed along in requests. They are
// omitted so that Django does not use session-based authentication and instead uses JWT
// Bearer schema. The default uri for ApolloClient is `/graphql`
const headers = getAuthHeaders();
const apolloClient = new ApolloClient({
  uri: "/graphql",
  cache,
  headers: headers,
  credentials: "omit",
});

setJwtTimeout(settings.jwtExpirySeconds);

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <CacheExpiryProvider>
          <MfaEnforcedContextProvider>
            <ThemeProvider theme={THEME_DEFAULT}>
              <ModalProvider>
                <ToastProvider>
                  <App />
                </ToastProvider>
              </ModalProvider>
            </ThemeProvider>
          </MfaEnforcedContextProvider>
        </CacheExpiryProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);
