import classNames from "classnames";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useEvent } from "../utils/events";
import "./Dropdown.scss";

export enum DropdownDirection {
  DOWN,
  UP,
}

export const DropdownPositions = {
  LEFT: { right: 0 },
  RIGHT: { left: 0 },
  CENTER: { right: 0, left: 0 },
};

export interface DropdownChoice {
  id: string;
  display: string;
  disabled?: boolean;
}

export interface DropdownProps<T extends DropdownChoice = DropdownChoice> {
  choices: T[];
  setChoice: (choice: T) => void;
  dropdownActive: boolean;
  setDropdownActive: (value: boolean) => void;
  className?: string;
  direction?: DropdownDirection;
  dropdownSpacing?: string;
  dropdownDisabled?: boolean;
  dropdownPosition?:
    | typeof DropdownPositions.LEFT
    | typeof DropdownPositions.RIGHT
    | typeof DropdownPositions.CENTER;
  dataTestId?: string;
}

/**
 * A dropdown allowing selecting a single option from a dropdown
 *
 * Note that `dropdownActive` and `setDropdownActive` are typically the return values
 * from a `useState` hook, managed by the parent component for maximum flexibility:
 * `const [dropdownActive, setDropdownActive] = useState(false);`
 *
 * @param props - Component props
 * @param props.choices - Available choices to show in the dropdown
 * @param props.setChoice - Hook to call when a choice is changed/selected
 * @param props.dropdownActive - If the dropdown is active/open or not
 * @param props.setDropdownActive - Set the dropdown active state
 * @param props.className - An optional CSS class name
 * @param props.direction - The direction of the dropdown
 * @param props.dropdownSpacing - Spacing between the toggle element and dropdown
 */
const Dropdown = <T extends DropdownChoice>({
  children,
  choices,
  setChoice,
  dropdownActive,
  setDropdownActive,
  className,
  direction = DropdownDirection.DOWN,
  dropdownSpacing = "0",
  dropdownDisabled = false,
  dropdownPosition = DropdownPositions.CENTER,
  dataTestId = "dropdown",
}: DropdownProps<T> & { children?: React.ReactNode }): React.ReactElement => {
  const dropdownNode = useRef<HTMLDivElement>(null);
  const [elemHeight, setElemHeight] = useState<number>(0);

  // Cannot use within a useCallback hook as it prevents us from receiving the correct
  // height (always returns 0). Keeping track of the height is necessary if we want to
  // move the dropdown above the toggle element (e.g. if the direction is UP).
  const dropdownToggleNode = (node: HTMLDivElement | null) => {
    if (!node) return;
    const { height } = node.getBoundingClientRect();
    setElemHeight(height);
  };

  const handleMouseDown = useCallback(
    (ev: MouseEvent) => {
      if (
        !(dropdownNode.current as HTMLDivElement).contains(ev.target as Node)
      ) {
        setDropdownActive(false); // click was outside the dropdown
      }
    },
    [setDropdownActive]
  );
  useEvent(document, "mousedown", handleMouseDown);

  const dropdownItems = useMemo(() => {
    // The first choice should always be closest to the mouse. So if we are displaying
    // the dropdown upwards, the first choice needs to be rendered last (in reverse).
    const choicesOrdered =
      direction === DropdownDirection.UP ? choices.slice().reverse() : choices;
    return choicesOrdered.map((choice) => {
      const className = classNames("Dropdown__item", {
        "Dropdown__item--disabled": choice.disabled,
      });

      return (
        <li
          className={className}
          key={choice.id}
          onClick={() => {
            if (!choice.disabled) setChoice(choice);
            setDropdownActive(false);
          }}
        >
          {choice.display}
        </li>
      );
    });
  }, [choices, setChoice, setDropdownActive, direction]);

  const getDropdownContentStyle = () => {
    if (direction === DropdownDirection.UP) {
      return {
        bottom: 0,
        marginBottom: dropdownSpacing,
        transform: `translateY(-${elemHeight}px)`,
      };
    } else {
      return { marginTop: dropdownSpacing, ...dropdownPosition };
    }
  };

  const handleClick = () => {
    if (!dropdownDisabled) setDropdownActive(!dropdownActive);
  };
  const dropdownClassNames = classNames("Dropdown", className, {
    "Dropdown--active": dropdownActive,
  });
  return (
    <div
      className={dropdownClassNames}
      ref={dropdownNode}
      data-testid={dataTestId}
    >
      <div
        className="Dropdown__toggle"
        onClick={handleClick}
        ref={dropdownToggleNode}
      >
        {children}
      </div>
      <ul className="Dropdown__content" style={getDropdownContentStyle()}>
        {dropdownItems}
      </ul>
    </div>
  );
};

export default Dropdown;
