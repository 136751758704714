import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import Tippy from "@tippyjs/react";
import React, { ReactNode, useState } from "react";
import { getCookie, setCookie } from "../../utils/cookies";
import { features } from "../../utils/settings";
import "./Banner.scss";

const TEXT_DEFAULT_TITLE = "Notice:";
const TEXT_DISMISS_BANNER = "Dismiss this notice";

const FEATURE_FLAG = "notificationBanner";
const COOKIE_PREFIX = "dismiss-banner_";
const DISMISS_TIME = 30; // Banner dismissal expires after 30 days
const BUTTON_STYLE = {
  width: 48,
  height: 48,
};

interface BannerProps {
  id: string;
  title?: string;
  children: ReactNode;
}

interface BannerDeps {
  features_?: typeof features;
}

/**
 * Notification banner component
 *
 * Content of notification passed as child component(s) to allow for custom formatting
 *
 * Banner can be dismissed via setting cookie which is checked for when component loads
 *
 * The banner can be disabled via feature flag. To disable it, set
 * FF_NOTIFICATION_BANNER to 'false' in the app's environmental config vars. The banner
 * defaults to being disabled if the feature flag is not configured.
 *
 * @param id ID of banner (used to determine whether user has dismissed it)
 * @param title Banner title (default: "Notice:")
 */
const Banner: React.FC<BannerProps & BannerDeps> = ({
  id,
  title = TEXT_DEFAULT_TITLE,
  children,
  features_ = features,
}) => {
  const disabled =
    !(FEATURE_FLAG in features_) || features_[FEATURE_FLAG] === false;
  const cookieId = `${COOKIE_PREFIX}${id}`;
  const [dismissed, setDismissed] = useState<boolean>(
    getCookie(cookieId) !== ""
  );

  // Dismiss banner
  const dismiss = () => {
    setCookie(cookieId, "true", DISMISS_TIME);
    setDismissed(true);
  };

  if (disabled || dismissed) {
    return <></>;
  }

  return (
    <div className="Banner">
      <div className="Banner__left">
        <div className="Banner__heading">{title}</div>
        <div className="Banner__content" role="status">
          {children}
        </div>
      </div>
      <div className="Banner__right">
        <div className="Banner__close-button">
          <Tippy content={TEXT_DISMISS_BANNER}>
            <IconButton
              color="default"
              sx={BUTTON_STYLE}
              onClick={dismiss}
              aria-label={TEXT_DISMISS_BANNER}
            >
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  transform: "scale(1.25)",
                  transformOrigin: "center",
                  opacity: 0.75,
                }}
              />
            </IconButton>
          </Tippy>
        </div>
      </div>
    </div>
  );
};

export default Banner;
