import React from "react";
import fliStylesDefault from "../../common/FancyListItem.scss";
import Spinner, { ColourType } from "../../common/Spinner";
import { FolderItemOrdering, useBrowseQuery } from "../../generated/graphql";
import hpStylesDefault from "../browse/HomePage.scss";
import {
  buildFolderItemComponent,
  getFolderPreviewHeight as getFolderPreviewHeightDefault,
} from "./utils/components";

interface FolderItemPreviewProps {
  path: string;
  first: number;
  ordering?: FolderItemOrdering;
  emptyCopyOverride?: string;
}
interface FolderItemPreviewDeps {
  getFolderPreviewHeight?: typeof getFolderPreviewHeightDefault;
  useBrowseQuery_?: typeof useBrowseQuery;
  buildFolderItemComponent_?: typeof buildFolderItemComponent;
}
const FolderItemPreview: React.FC<
  FolderItemPreviewProps & FolderItemPreviewDeps
> = ({
  path,
  first,
  ordering = FolderItemOrdering.Updated,
  emptyCopyOverride,
  getFolderPreviewHeight = getFolderPreviewHeightDefault,
  useBrowseQuery_ = useBrowseQuery,
  buildFolderItemComponent_ = buildFolderItemComponent,
}) => {
  const { loading, error, data } = useBrowseQuery_({
    variables: { path: path, first: first, ordering: ordering },
    fetchPolicy: "network-only",
  });
  const folder = data?.folderByPath || undefined;
  const user = data?.me || undefined;
  if (loading) {
    const spinnerStyles = {
      minHeight: getFolderPreviewHeight(first),
    };
    return (
      <div
        className="FolderItemPreview FolderItemPreview--spinner"
        style={spinnerStyles}
      >
        <Spinner
          colour={ColourType.SECONDARY}
          size="3rem"
          data-testid="loading"
        />
      </div>
    );
  }
  if (error || !folder || !user) {
    return (
      <div className="FolderItemPreview">
        <p data-testid="error-404-500">
          Something went wrong when retrieving this folder.
        </p>
      </div>
    );
  }
  const itemElements = folder.items.edges.map((edge) =>
    buildFolderItemComponent_(edge.node, user)
  );
  if (itemElements.length === 0) {
    return (
      <div className="FolderItemPreview">
        <p data-testid="no-items">
          {emptyCopyOverride || "This folder is empty."}
        </p>
      </div>
    );
  }
  return (
    <div className="FolderItemPreview FolderItemPreview--success">
      {itemElements}
    </div>
  );
};

/**
 * Determines the number of preview items to show in each FolderItemPreview
 *
 * @param innerHeight - The innerHeight of the window in rem
 * @param numPreviewsInCol - The number of FolderItemPreviews in the column
 * @param minNumItems - The minimum number of preview items to display
 * @param maxNumItems - The maximum number of preview items to display
 * @param fliStyles - An object containing FancyListItem styles
 * @param hpStyles - An object containing HomePage styles
 */
export const getNumPreviewItems = (
  {
    innerHeight,
    numPreviewsInCol,
    minNumItems,
    maxNumItems,
  }: {
    innerHeight: number;
    numPreviewsInCol: number;
    minNumItems: number;
    maxNumItems: number;
  },
  // Currently SCSS variable imports are undefined in tests so need to be passed using
  // dependency injection. This also prevents the defaults from being used in tests.
  /* istanbul ignore next */
  fliStyles: typeof fliStylesDefault = fliStylesDefault,
  /* istanbul ignore next */
  hpStyles: typeof hpStylesDefault = hpStylesDefault
) => {
  const fliHeightWithBorder = parseFloat(fliStyles.fliHeightWithBorder);
  const interFliSpacing = parseFloat(fliStyles.interFliSpacing);
  const pageHeightMinItems = parseFloat(hpStyles.pageHeightMinItems);
  const additionalSpace = Math.max(0, innerHeight - pageHeightMinItems);
  const additionalItems = Math.floor(
    additionalSpace /
      (numPreviewsInCol * (fliHeightWithBorder + interFliSpacing))
  );
  return Math.min(maxNumItems, minNumItems + additionalItems);
};

export default FolderItemPreview;
