import {
  AvailableDimensions,
  getVariantDimensionsChoices,
  isVariantDimensionField,
  ORIGINAL_VARIANT_COMBO,
  VariantCombination,
  VariantDimensionField,
  VariantDimensionsChoices,
  VariantDimensionValue,
  VARIANT_DIMENSION_METADATA,
} from "../../../common/variants";
import { ResourceVariant } from "../../../generated/graphql";

// GVDB here stands for getVariantByDimensions
export type VariantForGvdb = Pick<
  ResourceVariant,
  "id" | "periodOrder" | "driversWorksheets" | "theme"
>;

/**
 * Given a list of available values for each variant dimension, get variant dropdown choices
 *
 * @param availableDimensions - Available values for each variant dimension
 * @param djangoGlobalPerms - A user's djangoGlobalPerms
 * @param getVariantDimensionsChoices_ - Variant dimensions choices getter function
 */
export const getAvailableDimensionsChoices = (
  availableDimensions: AvailableDimensions,
  djangoGlobalPerms: string[] = [],
  getVariantDimensionsChoices_: typeof getVariantDimensionsChoices = getVariantDimensionsChoices
): VariantDimensionsChoices => {
  const baseDimensions = getVariantDimensionsChoices_({
    dimensionMetadataMap: VARIANT_DIMENSION_METADATA,
    djangoGlobalPerms: djangoGlobalPerms,
  });
  const dimensions = Object.create(Object.prototype);

  for (const dimension in baseDimensions) {
    if (
      baseDimensions.hasOwnProperty(dimension) &&
      isVariantDimensionField(dimension)
    )
      dimensions[dimension] = baseDimensions[dimension]
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((value) => {
          return {
            ...value,
            disabled: !availableDimensions[dimension].has(value.id),
          };
        });
  }

  return dimensions;
};

/**
 * Get all available dimensions given the current selected values and all variant combos
 *
 * Example: If a user currently has 'Chronological' selected for period order and
 * 'No Drivers Worksheet' selected for drivers worksheet and the model in question only
 * has variants without drivers worksheets. This function should return the following
 * object:
 *    {
 *      periodOrder: Set('Chronological', 'Annual Grouped'),
 *      driversWorksheets: Set('No Drivers Worksheets')
 *    }
 *
 * @param currentDimensionValues - The currently selected dimension values
 * @param variantCombinations - All variant dimension value combos available for a model
 */
export const getAllAvailableDimensionsValues = (
  currentDimensionValues: VariantCombination,
  variantCombinations: VariantCombination[]
): AvailableDimensions => {
  const availableDimensions = Object.create(Object.prototype);

  for (const dimension in currentDimensionValues) {
    if (
      currentDimensionValues.hasOwnProperty(dimension) &&
      isVariantDimensionField(dimension)
    )
      availableDimensions[dimension] = getAvailableDimensionValues(
        dimension,
        currentDimensionValues,
        variantCombinations
      );
  }

  return availableDimensions;
};

/**
 * Get the value for a dimension given other selected dim values and variants available
 *
 * Given the selected values for other dimensions and the variants available for a
 * model, this function will return a set of the possible values for a dimension
 *
 * @param currentDimension - Name of dimension for which you wish to get values
 * @param currentDimensionValues - Current selected dimension values
 * @param variantCombinations - All variant dimension combinations available for a model
 */
export const getAvailableDimensionValues = (
  currentDimension: VariantDimensionField,
  currentDimensionValues: VariantCombination,
  variantCombinations: VariantCombination[]
): Set<VariantDimensionValue> => {
  const otherDimensions: VariantDimensionField[] = [];

  for (const dimension in currentDimensionValues) {
    if (
      currentDimensionValues.hasOwnProperty(dimension) &&
      isVariantDimensionField(dimension)
    )
      if (dimension !== currentDimension) otherDimensions.push(dimension);
  }

  return new Set(
    variantCombinations
      .filter((combo) => {
        return otherDimensions.every(
          (dimension) => combo[dimension] === currentDimensionValues[dimension]
        );
      })
      .map((value) => value[currentDimension])
  );
};

/**
 * Get a resource variant for the given variant dimension values
 *
 * @param variants - List of variants for resource
 * @param dimensions - Variant dimension values
 */
export const getVariantByDimensions = <V extends VariantForGvdb>(
  variants: V[],
  dimensions: VariantCombination
): V | null => {
  return (
    variants.filter((variant) => {
      return Object.keys(ORIGINAL_VARIANT_COMBO).every((key) => {
        return isVariantDimensionField(key) && dimensions[key] === variant[key];
      });
    })[0] || null
  );
};
