import React from "react";
import { ReactComponent as CanalystLogo } from "../assets/logos/logo.svg";
import NavMenu from "./NavMenu";
import Steps from "./Steps";
import "./TopNavMinimal.scss";

interface TopNavMinimalProps {
  title: string;
  totalSteps: number;
  currentStep: number;
}

const TopNavMinimal: React.FC<TopNavMinimalProps> = ({
  title,
  totalSteps,
  currentStep,
}) => {
  return (
    <div className="TopNavMinimal">
      <span className="TopNavMinimal__left-section">
        <CanalystLogo />
      </span>
      <div className="TopNavMinimal__heading">
        <h2>{title}</h2>
        <Steps total={totalSteps} position={currentStep} />
      </div>
      <div className="TopNavMinimal__right-section">
        <div className="TopNavMinimal__nav">
          <NavMenu isUserSetup={true} />
        </div>
      </div>
    </div>
  );
};

export default TopNavMinimal;
