import UpdatePassword from "../../user_settings/update_password/UpdatePassword";
import { noop } from "../../utils/functools";

/**
 * Force the user to change their password. After they've done this, do a page
 * refresh as the user needs to log back in.
 *
 * This task is assigned to a user dynamically on the backend. Once the user
 * changes their password (e.g. away from a known insecure one) the task will be
 * considered completed by the backend.
 */
const PasswordChange: React.FC = () => {
  return (
    <UpdatePassword setBreadcrumbs={noop}>
      <p>
        We've recently upgraded our password requirements in accordance with
        security best practices. Please change your password before continuing.
      </p>
    </UpdatePassword>
  );
};

export default PasswordChange;
