import { Form, FormikProps } from "formik";
import React from "react";
import Button, { ButtonStyle, ButtonType } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import { DropdownChoice } from "../../common/Dropdown";
import VariantDimensionsDropdowns from "../../common/VariantDimensionsDropdowns";
import { ChoicesToVariantCombination } from "../../common/variants";
import FormError from "../../forms/FormError";
import routes from "../../utils/routes";
import VariantPreferenceExampleButton from "./VariantPreferenceExampleButton";

interface VariantPreferenceFormProps {
  formikProps: FormikProps<VariantPreferenceFromStateType>;
  mutationError?: string;
  djangoGlobalPerms: string[];
}

export type VariantPreferenceFromStateType = {
  periodOrder: DropdownChoice;
  driversWorksheets: DropdownChoice;
  theme: DropdownChoice;
};

const VariantPreferenceForm: React.FC<VariantPreferenceFormProps> = ({
  formikProps,
  mutationError,
  djangoGlobalPerms,
}) => {
  return (
    <div className="VariantPreferenceForm">
      <Form>
        <VariantDimensionsDropdowns djangoGlobalPerms={djangoGlobalPerms} />
        {mutationError && <FormError errorText={mutationError} />}
        <ButtonGroup>
          <Button style={ButtonStyle.TegusPrimary} type={ButtonType.Submit}>
            Update Default Format
          </Button>
          <VariantPreferenceExampleButton
            variantCombination={ChoicesToVariantCombination(formikProps.values)}
          />
          <Button
            linkTo={routes.settings}
            style={ButtonStyle.TegusSecondary}
            className="VariantPreferenceForm__cancel-button"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </Form>
    </div>
  );
};

export default VariantPreferenceForm;
