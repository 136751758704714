import { ApolloError } from "@apollo/client";
import { Formik } from "formik";
import React, {
  Context,
  useContext as useContextDefault,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { HOME_PAGE, Page } from "../common/Breadcrumbs";
import { DropdownChoice } from "../common/Dropdown";
import Spinner, { ColourType } from "../common/Spinner";
import {
  ContactUsCreateSupportRequestMutation,
  ModelUpdateType,
  RequestKind,
  useContactUsCreateSupportRequestMutation as useContactUsCreateSupportRequestMutationDefault,
} from "../generated/graphql";
import Card from "../layout/Card";
import ToastContext from "../toast/ToastContext";
import { ToastStyle } from "../toast/ToastDisplay";
import routes from "../utils/routes";
import "./ContactUs.scss";
import ContactUsForm from "./ContactUsForm";
import {
  FormError,
  getContactUsFormError as getContactUsFormErrorDefault,
} from "./utils";

export const CONTACT_US_PAGES = [
  HOME_PAGE,
  {
    isCurrent: true,
    nameOrIcon: "Contact Us",
  },
];

interface SubjectChoice extends DropdownChoice {
  id: RequestKind;
}
export interface UpdateReasonChoice extends DropdownChoice {
  id: ModelUpdateType;
}
export interface ContactUsFormState {
  subject?: SubjectChoice;
  updateReason?: UpdateReasonChoice;
  company: string;
  comments: string;
  formError: FormError | undefined;
}

interface ContactUsProps {
  setBreadcrumbs: (value: Page[]) => void;
}
interface ContactUsDeps {
  getContactUsFormError?: typeof getContactUsFormErrorDefault;
  useContext?: <T>(context: Context<T>) => T;
  useCreateSupportRequestMutation?: typeof useContactUsCreateSupportRequestMutationDefault;
}

const ContactUs: React.FC<ContactUsProps & ContactUsDeps> = ({
  setBreadcrumbs,
  getContactUsFormError = getContactUsFormErrorDefault,
  useContext = useContextDefault,
  useCreateSupportRequestMutation:
    useContactUsCreateSupportRequestMutation = useContactUsCreateSupportRequestMutationDefault,
}) => {
  useEffect(() => {
    setBreadcrumbs(CONTACT_US_PAGES);
  }, [setBreadcrumbs]);
  const history = useHistory();
  const { setToast } = useContext(ToastContext);
  const onError = (error: ApolloError) => {
    setToast({
      style: ToastStyle.Error,
      text: "Uh oh. There was an issue sending the support request email.",
    });
  };
  const onCompleted = (data: ContactUsCreateSupportRequestMutation) => {
    history.push(routes.home);
    setToast({
      style: ToastStyle.Info,
      text: "Support request successfully sent",
    });
  };
  const [sendRequest, result] = useContactUsCreateSupportRequestMutation({
    onCompleted: onCompleted,
    onError: onError,
  });

  return (
    <div className="ContactUs">
      <Formik<ContactUsFormState>
        initialValues={{
          subject: undefined,
          updateReason: undefined,
          company: "",
          comments: "",
          formError: undefined,
        }}
        onSubmit={(values, actions) => {
          const errorMessage = getContactUsFormError(values);
          if (errorMessage) {
            actions.setErrors({
              formError: errorMessage,
            });
          } else {
            setToast({
              style: ToastStyle.Info,
              text: "Sending support request…",
            });
            sendRequest({
              variables: {
                companyOrTicker: values.company,
                requestBody: values.comments,
                modelUpdateType: values.updateReason?.id,
                // The error checking in getContactUsFormError should ensure that values.subject is not undefined
                requestType: values!.subject!.id,
              },
            });
          }
        }}
      >
        {(formikProps) => {
          if (result.called && result.loading) {
            return (
              <div className="ContactUs__loading">
                <Spinner
                  colour={ColourType.SECONDARY}
                  size="3rem"
                  data-testid="loading"
                />
              </div>
            );
          } else {
            return (
              <Card title="Contact Us" tegus>
                <ContactUsForm
                  values={formikProps.values}
                  errors={formikProps.errors}
                />
              </Card>
            );
          }
        }}
      </Formik>
    </div>
  );
};
export default ContactUs;
