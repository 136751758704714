import React, { ChangeEventHandler } from "react";
import "./Checkbox.scss";

interface CheckboxProps {
  value: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: ChangeEventHandler;
  testId?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ value, testId, ...props }) => {
  const id = props.checked ? "checked" : "unchecked";

  return (
    <div className="Checkbox" data-testid={id}>
      <label data-testid={testId ? testId : "checkbox"}>
        <input type="checkbox" className="Checkbox__css-checkbox" {...props} />
        <i></i>
        <span className="Checkbox__checkbox-label">{value}</span>
      </label>
    </div>
  );
};

export default Checkbox;
