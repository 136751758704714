import React from "react";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import routes from "../../utils/routes";

interface ApiTokensDisplayTokenProps {
  token: string;
}

const ApiTokensDisplayToken: React.FC<ApiTokensDisplayTokenProps> = ({
  token,
}) => {
  return (
    <div className="ApiTokensDisplayToken">
      <div className="ApiTokensDisplayToken__help-text" data-testid="help-text">
        <p>
          Save this token and keep it somewhere safe. It will only be shown
          once.
        </p>
      </div>
      <div className="ApiTokensDisplayToken__token" data-testid="api-token">
        <p>
          <code>{token}</code>
        </p>
      </div>
      <ButtonGroup>
        <Button
          linkTo={routes.settingsApiTokens}
          style={ButtonStyle.TegusPrimary}
        >
          Done
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ApiTokensDisplayToken;
