import { ApolloCache } from "@apollo/client";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import React, {
  Context,
  KeyboardEvent,
  useContext as useContextDefault,
  useState,
} from "react";
import Button, {
  ButtonState,
  ButtonStyle,
  ButtonType,
} from "../../../common/buttons/Button";
import ButtonGroup from "../../../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../../../common/Spinner";
import MultiSelectList, {
  MultiSelectListItem,
} from "../../../forms/MultiSelectList/MultiSelectList";
import {
  useWatchListModalUpdateWatchListMutation,
  useWatchListModalUserSearchQuery,
  useWatchListModalUsersWatchingQuery,
  WatchListModalUpdateWatchListMutation,
  WatchListModalUsersWatchingDocument,
} from "../../../generated/graphql";
import ModalContext, { ModalContextValue } from "../../../modal/ModalContext";
import ToastContext, { ToastContextValue } from "../../../toast/ToastContext";
import { ToastStyle } from "../../../toast/ToastDisplay";
import { getErrorMessage } from "../../../utils/errorMessage";
import { ResourceForDl } from "../../../utils/variants";
import "./WatchListModal.scss";

// End-user visible strings
const TEXT_DESCRIPTION = "Configure users watching '{file}':";
const TEXT_LOADING = "Loading watchlist...";
const TEXT_UPDATE = "Update watchlist";
const TEXT_SAVING = "Updating watchlist...";
const TEXT_SEARCH = "Search for users...";
const TEXT_NO_USERS_SELECTED =
  "No users selected. Search for a user to select using the search bar above.";
const TEXT_NO_SEARCH_RESULTS = "No users found matching search criteria.";
const TEXT_ADD = "Add {name} to the watchlist";
const TEXT_REMOVE = "Remove {name} from the watchlist";
const TEXT_CANCEL = "Cancel";
const TEXT_CLOSE = "Close";

const ERROR_MESSAGE = "Something went wrong when retrieving the watchlist.";

// Toast messages
const TEXT_SAVE_SUCCESS = "Watchlist updated successfully";
const TEXT_SAVE_FAILURE = "Failed to update watchlist";

interface WatchListState {
  users: MultiSelectListItem[];
}

export interface WatchListModalProps {
  resource: ResourceForDl;
}

export interface WatchListModalDeps {
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
  useToastContext?: (context: Context<ToastContextValue>) => ToastContextValue;
}

/**
 * Content of the 'Watchlist' modal
 *
 * @param resource Resource to edit watchlist for
 */
const WatchListModal: React.FC<WatchListModalProps & WatchListModalDeps> = ({
  resource,
  useModalContext = useContextDefault,
  useToastContext = useContextDefault,
}) => {
  const { setToast } = useToastContext(ToastContext);
  const { clearModal } = useModalContext(ModalContext);

  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);

  const { data, loading, error } = useWatchListModalUsersWatchingQuery({
    variables: {
      id: resource.id,
    },
  });
  const [updateWatchListMutation, updateWatchListMutationResult] =
    useWatchListModalUpdateWatchListMutation({
      onCompleted: () => handleCompleted(),
      onError: () => handleError(),
      update: (cache, { data }) => handleUpdate(cache, data),
    });

  // Handle update after mutation called
  const handleUpdate = (
    cache: ApolloCache<WatchListModalUpdateWatchListMutation>,
    data: WatchListModalUpdateWatchListMutation | null | undefined
  ) => {
    cache.writeQuery({
      query: WatchListModalUsersWatchingDocument,
      data: data?.updateWatchList,
      variables: {
        id: resource.id,
      },
      overwrite: true,
    });
  };

  // Handle mutation completed
  const handleCompleted = () => {
    setToast({
      style: ToastStyle.Info,
      text: TEXT_SAVE_SUCCESS,
    });
    clearModal();
  };

  // Handle mutation error
  const handleError = () => {
    setToast({
      style: ToastStyle.Error,
      text: TEXT_SAVE_FAILURE,
    });
  };

  // Handle form submit
  const _submit = async (values: WatchListState) => {
    updateWatchListMutation({
      variables: {
        resourceId: resource.id,
        userIds: values.users.map((user) => {
          return user.value;
        }),
      },
    });
  };

  // Loading page
  if (loading || updateWatchListMutationResult.loading) {
    return (
      <div className="WatchListModal">
        <div className="WatchListModal__frame">
          <Spinner colour={ColourType.SECONDARY} size="3rem" />
          <div className="WatchListModal__loading-text">
            {loading && <>{TEXT_LOADING}</>}
            {updateWatchListMutationResult.loading && <>{TEXT_SAVING}</>}
          </div>
        </div>
      </div>
    );
  }

  // Error page
  if (error || !data?.results) {
    return (
      <div className="WatchListModal">
        <div className="WatchListModal__frame">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            style={{
              fontSize: "3rem",
            }}
          />
          <div className="WatchListModal__error-msg">
            {getErrorMessage(ERROR_MESSAGE, true)}
          </div>
        </div>
        <Button
          id="close"
          style={ButtonStyle.TegusSecondary}
          action={clearModal}
        >
          {TEXT_CLOSE}
        </Button>
      </div>
    );
  }

  // Main modal content
  return (
    <div className="WatchListModal">
      <Formik
        initialValues={{ users: data?.results as MultiSelectListItem[] }}
        onSubmit={_submit}
      >
        {() => (
          <Form onKeyPress={onKeyPress}>
            <p id="description">
              {TEXT_DESCRIPTION.replace("{file}", resource.name)}
            </p>
            <div className="WatchListModal__body">
              <div className="WatchListModal__user-list">
                <MultiSelectList
                  name="users"
                  queryHook={useWatchListModalUserSearchQuery}
                  onSave={() => {
                    setSaveEnabled(true);
                  }}
                  rowIcon={faUser}
                  customText={{
                    search: TEXT_SEARCH,
                    noItemsSelected: TEXT_NO_USERS_SELECTED,
                    noSearchResults: TEXT_NO_SEARCH_RESULTS,
                    addItem: TEXT_ADD,
                    removeItem: TEXT_REMOVE,
                  }}
                  objectId={resource.id}
                />
              </div>
            </div>
            <ButtonGroup>
              <Button
                id="cancel"
                style={ButtonStyle.TegusSecondary}
                action={clearModal}
              >
                {TEXT_CANCEL}
              </Button>
              <Button
                id="save"
                style={ButtonStyle.TegusPrimary}
                type={ButtonType.Submit}
                state={!saveEnabled ? ButtonState.Disabled : undefined}
              >
                {TEXT_UPDATE}
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/**
 * Disable form submit on enter key pressed in MultiSelectList search bar
 */
const onKeyPress = (keyEvent: KeyboardEvent) => {
  if (keyEvent.key === "Enter") {
    keyEvent.preventDefault();
  }
};

export default WatchListModal;
