import { gql } from "@apollo/client";
import { GraphQlObject } from "../../common/graphql";

export interface CreatePlaceholderRequestVars {
  placeholderId: string;
}

export interface CreatePlaceholderRequestReturnType extends GraphQlObject {
  success: boolean;
}

export const MODEL_REQUEST_MUTATION = gql`
  mutation createModelPlaceholderRequest($placeholderId: ID!) {
    createPlaceholderRequest(placeholderId: $placeholderId) {
      success
      __typename
    }
  }
`;
