import { useApolloClient } from "@apollo/client";
import React, {
  Context,
  useContext as useContextDefault,
  useState,
} from "react";
import Button, {
  ButtonState,
  ButtonStyle,
} from "../../../common/buttons/Button";
import ButtonGroup from "../../../common/buttons/ButtonGroup";
import Checkbox from "../../../common/Checkbox";
import Spinner, { ColourType } from "../../../common/Spinner";
import { useDeleteResourceModalDeleteResourceFileMutation } from "../../../generated/graphql";
import ModalContext, { ModalContextValue } from "../../../modal/ModalContext";
import ToastContext, { ToastContextValue } from "../../../toast/ToastContext";
import { ToastStyle } from "../../../toast/ToastDisplay";
import { ResourceForDl } from "../../../utils/variants";
import "./DeleteResourceModal.scss";

// End-user visible strings
const TEXT_DESCRIPTION = "Are you sure you want to delete '{name}'?";
const TEXT_CONFIRM = "Yes, delete '{name}'";
const TEXT_DELETE = "Delete resource file";
const TEXT_DELETING = "Deleting resource file...";
const TEXT_CANCEL = "Cancel";

// Toast messages
const TEXT_SAVE_SUCCESS = "Deleted resource '{name}' successfully";
const TEXT_SAVE_FAILURE = "Failed to delete resource '{name}'";

export interface DeleteResourceModalProps {
  resourceFile: ResourceForDl;
}

export interface DeleteResourceModalDeps {
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
  useToastContext?: (context: Context<ToastContextValue>) => ToastContextValue;
}

/**
 * Content of the 'Delete Resource File' modal
 *
 * @param resource Resource file to delete
 */
const DeleteResourceModal: React.FC<
  DeleteResourceModalProps & DeleteResourceModalDeps
> = ({
  resourceFile,
  useModalContext = useContextDefault,
  useToastContext = useContextDefault,
}) => {
  const { setToast } = useToastContext(ToastContext);
  const { clearModal } = useModalContext(ModalContext);

  const client = useApolloClient();

  const [deleteEnabled, setDeleteEnabled] = useState<boolean>(false);

  const [deleteResourceFileMutation, deleteResourceFileMutationResult] =
    useDeleteResourceModalDeleteResourceFileMutation({
      onCompleted: () => handleCompleted(),
      onError: () => handleError(),
    });

  // Handle mutation completed
  const handleCompleted = async () => {
    client.cache.evict({ id: `Resource:${resourceFile.id}` });
    setToast({
      style: ToastStyle.Info,
      text: TEXT_SAVE_SUCCESS.replace("{name}", resourceFile.name),
    });
    clearModal();
  };

  // Handle mutation error
  const handleError = () => {
    setToast({
      style: ToastStyle.Error,
      text: TEXT_SAVE_FAILURE.replace("{name}", resourceFile.name),
    });
  };

  // Handle confirm checkbox
  const handleCheckbox = () => {
    setDeleteEnabled(!deleteEnabled);
  };

  // Handle delete button
  const handleDelete = async () => {
    deleteResourceFileMutation({
      variables: {
        id: resourceFile.id,
      },
    });
  };

  // Loading page
  if (deleteResourceFileMutationResult.loading) {
    return (
      <div className="DeleteResourceModal">
        <div className="DeleteResourceModal__frame">
          <Spinner colour={ColourType.SECONDARY} size="3rem" />
          <div className="DeleteResourceModal__loading-text">
            {TEXT_DELETING}
          </div>
        </div>
      </div>
    );
  }

  // Main modal content
  return (
    <div className="DeleteResourceModal">
      <p id="description">
        {TEXT_DESCRIPTION.replace("{name}", resourceFile.name)}
      </p>
      <div className="DeleteResourceModal__body">
        <Checkbox
          onChange={handleCheckbox}
          value={TEXT_CONFIRM.replace("{name}", resourceFile.name)}
          checked={deleteEnabled}
        ></Checkbox>
      </div>
      <ButtonGroup>
        <Button
          id="cancel"
          style={ButtonStyle.TegusSecondary}
          action={clearModal}
        >
          {TEXT_CANCEL}
        </Button>
        <Button
          id="delete"
          style={ButtonStyle.TegusPrimary}
          action={handleDelete}
          state={!deleteEnabled ? ButtonState.Disabled : undefined}
          role="button"
        >
          {TEXT_DELETE}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default DeleteResourceModal;
