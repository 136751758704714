import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useField as useFieldDefault } from "formik";
import React, {
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useState,
} from "react";
import { BaseFieldProps } from "./BaseField";
import FormErrorSpacer from "./FormErrorSpacer";
import "./TextField.scss";

export enum TextFieldType {
  INPUT = "input",
  AREA = "textarea",
}

type TextFieldHtmlProps =
  | InputHTMLAttributes<HTMLElement>
  | TextareaHTMLAttributes<HTMLElement>;
export type TextFieldProps = TextFieldHtmlProps & {
  fieldType?: TextFieldType;
  maxCharCount?: number;
};
interface ShowPasswordButtonProps {
  showPassword?: boolean;
  toggleShowPassword?: (showPassword: boolean) => void;
}

const TextField: React.FC<
  BaseFieldProps & TextFieldProps & ShowPasswordButtonProps
> = ({
  useField = useFieldDefault,
  fieldType = TextFieldType.INPUT,
  maxCharCount,
  showPassword,
  toggleShowPassword,
  ...props
}) => {
  const [charCount, setCharCount] = useState(0);
  const [field, meta] = useField(props.name);
  const charCountError = maxCharCount != null && charCount > maxCharCount;
  const hasError = (meta.touched && meta.error) || charCountError;
  const fieldNames = classNames("TextField", {
    "TextField--invalid": hasError,
  });
  const charCountClassNames = classNames("TextField__charlimit-count", {
    "TextField__charlimit-count--error": charCountError,
  });
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCharCount(e.target.value.length);
  };
  /**
   * Handles input type state for password fields
   *
   * When this component is used as a password input field the existing prop for
   * the input type must be overridden.
   *
   * For all other cases props.type is used
   * @returns string
   */
  const inputType = (): string => {
    if (showPassword === undefined) {
      return props.type;
    }
    if (showPassword) {
      return "text";
    }
    return "password";
  };

  const inputHtml =
    fieldType === TextFieldType.AREA ? (
      <textarea
        onInput={onInputChange}
        autoComplete="off"
        {...field}
        {...props}
      />
    ) : (
      <div className="TextField__wrapper">
        <input
          onInput={onInputChange}
          autoComplete="off"
          {...field}
          {...props}
          type={inputType()}
        />
        <ShowPasswordButton {...{ showPassword, toggleShowPassword }} />
      </div>
    );

  return (
    <div className={fieldNames}>
      <div className="TextField__header">
        <label className="TextField__label" htmlFor={props.name}>
          {props.label}
          {props.required ? (
            <span className="TextField__label-asterix" data-testid="required">
              *
            </span>
          ) : null}
        </label>
        {maxCharCount ? (
          <div className="TextField__charlimit">
            <span className={charCountClassNames}>{charCount}</span>
            <span className="TextField__charlimit-max">
              /{maxCharCount.toString()}
            </span>
          </div>
        ) : null}
      </div>
      {inputHtml}
      {hasError ? (
        <div className="TextField__error" data-testid="error">
          <span className="TextField__error-message">{meta.error}</span>
        </div>
      ) : (
        <FormErrorSpacer />
      )}
    </div>
  );
};

/**
 * Used for displaying an eye/show password button next to an input field
 *
 * This component is tightly coupled to TextField and passes click events to
 * the component using the TextField.
 *
 * The state is shared with the parent component to allow multiple TextFields to
 * share the same input type state.
 *
 * Example use case is New Password and New Password Confirmed fields. Clicking
 * one of the show password buttons will change the input type on both fields
 * from "password" to "text" to reveal the password.
 */
const ShowPasswordButton: React.FC<ShowPasswordButtonProps> = ({
  showPassword,
  toggleShowPassword,
}) => {
  if (showPassword === undefined) {
    return <></>;
  }
  return (
    <button
      className="TextField__show-password-button"
      tabIndex={-1}
      type="button"
      onClick={() =>
        toggleShowPassword ? toggleShowPassword(!showPassword) : null
      }
    >
      {showPassword ? (
        <FontAwesomeIcon icon={faEyeSlash} />
      ) : (
        <FontAwesomeIcon icon={faEye} />
      )}
    </button>
  );
};

export default TextField;
