import { useApolloClient } from "@apollo/client";
import React, { Context, useContext, useState } from "react";
import Button, { ButtonState, ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Checkbox from "../../common/Checkbox";
import Spinner, { ColourType } from "../../common/Spinner";
import { useApiTokensDeleteDeleteTokenMutation } from "../../generated/graphql";
import ModalContext, { ModalContextValue } from "../../modal/ModalContext";
import ToastContext, { ToastContextValue } from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import "./ApiTokensDelete.scss";

//End-user visible strings
const TEXT_DESCRIPTION = "Are you sure you want to delete '{name}'?";
const TEXT_CONFIRM = "Yes, delete '{name}'";
const TEXT_DELETE = "Delete API Token";
const TEXT_DELETING = "Deleting API token...";
const TEXT_CANCEL = "Cancel";

// Toast messages
const TEXT_SAVE_SUCCESS = "Deleted API token '{name}' successfully";
const TEXT_SAVE_FAILURE = "Failed to delete API token '{name}'";

//PREFIX ID
const PREFIX = "ApiToken:";

export interface DeleteAPITokensProps {
  apiTokens: string;
  description: string;
}

export interface DeleteAPITokensModalDeps {
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
  useToastContext?: (context: Context<ToastContextValue>) => ToastContextValue;
  useDeleteApiToken?: typeof useApiTokensDeleteDeleteTokenMutation;
}

const ApiTokensDelete: React.FC<
  DeleteAPITokensProps & DeleteAPITokensModalDeps
> = ({
  apiTokens,
  description,
  useModalContext = useContext,
  useToastContext = useContext,
  useDeleteApiToken = useApiTokensDeleteDeleteTokenMutation,
}) => {
  const { setToast } = useToastContext(ToastContext);
  const { clearModal } = useModalContext(ModalContext);

  const [deleteEnabled, setDeleteEnabled] = useState<boolean>(false);
  const client = useApolloClient();

  // Handle mutation completed
  const handleCompleted = async () => {
    client.cache.evict({
      id: PREFIX + apiTokens,
    });
    client.cache.gc();

    setToast({
      style: ToastStyle.Info,
      text: TEXT_SAVE_SUCCESS.replace("{name}", description),
    });
    clearModal();
  };

  // Handle mutation error
  const handleError = async () => {
    setToast({
      style: ToastStyle.Error,
      text: TEXT_SAVE_FAILURE.replace("{name}", description),
    });
  };

  const [deleteApiToken, { loading }] = useDeleteApiToken({
    onError: () => {
      handleError();
    },
    onCompleted: () => {
      handleCompleted();
    },
  });
  // Handle confirm checkbox
  const handleCheckbox = () => {
    setDeleteEnabled(!deleteEnabled);
  };

  //when loading data
  if (loading) {
    return (
      <div title="loading" className="ApiTokensDelete">
        <div className="ApiTokensDelete__frame">
          <Spinner colour={ColourType.SECONDARY} size="3rem" />
          <div className="ApiTokensDelete__loading-text">{TEXT_DELETING}</div>
        </div>
      </div>
    );
  }

  // Handle delete button
  const handleDelete = async () => {
    //graphql mutation delete api tokens
    await deleteApiToken({
      variables: {
        id: apiTokens,
      },
    });
  };
  return (
    <div title="ApiTokensDelete" className="ApiTokensDelete">
      <p id="description">{TEXT_DESCRIPTION.replace("{name}", description)}</p>
      <div className="ApiTokensDelete__body">
        <Checkbox
          onChange={handleCheckbox}
          value={TEXT_CONFIRM.replace("{name}", description)}
          checked={deleteEnabled}
        ></Checkbox>
      </div>
      <ButtonGroup>
        <Button
          title="cancel"
          id="cancel"
          style={ButtonStyle.TegusSecondary}
          action={clearModal}
        >
          {TEXT_CANCEL}
        </Button>
        <Button
          title="delete"
          id={"delete" + apiTokens}
          style={ButtonStyle.TegusPrimary}
          action={handleDelete}
          state={!deleteEnabled ? ButtonState.Disabled : undefined}
        >
          {TEXT_DELETE}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ApiTokensDelete;
