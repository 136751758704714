import React from "react";
import "./FormError.scss";
import FormErrorSpacer from "./FormErrorSpacer";

export interface FormErrorProps {
  errorText?: string;
}
const FormError: React.FC<FormErrorProps> = ({ errorText }) => {
  if (errorText)
    return (
      <div className="FormError" data-testid="error">
        <span className="FormError__message">{errorText}</span>
      </div>
    );
  return <FormErrorSpacer />;
};
export default FormError;
