import React, { useContext as useContextDefault, useMemo } from "react";
import { useHistory as useHistoryDefault } from "react-router-dom";
import {
  Resource,
  ResourceVariant,
  UserForDlFragment,
} from "../generated/graphql";
import DownloadCustomVariant from "../home/common/DownloadCustomVariant";
import { getVariantByDimensions } from "../home/common/utils/variants";
import ModalContext from "../modal/ModalContext";
import { useDownload as useDownloadDefault } from "../utils/location";

const CUSTOM_VARIANT_MODAL_TITLE = "Default Format Not Available";

export type VariantForDl = Pick<
  ResourceVariant,
  "id" | "periodOrder" | "driversWorksheets" | "theme" | "downloadUrl"
>;

export type ResourceForDl = Pick<
  Resource,
  "id" | "name" | "variantsExpected" | "downloadUrl" | "__typename"
> & {
  variants: VariantForDl[];
};

export type UserForDl = UserForDlFragment;

/**
 * Gets the user's preferred variant from a list of variants
 *
 * The user's preferred variant is determined by the variantPreference field on a user
 * object. This ensures it stays up to date in the cache when a user updates their
 * preference
 */
export const getUserPreferred = <V extends VariantForDl>(
  variants: V[],
  user: UserForDl
): V | null => {
  return getVariantByDimensions(variants, user.variantPreference);
};

/**
 * Gets the appropriate download URL for a resource. Follows this logic
 *   1. If variants are not expected, return the default download URL
 *   2. Otherwise, return the URL for the user's preferred variant if available
 */
export const getResourceDownloadUrl = (
  resource: ResourceForDl,
  user: UserForDl
): string | null => {
  if (!resource.variantsExpected) return resource.downloadUrl;
  return getUserPreferred(resource.variants, user)?.downloadUrl || null;
};

/**
 * A hook that provides a download action for a resource. It will either:
 *   1. If variants are not expected, download the default variant
 *   2. Otherwise, if the user's preferred variant is available, download that
 *   3. If it isn't present a modal that allows downloading another variant
 */
export const useDownloadAction = (
  getResourceDownloadUrl_ = getResourceDownloadUrl,
  useContext = useContextDefault,
  useDownload = useDownloadDefault,
  useHistory = useHistoryDefault
): ((
  resource: ResourceForDl,
  user: UserForDl,
  latest_version?: boolean
) => void) => {
  const { setModal } = useContext(ModalContext);
  const download = useDownload();
  const history = useHistory();

  return useMemo(() => {
    return (
      resource: ResourceForDl,
      user: UserForDl,
      latest_version: boolean = false
    ) => {
      const sourceQueryArgs = history.location.search.match(/source=.+/);
      const downloadUrl = getResourceDownloadUrl_(resource, user);
      const latestVersionArgs = "latest_version=" + latest_version;

      if (sourceQueryArgs !== null && downloadUrl !== null) {
        const queryChar = downloadUrl.includes("?") ? "&" : "?";
        download(
          downloadUrl + queryChar + sourceQueryArgs + "&" + latestVersionArgs
        );
        return;
      }
      if (downloadUrl !== null) {
        const queryChar = downloadUrl.includes("?") ? "&" : "?";
        download(downloadUrl + queryChar + latestVersionArgs);
        return;
      }
      setModal({
        title: CUSTOM_VARIANT_MODAL_TITLE,
        content: <DownloadCustomVariant resource={resource} user={user} />,
      });
    };
  }, [history.location.search, getResourceDownloadUrl_, setModal, download]);
};
