import { faFileDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import React, { useMemo } from "react";
import Moment from "react-moment";
import Action from "../../common/Action";
import FancyListItem, { TEXT_SEPARATOR } from "../../common/FancyListItem";
import ContextMenu from "../../context_menu/ContextMenu";
import { VersionResourceFragment } from "../../generated/graphql";
import { HOUR_IN_MS } from "../../utils/datetime";
import { FA_ICON_DEFAULT_STYLE, getIconComponent } from "../../utils/icons";
import { useDownloadAction, UserForDl } from "../../utils/variants";
import { useCopyShareLink } from "../browse/utils/sharing";
import "./VersionsElem.scss";

interface VersionsElemProps {
  version: VersionResourceFragment;
  user: UserForDl;
  startCursor?: string;
  endCursor?: string;
}

interface VersionsElemDeps {
  useDownloadAction_?: typeof useDownloadAction;
  useCopyShareLink_?: typeof useCopyShareLink;
}

const VersionsElem: React.FC<VersionsElemProps & VersionsElemDeps> = ({
  version,
  user,
  useDownloadAction_ = useDownloadAction,
  useCopyShareLink_ = useCopyShareLink,
}) => {
  const download = useDownloadAction_();
  const copyShareLink = useCopyShareLink_(false);

  const subtext = useMemo(() => {
    const versionSubtext = getVersionSubtext(version);
    const updatedSubtext = getUpdateSubtext(version);
    const subtext = version.version
      ? [versionSubtext, updatedSubtext]
      : [updatedSubtext];

    return subtext;
  }, [version]);

  const secondaryActions = [
    {
      icon: (
        <FontAwesomeIcon icon={faFileDownload} style={FA_ICON_DEFAULT_STYLE} />
      ),
      id: "version-download",
      action: () => download(version, user),
      tooltipText: "Download",
    },
  ];

  return (
    <ContextMenu
      items={
        <>
          <Action
            action={() => copyShareLink(version)}
            data-testid="action-copy-link"
          >
            Copy Link
          </Action>
        </>
      }
    >
      <Tippy
        content={<Moment format="LLLL">{version.created}</Moment>}
        placement="top"
        delay={[500, 0]} // 500ms delay to show, 0ms delay to hide
      >
        <FancyListItem
          icon={getIconComponent(version.icon)}
          text={version.name}
          subtext={subtext}
          action={() => download(version, user)}
          secondaryActions={secondaryActions}
        />
      </Tippy>
    </ContextMenu>
  );
};

const getVersionSubtext = (
  version: VersionResourceFragment
): React.ReactNode => {
  const versionStr = version.version;
  return (
    <>
      {versionStr && `Version ${versionStr}`}
      {version.mostRecentQuarter && (
        <>
          {TEXT_SEPARATOR}
          {version.mostRecentQuarter}
        </>
      )}
      {version.updateTypeDisplay && (
        <>
          {TEXT_SEPARATOR}
          {version.updateTypeDisplay}
        </>
      )}
    </>
  );
};

const getUpdateSubtext = (
  version: VersionResourceFragment
): React.ReactNode => {
  return (
    <>
      Updated <Moment fromNowDuring={24 * HOUR_IN_MS}>{version.created}</Moment>
      {version.updatedBy.name && (
        <>
          {" by "}
          {version.updatedBy.name}
        </>
      )}
    </>
  );
};

export default VersionsElem;
