import { gql } from "@apollo/client";
import { GraphQlObject } from "../../common/graphql";

export interface ToggleWatchListData extends GraphQlObject {
  toggleWatchListItem: ToggleWatchListItem;
}

export interface ToggleWatchListItem extends GraphQlObject {
  watchListItem: WatchListItem;
}

export interface WatchListItem extends GraphQlObject {
  toggled: boolean;
}

export interface ToggleWatchListVars {
  resourceId: string;
}

export const TOGGLE_WATCH_LIST = gql`
  mutation ToggleWatchListItem($resourceId: ID!) {
    toggleWatchListItem(resourceId: $resourceId) {
      watchListItem {
        toggled
      }
    }
  }
`;
