import Cookies from "js-cookie";
import { getNextEncoded, redirect } from "./location";
import { features, Features } from "./settings";

const USER_JWT = Cookies.get("ca_access_token") || null;
const AUTHZ_JWT = Cookies.get("authz_access_token") || null;

// Use the user's "user" JWT, stored in cookie for auth
export const getUserJwt = (userJwt: string | null = USER_JWT) => userJwt;
export const getAuthzJwt = (authzJwt: string | null = AUTHZ_JWT) => authzJwt;

export const getAuthHeaders = ({
  userJwt = USER_JWT,
  authzJwt = AUTHZ_JWT,
  features_ = features,
}: {
  userJwt?: string | null;
  authzJwt?: string | null;
  features_?: Features;
} = {}) => {
  let headers = {};
  const token = features_.authzAuthentication
    ? getAuthzJwt(authzJwt)
    : getUserJwt(userJwt);
  if (token) {
    headers = {
      authorization: `Bearer ${token}`,
    };
  }
  return headers;
};

export const isAuthenticated = (userJwt: string | null = USER_JWT) => {
  return getUserJwt(userJwt) !== null;
};

// Sometimes it's useful (and fast) to get user data from the user JWT.
// This interface matches the data in the payload of a user JWT.
export interface UserFromJwt {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  company_name: string | null;
  is_superuser: boolean;
  is_staff: boolean;
  user_uuid: string;
}
export const getUserFromJwt = (userJwt: string | null = USER_JWT) => {
  const token = getUserJwt(userJwt);
  let user: UserFromJwt | null = null;
  let error = null;
  if (token) {
    try {
      user = JSON.parse(atob(token.split(".")[1]));
    } catch (err) {
      error = new Error(String(err));
    }
  } else {
    error = new Error("Cookie for user JWT not set");
  }
  return { user: user, error: error };
};

/**
 * Set a timeout that logs a user out after expirySeconds
 *
 * @param expirySeconds - The number of seconds to wait before logging a user out
 */
export const setLogoutTimeout = (
  expirySeconds: number,
  setTimeout_: typeof setTimeout = setTimeout,
  redirect_: typeof redirect = redirect
) => {
  setTimeout_(
    () => redirect_(`/logout${getNextEncoded()}`),
    expirySeconds * 1000
  );
};

export const setJwtTimeout = (
  jwtExpirySeconds: number | null,
  setLogoutTimeout_: typeof setLogoutTimeout = setLogoutTimeout
) => {
  if (jwtExpirySeconds)
    setLogoutTimeout_(
      // We subtract the seconds until expiry by 30 minutes as the JWT expiry, from
      // which we get tokenExpirySeconds is 25 hours and we want to log users out after
      // 24.5 hours
      jwtExpirySeconds - 30 * 60
    );
};

/**
 * Get user's initials from JWT user data
 *
 * @param user User data from JWT
 * @returns User's initials
 */
export const getUserInitials = (user: UserFromJwt) => {
  const { first_name: firstName, last_name: lastName } = user || {};
  return `${firstName ? firstName.substring(0, 1) : ""} ${
    lastName ? lastName.substring(0, 1) : ""
  }`;
};
