import { createTheme } from "@mui/material/styles";

export const COLOUR_TEAL = "#00838f";
export const COLOUR_TEAL_LIGHTER = "#daedee";
export const COLOUR_YELLOW = "#ffd200";

/**
 * Default MUI theme (light)
 */
export const THEME_DEFAULT = createTheme({
  palette: {
    primary: {
      main: COLOUR_TEAL,
    },
    action: {
      selected: COLOUR_TEAL,
    },
  },
});
