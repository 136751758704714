import { useField } from "formik";
import React from "react";
import Checkbox from "../../common/Checkbox";
import "./EmailRecentDownloadUpdate.scss";

interface EmailRecentDownloadUpdateProps {
  value: boolean;
}

const EmailRecentDownloadUpdate: React.FC<EmailRecentDownloadUpdateProps> = ({
  value,
}) => {
  const [, , helpers] = useField("emailRecentDownloadUpdate");

  return (
    <div className="EmailRecentDownloadUpdate">
      <h4 className="EmailRecentDownloadUpdate__title">
        Model Update Notifications
      </h4>
      <div className="EmailRecentDownloadUpdate__description">
        <span>
          Receive an email notification when a quarterly update is uploaded for
          a model you have recently downloaded.
        </span>
      </div>
      <div data-testid="email-recent-download-update-checkbox">
        <Checkbox
          value="Recent download updates"
          checked={value}
          onChange={() => helpers.setValue(!value)}
        />
      </div>
    </div>
  );
};

export default EmailRecentDownloadUpdate;
