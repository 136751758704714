import { wait } from "./wait";

const DEFAULT_WAIT_MS = 1000; // 1s
const DEFAULT_MAX_WAIT_MS = 30000; // 30s

/**
 * Polling function - call specified function on interval until exit condition satisfied
 *
 * Exit condition function must accept the response from the polled function and then
 * return false if polling should end
 *
 * @param fn Polled function - called on interval
 * @param fnCondition Exit condition function - stop polling if this returns false
 * @param ms Interval to call function (default: 1s)
 * @param maxMs Maximum time to poll for (default 30s)
 * @returns Final response from polled function
 */
export const poll: <T>(
  fn: (..._args: any[]) => T,
  fnCondition: (result: any) => boolean,
  ms?: number,
  maxMs?: number
) => Promise<T> = async (
  fn,
  fnCondition,
  ms = DEFAULT_WAIT_MS,
  maxMs = DEFAULT_MAX_WAIT_MS
) => {
  let result = await fn();
  let waitMs = 0;
  while (fnCondition(result) && waitMs < maxMs) {
    await wait(ms);
    waitMs += ms;
    result = await fn();
  }
  return result;
};
