import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import "./ToastDisplay.scss";

/**
 * The style of a toast notification
 * - Info is for general information (does not indicated a problem)
 * - Error is for warning or error information (indicates a problem)
 *
 * Warning is not available since these toasts would be yellow, and yellow is NOT used
 * to represent problems in our application (e.g. it is used as the primary button
 * colour). Error should be used for both warnings & errors. Using a simplified 2-colour
 * palette is less overwhelming for the user as well.
 */
export enum ToastStyle {
  Info = "ToastDisplay--info",
  Error = "ToastDisplay--error",
}

/**
 * Describes a toast item
 */
export interface Toast {
  style: ToastStyle;
  text: React.ReactNode;
}

/**
 * A toast notification. Try to keep text very short (one line). Do NOT end the text
 * string in a period. Exclamation points should be avoided but can be used at the end
 * if necessary.
 */
const ToastDisplay: React.FC<{ toast: Toast; clearToast: () => void }> = ({
  toast,
  clearToast,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Whenever the toast changes, the entry animation should be replayed. This is
    // essentially a no-op so is not covered by tests. Solution from here:
    // https://codepen.io/chriscoyier/pen/EyRroJ
    ref.current?.classList.remove("ToastDisplay--animate");
    void ref.current?.offsetWidth;
    ref.current?.classList.add("ToastDisplay--animate");
  }, [toast, ref]);

  return (
    <div
      className={`ToastDisplay ToastDisplay--animate ${toast.style}`}
      ref={ref}
    >
      <div className="ToastDisplay__text">{toast.text}</div>
      <div className="ToastDisplay__close-btn" onClick={clearToast}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );
};

export default ToastDisplay;
