import React, { useCallback, useRef, useState } from "react";
import { useEvent } from "../utils/events";
import "./ContextMenu.scss";

interface ContextMenuProps {
  items: React.ReactElement;
}

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
export const RIGHT_BUTTON = 2;

const ContextMenu: React.FC<ContextMenuProps> = ({ children, items }) => {
  const ref = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const [itemsVisible, setItemsVisible] = useState(false);
  const [itemsTop, setItemsTop] = useState("unset");
  const [itemsLeft, setItemsLeft] = useState("unset");

  // Right clicks on the wrapped element (children) should position and show the
  // context menu items. Normal clicks should cause the items to be hidden.
  const childrenOnMouseDown = useCallback(
    (ev: React.MouseEvent) => {
      /* istanbul ignore next */
      if (childrenRef.current === null) return;
      if (isRightClick(ev)) {
        // The menu will be shown directly below & to the right of the click
        const { top: viewportTop, left: viewportLeft } =
          childrenRef.current.getBoundingClientRect();
        const childrenTop = window.pageYOffset + viewportTop;
        const childrenLeft = window.pageXOffset + viewportLeft;
        setItemsTop(`${ev.pageY - childrenTop}px`);
        setItemsLeft(`${ev.pageX - childrenLeft}px`);

        setItemsVisible(true);
      } else {
        setItemsVisible(false);
      }
    },
    [childrenRef, setItemsTop, setItemsLeft, setItemsVisible]
  );

  // Any click outside of this component should cause the items to be hidden.
  useEvent(
    window,
    "mousedown",
    useCallback(
      (ev) => {
        if (ref.current === null || isElemClick(ev, ref.current)) return;
        setItemsVisible(false);
      },
      [ref, setItemsVisible]
    )
  );

  return (
    <div
      ref={ref}
      className="ContextMenu"
      // Prevent the default context menu from being shown
      onContextMenu={(ev) => ev.preventDefault()}
    >
      <div
        ref={childrenRef}
        className="ContextMenu__children"
        onMouseDown={childrenOnMouseDown}
      >
        {children}
      </div>
      {itemsVisible && (
        <div
          className="ContextMenu__items"
          style={{ top: itemsTop, left: itemsLeft }}
          onClick={() => setItemsVisible(false)}
        >
          {items}
        </div>
      )}
    </div>
  );
};

const isElemClick = (ev: MouseEvent | React.MouseEvent, elem: HTMLDivElement) =>
  elem.contains(ev.target as Node);
const isRightClick = (ev: MouseEvent | React.MouseEvent) =>
  ev.button === RIGHT_BUTTON;

export default ContextMenu;
