import { ApolloError } from "@apollo/client";
import { faFolderOpen } from "@fortawesome/pro-duotone-svg-icons";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, SxProps } from "@mui/material";
import { DataGridPro, GridFilterModel } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import React, { useCallback, useMemo } from "react";
import { MUI_X_LICENSE_KEY } from "../../constants/mui";
import { getErrorMessage } from "../../utils/errorMessage";
import { useDownloadAction } from "../../utils/variants";
import { COLUMNS } from "../common/columns";
import { Fields } from "../common/enums";
import { CompaniesRowData } from "../common/interfaces";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";
import { useResourceQuery } from "../common/utils";
import "./CompaniesTable.scss";
import CompaniesTableToolbar from "./CompaniesTableToolbar";

// License
LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY);

// Settings
const ROW_HEIGHT_PX = 36;
const HEADER_HEIGHT_PX = 50;
const ROW_BUFFER_SIZE = 30; // number of non-visible rows rendered
const COLUMN_BUFFER = 3;
const PINNED_COLUMNS = { right: [Fields.actions] };
const LOCALE_TEXT = {
  columnMenuSortAsc: STRINGS.table.sort.ascending,
  columnMenuSortDesc: STRINGS.table.sort.descending,
};

// MUI style overrides
const STYLE: SxProps = {
  border: 0,

  "& .MuiDataGrid-main": {
    fontSize: "1rem",
  },
  "& .MuiDataGrid-columnHeaders": {
    color: "#333",
    fontSize: "1.06rem",
    borderBottom: "0.07rem solid #ccc",
  },
  "& .MuiDataGrid-columnHeader:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-pinnedColumnHeaders": {
    borderTopRightRadius: "0.5rem",
  },
  "& .MuiDataGrid-columnSeparator": {
    opacity: "0 !important",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "0 !important",
    outline: "none !important",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-menuIcon": {
    mr: "0rem !important",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
    borderBottom: "0.06rem solid #ccc",
  },
  "& .MuiButton-root": {
    px: "0.75rem",
    borderRadius: "0.5rem",
  },
  "& .MuiIconButton-root": {
    color: "#00838f",
    fill: "#00838f",
    p: "0rem",
  },
};

export interface CompaniesTableProps {
  loading: boolean;
  error?: ApolloError;
  rows: CompaniesRowData[];
  numCompanies: number;
  filterModel: GridFilterModel;
  updateFilterModel: (filterModel: GridFilterModel) => any;
  updated: Date;
  updateNow: () => any;
  columnBuffer?: number;
}

export interface CompaniesTableDeps {
  useDownloadAction_?: typeof useDownloadAction;
  useResourceQuery_?: typeof useResourceQuery;
}

/**
 * Companies table
 *
 * @param loading Whether the companies data is loading
 * @param error Whether there was an error loading the data
 * @param rows The rows to display in the table (before filtering)
 * @param numCompanies Overall number of companies
 * @param filterModel The MUI grid filter model
 * @param updateFilterModel Callback to call when updating filter model
 * @param updated The last time the companies data was refreshed from the API
 * @param updateNow Callback to call to trigger companies data update
 * @param columnBuffer Number of columns to render outside visible area
 */
const CompaniesTable: React.FC<CompaniesTableProps & CompaniesTableDeps> = ({
  loading,
  error,
  rows,
  numCompanies,
  filterModel,
  updateFilterModel,
  updated,
  updateNow,
  columnBuffer = COLUMN_BUFFER,
  useDownloadAction_ = useDownloadAction,
  useResourceQuery_ = useResourceQuery,
}) => {
  const downloadResource = useResourceQuery_(useDownloadAction_());
  const downloadModel = useCallback(
    (params) => {
      downloadResource(params.row.id);
    },
    [downloadResource]
  );

  const components = useMemo(() => {
    return {
      Footer: () => {
        return (
          <CompaniesTableToolbar
            totalRows={numCompanies}
            updated={updated}
            updateNow={updateNow}
          />
        );
      },
      NoRowsOverlay: () => {
        return (
          <div className="CompaniesTable__overlay">
            <FontAwesomeIcon
              icon={faFolderOpen}
              style={{
                opacity: "0.25",
                transform: "scale(6)",
                transformOrigin: "center",
              }}
            />
            <div className="CompaniesTable__overlay-text">
              <div className="CompaniesTable__overlay-text-heading">
                {STRINGS.table.rows.noRowsHeading}
              </div>
              <div
                className="CompaniesTable__overlay-text-body"
                data-testid="no-rows-message"
              >
                {STRINGS.table.rows.noRowsBody}
              </div>
            </div>
          </div>
        );
      },
      LoadingOverlay: () => {
        return (
          <div className="CompaniesTable__loading">
            {!rows.length && (
              <div
                className="CompaniesTable__loading-description"
                data-testid="loading-message"
              >
                {STRINGS.table.loading.description}
              </div>
            )}
            <div
              className="CompaniesTable__loading-spinner"
              data-testid="loading-spinner"
            >
              <CircularProgress />
            </div>
          </div>
        );
      },
      ErrorOverlay: () => {
        return (
          <div className="CompaniesTable__overlay">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{
                opacity: "0.25",
                transform: "scale(6)",
                transformOrigin: "center",
              }}
            />
            <div className="CompaniesTable__overlay-text">
              <div
                className="CompaniesTable__overlay-text-body"
                data-testid="error-message"
              >
                {getErrorMessage(STRINGS.table.loading.error, true)}
              </div>
            </div>
          </div>
        );
      },
    };
  }, [numCompanies, rows.length, updateNow, updated]);

  return (
    <div className="CompaniesTable">
      <DataGridPro
        // Main
        sx={STYLE}
        rows={rows}
        loading={loading}
        error={error}
        // Columns
        columns={COLUMNS}
        pinnedColumns={PINNED_COLUMNS}
        disableColumnReorder={true}
        columnBuffer={columnBuffer}
        // Filtering
        filterModel={filterModel}
        onFilterModelChange={updateFilterModel}
        // Density
        rowHeight={ROW_HEIGHT_PX}
        headerHeight={HEADER_HEIGHT_PX}
        rowBuffer={ROW_BUFFER_SIZE}
        localeText={LOCALE_TEXT}
        // One-click download
        onRowClick={downloadModel}
        // Component overrides
        components={components}
        // Misc settings
        disableSelectionOnClick={true}
      />
    </div>
  );
};

const MemoizedTable = React.memo(CompaniesTable);

export default MemoizedTable;
