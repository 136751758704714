import { Context, useContext as useContextDefault } from "react";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import {
  MfaSettingsAuth0RegenerateRecoveryTokenMutation,
  useMfaSettingsAuth0RegenerateRecoveryTokenMutation,
} from "../../generated/graphql";
import ModalContext from "../../modal/ModalContext";
import ToastContext from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import Auth0RecoveryTokenModal from "./Auth0RecoveryTokenModal";

interface RegenerateRecoveryTokenAuth0Props {
  userId: string;
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}

interface RegenerateRecoveryTokenAuth0Deps {
  useToastContext?: <ToastContextValue>(
    context: Context<ToastContextValue>
  ) => ToastContextValue;
  useModalContext?: <ModalContextValue>(
    context: Context<ModalContextValue>
  ) => ModalContextValue;
}

const RegenerateRecoveryTokenAuth0: React.FC<
  RegenerateRecoveryTokenAuth0Props & RegenerateRecoveryTokenAuth0Deps
> = ({
  userId,
  setIsUpdating,
  useToastContext = useContextDefault,
  useModalContext = useContextDefault,
}) => {
  const { setToast } = useToastContext(ToastContext);
  const { setModal } = useModalContext(ModalContext);

  const [regenerateRecoveryToken] =
    useMfaSettingsAuth0RegenerateRecoveryTokenMutation({
      onCompleted: (result) => handleSuccess(result),
      onError: () => handleError(),
    });

  const handleSuccess = (
    result: MfaSettingsAuth0RegenerateRecoveryTokenMutation
  ) => {
    if (result.regenerateMfaRecoveryTokenAuth0?.token) {
      setIsUpdating(false);
      setToast({
        style: ToastStyle.Info,
        text: "Recovery token successfully regenerated",
      });
      console.log(result.regenerateMfaRecoveryTokenAuth0?.token);
      setRecoveryCodeModal(result.regenerateMfaRecoveryTokenAuth0?.token);
    } else {
      handleError();
    }
  };

  const handleError = () => {
    setIsUpdating(false);
    setToast({
      style: ToastStyle.Error,
      text: "Recovery token regeneration failed",
    });
  };

  const handleClick = () => {
    setIsUpdating(true);
    regenerateRecoveryToken({ variables: { userId: userId } });
  };

  const setRecoveryCodeModal = (token: string) => {
    setModal({
      title: "New MFA Recovery Token Generated",
      content: <Auth0RecoveryTokenModal token={token} />,
    });
  };

  return (
    <div className="RegenerateRecoveryTokenAuth0">
      <ButtonGroup>
        <Button
          onClick={handleClick}
          style={ButtonStyle.TegusPrimary}
          role="button"
        >
          Regenerate Recovery Token
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default RegenerateRecoveryTokenAuth0;
