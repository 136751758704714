import React, { Context, useCallback, useContext, useEffect } from "react";
import { Page } from "../../common/Breadcrumbs";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import { EMPTY_WATCH_LIST_COPY } from "../../common/copy";
import Card from "../../layout/Card";
import ModalContext from "../../modal/ModalContext";
import { PIX_TO_REM_CONVERSION } from "../../utils/document";
import routes from "../../utils/routes";
import settingsDefault, { Settings } from "../../utils/settings";
import FolderItemPreview, {
  getNumPreviewItems,
} from "../common/FolderItemPreview";
import "../common/FolderItemPreview.scss";
import Banner from "./Banner";
import "./HomePage.scss";
import NotificationModal from "./modals/NotificationModal";
import WhatsNew from "./WhatsNew";

export const INTERNET_DASHBOARD_SHARE_PATH = routes.share(
  "Ga4sxM",
  "internet-retail-dashboard-xlsx"
);

interface HomePageProps {
  setBreadcrumbs: (value: Page[]) => void;
}

interface HomePageDeps {
  innerHeight?: number;
  getNumItems?: typeof getNumPreviewItems;
  settings?: Settings;
  useContext_?: <T>(context: Context<T>) => T;
}

const HomePage: React.FC<HomePageProps & HomePageDeps> = ({
  setBreadcrumbs,
  settings = settingsDefault,
  innerHeight = window.innerHeight,
  getNumItems = getNumPreviewItems,
  useContext_ = useContext,
}) => {
  const { setModal } = useContext_(ModalContext);

  const showNotificationModal = useCallback(() => {
    setModal({
      title: "Introducing the newly redesigned Canalyst",
      content: (
        <NotificationModal id="theme-5-21-2023" dismissButtonCopy="Done">
          <p>
            Our new modern interface is the first step in creating a more
            unified research platform as we begin to integrate Canalyst into
            Tegus' suite of products.
          </p>
          <p>
            Don't worry - all of the powerful Canalyst functionality is right
            there where we left it, but we hope the new look and feel provides a
            more cohesive experience when navigating between our products.
          </p>
        </NotificationModal>
      ),
    });
  }, [setModal]);

  useEffect(() => {
    setBreadcrumbs([]);
    showNotificationModal();
  }, [setBreadcrumbs, showNotificationModal]);

  const innerHeightInREM = innerHeight / PIX_TO_REM_CONVERSION;
  const numItems = getNumItems({
    innerHeight: innerHeightInREM,
    numPreviewsInCol: 2,
    minNumItems: 3,
    maxNumItems: 5,
  });

  return (
    <div className="HomePage">
      {/*
      To add a notification banner to the home page, uncomment the block below
      and update the ID and notification text content.

      Make sure the ID contains a value unique to the new notification (eg. a date)
      otherwise the new banner may not appear for users who have dismissed the
      previous ones.

      Also make sure to enable the feature flag (FF_NOTIFICATION_BANNER) for the
      Canalyst app otherwise the new notification won't appear in production.
      */}

      <Banner id="post-auth0-5-21-2023" title="">
        <b>Notice:</b> With the latest upgrade to our authentication system,
        Tegus platform users can now log in to Canalyst using their Tegus
        platform credentials, offering a more seamless user experience.
        <br />
        <br />
        <b>For MFA Enabled Users:</b> You will need to{" "}
        <a href={routes.settingsMfa}>generate</a> new backup codes as your
        previous backup codes are no longer valid.
      </Banner>
      <div className="row">
        <div className="col-xl-8">
          <Card title="New Model Additions" tegus>
            <div>
              <FolderItemPreview
                path={settings.ipoFolderPath || ""}
                first={numItems}
              />
            </div>
            <div className="Centered">
              <ButtonGroup>
                <Button
                  spaOrMpa={routes.companies(
                    settings.ipoFolderPath || undefined
                  )}
                  style={ButtonStyle.TegusTertiary}
                >
                  View More
                </Button>
              </ButtonGroup>
            </div>
          </Card>
          <Card title="Recent Watchlist Model Updates" tegus>
            <div>
              <FolderItemPreview
                path={settings.watchListFolderPath || ""}
                first={numItems}
                emptyCopyOverride={EMPTY_WATCH_LIST_COPY}
              />
            </div>
            <div className="Centered">
              <ButtonGroup>
                <Button
                  spaOrMpa={routes.watchList}
                  style={ButtonStyle.TegusTertiary}
                >
                  View My Watchlist
                </Button>
              </ButtonGroup>
            </div>
          </Card>
        </div>
        <div className="col-xl-4">
          <WhatsNew />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
