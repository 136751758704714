import React from "react";
import {
  RouteComponentProps,
  useHistory as useHistoryDefault,
} from "react-router-dom";
import { goBack as goBackDefault } from "../../utils/location";
import Action, { ActionProps } from "../Action";
import "./Button.scss";

export enum ButtonState {
  Disabled = "Button--disabled",
}

export enum ButtonStyle {
  Primary = "Button--primary",
  PrimarySlim = "Button--primary-slim",
  Secondary = "Button--secondary",
  Tertiary = "Button--tertiary",
  TegusPrimary = "Button--tegus-primary",
  TegusSecondary = "Button--tegus-secondary",
  TegusSecondarySlim = "Button--tegus-secondary-slim",
  TegusTertiary = "Button--tegus-tertiary",
  TegusTertiaryAlt = "Button--tegus-tertiary-alt",
}

export enum ButtonType {
  Back,
  Submit,
}

export interface ButtonDeps {
  goBack?: () => void;
  useHistory?: () => RouteComponentProps["history"];
}

export interface ButtonProps extends ActionProps {
  state?: ButtonState;
  type?: ButtonType;
  style: ButtonStyle;
}

const Button: React.FC<ButtonDeps & ButtonProps> = ({
  children,
  type,
  state,
  style,
  goBack = goBackDefault,
  useHistory = useHistoryDefault,
  ...actionProps
}) => {
  const actionPropsFinal = {
    ...actionProps,
    className: `Button ${style} ${actionProps.className || ""} ${
      state || ""
    }`.trim(),
  };
  const reactHistory = useHistory();
  if (type === ButtonType.Submit) {
    return (
      <button
        className={actionPropsFinal.className}
        type="submit"
        data-testid="submit-button"
        disabled={state === ButtonState.Disabled}
      >
        {children}
      </button>
    );
  }

  if (type === ButtonType.Back) {
    return (
      <button
        className={actionPropsFinal.className}
        onClick={() => goBack(reactHistory)}
        data-testid="back-button"
      >
        Back
      </button>
    );
  }
  return <Action {...actionPropsFinal}>{children}</Action>;
};

export default Button;
