import { useField } from "formik";
import React, { ChangeEvent } from "react";
import Checkbox from "../../common/Checkbox";
import CheckboxList, {
  CheckboxListValueNameMap,
} from "../../common/CheckboxList";
import {
  IpoSubscriptionFolder,
  IpoSubscriptions as IpoSubscriptionsType,
} from "../../generated/graphql";
import "./IpoSubscriptions.scss";

interface IpoSubscriptionsProps {
  values: IpoSubscriptionsType;
}

type IpoSubscriptionsFoldersByName = Record<string, IpoSubscriptionFolder>;

type IpoSubscriptionsFoldersBySector = Record<
  string,
  IpoSubscriptionsFoldersByName
>;

const IpoSubscriptions: React.FC<IpoSubscriptionsProps> = ({ values }) => {
  const [, , helpers] = useField("ipoSubscriptions");

  // Folders from different sectors may have the same name so folders are split up by
  // sector before the folders in each section are reduced to a
  // IpoSubscriptionsFoldersByName object
  const folderArrayBySector = values.folders.reduce<
    Record<string, IpoSubscriptionFolder[]>
  >((acc, value) => {
    const sectorFolders = acc[value.sector] || [];
    return Object.assign(acc, { [value.sector]: [...sectorFolders, value] });
  }, {});

  const subscriptionsBySector: IpoSubscriptionsFoldersBySector = Object.keys(
    folderArrayBySector
  ).reduce<IpoSubscriptionsFoldersBySector>((foldersBySector, sector) => {
    return {
      ...foldersBySector,
      [sector]: folderArrayBySector[sector].reduce((foldersByName, value) => {
        return {
          ...foldersByName,
          [value.name]: value,
        };
      }, {}),
    };
  }, {});

  const setSectorValues = (sector: string) => {
    return (newValues: CheckboxListValueNameMap) => {
      const newValueFlattened = Object.values(
        newValues
      ) as IpoSubscriptionFolder[];
      const otherValues = Object.keys(subscriptionsBySector)
        .filter((key) => key !== sector)
        .reduce<IpoSubscriptionFolder[]>((arr, key) => {
          return arr.concat(Object.values(subscriptionsBySector[key]));
        }, []);
      const combinedFolders = newValueFlattened.concat(otherValues);

      helpers.setValue({
        __typename: "IpoSubscriptions",
        allFolders: combinedFolders.every((value) => value.isChecked),
        folders: combinedFolders,
      });
    };
  };

  const checklists = Object.entries(subscriptionsBySector)
    .sort()
    .map((entry, index) => {
      const [sector, folders] = entry;
      const placeClassName = index === 0 ? "FlexStart" : "FlexOther";
      return (
        <div
          key={sector}
          className={`IpoSubscriptions__checkbox-list ${placeClassName}`}
        >
          <CheckboxList
            name={sector}
            values={folders}
            setValues={setSectorValues(sector)}
            allValuesName="All Sectors"
          />
        </div>
      );
    });

  const allCompaniesOnChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    const newFolders = values.folders.map((value) => {
      return {
        ...value,
        isChecked: changeEvent.target.checked,
      };
    });

    helpers.setValue({
      __typename: "IpoSubscriptions",
      allFolders: newFolders.every((value) => value.isChecked),
      folders: newFolders,
    });
  };

  return (
    <div className="IpoSubscriptions">
      <h4 className="IpoSubscriptions__title">New Model Notifications</h4>
      <div className="IpoSubscriptions__description">
        <span>
          Select the sectors you would like to receive new model notifications
          for.
        </span>
      </div>
      <div className="IpoSubscriptions__all-companies">
        <Checkbox
          value="All Companies"
          checked={values.allFolders}
          onChange={allCompaniesOnChange}
        />
      </div>
      <div className="IpoSubscriptions__checkbox-lists">{checklists}</div>
    </div>
  );
};

export default IpoSubscriptions;
