import React from "react";
import { noop } from "../utils/functools";
import { Toast } from "./ToastDisplay";

const CONTEXT_DEFAULT_VALUE: ToastContextValue = {
  currentToast: null,
  clearToast: noop,
  setToast: noop,
  setToastAsync: noop,
};

// setToastAsync is useful when you need to:
//   1. redirect to a different page and
//   2. show a toast
// at the same time. Since toasts are cleared when the location changes (see
// <ToastContainer />) this can cause the toast to never be shown. If you do the
// redirect synchronously but set the toast asynchronously, this can ensure that
// the toast is shown _after_ the redirect, and it won't be cleared prematurely.
// See <ToastProvider /> to see the setToastAsync implementation.
export interface ToastContextValue {
  currentToast: Toast | null;
  clearToast: () => void;
  setToast: (toast: Toast) => void;
  setToastAsync: (toast: Toast) => void;
}

const ToastContext: React.Context<ToastContextValue> = React.createContext(
  CONTEXT_DEFAULT_VALUE
);

export default ToastContext;
