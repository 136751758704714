import { relayStylePagination } from "@apollo/client/utilities";

interface Schema {
  data: {
    __schema: {
      types: {
        kind: string;
        name: string;
        possibleTypes:
          | {
              kind: string;
              name: string;
            }[]
          | null;
      }[];
    };
  };
}

export const TYPE_POLICIES = {
  Folder: {
    fields: {
      items: relayStylePagination(["path", "ordering", "reverseOrdering"]),
    },
  },
} as const;

export const getPossibleTypes = (schema: Schema) => {
  // Designed based on
  // https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
  const possibleTypes: {
    [supertype: string]: string[];
  } = {};
  schema.data.__schema.types.forEach((supertype) => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map(
        (subtype) => subtype.name
      );
    }
  });
  return possibleTypes;
};
