import { ReactElement } from "react";
import { COMPANIES_STRINGS as STRINGS } from "./common/strings";
import "./CompaniesDisplay.scss";
import CompaniesViewButtons from "./main/CompaniesViewButtons";

export interface CompaniesDisplayProps {
  showSidebar: boolean;
  toggleSidebar: () => void;
  toggleFullscreen: () => any;
  sidebar: ReactElement;
  table: ReactElement;
}

/**
 * Companies page display component
 *
 * @param showSidebar Whether the sidebar is displayed
 * @param toggleSidebar Method to call to show/hide sidebar
 * @param toggleFullscreen Method to call to enable/disable fullscreen mode
 * @param table The Table component to render
 * @param sidebar The Sidebar component to render
 */
const CompaniesDisplay: React.FC<CompaniesDisplayProps> = ({
  showSidebar,
  toggleSidebar,
  toggleFullscreen,
  table,
  sidebar,
}) => {
  return (
    <div className="Companies">
      {/* Header */}
      <div className="Companies__header">
        {/* Header text */}
        <div className="Companies__header-left">{STRINGS.main.companies}</div>
        <div className="Companies__header-right">
          {/* View settings buttons */}
          <CompaniesViewButtons
            showSidebar={showSidebar}
            toggleSidebar={toggleSidebar}
            isFullscreen={false}
            toggleFullscreen={toggleFullscreen}
          />
        </div>
      </div>
      <div className="Companies__body">
        {/* Sidebar */}
        <div className="Companies__sidebar-column">
          {showSidebar && (
            <div className="Companies__sidebar-column-frame">{sidebar}</div>
          )}
        </div>
        {/* Table */}
        <div className="Companies__table-column">{table}</div>
      </div>
    </div>
  );
};

export default CompaniesDisplay;
