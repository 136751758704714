import React, { memo } from "react";
import Action from "../../common/Action";
import { SearchQuery } from "../../generated/graphql";

/**
 * A subset of `SearchDisplayProps` necessary for displaying hints
 */
interface SearchHintProps {
  data?: SearchQuery;
  isFolderSearch: boolean;
  setFolderId: (folderId?: string) => void;
  setPage: (page?: number) => void;
}

/**
 * Shows useful hints/suggestions above search results
 *
 * @param props - Same props as `<SearchDisplay />`
 */
const SearchHint: React.FC<SearchHintProps> = ({
  data,
  isFolderSearch,
  setFolderId,
  setPage,
}) => {
  if (data?.fromFolder && !isFolderSearch && data.search?.edges.length) {
    // Do not offer to search within a specific folder if there are no results
    return (
      <div
        className="SearchDisplay__search-hint"
        data-testid="all-folders-hint"
      >
        <p>
          Searching all folders.{" "}
          <Action
            className="SearchDisplay__hint-action"
            action={() => {
              setPage();
              setFolderId(data.fromFolder!.id);
            }}
          >
            Search within {data.fromFolder.name}
          </Action>{" "}
          instead.
        </p>
      </div>
    );
  } else if (data?.searchFolder && isFolderSearch) {
    return (
      <div
        className="SearchDisplay__search-hint"
        data-testid="within-folder-hint"
      >
        <p>
          Searching within {data.searchFolder.name}.{" "}
          <Action
            className="SearchDisplay__hint-action"
            action={() => {
              setPage();
              setFolderId();
            }}
          >
            Search all folders
          </Action>{" "}
          instead.
        </p>
      </div>
    );
  } else {
    return null;
  }
};

export default memo(SearchHint);
