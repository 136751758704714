import React, { RefObject } from "react";
import fliStylesDefault from "../../../common/FancyListItem.scss";
import { UserForDl } from "../../../utils/variants";
import PlaceholderElem from "../PlaceholderElem";
import ResourceElem from "../ResourceElem";
import SubFolderElem from "../SubFolderElem";
import {
  GenericItem,
  isGenericPlaceholder,
  isGenericResource,
} from "./graphql";

/**
 * Converts a FolderItem to an element component
 *
 * @param item - The folder item to build a component for
 * @param user - The user
 */
export const buildFolderItemComponent = (
  item: GenericItem,
  user: UserForDl,
  {
    highlighted,
    ref,
  }: { highlighted?: boolean; ref?: RefObject<HTMLDivElement> } = {}
) => {
  if (isGenericPlaceholder(item)) {
    return (
      <PlaceholderElem
        placeholder={item}
        highlighted={highlighted}
        key={`placeholder!${item.id}`}
        ref={ref}
        data-testid="item-elem"
      />
    );
  } else if (isGenericResource(item)) {
    return (
      <ResourceElem
        resource={item}
        user={user}
        highlighted={highlighted}
        key={`resource!${item.id}`}
        ref={ref}
        data-testid="item-elem"
      />
    );
  } else {
    return (
      <SubFolderElem
        folder={item}
        highlighted={highlighted}
        key={`folder!${item.id}`}
        ref={ref}
        data-testid="item-elem"
      />
    );
  }
};

/**
 * Returns a string representing the height of a FolderItemPreview in rem
 *
 * @param numItems - The number of folder items that will be previewed
 * @param fliStyles - An object containing FancyListItem styles
 */
export const getFolderPreviewHeight = (
  numItems: number,
  fliStyles: typeof fliStylesDefault = fliStylesDefault
): string => {
  const fliHeightWithBorder = parseFloat(fliStyles.fliHeightWithBorder);
  const interFliSpacing = parseFloat(fliStyles.interFliSpacing);
  const folderPreviewHeight =
    numItems * fliHeightWithBorder +
    Math.max(0, numItems - 1) * interFliSpacing;
  return `${folderPreviewHeight.toFixed(3)}rem`;
};
