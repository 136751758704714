import React, { useEffect } from "react";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { HOME_PAGE, Page } from "../../common/Breadcrumbs";
import { SearchQueryVariables, useSearchQuery } from "../../generated/graphql";
import SearchDisplay from "./SearchDisplay";

export const SEARCH_PAGE_NAME = "Search Files";
export const SEARCH_PAGE_SIZE = 20;

export enum SearchParam {
  FOLDER = "folder",
  FROM_FOLDER = "from",
  PAGE = "page",
  QUERY = "query",
}

interface SearchProps {
  setBreadcrumbs: (pages: Page[]) => void;
}

const Search: React.FC<SearchProps> = ({ setBreadcrumbs }) => {
  const [folderId, setFolderId] = useQueryParam(
    SearchParam.FOLDER,
    StringParam
  );

  const [pageDirty, setPage] = useQueryParam(SearchParam.PAGE, NumberParam);
  const page = cleanPageParam(pageDirty);

  const queryDirty = useQueryParam(SearchParam.QUERY, StringParam)[0];
  const query = (queryDirty || "").trim();

  const isFolderSearch = !!folderId;
  const fromFolderId = useQueryParam(SearchParam.FROM_FOLDER, StringParam)[0];

  const variables: SearchQueryVariables = {
    query: query,
    folder: folderId || undefined,
    first: SEARCH_PAGE_SIZE,
    after: pageParamToAfter(page),
    includeSearchFolder: isFolderSearch,
    searchFolder: folderId || "",
    includeFromFolder: !!fromFolderId && !isFolderSearch,
    fromFolder: fromFolderId || "",
  };
  const { loading, error, data } = useSearchQuery({
    variables: variables,
  });

  useEffect(() => {
    const breadcrumbs: Page[] = [{ ...HOME_PAGE, isCurrent: false }];
    breadcrumbs.push({ isCurrent: !query, nameOrIcon: SEARCH_PAGE_NAME });
    if (query) {
      breadcrumbs.push({ isCurrent: true, nameOrIcon: query });
    }
    setBreadcrumbs(breadcrumbs);
  }, [data, query, setBreadcrumbs]);

  return (
    <SearchDisplay
      query={query}
      loading={loading}
      error={error}
      data={data}
      isFolderSearch={isFolderSearch}
      setFolderId={setFolderId}
      page={page}
      setPage={setPage}
    />
  );
};

const cleanPageParam = (pageDirty?: number | null) => {
  // A valid page number is any integer greater than or equal to 1
  return pageDirty != null && Number.isInteger(pageDirty) && pageDirty > 0
    ? pageDirty
    : 1;
};
const pageParamToAfter = (page: number) => {
  // For page 1 & invalid page parameters, set after to undefined. For subsequent pages,
  // set the after cursor to the cursor of the last item on the previous page.
  if (page <= 1) {
    return undefined;
  } else {
    return ((page - 1) * SEARCH_PAGE_SIZE).toString();
  }
};

export default Search;
