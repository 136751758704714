import { Form, useField as useFieldDefault } from "formik";
import React, {
  Context,
  useContext as useContextDefault,
  useMemo,
} from "react";
import Button, { ButtonStyle, ButtonType } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Checkbox from "../../common/Checkbox";
import VariantDimensionsDropdowns from "../../common/VariantDimensionsDropdowns";
import {
  CustomVariantChoices,
  isVariantDimensionField,
  ORIGINAL_VARIANT_COMBO,
  VariantCombination,
} from "../../common/variants";
import FormError from "../../forms/FormError";
import { Resource, ResourceVariant } from "../../generated/graphql";
import ModalContext, { ModalContextValue } from "../../modal/ModalContext";
import {
  getAllAvailableDimensionsValues,
  getAvailableDimensionsChoices,
} from "./utils/variants";

export type VariantForDcvForm = Pick<
  ResourceVariant,
  "id" | "periodOrder" | "driversWorksheets" | "theme"
>;
export type ResourceForDcvForm = Pick<Resource, "id" | "name"> & {
  variants: VariantForDcvForm[];
};

export interface CustomVariantFormValuesType extends CustomVariantChoices {
  changeDefault: boolean;
  formError?: string;
}

interface DownloadCustomVariantFormProps {
  resource: ResourceForDcvForm;
  formError?: string;
  djangoGlobalPerms: string[];
}

interface DownloadCustomVariantFormDeps {
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
  useField?: typeof useFieldDefault;
}

const DownloadCustomVariantForm: React.FC<
  DownloadCustomVariantFormProps & DownloadCustomVariantFormDeps
> = ({
  resource,
  formError,
  djangoGlobalPerms,
  useModalContext = useContextDefault,
  useField = useFieldDefault,
}) => {
  const [changeDefault, , changeDefaultHelpers] = useField("changeDefault");
  const dimensionState = useDimensionState();

  const { clearModal } = useModalContext(ModalContext);

  const choices = useMemo(() => {
    const availableDimensions = getAllAvailableDimensionsValues(
      { ...dimensionState },
      resource.variants
    );
    return getAvailableDimensionsChoices(
      availableDimensions,
      djangoGlobalPerms
    );
  }, [resource, dimensionState, djangoGlobalPerms]);

  return (
    <div className="DownloadCustomVariantForm">
      <p className="DownloadCustomVariantForm__description">
        Select a different format for {resource.name}
      </p>
      <Form>
        <VariantDimensionsDropdowns
          useDescriptions={true}
          choicesOverride={choices}
          djangoGlobalPerms={djangoGlobalPerms}
        />
        {formError && <FormError errorText={formError} />}
        <Checkbox
          value="Save this as my default format for all future model downloads"
          checked={changeDefault.value}
          onChange={() => changeDefaultHelpers.setValue(!changeDefault.value)}
        />
        <ButtonGroup>
          <Button style={ButtonStyle.TegusPrimary} type={ButtonType.Submit}>
            Download
          </Button>
          <Button
            style={ButtonStyle.TegusSecondary}
            action={() => clearModal()}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </Form>
    </div>
  );
};

/**
 * Hook to get variant dimension state from the dropdowns
 */
export const useDimensionState = (
  useField: typeof useFieldDefault = useFieldDefault,
  originalVariants: VariantCombination = ORIGINAL_VARIANT_COMBO
): VariantCombination => {
  const dimState = Object.create(Object.prototype);

  // React advise you don't use hooks inside loops
  // https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level.
  // However, if as we are sure of the order of dimensions in ORIGINAL_VARIANTS, we are
  // safe to do so here.
  for (const dimension in originalVariants) {
    if (
      originalVariants.hasOwnProperty(dimension) &&
      isVariantDimensionField(dimension)
    ) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [field, ,] = useField(dimension);
      dimState[dimension] = field.value.id;
    }
  }

  return dimState;
};

export default DownloadCustomVariantForm;
