import { faFileDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import Tippy from "@tippyjs/react";
import React, { Context, useContext, useState } from "react";
import { ReactComponent as RequestUpdate } from "../../assets/icons/request-update.svg";
import ModelUpdateRequest from "../../contact_us/ModelUpdateRequest";
import DownloadCustomVariant from "../../home/common/DownloadCustomVariant";
import ModalContext from "../../modal/ModalContext";
import { COLOUR_TEAL_LIGHTER } from "../../utils/theme";
import { useDownloadAction } from "../../utils/variants";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";
import { useResourceQuery } from "../common/utils";
import CompaniesTableDownloadMenu from "./CompaniesTableDownloadMenu";
import "./CompaniesTableRowActions.scss";
import CompaniesTableWatchListButton from "./CompaniesTableWatchListButton";

// Settings
const ROW_ACTION_BUTTON_SIZE = 36;

interface CompaniesTableRowActionsProps {
  id: string;
  watchListed: boolean;
}

interface CompaniesTableRowActionsDeps {
  useContext_?: <T>(context: Context<T>) => T;
  useDownloadAction_?: typeof useDownloadAction;
  useResourceQuery_?: typeof useResourceQuery;
}

/**
 * Action buttons that appear for each table row
 *
 * @param id The company ID
 * @param watchListed Whether the company is watchlisted
 */
const CompaniesTableRowActions: React.FC<
  CompaniesTableRowActionsProps & CompaniesTableRowActionsDeps
> = ({
  id,
  watchListed,
  useContext_ = useContext,
  useDownloadAction_ = useDownloadAction,
  useResourceQuery_ = useResourceQuery,
}) => {
  const { setModal } = useContext_(ModalContext);
  const [menuAnchorElement, setMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  const downloadResource = useResourceQuery_(useDownloadAction_());
  const showUpdateRequestModal = useResourceQuery_((resource) => {
    setModal({
      title: STRINGS.table.rowActions.modelUpdateRequest,
      content: <ModelUpdateRequest resource={resource} />,
    });
  });
  const showVariantModal = useResourceQuery_((resource, user) => {
    setModal({
      title: STRINGS.table.downloadMenu.downloadDifferentModelFormat,
      content: <DownloadCustomVariant resource={resource} user={user} />,
    });
  });

  return (
    <div className="CompaniesTableRowActions">
      <div className="CompaniesTableRowActions__buttons">
        {/* Watchlist button */}
        <CompaniesTableWatchListButton id={id} watchListed={watchListed} />
        {/* Request update button */}
        <RowActionButton
          icon={<RequestUpdate className="SvgIcon" />}
          tooltip={STRINGS.table.rowActions.requestUpdate}
          onClick={() => showUpdateRequestModal(id)}
        />
        {/* Download menu button */}
        <RowActionButton
          icon={<FontAwesomeIcon icon={faFileDownload} fixedWidth />}
          tooltip={STRINGS.table.rowActions.download}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setMenuAnchorElement(event.currentTarget);
          }}
        />
        {/* Download menu */}
        {menuAnchorElement && (
          <CompaniesTableDownloadMenu
            companyId={id}
            anchorElement={menuAnchorElement}
            closeMenu={() => setMenuAnchorElement(null)}
            showVariantModal={showVariantModal}
            downloadResource={downloadResource}
          />
        )}
      </div>
    </div>
  );
};

interface RowActionButtonProps {
  tooltip: string;
  icon: JSX.Element;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => any;
}

/**
 * Companies table row action button
 *
 * @param tooltip Tooltip text to display
 * @param icon Icon element to display
 * @param onClick Action to perform when button is clicked
 */
export const RowActionButton: React.FC<RowActionButtonProps> = ({
  tooltip,
  icon,
  onClick,
}) => {
  const style = {
    width: ROW_ACTION_BUTTON_SIZE,
    height: ROW_ACTION_BUTTON_SIZE,
    "&:hover": {
      backgroundColor: COLOUR_TEAL_LIGHTER,
    },
  };
  return (
    <div className="CompaniesTableRowActions__button">
      <Tippy content={tooltip} trigger="mouseenter">
        <IconButton
          color="primary"
          sx={style}
          onClick={onClick}
          aria-label={tooltip}
        >
          {icon}
        </IconButton>
      </Tippy>
    </div>
  );
};

const MemoizedActions = React.memo(CompaniesTableRowActions);

export default MemoizedActions;
