import { ApolloError } from "@apollo/client";
import { Context, useContext, useState } from "react";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../../common/Spinner";
import {
  MfaSettingsAuth0SetUpMfaMutation,
  MfaSettingsMeQuery,
  useMfaSettingsAuth0SetUpMfaMutation,
} from "../../generated/graphql";
import Card from "../../layout/Card";
import ToastContext from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import { redirect } from "../../utils/location";
import routes from "../../utils/routes";
import "./MfaSettingsAuth0.scss";
import RegenerateRecoveryTokenAuth0 from "./RegenerateRecoveryTokenAuth0";
import ResetMfaAuth0Button from "./ResetMfaAuth0";

interface MfaSettingsAuth0Props {
  loading: boolean;
  error?: ApolloError;
  data?: MfaSettingsMeQuery;
  useToastContext?: <T>(context: Context<T>) => T;
  redirectFn?: (url: string) => void;
  openFn?: (url: string, target: string) => void;
}

const MfaSettingsAuth0: React.FC<MfaSettingsAuth0Props> = ({
  loading,
  error,
  data,
  useToastContext = useContext,
  redirectFn = redirect,
  openFn = window.open,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { setToast } = useToastContext(ToastContext);

  const [configureMfa] = useMfaSettingsAuth0SetUpMfaMutation({
    onCompleted: (result) => handleSuccess(result),
    onError: () => handleError(),
  });

  const handleSuccess = (result: MfaSettingsAuth0SetUpMfaMutation) => {
    setIsUpdating(false);

    if (result.setUpMfaAuth0?.url) {
      openFn(`${result.setUpMfaAuth0?.url}`, "_blank");
      redirectFn("/settings");
    } else {
      setToast({
        style: ToastStyle.Error,
        text: "Failed to retrieve MFA setup URL",
      });
    }
  };

  const handleError = () => {
    setIsUpdating(false);
    setToast({
      style: ToastStyle.Error,
      text: "Failed trying to set up MFA",
    });
  };

  if (loading) {
    return (
      <div
        className="MfaSettingsAuth0 MfaSettingsAuth0--loading"
        data-testid="mfa-settings-auth0-spinner"
      >
        <Spinner colour={ColourType.SECONDARY} size="3rem" />
      </div>
    );
  }

  if (!data?.me || error) {
    return (
      <div data-testid="error-500">
        <Card title="Uh oh.">
          <p>
            Something went wrong when retrieving the page for resetting
            multi-factor authentication. Please try again later or{" "}
            <a href={routes.support}>contact support</a> for assistance.
          </p>
          <ButtonGroup>
            <Button
              linkTo={routes.settings}
              style={ButtonStyle.TegusPrimary}
              role="button"
            >
              Back
            </Button>
            <Button
              href={routes.support}
              style={ButtonStyle.TegusSecondary}
              role="button"
            >
              Contact Us
            </Button>
          </ButtonGroup>
        </Card>
      </div>
    );
  }

  const handleClick = () => {
    setIsUpdating(true);
    configureMfa({ variables: { userId: data.me.id } });
  };

  if (!data.me.mfaSetupComplete) {
    return (
      <div className="MfaSettingsAuth0" data-testid="mfa-settings-auth0-set-up">
        <Card title="Configure MFA">
          <p>
            Multi-factor authentication is not setup on your account yet. Click
            below to set it up now.
          </p>
          <ButtonGroup>
            <Button
              style={ButtonStyle.TegusPrimary}
              action={handleClick}
              role="button"
            >
              Set Up MFA
            </Button>
          </ButtonGroup>
        </Card>
        {isUpdating && (
          <div className="MfaSettingsAuth0--loading">
            <Spinner colour={ColourType.SECONDARY} size="3rem" />
          </div>
        )}
      </div>
    );
  }

  // If MFA is not set up display a button that generates an enrollment
  // ticket and follows the URL instead of the components below

  return (
    <div className="MfaSettingsAuth0" data-testid="mfa-settings-auth0">
      <div className="RegenerateRecoveryToken">
        <Card title="Regenerate Recovery Token" tegus>
          <RegenerateRecoveryTokenAuth0
            userId={data.me.id}
            setIsUpdating={setIsUpdating}
          />
        </Card>
      </div>
      <div className="ResetMfaAuth0">
        <Card title="Reset MFA" tegus>
          <p>
            Reset your multi-factor authentication if you have a new phone. You
            can then set up multi-factor authentication on your new phone.
          </p>
          <ButtonGroup>
            <ResetMfaAuth0Button
              userId={data.me.id}
              setIsUpdating={setIsUpdating}
              disable={false}
              style={
                !data.me.mfaEnforced
                  ? ButtonStyle.TegusSecondary
                  : ButtonStyle.TegusPrimary
              }
            >
              Reset
            </ResetMfaAuth0Button>

            {!data.me.mfaEnforced && (
              <ResetMfaAuth0Button
                userId={data.me.id}
                setIsUpdating={setIsUpdating}
                disable={true}
                style={ButtonStyle.TegusPrimary}
              >
                Disable
              </ResetMfaAuth0Button>
            )}
          </ButtonGroup>
        </Card>
        {isUpdating && (
          <div
            className="MfaSettingsAuth0--loading"
            data-testid="mfa-settings-auth0-management-spinner"
          >
            <Spinner colour={ColourType.SECONDARY} size="3rem" />
          </div>
        )}
      </div>
    </div>
  );
};

export default MfaSettingsAuth0;
