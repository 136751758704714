import { CircularProgress } from "@mui/material";
import { Category, CategoryMap } from "../common/interfaces";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";
import CompaniesSidebarCategory from "./CompaniesSidebarCategory";
import "./CompaniesSidebarCategoryList.scss";

// Initial root category sort order
const ROOT_CATEGORIES = [
  "US Companies",
  "Canadian Companies",
  "Global Companies",
  "Upcoming IPOs and SPACs",
  "Delisted Companies",
];

interface CompaniesSidebarListProps {
  loading: boolean;
  categories: Category[];
  onSelect: (categories: Category[]) => any;
  onExpand: (categories: Category[]) => any;
}

/**
 * A list of categories to display within a sidebar section
 *
 * @param loading Whether category list is loading
 * @param categories List of categories to display
 * @param onSelect Callback to call when a category is selected or deselected
 * @param onExpand Callback to call when a category is expanded or collapsed
 */
const CompaniesSidebarCategoryList: React.FC<CompaniesSidebarListProps> = ({
  loading,
  categories,
  onSelect,
  onExpand,
}) => {
  const rootCategories: CategoryMap = {};

  categories.forEach((category) => {
    rootCategories[category.name] = category;
  });

  if (loading) {
    return (
      <div
        className="CompaniesSidebarCategoryList__loading"
        data-testid="categories-loading"
      >
        <div className="CompaniesSidebarCategoryList__loading-text">
          {STRINGS.sidebar.categoryList.loading}
        </div>
        <div className="CompaniesSidebarCategoryList__loading-progress">
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Sort initial root categories in specified order */}
      {ROOT_CATEGORIES.map((folder) => {
        return Object.keys(rootCategories).indexOf(folder) !== -1 ? (
          <CompaniesSidebarCategory
            categories={categories}
            category={rootCategories[folder]}
            indent={1}
            onSelect={onSelect}
            onExpand={onExpand}
            key={rootCategories[folder].id}
          />
        ) : undefined;
      })}
      {/* Sort remainining root categories alphabetically */}
      {Object.values(rootCategories).map((folder) => {
        return ROOT_CATEGORIES.indexOf(folder.name) === -1 ? (
          <CompaniesSidebarCategory
            categories={categories}
            category={folder}
            indent={1}
            onSelect={onSelect}
            onExpand={onExpand}
            key={folder.id}
          />
        ) : undefined;
      })}
    </>
  );
};

export default CompaniesSidebarCategoryList;
