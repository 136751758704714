import { useApolloClient } from "@apollo/client";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FieldArray, Form, Formik } from "formik";
import React, {
  Context,
  useContext as useContextDefault,
  useState,
} from "react";
import Button, {
  ButtonState,
  ButtonStyle,
  ButtonType,
} from "../../../common/buttons/Button";
import ButtonGroup from "../../../common/buttons/ButtonGroup";
import IconButton from "../../../common/buttons/IconButton";
import { DropdownChoice } from "../../../common/Dropdown";
import { HTTP_STATUS } from "../../../constants/http";
import { FileData } from "../../../forms/FileUpload";
import { BrowseFolderItemEdgeFragment } from "../../../generated/graphql";
import ModalContext, { ModalContextValue } from "../../../modal/ModalContext";
import ToastContext, { ToastContextValue } from "../../../toast/ToastContext";
import { ToastStyle } from "../../../toast/ToastDisplay";
import {
  FileType,
  FileUpload,
  uploadFiles as uploadFilesDefault,
  waitForUpload as waitForUploadDefault,
} from "../../../utils/upload";
import { BrowseFolderByPath } from "../utils/graphql";
import "./AddResourceModal.scss";
import AddResourceRow from "./AddResourceRow";

// Settings
const MAX_UPLOADS = 8;
const MAX_FILE_SIZE = 26214400;

// End-user visible strings
const TEXT_MODAL_DESCRIPTION =
  "Choose resource(s) to add to folder '{folder}':";
const TEXT_ADD_ADDITIONAL_RESOURCE = "Add additional resource...";
const TEXT_REMOVE_RESOURCE = "Remove resource";
const TEXT_RESOURCE_FILE = "Resource file";
const TEXT_RESOURCE_TYPE = "Resource type";
const TEXT_SAVE = "Save";
const TEXT_CANCEL = "Cancel";

// Toast messages
export const MSG_UPLOAD_RESOURCE = "Uploading resource '{name}'...";
export const MSG_UPLOAD_MULTIPLE = "Uploading resources...";
export const MSG_UPLOAD_RESOURCE_SUCCESS =
  "Uploaded resource '{name}' successfully";
export const MSG_UPLOAD_MULTIPLE_SUCCESS = "Resources uploaded successfully";
export const MSG_UPLOAD_RESOURCE_ERROR = "Failed to upload resource '{name}'";
export const MSG_UPLOAD_MULTIPLE_ERROR = "Failed to upload resources";

// Validation error messages
export const ERROR_DEFAULT = "All resources require a file and type";
export const ERROR_MISSING_TYPE = "Missing type for resource '{name}'";
export const ERROR_FILE_TOO_LARGE =
  "'{name}' is larger than the max file size (25 MB)";
export const ERROR_UPLOAD_TOO_LARGE =
  "Total size of all files must be less than 25 MB";

export enum ToastMessageType {
  INFO,
  SUCCESS,
  ERROR,
}

export interface ResourceRow {
  file: FileData | null;
  type: DropdownChoice | null;
}
export interface AddResourceState {
  resources: ResourceRow[];
}

export interface AddResourceModalProps {
  folder: BrowseFolderByPath;
  setIsUpdating?: (val: boolean) => any;
}

export interface AddResourceModalDeps {
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
  useToastContext?: (context: Context<ToastContextValue>) => ToastContextValue;
  uploadFiles?: typeof uploadFilesDefault;
  waitForUpload?: typeof waitForUploadDefault;
}

/**
 * Content of the 'Add resource' modal
 *
 * @param props - Component props
 * @param props.folder - Folder that resources are being added to
 * @param props.setIsUpdating - Hook to call when resource files are uploaded / finished uploading
 */
const AddResourceModal: React.FC<AddResourceModalProps & AddResourceModalDeps> =
  ({
    folder,
    setIsUpdating = (val: boolean) => {},
    useModalContext = useContextDefault,
    useToastContext = useContextDefault,
    uploadFiles = uploadFilesDefault,
    waitForUpload = waitForUploadDefault,
  }) => {
    const { setToast } = useToastContext(ToastContext);
    const { clearModal } = useModalContext(ModalContext);

    const client = useApolloClient();

    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const DefaultResource: ResourceRow = {
      file: null,
      type: null,
    };

    const InitialValues: AddResourceState = {
      resources: [{ ...DefaultResource }],
    };

    // Validate form
    const _validate = (values: AddResourceState) => {
      setShowError(false);

      const errors: string[] = [];
      const names: string[] = [];
      let size: number = 0;

      values.resources.forEach((resource) => {
        const error = validateRow(resource, folder.items.edges);
        if (error) {
          errors.push(error);
        }
        if (resource.file) {
          names.push(resource.file.name);
          size += resource.file.size;
        }
      });

      if (size > MAX_FILE_SIZE) {
        errors.push(ERROR_UPLOAD_TOO_LARGE);
      }

      setErrorMessages(errors);
    };

    // Upload files
    const _upload = async (resources: ResourceRow[]) => {
      const uploads = getFileUploads(resources);

      setIsUpdating(true);
      const uploadResponse = await uploadFiles(uploads, folder.id);

      if (uploadResponse.status !== HTTP_STATUS.CREATED) {
        setToast({
          style: ToastStyle.Error,
          text: getToastMessage(ToastMessageType.ERROR, resources),
        });
        setIsUpdating(false);
        return;
      }

      uploadResponse.json().then(async (data) => {
        const pollResponse = await waitForUpload(data["job_id"]);

        if (pollResponse.status === HTTP_STATUS.OK) {
          await client.refetchQueries({
            include: ["Browse"],
          });
          setToast({
            style: ToastStyle.Info,
            text: getToastMessage(ToastMessageType.SUCCESS, resources),
          });
        } else {
          setToast({
            style: ToastStyle.Error,
            text: getToastMessage(ToastMessageType.ERROR, resources),
          });
        }
        setIsUpdating(false);
      });
    };

    // Submit form
    const _submit = (values: AddResourceState) => {
      if (errorMessages.length > 0) {
        setShowError(true);
      } else {
        setToast({
          style: ToastStyle.Info,
          text: getToastMessage(ToastMessageType.INFO, values.resources),
        });

        _upload(values.resources);
        clearModal();
      }
    };

    return (
      <div className="AddResourceModal">
        <Formik
          initialValues={InitialValues}
          validate={_validate}
          onSubmit={_submit}
        >
          {({ values }) => (
            <Form>
              {/* Modal description text */}
              <p>{TEXT_MODAL_DESCRIPTION.replace("{folder}", folder.name)}</p>
              <FieldArray name="resources">
                {({ remove, push }) => (
                  <div className="MultiUpload">
                    {/* Table header */}
                    <div className="MultiUpload__row">
                      <div className="MultiUpload__item--header ResourceFile">
                        {TEXT_RESOURCE_FILE}
                      </div>
                      <div className="MultiUpload__item--header ResourceType">
                        {TEXT_RESOURCE_TYPE}
                      </div>
                      <div className="MultiUpload__item--header"></div>
                    </div>
                    {/* Table body */}
                    {values.resources.length > 0 &&
                      values.resources.map((_, index) => (
                        <div className="MultiUpload__row" key={index}>
                          {/* Resource file & type fields */}
                          <AddResourceRow name={`resources.${index}`} />
                          {/* Remove row button */}
                          <div className="MultiUpload__item">
                            <IconButton
                              id={`remove-row-${index}`}
                              icon={faTrash}
                              tooltip={TEXT_REMOVE_RESOURCE}
                              disabled={
                                index === 0 && values.resources.length === 1
                              }
                              onClick={() => {
                                remove(index);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    {/* Table footer*/}
                    <div className="MultiUpload__footer">
                      {/* Validation error message */}
                      <div className="ErrorMessage">
                        {showError && <>{errorMessages[0]}</>}
                      </div>
                      {/* Add additional resource button */}
                      <div className="Actions">
                        <Button
                          id="add-row"
                          style={ButtonStyle.TegusSecondarySlim}
                          action={() => {
                            push({ ...DefaultResource });
                          }}
                          state={
                            values.resources.length < MAX_UPLOADS
                              ? undefined
                              : ButtonState.Disabled
                          }
                        >
                          {TEXT_ADD_ADDITIONAL_RESOURCE}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </FieldArray>
              <ButtonGroup>
                {/* Cancel button */}
                <Button
                  id="cancel"
                  style={ButtonStyle.TegusSecondary}
                  action={clearModal}
                >
                  {TEXT_CANCEL}
                </Button>
                {/* Save button */}
                <Button
                  id="save"
                  style={ButtonStyle.TegusPrimary}
                  type={ButtonType.Submit}
                >
                  {TEXT_SAVE}
                </Button>
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

/**
 * Validate individual ResourceRow
 *
 * @param row ResourceRow to validate
 * @param edges List of other items in the folder
 * @returns Error message if there was a validation error, null otherwise
 */
export const validateRow = (
  row: ResourceRow,
  edges: Array<{ __typename: "FolderItemEdge" } & BrowseFolderItemEdgeFragment>
): string | null => {
  if (row.file === null) {
    return ERROR_DEFAULT;
  }
  if (row.type === null) {
    return ERROR_MISSING_TYPE.replace("{name}", row.file.name);
  }
  if (row.file.size > MAX_FILE_SIZE) {
    return ERROR_FILE_TOO_LARGE.replace("{name}", row.file.name);
  }
  return null;
};

/**
 * Get file upload data from list of resources
 *
 * @param resources Resources to upload
 * @returns List of FileUploads
 */
export const getFileUploads = (resources: ResourceRow[]) => {
  const uploads: FileUpload[] = [];

  for (const resource of resources) {
    if (resource.file && resource.type) {
      uploads.push({
        file: resource.file,
        fileType: FileType.ResourceFile,
        resourceType: resource.type?.id,
      });
    }
  }

  return uploads;
};

/**
 * Get toast message to display based on message type and resources uploaded
 *
 * @param messageType Type of message to display (info, success, or error)
 * @param resources Resources uploaded
 * @returns Toast message, or blank string if there was an error
 */
export const getToastMessage = (
  messageType: ToastMessageType,
  resources: ResourceRow[]
): string => {
  if (resources.length === 0 || resources[0].file === null) {
    return "";
  }
  const firstFileName = resources[0].file.name;
  const multiple = resources.length > 1;

  if (messageType === ToastMessageType.INFO) {
    return multiple
      ? MSG_UPLOAD_MULTIPLE
      : MSG_UPLOAD_RESOURCE.replace("{name}", firstFileName);
  }
  if (messageType === ToastMessageType.SUCCESS) {
    return multiple
      ? MSG_UPLOAD_MULTIPLE_SUCCESS
      : MSG_UPLOAD_RESOURCE_SUCCESS.replace("{name}", firstFileName);
  }
  return multiple
    ? MSG_UPLOAD_MULTIPLE_ERROR
    : MSG_UPLOAD_RESOURCE_ERROR.replace("{name}", firstFileName);
};

export default AddResourceModal;
