import React, { useCallback, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import Spinner, { ColourType } from "../../common/Spinner";
import {
  ShareLinkFolderFragment,
  ShareLinkFolderItemFragment,
  ShareLinkPlaceholderFragment,
  ShareLinkQuery,
  ShareLinkResourceFragment,
  useShareLinkQuery,
} from "../../generated/graphql";
import ToastContext from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import { getFolderUrl } from "../../utils/folders";
import routes from "../../utils/routes";
import { useDownloadAction } from "../../utils/variants";
import "./ShareLink.scss";

interface ShareLinkDeps {
  useDownloadAction_?: typeof useDownloadAction;
  useContext_?: typeof useContext;
  useQueryParam_?: typeof useQueryParam;
}

/**
 * Handle share links like /share/B8gS6G or /share/B8gS6G/apple-aapl-us
 *
 * These links are available for any folder item:
 *   1. For folders they redirect directly to the folder
 *   2. For resources (including tools) they trigger a download, redirect to the
 *      containing folder, and highlight the resource
 *   3. For placeholders they redirect to the containing folder and highlight
 *      the placeholder
 */
const ShareLink: React.FC<ShareLinkDeps> = ({
  useDownloadAction_ = useDownloadAction,
  useContext_ = useContext,
  useQueryParam_ = useQueryParam,
}) => {
  const history = useHistory();
  const download = useDownloadAction_();
  const { setToastAsync } = useContext_(ToastContext);
  const { id } = useParams<{ id: string }>();
  const [latestVersion] = useQueryParam_(
    "latest",
    withDefault(BooleanParam, true)
  );
  const source_value = history.location.search.split("?source=")[1];

  const showItem = useCallback(
    (item: ShareLinkPlaceholderFragment | ShareLinkResourceFragment) => {
      if (source_value !== undefined) {
        history.replace(
          `${getFolderUrl(item.folder)}?show=${item.id}&source=${source_value}`
        );
      } else {
        history.replace(`${getFolderUrl(item.folder)}?show=${item.id}`);
      }
    },
    [history, source_value]
  );
  const onError = useCallback(() => {
    history.replace(routes.home);
    setToastAsync({
      style: ToastStyle.Error,
      text: "Sorry, the requested item could not be found",
    });
  }, [history, setToastAsync]);
  const onCompleted = useCallback(
    (data: ShareLinkQuery) => {
      if (!data.folderItem) {
        onError();
      } else if (isFolder(data.folderItem)) {
        history.replace(getFolderUrl(data.folderItem));
      } else if (isPlaceholder(data.folderItem)) {
        showItem(data.folderItem);
      } else {
        showItem(data.folderItem);
        download(data.folderItem, data.me);
      }
    },
    [history, download, showItem, onError]
  );
  useShareLinkQuery({
    variables: { id: id, latestVersion: latestVersion },
    onError,
    onCompleted,
  });

  return (
    <div className="ShareLink">
      <Spinner size="3rem" colour={ColourType.SECONDARY} />
    </div>
  );
};

const isFolder = (
  item: ShareLinkFolderItemFragment
): item is ShareLinkFolderFragment => {
  return item.__typename === "Folder";
};

const isPlaceholder = (
  item: ShareLinkFolderItemFragment
): item is ShareLinkPlaceholderFragment => {
  return item.__typename === "Placeholder";
};

export default ShareLink;
