import { useApolloClient } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import { Form, Formik } from "formik";
import React, {
  Context,
  useContext as useContextDefault,
  useState,
} from "react";
import Button, {
  ButtonState,
  ButtonStyle,
  ButtonType,
} from "../../../common/buttons/Button";
import ButtonGroup from "../../../common/buttons/ButtonGroup";
import { HTTP_STATUS } from "../../../constants/http";
import FileUpload, { FileData } from "../../../forms/FileUpload";
import ModalContext, { ModalContextValue } from "../../../modal/ModalContext";
import ToastContext, { ToastContextValue } from "../../../toast/ToastContext";
import { ToastStyle } from "../../../toast/ToastDisplay";
import {
  FileType,
  FileUpload as FileUploadData,
  uploadFiles as uploadFilesDefault,
  waitForUpload as waitForUploadDefault,
} from "../../../utils/upload";
import { GenericResource } from "../../common/utils/graphql";
import "./ReplaceResourceModal.scss";

// Settings
const MAX_FILE_SIZE = 26214400; // Max size accepted by backend is 25 MB

// End-user visible strings
const TEXT_DESCRIPTION = "Choose a replacement file for resource '{name}':";
const TEXT_SAVE = "Save";
const TEXT_UPLOADING = "Uploading...";
const TEXT_CANCEL = "Cancel";
const TEXT_CLOSE = "Close";

const ERROR_FILE_TOO_LARGE = "Replacement file size must be less than 25 MB";

// Toast messages
const TEXT_REPLACING = "Uploading replacement file...";
const TEXT_SAVE_SUCCESS = "Replaced resource '{name}' successfully";
const TEXT_SAVE_FAILURE = "Failed to replace resource '{name}'";

// ARIA labels
const TEXT_REPLACE_RESOURCE = "Replace resource file";

export interface ReplaceResourceState {
  file: FileData | null;
}

export interface ReplaceResourceModalProps {
  resourceFile: GenericResource;
}

export interface ReplaceResourceModalDeps {
  uploadFiles?: typeof uploadFilesDefault;
  waitForUpload?: typeof waitForUploadDefault;
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
  useToastContext?: (context: Context<ToastContextValue>) => ToastContextValue;
}

/**
 * Content of the 'Replace Resource File' modal
 *
 * @param resourceFile Resource file to replace
 */
const ReplaceResourceModal: React.FC<
  ReplaceResourceModalProps & ReplaceResourceModalDeps
> = ({
  resourceFile,
  useModalContext = useContextDefault,
  useToastContext = useContextDefault,
  uploadFiles = uploadFilesDefault,
  waitForUpload = waitForUploadDefault,
}) => {
  const { setToast } = useToastContext(ToastContext);
  const { clearModal } = useModalContext(ModalContext);

  const client = useApolloClient();

  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isUploading, setIsUploading] = useState<boolean>(false);

  // Handle file uploaded
  const handleFileSelected = (file: FileData) => {
    setErrorMessage("");
    _validate(file);
  };

  // Handle clear button
  const handleFileCleared = () => {
    setErrorMessage("");
    setSaveEnabled(false);
  };

  // Validate file
  const _validate = (file: FileData) => {
    setSaveEnabled(false);

    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage(ERROR_FILE_TOO_LARGE);
    } else {
      setSaveEnabled(true);
    }
  };

  // Upload file
  const _upload = async (values: ReplaceResourceState) => {
    setIsUploading(true);
    setSaveEnabled(false);

    const uploadResponse = await uploadFiles(
      getFileUploadData(values, resourceFile.id),
      resourceFile.folder.id
    );

    if (uploadResponse.status !== HTTP_STATUS.CREATED) {
      setToast({
        style: ToastStyle.Error,
        text: TEXT_SAVE_FAILURE.replace("{name}", resourceFile.name),
      });
      setIsUploading(false);
      setSaveEnabled(false);
      return;
    }

    uploadResponse.json().then(async (data) => {
      const pollResponse = await waitForUpload(data["job_id"]);

      if (pollResponse.status === HTTP_STATUS.OK) {
        await client.refetchQueries({
          include: ["Browse"],
        });
        setToast({
          style: ToastStyle.Info,
          text: TEXT_SAVE_SUCCESS.replace("{name}", resourceFile.name),
        });
        clearModal();
      } else {
        setToast({
          style: ToastStyle.Error,
          text: TEXT_SAVE_FAILURE.replace("{name}", resourceFile.name),
        });
      }
      setIsUploading(false);
    });
  };

  // Submit
  const _submit = (values: ReplaceResourceState) => {
    setToast({
      style: ToastStyle.Info,
      text: TEXT_REPLACING,
    });
    _upload(values);
  };

  // Upload progress page
  if (isUploading) {
    return (
      <div className="ReplaceResourceModal">
        <div className="ReplaceResourceModal__frame">
          <div className="ReplaceResourceModal__progress-bar">
            <LinearProgress />
          </div>
          <div className="WatchListModal__uploading-text">{TEXT_UPLOADING}</div>
        </div>
        <Button
          id="close"
          style={ButtonStyle.TegusSecondary}
          action={clearModal}
          role="button"
          aria-label={TEXT_CLOSE}
        >
          {TEXT_CLOSE}
        </Button>
      </div>
    );
  }

  // Main modal content
  return (
    <div
      className="ReplaceResourceModal"
      role="form"
      aria-label={TEXT_REPLACE_RESOURCE}
      aria-describedby="description"
    >
      <Formik initialValues={{ file: null }} onSubmit={_submit}>
        {() => (
          <Form>
            {/* Description text */}
            <p id="description">
              {TEXT_DESCRIPTION.replace("{name}", resourceFile.name)}
            </p>
            <div
              className={
                saveEnabled
                  ? "ReplaceResourceModal__body ReplaceResourceModal__body--save-enabled"
                  : errorMessage
                  ? "ReplaceResourceModal__body ReplaceResourceModal__body--error"
                  : "ReplaceResourceModal__body"
              }
              data-testid="file-upload-frame"
            >
              {/* File upload field */}
              <FileUpload
                name="file"
                filenameWidth="25.5rem"
                hasClearButton={true}
                onFileSelected={handleFileSelected}
                onFileCleared={handleFileCleared}
              ></FileUpload>

              {/* Validation error message */}
              {errorMessage && (
                <div className="ReplaceResourceModal__error-message">
                  {errorMessage}
                </div>
              )}
            </div>
            <ButtonGroup>
              {/* Cancel button */}
              <Button
                id="cancel"
                style={ButtonStyle.TegusSecondary}
                action={clearModal}
                role="button"
                aria-label={TEXT_CANCEL}
              >
                {TEXT_CANCEL}
              </Button>
              {/* Save button */}
              <Button
                id="save"
                style={ButtonStyle.TegusPrimary}
                type={ButtonType.Submit}
                state={saveEnabled ? undefined : ButtonState.Disabled}
              >
                {TEXT_SAVE}
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/**
 * Get file upload data from form value
 *
 * @param values Form data to get file upload data from
 * @param replaceId ID of resource to replace
 * @returns List of file uploads
 */
export const getFileUploadData = (
  values: ReplaceResourceState,
  replaceId: string
) => {
  const uploads: FileUploadData[] = [];

  if (values.file) {
    uploads.push({
      file: values.file,
      fileType: FileType.ResourceFile,
      replaceId: replaceId,
    });
  }

  return uploads;
};

export default ReplaceResourceModal;
