import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import CompaniesTableRowActions from "../table/CompaniesTableRowActions";
import { Fields } from "./enums";
import { COMPANIES_STRINGS as STRINGS } from "./strings";
import { formatColumnHeader, formatCurrency, formatUpdated } from "./utils";

/**
 * Companies table column definitions
 *
 * See https://mui.com/x/react-data-grid/columns/#column-definitions for documentation
 *
 * Note: default widths were determined by resizing column until the header doesn't
 *       display an ellipsis for the column name when hovering over it (due to how
 *       mui-data-grid overlays the sort and filter icons if the column isn't wide enough)
 */

/**
 * Company name
 */
export const COLUMN_COMPANY_NAME: GridColDef = {
  field: Fields.name,
  headerName: STRINGS.table.columnHeaders.companyName,
  type: "string",
  pinnable: false,
  hideable: false,
  disableReorder: true,
  width: 325,
  headerClassName: "CompaniesTable__header-first",
  cellClassName: "CompaniesTable__cell-first",
};

/**
 * Ticker
 */
export const COLUMN_TICKER: GridColDef = {
  field: Fields.ticker,
  headerName: STRINGS.table.columnHeaders.ticker,
  type: "string",
  pinnable: false,
  width: 107,
  cellClassName: "CompaniesTable__cell",
};

/**
 * Region
 */
export const COLUMN_REGION: GridColDef = {
  field: Fields.region,
  headerName: STRINGS.table.columnHeaders.region,
  type: "string",
  pinnable: false,
  width: 94,
  cellClassName: "CompaniesTable__cell",
};

/**
 * Last updated
 */
export const COLUMN_UPDATED: GridColDef = {
  field: Fields.updated,
  headerName: STRINGS.table.columnHeaders.lastUpdated,
  type: "dateTime",
  pinnable: false,
  width: 160,
  cellClassName: "CompaniesTable__cell",
  valueFormatter: (params: GridValueFormatterParams<string>) => {
    return formatUpdated(params.value);
  },
};

/**
 * Update type
 */
export const COLUMN_UPDATE_TYPE: GridColDef = {
  field: Fields.updateType,
  headerName: STRINGS.table.columnHeaders.updateType,
  type: "string",
  pinnable: false,
  width: 151,
  cellClassName: "CompaniesTable__cell",
  renderHeader: () => {
    return formatColumnHeader(
      STRINGS.table.columnHeaders.updateType,
      STRINGS.table.columnDescriptions.updateType
    );
  },
};

/**
 * Period
 */
export const COLUMN_PERIOD: GridColDef = {
  field: Fields.period,
  headerName: STRINGS.table.columnHeaders.period,
  type: "string",
  pinnable: false,
  width: 125,
  cellClassName: "CompaniesTable__cell",
  renderHeader: () => {
    return formatColumnHeader(
      STRINGS.table.columnHeaders.period,
      STRINGS.table.columnDescriptions.period
    );
  },
};

/**
 * Revenue
 */
export const COLUMN_REVENUE: GridColDef = {
  field: Fields.revenue,
  headerName: STRINGS.table.columnHeaders.revenue,
  headerAlign: "left",
  type: "number",
  pinnable: false,
  width: 171,
  cellClassName: "CompaniesTable__cell",
  renderHeader: () => {
    return formatColumnHeader(
      STRINGS.table.columnHeaders.revenue,
      STRINGS.table.columnDescriptions.revenue
    );
  },
  valueFormatter: (params: GridValueFormatterParams<number>) => {
    return formatCurrency(params.value, "M");
  },
};

/**
 * Sector
 */
export const COLUMN_SECTOR: GridColDef = {
  field: Fields.sector,
  headerName: STRINGS.table.columnHeaders.sector,
  type: "string",
  pinnable: false,
  width: 250,
  cellClassName: "CompaniesTable__cell",
};

/**
 * Industry
 */
export const COLUMN_INDUSTRY: GridColDef = {
  field: Fields.industry,
  headerName: STRINGS.table.columnHeaders.industry,
  type: "string",
  pinnable: false,
  width: 250,
  cellClassName: "CompaniesTable__cell",
};

/**
 * Actions
 */
export const COLUMN_ACTIONS: GridColDef = {
  field: Fields.actions,
  headerName: STRINGS.table.columnHeaders.actions,
  type: "actions",
  sortable: false,
  filterable: false,
  hideable: false,
  pinnable: false,
  resizable: false,
  disableReorder: true,
  width: 120,
  cellClassName: "CompaniesTable__cell-actions",
  renderCell: (params: GridRenderCellParams<string>) => {
    return (
      <CompaniesTableRowActions
        id={params.row.id}
        watchListed={params.row.watchListed}
      />
    );
  },
};

/**
 * Companies table columns list
 */
export const COLUMNS: GridColDef[] = [
  COLUMN_COMPANY_NAME,
  COLUMN_TICKER,
  COLUMN_REGION,
  COLUMN_UPDATED,
  COLUMN_UPDATE_TYPE,
  COLUMN_PERIOD,
  COLUMN_REVENUE,
  COLUMN_SECTOR,
  COLUMN_INDUSTRY,
  COLUMN_ACTIONS,
];
