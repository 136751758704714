import { useQuery } from "@apollo/client";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { faKey } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Context, useContext, useEffect } from "react";
import Moment from "react-moment";
import { RouteProps } from "react-router-dom";
import { HOME_PAGE, Page, SETTINGS_PAGE } from "../../common/Breadcrumbs";
import Button, { ButtonStyle, ButtonType } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import FancyListItem, { SecondaryAction } from "../../common/FancyListItem";
import Spinner, { ColourType } from "../../common/Spinner";
import Card from "../../layout/Card";
import ModalContext from "../../modal/ModalContext";
import { Modal } from "../../modal/ModalDisplay";
import { HOUR_IN_MS } from "../../utils/datetime";
import { FA_ICON_DEFAULT_STYLE } from "../../utils/icons";
import routes from "../../utils/routes";
import {
  ApiToken,
  ApiTokenData,
  GET_USER_API_TOKENS_QUERY,
} from "./ApiTokens.graphql";
import "./ApiTokens.scss";
import ApiTokensDelete from "./ApiTokensDelete";
import ApiTokensDescription from "./ApiTokensDescription";

const NO_DESCRIPTION = "No Description";

export interface ApiTokensProps extends RouteProps {
  setBreadcrumbs: (value: Page[]) => void;
  useContext_?: <T>(context: Context<T>) => T;
}
export const API_TOKEN_PAGES = [
  HOME_PAGE,
  SETTINGS_PAGE,
  {
    isCurrent: true,
    nameOrIcon: "API Tokens",
  },
];

const ApiTokens: React.FC<ApiTokensProps> = ({
  setBreadcrumbs,
  useContext_ = useContext,
}) => {
  const { setModal } = useContext_(ModalContext);
  const { loading, error, data } = useQuery<ApiTokenData>(
    GET_USER_API_TOKENS_QUERY,
    { fetchPolicy: "network-only" }
  );
  useEffect(() => {
    setBreadcrumbs(API_TOKEN_PAGES);
  }, [setBreadcrumbs]);

  if (loading) {
    return (
      <div className="ApiTokens ApiTokens--loading">
        <Spinner colour={ColourType.SECONDARY} size="3rem" />
      </div>
    );
  }
  if (error || !data || !data.myApiTokens) {
    return (
      <Card title="Uh oh." data-testid="error-500">
        <p>
          Something went wrong when retrieving the API tokens settings page.
          Please try again later or <a href={routes.support}>contact support</a>{" "}
          for assistance.
        </p>
        <ButtonGroup>
          <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
          <Button linkTo={routes.support} style={ButtonStyle.TegusSecondary}>
            Contact Us
          </Button>
        </ButtonGroup>
      </Card>
    );
  }

  const apiTokenItems: React.ReactElement[] = data.myApiTokens.map((edge) => {
    return getApiTokenItem(edge, setModal);
  });

  return (
    <div className="ApiTokens">
      <Card title="API Tokens" tegus>
        <ApiTokensDescription />
        <div className="ApiTokens__list">
          {apiTokenItems.length > 0 ? (
            <div className="row">
              <div className="col-12">{apiTokenItems}</div>
            </div>
          ) : (
            <p data-testid="no-tokens">You have no API tokens.</p>
          )}
        </div>
        <ButtonGroup>
          <Button
            linkTo={routes.settingsApiTokensCreate}
            style={ButtonStyle.TegusPrimary}
          >
            Create New API Token
          </Button>
          <Button linkTo={routes.settings} style={ButtonStyle.TegusSecondary}>
            Back
          </Button>
        </ButtonGroup>
      </Card>
    </div>
  );
};

const getApiTokenItem = (
  apiToken: ApiToken,
  setModal: (modal: Modal) => void
) => {
  const id = apiToken.id;
  const description = apiToken.description;
  const secondaryAction: SecondaryAction = {
    icon: (
      <FontAwesomeIcon
        className="delete-button"
        icon={faTrashAlt}
        style={FA_ICON_DEFAULT_STYLE}
      />
    ),
    id: id,
    action: () =>
      setModal({
        title: "Delete API Token",
        content: (
          <ApiTokensDelete
            description={description || NO_DESCRIPTION}
            apiTokens={id}
          />
        ),
      }),
    tooltipText: "Delete",
  };

  return (
    <FancyListItem
      key={id}
      icon={<FontAwesomeIcon icon={faKey} style={FA_ICON_DEFAULT_STYLE} />}
      text={description || NO_DESCRIPTION}
      subtext={[
        <span>
          Created{" "}
          <Moment fromNowDuring={24 * HOUR_IN_MS}>{apiToken.created}</Moment>
        </span>,
      ]}
      secondaryActions={[secondaryAction]}
      data-testid="token-list-item"
    />
  );
};

export default ApiTokens;
