import { faAngleDown, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { COLOUR_TEAL } from "../../utils/theme";
import { Category } from "../common/interfaces";
import { COMPANIES_STRINGS as STRINGS } from "../common/strings";
import "./CompaniesSidebarCategory.scss";

const DEFAULT_INDENT = 0;

interface CompaniesSidebarCategoryProps {
  categories: Category[];
  category: Category;
  indent?: number;
  onSelect: (categories: Category[]) => any;
  onExpand: (categories: Category[]) => any;
  ancestorSelected?: boolean;
}

/**
 * An individual selectable / expandable category within the companies sidebar
 *
 * @param categories List of all categories
 * @param category Category to display
 * @param indent Indent level
 * @param onSelect Callback to call when a category is selected or deselected
 * @param onExpand Callback to call when a category is expanded or collapsed
 * @param ancestorSelected Whether this category's ancestor is selected
 */
const CompaniesSidebarCategory: React.FC<CompaniesSidebarCategoryProps> = ({
  categories,
  category,
  indent = DEFAULT_INDENT,
  onSelect,
  onExpand,
  ancestorSelected = false,
}) => {
  const hasChildren = category.items.length > 0;
  const isSelected = ancestorSelected || category.selected;
  const isExpanded = category.expanded;

  const handleSelect = () => {
    category.selected = !category.selected;
    onSelect([...categories]);
  };

  const handleExpand = () => {
    category.expanded = !category.expanded;
    onExpand([...categories]);
  };

  return (
    <nav aria-label={category.name}>
      <List dense={true} disablePadding>
        <ListItem
          sx={{
            "& .MuiListItemSecondaryAction-root": {
              left: 0.5 + indent + "rem",
              right: "initial",
            },
          }}
          disablePadding
          secondaryAction={
            <Checkbox
              edge="start"
              onChange={handleSelect}
              checked={isSelected}
              disabled={ancestorSelected}
              indeterminate={category.indeterminate}
              inputProps={{
                "aria-label": STRINGS.sidebar.category.filterBy.replace(
                  "{category}",
                  category.name
                ),
                "aria-checked": isSelected,
              }}
              sx={{
                "&.Mui-checked": {
                  color: COLOUR_TEAL,
                },
              }}
            />
          }
        >
          <ListItemButton
            onClick={() => {
              if (hasChildren) {
                handleExpand();
              } else {
                handleSelect();
              }
            }}
            sx={{
              py: "0.125rem",
              pr: "0.75rem !important",
              pl: 2.75 + indent + "rem",
            }}
            aria-label={
              hasChildren
                ? STRINGS.sidebar.category.expand.replace(
                    "{category}",
                    category.name
                  )
                : STRINGS.sidebar.category.filterBy.replace(
                    "{category}",
                    category.name
                  )
            }
            aria-pressed={isExpanded}
            aria-disabled={ancestorSelected}
            disabled={ancestorSelected}
          >
            <ListItemText
              disableTypography
              primary={category.name}
              sx={{ color: "#333", fontSize: "1rem" }}
            />
            {hasChildren && (
              <div className="CompaniesSidebarCategory__expand-icon">
                <FontAwesomeIcon
                  icon={isExpanded ? faAngleDown : faAngleRight}
                  fixedWidth
                />
              </div>
            )}
          </ListItemButton>
        </ListItem>
        {hasChildren && (
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            {category.items.map((subcategory) => {
              return (
                <CompaniesSidebarCategory
                  categories={categories}
                  category={subcategory}
                  indent={indent + 1}
                  onSelect={onSelect}
                  onExpand={onExpand}
                  ancestorSelected={isSelected}
                  key={subcategory.id}
                />
              );
            })}
          </Collapse>
        )}
      </List>
    </nav>
  );
};

export default CompaniesSidebarCategory;
