import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useField as useFieldDefault } from "formik";
import React, { useState } from "react";
import Dropdown, { DropdownChoice } from "../common/Dropdown";
import { noop } from "../utils/functools";
import { FA_ICON_DEFAULT_STYLE } from "../utils/icons";
import "./InlineChoiceField.scss";

const DEFAULT_NULL_CHOICE_TEXT = "Please select one…";

export interface InlineChoiceFieldProps {
  name: string;
  choices: DropdownChoice[];
  nullChoiceText?: string;
  onChoiceSet?: (choice: DropdownChoice) => any;
  disabled?: boolean;
}

export interface InlineChoiceFieldDeps {
  useField?: typeof useFieldDefault;
}

/**
 * A field for selecting a single option from a dropdown, meant for display inline
 * in lists of form elements.
 *
 * Similar to ChoiceField but with some style changes.
 *
 * @param props - Component props
 * @param props.name - The internal name of the field
 * @param props.choices - Available choices. First one will be default, if available
 * @param props.nullChoiceText - Text to display if no choice is selected
 * @param props.onChoiceSet - Hook to call when user selects a choice
 * @param props.disabled - Set whether choice field is disabled
 */
const InlineChoiceField: React.FC<
  InlineChoiceFieldProps & InlineChoiceFieldDeps
> = ({
  name,
  choices,
  nullChoiceText = DEFAULT_NULL_CHOICE_TEXT,
  disabled = false,
  onChoiceSet = noop,
  useField = useFieldDefault,
}) => {
  const [field, , helper] = useField(name);

  const [dropdownActive, setDropdownActive] = useState(false);
  const fieldClassNames = classNames("InlineChoiceField__field", {
    "InlineChoiceField__field--active": dropdownActive,
    "InlineChoiceField__field--disabled": disabled,
  });
  const displayText =
    choices.length > 0 ? field.value?.display ?? nullChoiceText : "-";

  // Handle choice change
  const onChange = (choice: DropdownChoice) => {
    setDropdownActive(false);
    helper.setValue(choice);
    onChoiceSet(choice);
  };

  // Get dropdown arrow icon depending on field state
  const getArrow = () => {
    if (dropdownActive) {
      return (
        <FontAwesomeIcon
          className="InlineChoiceField__arrow"
          icon={faChevronUp}
          style={FA_ICON_DEFAULT_STYLE}
          data-testid="up-arrow"
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          className={
            disabled
              ? "InlineChoiceField__arrow--disabled"
              : "InlineChoiceField__arrow"
          }
          icon={faChevronDown}
          style={FA_ICON_DEFAULT_STYLE}
          data-testid="down-arrow"
        />
      );
    }
  };

  return (
    <div className="InlineChoiceField">
      <Dropdown
        choices={choices}
        setChoice={onChange}
        dropdownActive={dropdownActive}
        setDropdownActive={setDropdownActive}
        dropdownDisabled={disabled}
      >
        <div className={fieldClassNames} id={field.name}>
          <span
            className={
              disabled && displayText === nullChoiceText
                ? "InlineChoiceField__selection-text--disabled"
                : "InlineChoiceField__selection-text"
            }
          >
            {displayText}
          </span>
          <div className="InlineChoiceField__arrow-container">{getArrow()}</div>
        </div>
      </Dropdown>
    </div>
  );
};

export default InlineChoiceField;
