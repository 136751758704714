import React, { memo } from "react";
import Action from "../../common/Action";
import { TEXT_SEPARATOR } from "../../common/FancyListItem";
import { SearchSearchItemFolderFragment } from "../../generated/graphql";
import { getFolderName, getFolderUrl } from "../../utils/folders";

/**
 * The folder path portion of a folder item element subtext
 *
 * @param props - Component props
 * @param props.folder The folder that the folder item belongs to. Can be null (e.g. for
 *   the root folder)
 */
const FolderItemFolderElemNoMemo: React.FC<{
  folder: SearchSearchItemFolderFragment | null;
}> = ({ folder }) => {
  if (!folder) return null;
  return (
    <>
      in{" "}
      <span className="FancyListItem__hover-show">
        {folder.ancestorFolders
          .slice(0, -1) // Copy but exclude the root folder
          .reverse()
          .reduce<React.ReactNode[]>((acc, cur, idx) => {
            return [
              ...acc,
              <Action
                className="FancyListItem__text-action"
                linkTo={getFolderUrl(cur)}
                key={cur.id}
              >
                {getFolderName(cur)}
              </Action>,
              <span className="FancyListItem__path-separator" key={idx}></span>,
            ];
          }, [])}
      </span>
      <Action
        className="FancyListItem__text-action"
        linkTo={getFolderUrl(folder)}
        key={folder.id}
      >
        {getFolderName(folder)}
      </Action>
      {TEXT_SEPARATOR}
    </>
  );
};

export default memo(FolderItemFolderElemNoMemo);
