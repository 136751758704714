import React from "react";
import Moment from "react-moment";
import "./CopyrightFooter.scss";

const CopyrightFooter: React.FC = () => {
  return (
    <div className="CopyrightFooter">
      <p>
        © 2015–
        <Moment format="YYYY" /> Canalyst. All rights reserved.
      </p>
    </div>
  );
};

export default CopyrightFooter;
