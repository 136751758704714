import { ApolloError, useMutation } from "@apollo/client";
import React, { Context, useContext as useContextDefault } from "react";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import { Placeholder } from "../../generated/graphql";
import ModalContext, { ModalContextValue } from "../../modal/ModalContext";
import ToastContext, { ToastContextValue } from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import {
  CreatePlaceholderRequestReturnType,
  CreatePlaceholderRequestVars,
  MODEL_REQUEST_MUTATION,
} from "./PlaceholderModelRequest.graphql";

export type PlaceholderForModelRequest = Pick<
  Placeholder,
  "__typename" | "id" | "name"
>;

interface PlaceholderModelRequestProps {
  placeholder: PlaceholderForModelRequest;
}

interface PlaceholderModelRequestDeps {
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
  useToastContext?: (context: Context<ToastContextValue>) => ToastContextValue;
}

const PlaceholderModelRequest: React.FC<
  PlaceholderModelRequestProps & PlaceholderModelRequestDeps
> = ({
  placeholder,
  useModalContext = useContextDefault,
  useToastContext = useContextDefault,
}) => {
  const { clearModal } = useModalContext(ModalContext);
  const { setToast } = useToastContext(ToastContext);

  const [modelRequestMutation] = useMutation<
    CreatePlaceholderRequestReturnType,
    CreatePlaceholderRequestVars
  >(MODEL_REQUEST_MUTATION, {
    onCompleted: (data) => handleSuccess(data),
    onError: (error) => handleError(error),
  });

  const handleError = (_: ApolloError) => {
    setToast({ style: ToastStyle.Error, text: "Model Request Failed" });
  };

  const handleSuccess = (_: CreatePlaceholderRequestReturnType) => {
    setToast({
      style: ToastStyle.Info,
      text: "Model Request Successfully Sent",
    });
  };

  const handleConfirm = async () => {
    setToast({ style: ToastStyle.Info, text: "Sending Model Request" });
    clearModal();
    await modelRequestMutation({
      variables: {
        placeholderId: placeholder.id,
      },
    });
  };

  const message = `Please confirm that you would like to receive the model for
  ${placeholder.name}. We will follow up with you via email.`;

  // preventDefault is called onMouseDown events to stop the focus being removed from
  // the main page when modal buttons are clicked
  return (
    <div className="PlaceholderModelRequest">
      <p>{message}</p>
      <ButtonGroup>
        <Button
          style={ButtonStyle.TegusPrimary}
          action={handleConfirm}
          className="PlaceholderModelRequest__confirm"
          onMouseDown={(event) => event.preventDefault()}
        >
          Confirm
        </Button>
        <Button
          style={ButtonStyle.TegusSecondary}
          action={clearModal}
          className="PlaceholderModelRequest__cancel"
          onMouseDown={(event) => event.preventDefault()}
        >
          Cancel
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default PlaceholderModelRequest;
