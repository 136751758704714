import React, { useEffect } from "react";
import { HOME_PAGE, Page, SETTINGS_PAGE } from "../../common/Breadcrumbs";
import Button, { ButtonStyle, ButtonType } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import { useMfaSetupPageMeQuery } from "../../generated/graphql";
import Card from "../../layout/Card";
import routes from "../../utils/routes";
import "../api_tokens/ApiTokens.scss";
import MfaSetup from "./MfaSetup";
import "./MfaSetupPage.scss";
import { mfaSetupError, mfaSetupLoading } from "./MfaSetupSteps";

interface MfaSetupPageProps {
  setBreadcrumbs: (value: Page[]) => void;
}

export const MFA_SETUP_PAGE_PAGES = [
  HOME_PAGE,
  SETTINGS_PAGE,
  {
    isCurrent: true,
    nameOrIcon: "Multi-Factor Authentication Setup",
  },
];

const MfaSetupPage: React.FC<MfaSetupPageProps> = ({ setBreadcrumbs }) => {
  useEffect(() => {
    setBreadcrumbs(MFA_SETUP_PAGE_PAGES);
  }, [setBreadcrumbs]);
  const { loading, error, data } = useMfaSetupPageMeQuery();
  if (loading) {
    return mfaSetupLoading;
  }
  if (error) {
    return mfaSetupError;
  }
  if (data?.me.mfaSetupComplete === true) {
    return (
      <Card
        title="Multi-factor Authentication Setup"
        data-testid="setup-already"
      >
        <p>
          Multi-factor authentication is already setup on your account yet.
          Click <a href={routes.settingsMfaSetup}>here</a> to view your
          settings.
        </p>
        <ButtonGroup>
          <Button type={ButtonType.Back} style={ButtonStyle.TegusPrimary} />
        </ButtonGroup>
      </Card>
    );
  }
  return (
    <div className="MfaSetupPage">
      <MfaSetup />
    </div>
  );
};

export default MfaSetupPage;
