import { ApolloError } from "@apollo/client";
import React from "react";
import Button, { ButtonStyle } from "../common/buttons/Button";
import ButtonGroup from "../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../common/Spinner";
import { TaskType, UserSetupTaskFragment } from "../generated/graphql";
import Card from "../layout/Card";
import IpoNotifications from "./tasks/IpoNotifications";
import MfaSetupEnforced from "./tasks/MfaSetupEnforced";
import PasswordChange from "./tasks/PasswordChange";
import UserAgreement from "./tasks/UserAgreement";
import "./UserSetupDisplay.scss";

const TASK_COMPONENT_MAP: Record<TaskType, React.FC> = {
  [TaskType.PasswordChange]: PasswordChange,
  [TaskType.UserAgreement]: UserAgreement,
  [TaskType.IpoNotifications]: IpoNotifications,
  [TaskType.MfaSetup]: MfaSetupEnforced,
};

interface UserSetupDisplayProps {
  loading: boolean;
  tasks?: UserSetupTaskFragment[];
  error?: ApolloError;
}

/**
 * Render the user setup process. See UserSetup for business logic.
 */
const UserSetupDisplay: React.FC<UserSetupDisplayProps> = ({
  loading,
  tasks,
  error,
}) => {
  const Wrapper: React.FC = ({ children }) => (
    <div className="UserSetupDisplay">{children}</div>
  );

  if (loading)
    return (
      <Wrapper>
        <Spinner colour={ColourType.SECONDARY} size="3rem" />
      </Wrapper>
    );

  if (error || tasks === undefined || tasks.length === 0)
    return (
      <Wrapper>
        <UserSetupDisplayErrorMessage />
      </Wrapper>
    );

  const NextUserSetupTask = TASK_COMPONENT_MAP[tasks[0].type];
  return (
    <Wrapper>
      <NextUserSetupTask />
    </Wrapper>
  );
};

export const UserSetupDisplayErrorMessage: React.FC = () => (
  <Card title="Uh oh." data-testid="error-500">
    <p>
      Something went wrong when retrieving this page. Please try again later or{" "}
      <a href="/contact-us">contact support</a> for assistance.
    </p>
    <ButtonGroup>
      <Button href="/contact-us" style={ButtonStyle.TegusSecondary}>
        Contact Us
      </Button>
    </ButtonGroup>
  </Card>
);

export default UserSetupDisplay;
