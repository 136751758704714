import classNames from "classnames";
import React, { useMemo, useRef } from "react";
import { FolderItemEdge, ResourceDisplay } from "../generated/graphql";
import BrowseAutoScroll from "../home/browse/BrowseAutoScroll";
import ResourceDisplayItem from "./ResourceDisplayItem";
import "./ResourcesDisplay.scss";

export enum ColumnSize {
  DEFAULT = "col",
  LARGE = "col-lg",
}

export enum DisplayStyle {
  FULL_GRID = "ResourcesDisplay--full-grid",
  STACK_ROW = "ResourcesDisplay--stack-row",
  HIDE_ROW = "ResourcesDisplay--hide-row",
}

interface ResourcesDisplayProps {
  resourceDisplays: ResourceDisplay[];
  displayStyle: DisplayStyle;
  columnSize?: ColumnSize;
  itemToHighlightFiid?: string;
}

const ResourcesDisplay: React.FC<ResourcesDisplayProps> = ({
  resourceDisplays,
  displayStyle,
  itemToHighlightFiid,
  columnSize = ColumnSize.DEFAULT,
}) => {
  const itemToHighlightRef = useRef<HTMLDivElement>(null);
  const itemAtBottomRef = useRef<HTMLDivElement>(null);

  const resourceDisplayClassNames = classNames(
    "ResourcesDisplay row",
    displayStyle
  );

  const itemElements = useMemo(
    () =>
      resourceDisplays.map((resourceDisplay, index) => {
        const highlighted =
          resourceDisplay.fiid !== undefined &&
          resourceDisplay.fiid === itemToHighlightFiid;
        const ref = highlighted
          ? itemToHighlightRef
          : index + 1 === resourceDisplays.length
          ? itemAtBottomRef
          : undefined;
        return (
          <ResourceDisplayItem
            key={index}
            data-testid="resource-display-component"
            resourceDisplay={resourceDisplay}
            columnSize={columnSize}
            highlighted={highlighted}
            ref={ref}
          />
        );
      }),
    [resourceDisplays, columnSize, itemToHighlightFiid]
  );

  const edges: Pick<FolderItemEdge, "__typename">[] = [];

  return (
    <div
      className={resourceDisplayClassNames}
      data-testid="resource-display-components"
    >
      {itemElements}
      <BrowseAutoScroll
        enabled={!!itemToHighlightFiid}
        itemToHighlightRef={itemToHighlightRef}
        itemAtBottomRef={itemAtBottomRef}
        edges={edges}
        itemToHighlightFiid={itemToHighlightFiid}
      />
    </div>
  );
};

export default ResourcesDisplay;
