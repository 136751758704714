const defaultGetFileReader = () => {
  return new FileReader();
};

/**
 * Read data from specified file
 *
 * @param file File to read
 * @param getFileReader Method that returns FileReader object to use
 * @returns Promise containing data read from file (as Bbase64 DataURL)
 */
export const readFile = async (
  file: File,
  getFileReader: () => FileReader = defaultGetFileReader
): Promise<string> => {
  return new Promise((resolve) => {
    const reader = getFileReader();
    reader.addEventListener("load", () => {
      resolve(reader.result?.toString() || "");
    });
    reader.readAsDataURL(file);
  });
};

/**
 * Convert file data, name, and mimetype into `File` object
 *
 * @param data Data read from file (as base64 DataURL)
 * @param name File name
 * @param mimetype File mimetype
 * @returns File object
 */
export const convertFileData = (
  data: string,
  name: string,
  mimetype: string
): File => {
  // convert base64 to raw binary data held in a string
  const byteString = atob(data.split(",")[1]);

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uintArray = new Uint8Array(arrayBuffer);
  for (var i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i);
  }

  return new File([arrayBuffer], name, { type: mimetype });
};
