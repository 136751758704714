import { Form, FormikErrors } from "formik";
import React, { useMemo } from "react";
import Button, { ButtonStyle, ButtonType } from "../common/buttons/Button";
import {
  ALT_CONTACT,
  COVERAGE_CRITERIA,
  MODEL_REQUEST_RESPONSE_TIME,
} from "../common/copy";
import { DropdownChoice } from "../common/Dropdown";
import ChoiceField from "../forms/ChoiceField";
import FormError from "../forms/FormError";
import TextField, { TextFieldType } from "../forms/TextField";
import { ModelUpdateType, RequestKind } from "../generated/graphql";
import { ContactUsFormState } from "./ContactUs";
import "./ContactUsForm.scss";
import { MaxChars } from "./utils";

export const SupportEmailChoices: Record<RequestKind, string> = {
  MODEL_UPDATE: "I'd like to request an update of an existing model",
  ADD_SEATS: "I'd like to add additional seats",
  MODEL_REQUEST: "I'd like to place an indication of interest for a new model",
  OTHER: "Other",
};

// Exclude other deprecated ModelUpdateTypes since we will
// not support them in the SPA but they are currently supported in the MPA
export type ModelUpdateTypeSubset = Exclude<
  ModelUpdateType,
  | ModelUpdateType.AnnualGrouped
  | ModelUpdateType.DcfTab
  | ModelUpdateType.LboTab
>;

export const UpdateReasonChoices: Record<ModelUpdateTypeSubset, string> = {
  COMPANY_EARNINGS: "The company has reported earnings",
  COMPANY_ANNOUNCEMENT:
    "The company has made a major announcement or restatement between earnings",
  MODEL_IMPROVEMENT:
    "I would like to request the inclusion of additional metrics in the " +
    "model or other model improvement(s)",
  OTHER: "Other",
};

export const mapKindToDropdownChoices = (kind: Record<string, string>) => {
  return Object.entries(kind).map(([key, value]) => {
    return {
      id: key,
      display: value,
    };
  });
};
interface ContactUsFormProps {
  values: ContactUsFormState;
  errors: FormikErrors<ContactUsFormState>;
}

const ContactUsForm: React.FC<ContactUsFormProps> = ({ values, errors }) => {
  const subjectChoices = useMemo<DropdownChoice[]>(
    () => mapKindToDropdownChoices(SupportEmailChoices),
    []
  );
  const updateReasonChoices = useMemo<DropdownChoice[]>(
    () => mapKindToDropdownChoices(UpdateReasonChoices),
    []
  );
  const subjectIsChosen = values.subject !== undefined;
  const showUpdateReason = values.subject?.id === RequestKind.ModelUpdate;
  const showModelRequestNotes = values.subject?.id === RequestKind.ModelRequest;

  return (
    <div className="ContactUsForm">
      <Form>
        <div className="row">
          <div className="col">
            <div className="ContactUsForm__description">
              <p>
                Use this form to submit a customer support request and we will
                respond as soon as possible.
              </p>
              {ALT_CONTACT}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl">
            <ChoiceField
              label="Subject"
              name="subject"
              choices={subjectChoices}
              nullChoiceText="Please select one..."
            />
          </div>
          <div className="col-xl">
            {subjectIsChosen && (
              <TextField
                label="Company Name or Ticker"
                name="company"
                type="text"
                maxCharCount={MaxChars.Company}
                required={false}
                fieldType={TextFieldType.INPUT}
              />
            )}
          </div>
        </div>
        {showUpdateReason && (
          <div className="row">
            <div className="col">
              <ChoiceField
                label="Update Reason"
                name="updateReason"
                choices={updateReasonChoices}
                nullChoiceText="Please select one..."
              />
            </div>
          </div>
        )}
        {showModelRequestNotes && (
          <div className="ContactUsForm__model-request-notes row">
            <div className="col">
              <div className="ContactUsForm__alert">{COVERAGE_CRITERIA}</div>
              <div className="ContactUsForm__alert">
                {MODEL_REQUEST_RESPONSE_TIME}
              </div>
            </div>
          </div>
        )}
        {subjectIsChosen && (
          <div className="row">
            <div className="col">
              <TextField
                label="Comments"
                name="comments"
                type="text"
                maxCharCount={MaxChars.Comments}
                required={false}
                fieldType={TextFieldType.AREA}
                data-testid="comments-input"
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            {errors.formError && <FormError errorText={errors.formError} />}
          </div>
        </div>
        {subjectIsChosen && (
          <div className="row">
            <div className="col">
              <Button style={ButtonStyle.TegusPrimary} type={ButtonType.Submit}>
                Send Request
              </Button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
export default ContactUsForm;
