import { ApolloError } from "@apollo/client";
import React, { useMemo } from "react";
import Spinner, { ColourType } from "../common/Spinner";
import "./SearchDropdownDisplay.scss";
import SearchDropdownFooter from "./SearchDropdownFooter";
import SearchResult from "./SearchResult";
import { Result } from "./utils/results";

export interface SearchDropdownDisplayProps {
  error: ApolloError | undefined;
  spinning: boolean;
  results: Result[] | null;
  selectedResult: Result | null;
  setSelectedResult: (value: Result | null) => void;
}

/**
 * Component to handle the display of the search dropdown
 *
 * @param props - Component props
 * @param props.error - Search query error
 * @param props.spinning - If search results are loading (visually)
 * @param props.results - The current search results
 * @param props.selectedResult - The current selected/highlighted search result
 * @param props.setSelectedResult - Set the selected/highlighted search result
 */
const SearchDropdownDisplay: React.FC<SearchDropdownDisplayProps> = ({
  error,
  spinning,
  results,
  selectedResult,
  setSelectedResult,
}) => {
  return useMemo(() => {
    if (error) {
      return (
        <div className="SearchDropdownDisplay">
          <div className="SearchDropdownDisplay__msg" key="msg-error">
            Something went wrong…
          </div>
        </div>
      );
    } else if (spinning) {
      return (
        <div className="SearchDropdownDisplay">
          <Spinner key="spinner" colour={ColourType.SECONDARY} size="1.75rem" />
        </div>
      );
    } else if (results?.length) {
      const memo: React.ReactNode[] = results.map((result) => (
        <SearchResult
          result={result}
          selectedResult={selectedResult}
          setSelectedResult={setSelectedResult}
          key={`result-${result.id}`}
        />
      ));
      memo.push(
        <SearchDropdownFooter selectedResult={selectedResult} key="dd-footer" />
      );
      return (
        <div
          className="SearchDropdownDisplay"
          onMouseLeave={() => setSelectedResult(null)}
        >
          {memo}
        </div>
      );
    } else if (results !== null) {
      return (
        <div className="SearchDropdownDisplay">
          <div className="SearchDropdownDisplay__msg" key="msg-empty">
            No search results…
          </div>
        </div>
      );
    } else {
      return null;
    }
  }, [error, spinning, results, selectedResult, setSelectedResult]);
};

export default SearchDropdownDisplay;
