import React, {
  Context,
  useContext as useContextDefault,
  useEffect,
  useState,
} from "react";
import Button, { ButtonStyle } from "../../../common/buttons/Button";
import ButtonGroup from "../../../common/buttons/ButtonGroup";
import ModalContext, { ModalContextValue } from "../../../modal/ModalContext";
import { getCookie, setCookie } from "../../../utils/cookies";
import { features } from "../../../utils/settings";

const FEATURE_FLAG = "notificationModal";
const COOKIE_PREFIX = "dismiss-notification_";
const DISMISS_TIME = 30; // Notification dismissal expires after 30 days

const DEFAULT_DISMISS_BUTTON_COPY = "Dismiss";

export interface NotificationModalProps {
  id: string;
  dismissButtonCopy?: string;
}

export interface NotificationModalDeps {
  useModalContext?: (context: Context<ModalContextValue>) => ModalContextValue;
  features_?: typeof features;
}

/**
 * Notification modal
 *
 * Use children to add content to the modal. Eg.:
 *
 * setModal({
 *   title: 'Modal title',
 *   content: (
 *     <NotificationModal id="test">
 *       <p>Modal content</p>
 *     </NotificationModal>
 *   )
 * })
 *
 * The notification modal works the same as the notification banner, in that it sets a
 * cookie containing the id prop when dismissed and only displays the modal if the
 * cookie doesn't exist.
 *
 * You can also disable the modal entirely by setting FF_NOTIFICATION_MODAL to false
 *
 * @param id ID of notification (used to determine whether user has dismissed it)
 * @param okButtonCopy Copy to display on dismiss button
 */
const NotificationModal: React.FC<
  NotificationModalProps & NotificationModalDeps
> = ({
  id,
  dismissButtonCopy = DEFAULT_DISMISS_BUTTON_COPY,
  children,
  useModalContext = useContextDefault,
  features_ = features,
}) => {
  const { clearModal } = useModalContext(ModalContext);

  const disabled =
    !(FEATURE_FLAG in features_) || features_[FEATURE_FLAG] === false;
  const cookieId = `${COOKIE_PREFIX}${id}`;
  const [dismissed, setDismissed] = useState<boolean>(
    getCookie(cookieId) !== ""
  );

  // Do not show modal if it was dismissed previously
  useEffect(() => {
    if (dismissed || disabled) {
      clearModal();
    }
  }, [clearModal, disabled, dismissed]);

  // Dismiss modal
  const dismiss = () => {
    setCookie(cookieId, "true", DISMISS_TIME);
    setDismissed(true);
  };

  // Handle OK button
  const handleDismissButton = () => {
    dismiss();
    clearModal();
  };

  // Main modal content
  return (
    <div className="NotificationModal">
      <div className="NotificationModal__body" role="status">
        {children}
      </div>
      <ButtonGroup>
        <Button
          id="ok"
          style={ButtonStyle.TegusPrimary}
          action={handleDismissButton}
          role="button"
        >
          {dismissButtonCopy}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default NotificationModal;
