import React from "react";
import "./Steps.scss";

interface StepsProps {
  total: number;
  position: number;
}

const Steps: React.FC<StepsProps> = ({ total, position }) => {
  return (
    <div className="ml-auto mr-auto">
      {Array.from({ length: total }).map((_, i) => {
        i++;
        return (
          <span
            key={i}
            className={`Steps ${
              position === i ? "Steps__active" : "Steps__inactive"
            }`}
          ></span>
        );
      })}
    </div>
  );
};

export default Steps;
