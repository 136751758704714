import {
  faEllipsisH,
  faFileDownload,
  faFileImport,
  faHistory,
  faTrashAlt,
  faUserEdit,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Context, useContext, useMemo } from "react";
import Moment from "react-moment";
import { ReactComponent as RequestUpdate } from "../../assets/icons/request-update.svg";
import Action from "../../common/Action";
import FancyListItem, {
  SecondaryAction,
  TEXT_SEPARATOR,
} from "../../common/FancyListItem";
import ModelUpdateRequest from "../../contact_us/ModelUpdateRequest";
import ContextMenu from "../../context_menu/ContextMenu";
import { FileTypeKind, ResourceKind } from "../../generated/graphql";
import ModalContext from "../../modal/ModalContext";
import { Modal } from "../../modal/ModalDisplay";
import { HOUR_IN_MS } from "../../utils/datetime";
import { FA_ICON_DEFAULT_STYLE, getIconComponent } from "../../utils/icons";
import {
  ResourceForDl,
  useDownloadAction,
  UserForDl,
} from "../../utils/variants";
import DeleteResourceModal from "../browse/modals/DeleteResourceModal";
import ReplaceResourceModal from "../browse/modals/ReplaceResourceModal";
import WatchListModal from "../browse/modals/WatchListModal";
import { useCopyShareLink } from "../browse/utils/sharing";
import DownloadCustomVariant from "./DownloadCustomVariant";
import FolderItemFolderElem from "./FolderItemFolderElem";
import "./ResourceElem.scss";
import { GenericResource, isSearchResource } from "./utils/graphql";
import Versions from "./Versions";
import WatchListElem from "./WatchListElem";

export const RESOURCE_FILE_TYPES: { [key: string]: string } = {
  [FileTypeKind.DropinTemplate]: "Drop-in Template",
  [FileTypeKind.Dashboard]: "Dashboard",
  [FileTypeKind.Executable]: "Executable",
  [FileTypeKind.GuidePdf]: "Guide/PDF",
  [FileTypeKind.Other]: "Other",
};

interface ResourceElemProps {
  resource: GenericResource;
  user: UserForDl;
  highlighted?: boolean;
}
interface ResourceElemDeps {
  useDownloadAction_?: typeof useDownloadAction;
  useCopyShareLink_?: typeof useCopyShareLink;
  useContext_?: <T>(context: Context<T>) => T;
}

export const DOWNLOAD_A_PREVIOUS_VERSION = "Download a Previous Version";
export const SEE_ALL_VERSIONS = "See all versions";
export const MODEL_UPDATE_REQUEST = "Model Update Request";
export const REQUEST_AN_UPDATE = "Request an Update";
export const DOWNLOAD_A_DIFFERENT_MODEL_FORMAT =
  "Download a Different Model Format";
export const NO_ALTERNATIVE_FORMATS_CURRENTLY_AVAILABLE =
  "No Alternative Formats Currently Available";
export const DOWNLOAD_A_DIFFERENT_FORMAT = "Download a Different Format";
export const REMOVE_FROM_WATCH_LIST = "Remove From Watchlist";
export const ADD_TO_WATCH_LIST = "Add to Watchlist";
export const EDIT_WATCH_LIST = "Edit Watchlist";
export const EDIT_USERS_WATCHING = "Edit users watching";
export const REPLACE_RESOURCE_FILE = "Replace resource file";
export const DELETE_RESOURCE_FILE = "Delete resource file";
export const DELETE_RESOURCE = "Delete resource";

const ResourceElem = React.forwardRef<
  HTMLDivElement,
  ResourceElemProps & ResourceElemDeps
>(
  (
    {
      resource,
      user,
      highlighted,
      useDownloadAction_ = useDownloadAction,
      useCopyShareLink_ = useCopyShareLink,
      useContext_ = useContext,
    },
    ref
  ) => {
    const download = useDownloadAction_();
    const copyShareLink = useCopyShareLink_();
    const { setModal } = useContext_(ModalContext);

    const subtext = useMemo(() => {
      return (
        <>
          {isSearchResource(resource) && (
            <FolderItemFolderElem folder={resource.folder} />
          )}
          Updated{" "}
          <Moment fromNowDuring={24 * HOUR_IN_MS}>
            {resource.updateEventDate
              ? resource.updateEventDate
              : resource.updated}
          </Moment>
          {resource.mostRecentQuarter && ` for ${resource.mostRecentQuarter}`}
          {resource.updateTypeDisplay && (
            <>
              {TEXT_SEPARATOR}
              {resource.updateTypeDisplay}
            </>
          )}
          {user.staff && resource.fileType && (
            <>
              {TEXT_SEPARATOR}
              {RESOURCE_FILE_TYPES[resource.fileType]}
            </>
          )}
          {user.staff && resource.isCustom && (
            <>
              {TEXT_SEPARATOR}
              Custom
            </>
          )}
        </>
      );
    }, [resource, user]);
    const secondaryActions = useMemo(() => {
      return getSecondaryActions(resource, user, download, setModal);
    }, [resource, user, download, setModal]);

    return (
      <ContextMenu
        items={
          <>
            <Action
              action={() => copyShareLink(resource)}
              data-testid="action-copy-link"
            >
              Copy Link
            </Action>
          </>
        }
      >
        <FancyListItem
          icon={getIconComponent(resource.icon)}
          text={resource.name}
          subtext={[subtext]}
          action={() => download(resource, user, true)}
          secondaryActions={secondaryActions}
          highlighted={highlighted}
          ref={ref}
        />
      </ContextMenu>
    );
  }
);

const getSecondaryActions = (
  resource: GenericResource,
  user: UserForDl,
  download: (
    resource: ResourceForDl,
    user: UserForDl,
    latest_version?: boolean
  ) => void,
  setModal: (modal: Modal) => void
): SecondaryAction[] => {
  const requiredSecondaryActions: SecondaryAction[] = [
    ...(resource.kind === ResourceKind.Model
      ? [
          {
            icon: (
              <RequestUpdate className="ResourceElem__request-update-icon" />
            ),
            id: "update-request",
            action: () =>
              setModal({
                title: MODEL_UPDATE_REQUEST,
                content: <ModelUpdateRequest resource={resource} />,
              }),
            tooltipText: REQUEST_AN_UPDATE,
          },
        ]
      : []),
    {
      icon: (
        <FontAwesomeIcon icon={faFileDownload} style={FA_ICON_DEFAULT_STYLE} />
      ),
      id: "resource-download",
      action: () => download(resource, user, true),
      tooltipText: "Download",
    },
    ...(resource.kind === ResourceKind.Model
      ? [
          ...(user.staff ? getModelStaffActions(resource, user, setModal) : []),
          {
            icon: (
              <FontAwesomeIcon
                icon={faEllipsisH}
                style={FA_ICON_DEFAULT_STYLE}
              />
            ),
            id: "custom-variant",
            action: () =>
              setModal({
                title: DOWNLOAD_A_DIFFERENT_MODEL_FORMAT,
                content: (
                  <DownloadCustomVariant resource={resource} user={user} />
                ),
              }),
            disabled: resource.variants.length <= 1,
            tooltipText:
              resource.variants.length <= 1
                ? NO_ALTERNATIVE_FORMATS_CURRENTLY_AVAILABLE
                : DOWNLOAD_A_DIFFERENT_FORMAT,
          },
        ]
      : []),
    ...(resource.kind === ResourceKind.Other
      ? [
          ...(user.staff
            ? getResourceFileStaffActions(resource, user, setModal)
            : []),
        ]
      : []),
  ];
  if (resource.watchListed !== null) {
    requiredSecondaryActions.unshift({
      icon: <WatchListElem resource={resource} />,
      id: "watch-list-elem",
      tooltipText: resource.watchListed
        ? REMOVE_FROM_WATCH_LIST
        : ADD_TO_WATCH_LIST,
    });
  }

  return requiredSecondaryActions;
};

const getModelStaffActions = (
  resource: ResourceForDl,
  user: UserForDl,
  setModal: (modal: Modal) => void
): SecondaryAction[] => {
  return [
    {
      icon: <FontAwesomeIcon icon={faHistory} style={FA_ICON_DEFAULT_STYLE} />,
      id: "versions",
      action: () =>
        setModal({
          title: DOWNLOAD_A_PREVIOUS_VERSION,
          content: <Versions resource={resource} user={user} />,
        }),
      tooltipText: SEE_ALL_VERSIONS,
    },
    ...(user.djangoGlobalPerms.includes("files.add_filefavourite") &&
    user.djangoGlobalPerms.includes("files.change_filefavourite")
      ? [
          {
            icon: (
              <FontAwesomeIcon
                icon={faUserEdit}
                style={FA_ICON_DEFAULT_STYLE}
              />
            ),
            id: "watch-list",
            action: () => {
              setModal({
                title: EDIT_WATCH_LIST,
                content: <WatchListModal resource={resource} />,
                noScroll: true,
              });
            },
            tooltipText: EDIT_USERS_WATCHING,
          },
        ]
      : []),
  ];
};

const getResourceFileStaffActions = (
  resource: GenericResource,
  user: UserForDl,
  setModal: (modal: Modal) => void
): SecondaryAction[] => {
  return [
    ...(user.djangoGlobalPerms.includes("files.change_resourcefile")
      ? [
          {
            icon: (
              <FontAwesomeIcon
                icon={faFileImport}
                style={FA_ICON_DEFAULT_STYLE}
              />
            ),
            id: "replace-resource",
            action: () => {
              setModal({
                title: REPLACE_RESOURCE_FILE,
                content: <ReplaceResourceModal resourceFile={resource} />,
                noScroll: true,
              });
            },
            tooltipText: REPLACE_RESOURCE_FILE,
          },
        ]
      : []),
    {
      icon: <FontAwesomeIcon icon={faHistory} style={FA_ICON_DEFAULT_STYLE} />,
      id: "versions",
      action: () => {
        setModal({
          title: DOWNLOAD_A_PREVIOUS_VERSION,
          content: <Versions resource={resource} user={user} />,
        });
      },
      tooltipText: SEE_ALL_VERSIONS,
    },

    ...(user.djangoGlobalPerms.includes("files.delete_resourcefile")
      ? [
          {
            icon: (
              <FontAwesomeIcon
                icon={faTrashAlt}
                style={FA_ICON_DEFAULT_STYLE}
              />
            ),
            id: "delete-resource-file",
            action: () => {
              setModal({
                title: DELETE_RESOURCE_FILE,
                content: <DeleteResourceModal resourceFile={resource} />,
              });
            },
            tooltipText: DELETE_RESOURCE,
          },
        ]
      : []),
  ];
};

export default ResourceElem;
