import React from "react";

const ApiTokensDescription: React.FC = () => {
  return (
    <div className="ApiTokens__description">
      <p>
        API tokens are used to authenticate with the Model Data System API. When
        making requests, include the token in the Authorization header using the
        Bearer scheme: <code>Authorization: Bearer &lt;token&gt;</code>.
      </p>
    </div>
  );
};

export default ApiTokensDescription;
