import Action from "../../common/Action";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import { ActionType, Promo } from "../../generated/graphql";
import Card from "../../layout/Card";

export type PromoForPromoDisplay = Pick<
  Promo,
  | "title"
  | "description"
  | "imageUrl"
  | "imageAltText"
  | "action"
  | "__typename"
>;
interface PromoDisplayProps {
  promo: PromoForPromoDisplay;
}

const PromoDisplay: React.FC<PromoDisplayProps> = ({ promo }) => {
  const linkArgs =
    promo.action.type === ActionType.ExternalLink
      ? { href: promo.action.link }
      : { linkTo: promo.action.link };
  return (
    <Card>
      <Action {...linkArgs} data-testid="promo-action">
        <img
          src={promo.imageUrl}
          alt={promo.imageAltText}
          data-testid="promo-img"
        />
        <h4 data-testid="promo-title">{promo.title}</h4>
      </Action>
      <p data-testid="promo-descr">{promo.description}</p>
      <ButtonGroup>
        <Button
          style={ButtonStyle.TegusTertiaryAlt}
          data-testid="promo-button"
          {...linkArgs}
        >
          {promo.action.text}
        </Button>
      </ButtonGroup>
    </Card>
  );
};

export default PromoDisplay;
