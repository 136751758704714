import { Form, Formik, FormikHelpers } from "formik";
import React, { useCallback, useContext } from "react";
import Button, {
  ButtonState,
  ButtonStyle,
  ButtonType,
} from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import FormError from "../../forms/FormError";
import TextField from "../../forms/TextField";
import {
  MfaSetupFormEnableMfaMutationVariables,
  useMfaSetupFormEnableMfaMutation,
} from "../../generated/graphql";
import ModalContext from "../../modal/ModalContext";
import { noop } from "../../utils/functools";
import { redirect } from "../../utils/location";
import routes from "../../utils/routes";
import "../api_tokens/ApiTokens.scss";
import BackupTokens from "./BackupTokens";
import { MfaEnforcedContext } from "./MfaSetup";
import "./MfaSetupForm.scss";

export const INCORRECT_TOKEN_LEN_MSG = "A six digit MFA token is required";
export const BACKUP_TOKENS_MODAL_TITLE =
  "Multi-Factor Authentication Backup Tokens Generated";

interface MfaSetupFormDeps {
  redirect_?: (url: string) => void;
  useContext_?: typeof useContext;
}
const MfaSetupForm: React.FC<MfaSetupFormDeps> = ({
  redirect_ = redirect,
  useContext_ = useContext,
}) => {
  const { setModal } = useContext_(ModalContext);
  const { mfaEnforced } = useContext(MfaEnforcedContext);
  const [enableMfa, { error: mutationError }] =
    useMfaSetupFormEnableMfaMutation({
      onCompleted: () =>
        mfaEnforced ? showBackupTokens() : redirect_(routes.settingsMfa),
    });
  const initialValues = { token: "", formError: "" };
  const showBackupTokens = () => {
    setModal({
      title: BACKUP_TOKENS_MODAL_TITLE,
      content: <BackupTokens />,
    });
  };
  const onSubmit = useCallback(
    async (
      values: MfaSetupFormEnableMfaMutationVariables,
      actions: FormikHelpers<{ token: string; formError: string }>
    ) => {
      if (values.token.toString().length !== 6) {
        actions.setErrors({
          formError: INCORRECT_TOKEN_LEN_MSG,
        });
      } else {
        // Catch errors to prevent uncaught promise exceptions:
        // https://github.com/formium/formik/issues/1580
        await enableMfa({ variables: { token: values.token } }).catch(noop);
      }
    },
    [enableMfa]
  );
  return (
    <div className="MfaSetupForm">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(props) => (
          <Form>
            <TextField
              label="MFA Token"
              name="token"
              type="string"
              maxCharCount={6}
              required={true}
              disabled={props.isSubmitting}
              maxLength={6}
            />
            <FormError
              errorText={props.errors.formError || mutationError?.message}
            />
            <ButtonGroup>
              <Button
                state={props.isSubmitting ? ButtonState.Disabled : undefined}
                style={ButtonStyle.TegusPrimary}
                type={ButtonType.Submit}
              >
                Verify
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MfaSetupForm;
