import React from "react";
import { useLocation } from "react-router";
import { Redirect } from "react-router-dom";

const TrailingSlashRedirect: React.FC = () => {
  const location = useLocation();
  if (location.pathname.endsWith("/")) {
    const standardLocation =
      location.pathname.slice(0, -1) + location.search + location.hash;
    return <Redirect to={standardLocation} />;
  } else {
    return null;
  }
};

export default TrailingSlashRedirect;
