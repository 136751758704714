import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

interface SimpleTableProps {
  header: string[];
  values: any[][];
}

/**
 * Create a simple MUI table from the values provided
 *
 * @param header - The column names of the table
 * @param values - The values from which to create a table
 * @returns a simple MUI Table component
 */
const SimpleTable: React.FC<SimpleTableProps> = ({ header, values }) => {
  return (
    <div className="SimpleTable">
      <Table>
        <TableHead>
          <TableRow>
            {header.map((columnName, index) => (
              <TableCell key={index}>{columnName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row, index) => {
            return (
              <TableRow key={index}>
                {row.map((cell, index) => {
                  return <TableCell key={index}>{cell}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default SimpleTable;
