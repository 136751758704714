import CreateShortUrl from "./CreateShortUrl";
import MyShortUrls from "./MyShortUrls";
import "./UrlShortener.scss";

const UrlShortener: React.FC = () => {
  return (
    <div className="UrlShortener">
      <CreateShortUrl />
      <MyShortUrls />
    </div>
  );
};

export default UrlShortener;
