import React, { useMemo, useState } from "react";
import ModalContext from "./ModalContext";
import { Modal } from "./ModalDisplay";

const ModalProvider: React.FC = ({ children }) => {
  const [currentModal, setCurrentModal] = useState<Modal | null>(null);
  const modalContextValue = useMemo(() => {
    return {
      currentModal: currentModal,
      clearModal: () => setCurrentModal(null),
      setModal: setCurrentModal,
    };
  }, [currentModal]);

  return (
    <ModalContext.Provider value={modalContextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
