import {
  ApolloCache,
  ApolloClient,
  ApolloError,
  useApolloClient,
} from "@apollo/client";
import React, { useContext, useMemo, useRef } from "react";
import Button, { ButtonStyle } from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Spinner, { ColourType } from "../../common/Spinner";
import {
  BackupTokensUpdateMfaBackupTokensMutation,
  useBackupTokensMyMfaBackupTokensQuery,
  useBackupTokensUpdateMfaBackupTokensMutation,
  UserSetupUserSetupDocument,
} from "../../generated/graphql";
import Card from "../../layout/Card";
import ModalContext from "../../modal/ModalContext";
import ToastContext from "../../toast/ToastContext";
import { ToastStyle } from "../../toast/ToastDisplay";
import { noop } from "../../utils/functools";
import routes from "../../utils/routes";
import "../api_tokens/ApiTokens.scss";
import BackupTokenButtons from "./BackupTokenButtons";
import "./BackupTokens.scss";
import BackupTokensModal from "./BackupTokensModal";
import { MfaEnforcedContext } from "./MfaSetup";

interface BackupTokensProps {
  useContext_?: typeof useContext;
  useInitialBackupTokens_?: typeof useInitialBackupTokens;
  apolloClient?: () => ApolloClient<any>;
}
const BackupTokens: React.FC<BackupTokensProps> = ({
  useContext_ = useContext,
  useInitialBackupTokens_ = useInitialBackupTokens,
  apolloClient = useApolloClient,
}) => {
  const { setModal } = useContext_(ModalContext);
  const { setToast } = useContext_(ToastContext);
  const { mfaEnforced } = useContext(MfaEnforcedContext);
  const refetch = apolloClient();
  const tokensRef = useRef<HTMLDivElement>(null);
  const setBackupTokensModal = () => {
    setModal({
      title: "New MFA Backup Codes Generated",
      content: <BackupTokensModal />,
    });
  };
  const mfaSetupStepComplete = async () => {
    await refetch.refetchQueries({
      include: [UserSetupUserSetupDocument],
    });
  };
  const [updateMfaTokens, { loading: mutationLoading, data: mutationData }] =
    useBackupTokensUpdateMfaBackupTokensMutation({
      onCompleted: () => setBackupTokensModal(),
      onError: () =>
        setToast({
          style: ToastStyle.Error,
          text: "Failed to generate new codes",
        }),
      update: (cache, { data }) => updateTokensCache(cache, data),
    });
  const {
    loading: initialLoading,
    error: initialError,
    tokens: initialTokens,
  } = useInitialBackupTokens_(mfaEnforced ? noop : setBackupTokensModal);
  const tokens =
    mutationData?.updateMfaBackupTokens?.mfaBackupTokens || initialTokens;
  const content = useMemo(() => {
    if (initialLoading || mutationLoading) {
      return (
        <div className="BackupTokens BackupTokens--loading">
          <Spinner colour={ColourType.SECONDARY} size="3rem" />
        </div>
      );
    } else if (initialError || !initialTokens) {
      return (
        <Card title="Uh oh." data-testid="error-500">
          <p>
            Something went wrong when retrieving your backup tokens. Please try
            again later or <a href={routes.support}>contact support</a> for
            assistance.
          </p>
          <ButtonGroup>
            <Button linkTo={routes.settings} style={ButtonStyle.TegusPrimary}>
              Back
            </Button>
            <Button href={routes.support} style={ButtonStyle.TegusSecondary}>
              Contact Us
            </Button>
          </ButtonGroup>
        </Card>
      );
    } else {
      return (
        <div ref={tokensRef}>
          <ul>
            <pre>
              {tokens?.map((token) => (
                <li data-testid="token">{token}</li>
              ))}
            </pre>
          </ul>
        </div>
      );
    }
  }, [initialLoading, mutationLoading, initialError, initialTokens, tokens]);
  return (
    <div className="BackupTokens">
      <Card tegus>
        <div>
          <Card data-testid="reset-card">
            {!mfaEnforced && <h4>Multi-Factor Authentication Backup Codes</h4>}
            <p>
              Important note: We recommend that you copy, download, or print
              these backup codes and keep them in a safe place.
            </p>
            <p>
              Use these backup codes to log in to your Canalyst account if you
              cannot access the authenticator app on your phone. Each code can
              only be used once. Here are your remaining valid codes:
            </p>
            {content}
          </Card>
          {tokens ? (
            <BackupTokenButtons
              tokens={tokens}
              tokensRef={tokensRef}
              generateNewTokens={updateMfaTokens}
              onFinish={mfaSetupStepComplete}
            />
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export const useInitialBackupTokens = (
  onTokensCreated: () => void
): {
  loading: boolean;
  error?: ApolloError;
  tokens?: string[];
} => {
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useBackupTokensMyMfaBackupTokensQuery({
    onCompleted: () => handleQuerySuccess(),
  });
  // If no onError method is provided to a mutation then errors will be directly
  // raised as opposed to being included in the response
  // https://github.com/apollographql/react-apollo/issues/2737#issuecomment-457927422
  const [
    updateMfaTokens,
    {
      loading: mutationLoading,
      error: mutationError,
      data: mutationData,
      called: mutationCalled,
    },
  ] = useBackupTokensUpdateMfaBackupTokensMutation({
    onCompleted: onTokensCreated,
    onError: () => noop,
    update: (cache, { data }) => updateTokensCache(cache, data),
  });
  const queryTokens = queryData?.myMfaBackupTokens || undefined;
  const handleQuerySuccess = () => {
    if (!mutationCalled && queryTokens?.length === 0) {
      updateMfaTokens();
    }
  };
  if (mutationCalled) {
    const newTokens =
      mutationData?.updateMfaBackupTokens?.mfaBackupTokens || undefined;
    return {
      loading: mutationLoading,
      error: mutationError,
      tokens: newTokens,
    };
  }
  // The length of queryTokens is checked because there is a gap between when
  // the query returns without tokens and mutationCalled is set to true
  if (queryLoading || queryTokens?.length === 0) {
    return {
      loading: true,
      error: undefined,
      tokens: undefined,
    };
  }
  if (queryError) {
    return {
      loading: false,
      error: queryError,
      tokens: undefined,
    };
  }
  return {
    loading: false,
    error: undefined,
    tokens: queryTokens,
  };
};

const updateTokensCache = (
  cache: ApolloCache<BackupTokensUpdateMfaBackupTokensMutation>,
  data?: BackupTokensUpdateMfaBackupTokensMutation | null
) => {
  cache.modify({
    fields: {
      myMfaBackupTokens() {
        return data?.updateMfaBackupTokens?.mfaBackupTokens;
      },
    },
  });
};

export default BackupTokens;
