import React, { memo } from "react";
import Action from "../../common/Action";
import FancyListItem from "../../common/FancyListItem";
import ContextMenu from "../../context_menu/ContextMenu";
import { getIconComponent } from "../../utils/icons";
import routes from "../../utils/routes";
import { pluralize } from "../../utils/strings";
import { useCopyShareLink } from "../browse/utils/sharing";
import FolderItemFolderElem from "./FolderItemFolderElem";
import { GenericFolder, isSearchFolder } from "./utils/graphql";

interface SubFolderElemNoMemoProps {
  folder: GenericFolder;
  highlighted?: boolean;
}
interface SubFolderElemNoMemoDeps {
  useCopyShareLink_?: typeof useCopyShareLink;
}

const SubFolderElemNoMemo = React.forwardRef<
  HTMLDivElement,
  SubFolderElemNoMemoProps & SubFolderElemNoMemoDeps
>(({ folder, highlighted, useCopyShareLink_ = useCopyShareLink }, ref) => {
  const copyShareLink = useCopyShareLink_();
  return (
    <ContextMenu
      items={
        <>
          <Action
            action={() => copyShareLink(folder)}
            data-testid="action-copy-link"
          >
            Copy Link
          </Action>
        </>
      }
    >
      <FancyListItem
        icon={getIconComponent(folder.icon)}
        text={folder.name}
        subtext={[
          <>
            {isSearchFolder(folder) && (
              <FolderItemFolderElem folder={folder.parentFolder || null} />
            )}
            {folder.itemCountRecursive}{" "}
            {pluralize(folder.itemCountRecursive, "file")}
          </>,
        ]}
        linkTo={routes.companies(folder.path)}
        highlighted={highlighted}
        ref={ref}
      />
    </ContextMenu>
  );
});

export default memo(SubFolderElemNoMemo);
