import {
  Event,
  EventHint,
  init as initOriginalDefault,
  setUser as setUserDefault,
  showReportDialog as showReportDialogDefault,
} from "@sentry/browser";
import { CaptureContext } from "@sentry/types";
import { getUserFromJwt as getUserFromJwtDefault } from "./auth";
import settings, { RELEASE_VERSION_UNKNOWN } from "./settings";

export const SKIP_DIALOG_CONTEXT: CaptureContext = {
  contexts: { canalyst: { skipReportDialog: true } },
} as const;

/**
 * Initialize Sentry. Arguments are used for dependency injection (use defaults)
 */
export const init = ({
  dsn = settings.sentryDsn,
  releaseVersion = settings.sentryReleaseVersion,
  beforeSend = beforeSendDefault,
  getUserFromJwt = getUserFromJwtDefault,
  initOriginal = initOriginalDefault,
  setUser = setUserDefault,
} = {}) => {
  const releaseVersionDisplay = releaseVersion || RELEASE_VERSION_UNKNOWN;
  console.log(`Web Portal version ${releaseVersionDisplay}`);

  try {
    initOriginal({
      dsn: dsn || undefined,
      release: releaseVersion || undefined,
      beforeSend,
    });
  } catch (err) {
    console.warn(`Initializing Sentry failed: ${err}`);
    return;
  }

  const { user } = getUserFromJwt();
  setUser(
    user
      ? {
          ...user,
          id: user.user_uuid,
        }
      : null
  );
};

/**
 * A handler to be called before any event is sent to Sentry
 *
 * In our case, we show a report dialog to the user. This can be disabled by
 * adding a canalyst context of { skipReportDialog: true } to an event. E.g.
 *
 *   >>> Sentry.captureException(err, {
 *   ...   contexts: { canalyst: { skipReportDialog: true } },
 *   ... })
 *
 * @param event - The Sentry event, provided by Sentry
 *
 * @returns The Sentry event; same as the event param
 */
export const beforeSendDefault = (
  event: Event,
  hint?: EventHint,
  showReportDialog = showReportDialogDefault
) => {
  if (event.exception && !event.contexts?.canalyst?.skipReportDialog) {
    showReportDialog({ eventId: event.event_id });
  }
  return event;
};
